import { Suspense, useState } from 'react';
import * as React from 'react';
import FeatherIcon from 'feather-icons-react';
import {
    Card,
    CardContent,
    Typography,
    TableHead,
    Box,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
} from '@mui/material';
import Spinner from '../../../../views/spinner/Spinner';
import NewVaccination from '../../PatientDetail/NewVaccination';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getVaccinationDetail, getVaccineHistoryByAge } from '../../../../redux/patients/AsyncActions';

const VaccineHistory = ({ vaccineData }) => {
    const dispatch = useDispatch()
    const { state } = useLocation();
    const { id } = state ? state : {};
    const [openNewVaccine, setopenNewVaccine] = useState(false)
    const [vaccineToUpdate, setvaccineToUpdate] = useState(false)

    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)

    const openAddVaccineHandler = (val) => {
        setopenNewVaccine(val)
        if (val === false) {
            dispatch(getVaccineHistoryByAge(id))
        }
    }
    const getVaccineDetailHandler = async (vaccinationId) => {
        let result = await dispatch(getVaccinationDetail(vaccinationId))
        if (result && result.status === true) {
            setvaccineToUpdate(result.data)
            setopenNewVaccine(true)
        }
    }
    function Row(props) {
        const { row, rowAge } = props;
        return (
            <>
                <TableRow>
                    <TableCell>
                        <Typography variant="h5" fontWeight="400">
                            {rowAge && rowAge !== '' ? rowAge : '-'}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        {row
                            ? row.map((it, i) => {
                                return <Typography variant="h5" fontWeight="400" className="borderdDiv" display="flex" justifyContent="space-between">
                                    {it.vaccination && it.vaccination !== null && it.vaccination !== '' ? it.vaccination : '-'}
                                    &nbsp;{userinfo?.role !== 'admin' && <Tooltip title='Edit vaccination'>
                                        <span>
                                            <FeatherIcon icon={'edit'} width="12" height='12px' onClick={() => { getVaccineDetailHandler(it.vaccinationId) }} />
                                        </span>
                                    </Tooltip>}
                                </Typography>

                            }) : <Typography variant="h5" fontWeight="400">-</Typography>
                        }
                    </TableCell>
                    <TableCell>
                        {row
                            ? row.map((it, i) => {
                                return <Typography variant="h5" fontWeight="400" className="borderdDiv">
                                    {it.batchNumber && it.batchNumber !== null && it.batchNumber !== '' ? (it.batchNumber) : '-'} </Typography>
                            }) : <Typography variant="h5" fontWeight="400">-</Typography>
                        }

                    </TableCell>
                    <TableCell>
                        {row
                            ? row.map((it, i) => {
                                return <Typography variant="h5" fontWeight="400" className="borderdDiv">
                                    {it.vaccinationDate ? it.vaccinationDate : it.createdAt}
                                    {/* if vaccination date is not present then show created date because imported data dont have vaccination date */} </Typography>
                            }) : <Typography variant="h5" fontWeight="400">-</Typography>
                        }
                    </TableCell>
                </TableRow>
            </>
        );
    }

    return (<div className="vaccineHistory" style={{ width: '100%' }}>
        {/* <Spinner /> */}
        <Suspense fallback={<Spinner />}>
            <Card className='vaccineHistoryCard'>
                <CardContent>
                    <Box
                        sx={{
                            overflow: {
                                xs: 'auto',
                                sm: 'auto',
                                md: 'auto',

                            },
                        }}
                    >

                        <Table
                            aria-label="custom pagination table"
                            sx={{
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h5" fontWeight='600' >Age</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h5" fontWeight='600' >Immunization</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h5" fontWeight='600' >Batch No.</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h5" fontWeight='600' >Date</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {vaccineData && Object.keys(vaccineData).map((m, i) => {
                                    return <Row key={i} row={vaccineData[m]} rowAge={m} />
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                    {openNewVaccine && <NewVaccination changeopenNewVaccine={openAddVaccineHandler} updateVaccineData={vaccineToUpdate} />}
                </CardContent>
            </Card>
        </Suspense>
    </div>
    );
};

export default VaccineHistory;
