import axios from 'axios';
import { URL } from '../config';

const request = axios.create({
  baseURL: URL,
});

request.interceptors.request.use((config) => {
  let token = sessionStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export default request;
