import {
    CONFIRMATION_MODAL_DATA,
} from '../constants';

const INIT_STATE = {
    showModal: false,
    modalContent: "",
    button1: false,
    button2: false,
    buttonText1: '',
    buttonText2: '',
    buttonAction1: () => { },
    buttonAction2: () => { },
    origin: ""
};

const ConfirmationModalReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONFIRMATION_MODAL_DATA:
            return {
                ...state,
                showModal: action.isShow,
                modalContent: action.data.modalContent,
                button1: action.data.button1,
                button2: action.data.button2,
                buttonText1: action.data.buttonText1,
                buttonText2: action.data.buttonText2,
                buttonAction1: action.data.buttonAction1,
                buttonAction2: action.data.buttonAction2,
                origin: action.data.origin,
            };
        default:
            return state;
    }
};
export default ConfirmationModalReducer
