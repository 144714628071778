import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

import { Box, Button } from '@mui/material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const FilteredDateRange = ({
  open,
  toggle,
  updateFilterDate,
  currStart,
  currEnd,
}) => {
  const [startDate, setStartDate] = useState(currStart);
  const [endDate, setEndDate] = useState(currEnd);
  const onSubmitHandler = () => {
    updateFilterDate(startDate, endDate);
    toggle();
  };
  const onRemoveFilter = () => {
    setStartDate('');
    setEndDate('');
    updateFilterDate('', '');
    toggle();
  };
  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={toggle}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <label>Start date</label>
              <input
                value={startDate}
                type='date'
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Box>
            <Box>
              <label>End date</label>
              <input
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                type='date'
              />
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: '10px',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <Button
              disabled={!startDate || !endDate}
              variant='contained'
              color='primary'
              onClick={onSubmitHandler}
            >
              Submit
            </Button>
            <Button
              disabled={!startDate || !endDate}
              variant='outlined'
              color='primary'
              onClick={onRemoveFilter}
            >
              Remove filter
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default FilteredDateRange;
