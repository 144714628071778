import {
    CONFIRMATION_MODAL_DATA,
} from '../constants';



export const showConfirmationModalAction = (isShow, data) => ({
    type: CONFIRMATION_MODAL_DATA,
    isShow, data
});
