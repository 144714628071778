import React, { useMemo, useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardContent, Typography } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import MonthIcon from '../../assets/icons/MonthIcon.png'
import moment from 'moment/moment';
import {
    SHOW_SPINNER,
} from '../../redux/constants';

const CommonDiagnoses = () => {

    const dispatch = useDispatch()

    // const theme = useTheme(); 
    const mostCommonDiagnoses = useSelector(state => state.ReportReducer.mostCommonDiagnosis)
    const [patientAgesArray, setPatientAgesArray] = useState([]);
    const options = {}
    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const [chartData, setChartData] = useState({
        series: [1547, 71, 67, 46, 41, 40, 38, 38],
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: [],
            colors: ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#4caf50', '#ff9800'],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
        },
    });

    useEffect(async () => {
        if (mostCommonDiagnoses.length > 0) {
            var diagnosisInformantArray = mostCommonDiagnoses.map((item, index) => { return item.diagnosisInformant });
            var diagnosisInformantValueArray = mostCommonDiagnoses.map((item, index) => { return item.count });
            setChartData(prevChartData => ({
                ...prevChartData,
                series: diagnosisInformantValueArray,
                options: {
                    labels: diagnosisInformantArray,
                }
            }));
        }
    }, [mostCommonDiagnoses])

    return (
        <Card
            sx={{
                p: {
                    xs: '10px',
                    sm: '10px',
                    lg: '20px',
                },
                width: '100%'
            }}
        >
            <CardContent>
                <Typography sx={{
                    flex: '1 1 100%', p: {
                        xs: '10px',
                        sm: '10px',
                        lg: '10px',
                    },
                    color: '#464646',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px'
                }} variant="h4" id="" fontWeight="500" component="div">
                    <img src={MonthIcon} className="tabIcon" alt="logoImg" />
                    Most Common Diagnoses
                </Typography>
                {
                    mostCommonDiagnoses.length > 0 &&
                    <Chart
                        options={chartData.options}
                        series={chartData.series}
                        type="pie"
                        height="400"
                    // width="380"
                    />
                }

            </CardContent>
        </Card>
    );
};

export default CommonDiagnoses;
