import { Box, Typography } from '@mui/material';
import React from 'react';

const MedicationPreviewNew = ({ data }) => {
  return (
    <div className='observationtabpanelContent'>
      <Typography className='' color='' variant='h5' fontWeight='500'>
        {' '}
        Medication
      </Typography>
      <Box sx={{ width: '100%' }}>
        {Object.keys(data)
          .filter((key) => data[key])
          .map((item) => (
            <Typography variant='h5' fontWeight='400' component='li'>
              {item}
              {item === 'other' && ' - ' + data.other}
            </Typography>
          ))}
      </Box>
    </div>
  );
};

export default MedicationPreviewNew;
