import {
    SEARCH_PATIENTS,
    UPDATE_PATIENT,
    DELETE_PATIENT,
    ADD_PATIENT,
    SAVE_OBSERVATION,
    SAVE_ALLERGY,
    SAVE_VACCINATION,
    SAVE_EXAMINATION,
    SAVE_GROWTH,
    SAVE_DOCUMENTUPLOAD,
    SAVE_DIAGNOSIS,
    TAB_NO,
    MOBILEVISITSIDEBAROPEN,
    ADD_PATIENT_EMERGENCY_CONTACT,
    UPDATE_PATIENT_EMERGENCY_CONTACT,
    GET_PATIENT_EMERGENCY_CONTACT,
    DELETE_PATIENT_EMERGENCY_CONTACT
} from '../constants';

export const patientSearch = (searchTerm) => ({
    type: SEARCH_PATIENTS,
    searchTerm,
});
export const deletePatient = (id) => ({
    type: DELETE_PATIENT,
    id,
});
export const updatePatient = (id, field, value) => ({
    type: UPDATE_PATIENT,
    id,
    field,
    value,
});

export const addPatient = (data) => ({
    type: ADD_PATIENT,
    data
});

export const saveObservationData = (data) => ({
    type: SAVE_OBSERVATION, data
})
export const saveAllergyData = (data) => ({
    type: SAVE_ALLERGY, data
})
export const saveVaccinationData = (data) => ({
    type: SAVE_VACCINATION, data
})
export const saveExaminationData = (data) => ({
    type: SAVE_EXAMINATION, data
})
export const saveGrowthData = (data) => ({
    type: SAVE_GROWTH, data
})
export const saveDocumentData = (data) => ({
    type: SAVE_DOCUMENTUPLOAD, data
})
export const saveDiagnosisData = (data) => ({
    type: SAVE_DIAGNOSIS, data
})
export const changeTabNo = (data) => ({
    type: TAB_NO, data
})

export const openMblVisitSidebar = (data) => ({
    type: MOBILEVISITSIDEBAROPEN, data
})


export const addPatientEmergencyContact = (data) => ({
    type: ADD_PATIENT_EMERGENCY_CONTACT,
    data
});

export const getPatientEmergencyContacts = (data) => ({
    type: GET_PATIENT_EMERGENCY_CONTACT,
    data
});

export const deletePatientEmergencyContacts = (data) => ({
    type: DELETE_PATIENT_EMERGENCY_CONTACT,
    data
});
