import React, { useEffect, useRef } from 'react';
import { Typography, } from '@mui/material';
import CustomTextField from '../../forms/custom-elements/CustomTextField';
import logo2 from '../../../assets/images/newpatientIcon/Rectangle 4 (5).png'
import CustomFormLabel from '../../forms/custom-elements/CustomFormLabel';

const OtherPanel = ({ detail, returnPanelData, scrollToField }) => {
    const referredByRef = useRef(null);
    const notesRef = useRef(null);

    const scrollDown = (ref) => {
        if (ref.current) {
            let scrollToPx = ref.current.offsetTop - 100
            window.scrollTo({
                top: scrollToPx,
                behavior: 'smooth',
            });
        }
    };
    // const changeBorderColor = (ref) => {
    //     ref.current.style.border = "2px solid red";
    //     ref.current.style.borderRadius = "5px";
    // }
    const removeError = (ref) => {
        ref.current.style.border = "unset";
        ref.current.style.borderRadius = "unset";
        // dispatch(showNotificationAction(false, { type: '', content: '' }))
    }

    useEffect(() => {
        if (scrollToField && scrollToField !== "") {
            if (scrollToField === "referredByRef") {
                scrollDown(referredByRef)
                // changeBorderColor(referredByRef)
            } else if (scrollToField === "notesRef") {
                scrollDown(notesRef)
                // changeBorderColor(notesRef)
            } else {
            }
        }
    }, [scrollToField])

    return <div className="tabpanelContent">
        <Typography className='panelHeading'>
            <img src={logo2} className="tabIcon" alt="logoImg" />
            Other</Typography>
        <div ref={referredByRef} onClick={() => { removeError(referredByRef) }}>
            <CustomFormLabel>Referred By</CustomFormLabel>
            <CustomTextField
                id="Referred_By"
                placeholder="Referred By"
                variant="outlined"
                // fullWidth
                // size="small"
                value={detail.otherRefferedBy}
                onChange={(e) => returnPanelData('otherRefferedBy', e.target.value)}
            />
        </div>
        <div style={{ width: '100%' }} ref={notesRef} onClick={() => { removeError(notesRef) }}>
            <CustomFormLabel>Notes</CustomFormLabel>
            <CustomTextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                size="small"
                placeholder="Notes"
                value={detail.notes}
                onChange={(e) => returnPanelData('notes', e.target.value)}
                className="border-g1"
            />
        </div>
    </div>
}
export default OtherPanel