import React from "react";
import PageContainer from "../container/PageContainer";
import AppointmentCalendar from '../Appoinment/Appoinment'
import Breadcrumb from "../../layouts/full-layout/breadcrumb/Breadcrumb";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const UpcomingAppointments = () => {
    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const rootRoute = useMemo(() => {
        if (userinfo && userinfo.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])
    const BCrumb = [
        {
            to: rootRoute + '/dashboard',
            title: 'Dashboard',
        },
        {
            title: 'Upcoming Appointments'

        },
    ];
    return <>
        <PageContainer title="Upcoming Appointments" description="This is Upcoming Appointments page">
            <Breadcrumb items={BCrumb} />

            <div className="patientDetailPage">
                <AppointmentCalendar showAppointment='upcoming' />
            </div>
        </PageContainer>
    </>
}

export default UpcomingAppointments 