import { Typography, Card, CardContent, Toolbar } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import UpcomingIcon from '../../assets/icons/UpcomingIcon.png'
import CalendarIcon from '../../assets/icons/CalendarIcon.png'

const AppointmentCard = () => {
    const navigate = useNavigate()
    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const rootRoute = useMemo(() => {
        if (userinfo && userinfo.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])

    return <Card
        sx={{
            p: {
                xs: '10px',
                sm: '10px',
                lg: '20px',
            },
            width: '100%'
        }}
    >
        <CardContent>
            <Typography sx={{
                flex: '1 1 100%', p: {
                    xs: '10px',
                    sm: '10px',
                    lg: '10px',
                },
                mb: 2,
                color: '#464646',
                display: 'flex',
                alignItems: 'center',
                gap: '20px'
            }} variant="h4" id="" fontWeight="500" component="div">
                <img src={CalendarIcon} className="tabIcon" alt="logoImg" />
                Appointment
            </Typography>
            <Toolbar
                sx={{
                    pl: { sm: 3 },
                    pr: { xs: 2, sm: 2 },
                    m: '0 10px 10px 10px',
                    // ...(numSelected > 0 && {
                    bgcolor: (theme) => '#F5FAFF',
                    // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    // }),
                    borderRadius: '4px',
                    boxShadow: '0px 1px 3px rgba(40, 53, 147, 0.25)',
                    // width: '100%'
                }}
            >

                <Typography sx={{ flex: '1 1 100%', cursor: 'pointer' }} id="newVisitTitle" component="div" onClick={() => {
                    navigate(rootRoute + '/dashboard/upcomingappointment')
                }}>
                    <Typography sx={{
                        color: "#464646 !important", display: 'flex',
                        alignItems: 'center',
                        gap: '20px'
                    }} fontSize="16px" >
                        <img src={UpcomingIcon} className="tabIcon" alt="logoImg" />
                        Upcoming Appointment
                    </Typography>
                    <FeatherIcon icon="arrow-right" color='#1990FC' />
                </Typography>
            </Toolbar>
        </CardContent>
    </Card >
}
export default AppointmentCard