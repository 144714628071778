import React from 'react';
import { useNavigate } from 'react-router';
import { Typography, Grid, Card, Button } from '@mui/material';
import PageContainer from '../../container/PageContainer';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import {
  getPatientDetail,
  getPatientEmergecyContactsDetail,
} from '../../../redux/patients/AsyncActions';
import { useState } from 'react';

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id, firstName, lastName, dob } = state ? state : {};

  const userinfo = useSelector((state) => state.AuthenticationReducer.userinfo);

  const [patientData, setpatientData] = useState({});
  const [patientDetail, setpatientDetail] = useState({});
  const [patientEmergencyContactDetail, setPatientEmergencyContactDetail] = useState([]);

  const rootRoute = useMemo(() => {
    if (userinfo && userinfo.role === 'admin') {
      return '/admin';
    } else {
      return '';
    }
  }, [userinfo]);

  const BCrumb = [
    {
      to: rootRoute + '/patient',
      title: 'Patients',
    },
    {
      onclick: () => {
        navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName, dob } });
      },
      title: 'Detail',
    },
    {
      title: 'Profile',
    },
  ];

  useEffect(() => {
    (async () => {
      let result = await dispatch(getPatientDetail(id));
      if (result && result.code === 200) {
        let re = result.data;
        let guardianInfoResult = await dispatch(getPatientEmergecyContactsDetail(re.patientId));
        let guardianInfoResponse = guardianInfoResult.data;
        setpatientDetail(re);
        let patData = {
          Identification: {
            Name:
              re.firstName !== '' || re.middleName !== '' || re.lastName !== ''
                ? re.firstName + ' ' + re.middleName + ' ' + re.lastName
                : 'Not available',
            Email: re.email ? re.email : 'Not available',
            Phone: re.phone ? re.phone : 'Not available',
            'Secondary Phone': re.secondaryPhone ? re.secondaryPhone : 'Not available',
            'D.O.B': re.dob ? re.dob : 'Not available',
            Gender: re.gender ? re.gender : 'Not available',
            Status: re.status ? re.status[0].toUpperCase() + re.status.slice(1) : 'Not available',
          },
          Address: {
            'Address line 1': re.address1 ? re.address1 : 'Not available',
            'Address line 2': re.address2 ? re.address2 : 'Not available',
            City: re.city ? re.city : 'Not available',
            ZIP: re.zip ? re.zip : 'Not available',
            State: re.state ? re.state : 'Not available',
            Country: re.country ? re.country : 'Not available',
          },
          'Guardian Info': [],
          // "Medical Info": {
          //     "Medical Problem": re.medicalProblem ? re.medicalProblem : 'Not available',
          //     "Other Medical Problem": re.otherMedicalProblem ? re.otherMedicalProblem : 'Not available',
          //     "Surgical Year": re.surgicalYear ? re.surgicalYear : 'Not available',
          //     "Surgical Procedure": re.surgicalProcedure ? re.surgicalProcedure : 'Not available',
          //     "Reason": re.surgicalReason ? re.surgicalReason : 'Not available',
          //     "Family History": re.familyHistory ? re.familyHistory : 'Not available',
          //     "Other": re.other ? re.other : 'Not available',
          //     "Medication Name": re.medicationName ? re.medicationName : 'Not available',
          //     "Dosage": re.medicationDosage ? re.medicationDosage : 'Not available',
          //     "Frequency": re.medicationFrequency ? re.medicationFrequency : 'Not available'
          // },
          Coverage: {
            'Insurance Company': re.insuranceCompany ? re.insuranceCompany : 'Not available',
            'Policy Number': re.policyNumber ? re.policyNumber : 'Not available',
            'Expiration Date': re.expirationDate ? re.expirationDate : 'Not available',
          },
          Other: {
            'Referred By': re.otherRefferedBy ? re.otherRefferedBy : 'Not available',
            Notes: re.notes ? re.notes : 'Not available',
          },
        };

        if (guardianInfoResponse.length > 0) {
          setPatientEmergencyContactDetail(guardianInfoResponse);
          // let arr = [{}]
          guardianInfoResponse.forEach((element) => {
            patData['Guardian Info'].push({
              'Full Name':
                element.firstName && element.lastName
                  ? element.firstName + ' ' + element.lastName
                  : 'Not available',
              Relation: element.relation ? element.relation : 'Not available',
              Phone: element.phoneNumber ? element.phoneNumber : 'Not available',
              'Secondary Phone-1': element.secondaryPhoneNumber
                ? element.secondaryPhoneNumber
                : 'Not available',
              'Secondary Phone-2': element.secondaryPhoneNumberTwo
                ? element.secondaryPhoneNumberTwo
                : 'Not available',
            });
          });
        } else {
          setPatientEmergencyContactDetail([]);
          patData['Guardian Info'].push({
            'Full Name': 'Not available',
            Relation: 'Not available',
            Phone: 'Not available',
            'Secondary Phone-1': 'Not available',
            'Secondary Phone-2': 'Not available',
          });
        }

        setpatientData(patData);
      } else {
      }
    })();
  }, [dispatch, id]);

  let breadtitle =
    (firstName && firstName[0] && firstName[0].toUpperCase() + firstName.slice(1)) +
    ' ' +
    (lastName && lastName[0] && lastName[0].toUpperCase() + lastName.slice(1));

  return (
    <PageContainer title="Patient Detail" description="this is view Patient page">
      <Breadcrumb
        title={breadtitle}
        items={BCrumb}
        children={
          <div style={{ textAlign: 'right', margin: '15px 0px', width: '100%' }}>
            <Button
              color="primary"
              variant="contained"
              sx={{ mt: 0 }}
              onClick={() => {
                navigate(rootRoute + '/patient/edit', {
                  state: {
                    id: id,
                    firstName: firstName,
                    lastName: lastName,
                    patientDetail,
                    patientEmergencyContactDetail,
                  },
                });
              }}
            >
              Edit
            </Button>
          </div>
        }
      />

      <div className="patientDetailPage">
        {Object.keys(patientData).map((element, eleIn) => {
          return (
            <Card
              key={eleIn}
              sx={{
                p: 3,
                width: '100%',
              }}
            >
              <Typography
                variant="h2"
                sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}
              >
                {element}
              </Typography>

              <Grid container spacing={2}>
                {patientData[element].length > 0 ? (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {patientData[element].map((arrEle, arrin) => {
                      return (
                        <Grid key={arrin} container spacing={2}>
                          {Object.keys(arrEle).map((gaurdionEle, gauInn) => {
                            return (
                              <Grid
                                key={gauInn}
                                item
                                lg={patientData[element].length > 1 ? 3 : 4}
                                md={12}
                                sm={6}
                                xs={12}
                              >
                                <Typography variant="h6" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                  {gaurdionEle}
                                </Typography>
                                <Typography variant="body2">{arrEle[gaurdionEle]}</Typography>
                              </Grid>
                            );
                          })}
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  Object.keys(patientData[element]).map((innerEle, inn) => {
                    return (
                      <Grid key={inn} item lg={4} md={6} sm={6} xs={12}>
                        <Typography variant="h6" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                          {innerEle}
                        </Typography>
                        <Typography variant="body2">{patientData[element][innerEle]}</Typography>
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </Card>
          );
        })}
      </div>
    </PageContainer>
  );
};

export default Profile;
