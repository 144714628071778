const MostCommonlyPrescribedMedication = async (data) => {

    if (data.length > 0) {
        var finalArray = [];
        for (let i = 0; i < data.length; i++) {

            const result = {};

            let medicationRecord = JSON.parse(data[i].clinicalSummaryMedicationData);
            Object.entries(medicationRecord).forEach(([category, drugs]) => {

                const trueChildObjects = Object.fromEntries(
                    Object.entries(drugs).filter(([drug, value]) => value === true)
                );

                if (Object.keys(trueChildObjects).length > 0) {
                    result[category] = trueChildObjects;
                    const newObject = Object.fromEntries(
                        Object.entries(result).map(([category, trueChildObjects]) => [category, Object.keys(trueChildObjects).length])
                    );
                    finalArray.push(newObject);
                }

            });

            if (i === data.length - 1) {
                let totalMedications = await calculateTotalMedications(finalArray);
                return totalMedications
            }
        }
    }else {
        return {}
    }

}

const calculateTotalMedications = (data) => {
    const result = {};

    data.forEach((obj) => {
        Object.entries(obj).forEach(([category, count]) => {
            if (!result[category]) {
                result[category] = count;
            } else {
                result[category] += count;
            }
        });
    });

    return result;
};


export default MostCommonlyPrescribedMedication