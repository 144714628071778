import { Button, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { debounce } from "lodash"
import FeatherIcon from "feather-icons-react";
import PropTypes from "prop-types";

import Breadcrumb from "../../layouts/full-layout/breadcrumb/Breadcrumb";
import {
  fetchEditedPatients,
  fetchPatients,
} from "../../redux/patients/AsyncActions";
import PageContainer from "../container/PageContainer";
import CustomTextField from "../forms/custom-elements/CustomTextField";
import ChooseHospital from "../Hospitals/ChooseHospital";
import PatientList from "./PatientList";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Patient = () => {
  let dispatch = useDispatch();
  const userinfo = useSelector((state) => state.AuthenticationReducer.userinfo);
  const rootRoute = useMemo(() => {
    if (userinfo && userinfo.role === "admin") {
      return "/admin";
    } else {
      return "";
    }
  }, [userinfo]);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setperPage] = useState(10);

  const BCrumb = [
    {
      to: rootRoute + "/patient",
      title: "Patients",
    },
    // {
    //     title: 'Pagination Table',
    // },
  ];

  const handleChange = (event, newValue) => {
    setPage(1);
    setperPage(10);
    setValue(newValue);
  };
  const hospitalSelectedState = useSelector(
    (state) => state.HospitalReducer.hospitalSelected
  );

  // useEffect(() => {
  //     if (userinfo?.role === 'admin') {
  //         if (hospitalSelectedState?.hospitalId)
  //             dispatch(fetchPatients({ hospitalId: hospitalSelectedState?.hospitalId, page: 0, perPage: 10, search: '' }));
  //     }
  // }, [hospitalSelectedState, dispatch, userinfo]);

  // useEffect(() => {
  //     if (userinfo?.role !== 'admin') {
  //         if (userinfo?.hospitalId)
  //             dispatch(fetchEditedPatients({ hospitalId: userinfo?.hospitalId, page: 0, perPage: 10, search: '' }))
  //         dispatch(fetchPatients({ page: 0, perPage: 10, search: '' }));
  //     }
  // }, [userinfo, dispatch]);

  useEffect(() => {
    let timerId = setTimeout(() => {
      getPatientLists(search, value);
    }, 200);
    return () => {
      clearTimeout(timerId);
    };
  }, [page, perPage, value, hospitalSelectedState, search]);

  const getPatientLists = (searchTxt, tabValue) => {
    if (userinfo?.role === "admin") {
      if (hospitalSelectedState?.hospitalId && tabValue === 0)
        dispatch(
          fetchPatients({
            hospitalId: hospitalSelectedState?.hospitalId,
            page,
            perPage,
            search: searchTxt,
          })
        );
    } else if (userinfo?.role !== "admin") {
      if (userinfo?.hospitalId && tabValue === 1)
        //call only when my patient tab is open
        dispatch(
          fetchEditedPatients({
            hospitalId: userinfo?.hospitalId,
            page,
            perPage,
            search: searchTxt,
          })
        );
      if (tabValue === 0)
        dispatch(fetchPatients({ page, perPage, search: searchTxt }));
    }
  };

  const getPageInfoFun = (page, perPage) => {
    setPage(page + 1);
    setperPage(perPage);
  };
  // debounce takes the old value of search, when it was compled. So passed latest value of searchTxt in parameters
  // const handleSearch = useCallback(debounce((searchTxt, tabValue) => getPatientLists(searchTxt, tabValue), 500), [])
  // const handleSearch = debounce((searchTxt) => { getPatientLists(searchTxt) }, 1000)
  const handleSearch = (searchTxt, tabValue) => {};

  return (
    <div className="patientPage">
      <PageContainer title="Patient" description="this is Patient page">
        {/* breadcrumb */}
        <Breadcrumb
          title="Patient List"
          items={BCrumb}
          children={
            <div
              style={{ textAlign: "right", margin: "15px 0px", width: "100%" }}
            >
              {userinfo?.role === "admin" ? (
                <ChooseHospital />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className="addpatientBtn"
                >
                  <FeatherIcon icon="plus" style={{ width: 15, height: 15 }} />
                  <a
                    href={"/#" + rootRoute + "/patient/add"}
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    {" "}
                    ADD NEW PATIENT
                  </a>
                </Button>
              )}
            </div>
          }
        />
        {/* end breadcrumb */}
        <div
          className="patientDetailPage patientlist"
          style={{ gap: 0, gridGap: 0 }}
        >
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              // ...(numSelected > 0 && {
              // bgcolor: (theme) => 'white'
              bgcolor: (theme) => "#1990FC",
              // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
              // }),
              borderRadius: "4px 4px 0 0",
              boxShadow: "none",
              width: "100%",
            }}
            className="patientlistHeader"
          >
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="patientTableTitle"
              component="div"
            >
              <FeatherIcon icon={"search"} className="searchIcon" />
              <Typography sx={{ mr: { lg: 4, xs: 1 } }}> Search </Typography>
              <CustomTextField
                id="searchpatient"
                placeholder="Enter text"
                variant="outlined"
                //  fullWidth
                size="small"
                value={search}
                onChange={(e) => {
                  setPage(1);
                  setperPage(10);
                  setSearch(e.target.value);
                  handleSearch(e.target.value, value);
                }}
              />
              <FeatherIcon
                icon={"x"}
                style={{ marginLeft: "-30px", zIndex: 2 }}
                onClick={() => {
                  setSearch("");
                  getPatientLists("", value);
                }}
              />{" "}
              &nbsp;
            </Typography>
          </Toolbar>
          <div
            className=""
            style={{ width: "100%", backgroundColor: "white", padding: "20px" }}
          >
            {userinfo?.role !== "admin" ? (
              <>
                {" "}
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    textColor="secondary"
                    allowScrollButtonsMobile
                    scrollButtons
                    indicatorColor="secondary"
                  >
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                      }}
                      label="All Patients"
                      {...a11yProps(0)}
                      // onClick={() => scrollDown(newVaccineRef)}
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                      }}
                      label="My Patients"
                      {...a11yProps(1)}
                      onClick={() => {
                        // getHistory()
                        // scrollDown(historyRef)
                      }}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0} component="div">
                  <PatientList
                    type="all"
                    search={search}
                    getPageInfo={getPageInfoFun}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} component="div">
                  <PatientList
                    type="users"
                    search={search}
                    getPageInfo={getPageInfoFun}
                  />
                </TabPanel>
              </>
            ) : (
              <PatientList
                type="all"
                search={search}
                getPageInfo={getPageInfoFun}
              />
            )}
          </div>
        </div>
      </PageContainer>
    </div>
  );
};
export default Patient;
