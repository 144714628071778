import {
    USER_LOGGED_IN
} from '../constants';

const INIT_STATE = {
    userinfo: {}
};

const AuthenticationReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case USER_LOGGED_IN:
            return {
                ...state,
                userinfo: action.userinfo,
            };

        default:
            return state;
    }
};

export default AuthenticationReducer;
