import React, { useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardContent, Typography } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import MonthIcon from '../../assets/icons/MonthIcon.png'
import { useEffect } from 'react';
import moment from 'moment/moment';

const MostCommonlyPrescribedMedication = () => {

    // const theme = useTheme(); 
    const mostCommnlyPrescribedMedicationList = useSelector(state => state.ReportReducer.mostCommnlyPrescribedMedication)
    const [patientAgesArray, setPatientAgesArray] = useState([]);
    const [chartData, setChartData] = useState({
        options: {
            chart: {
                id: 'fb',
                group: 'social',
                type: 'line',
                height: 160
            },
            plotOptions: {
                line: {
                    markers: {
                        size: 0,
                    },
                }
            },
            xaxis: {
                categories: [],
                title: {
                    text: 'Medication Count'
                }
            },
            yaxis: {
                title: {
                    text: 'Medication Names'
                }
            }
        },
        series: [
            {
                name: "Vaccines Count :-",
                data: [],
            }
        ]
    });

    useEffect(() => {
        if (mostCommnlyPrescribedMedicationList) {
            const medicationName = Object.keys(mostCommnlyPrescribedMedicationList);
            const medicationValues = Object.values(mostCommnlyPrescribedMedicationList);
            setChartData(prevChartData => ({
                ...prevChartData,
                options:
                {
                    plotOptions: {
                        line: {
                            markers: {
                                size: 10,
                            },
                        }
                    },
                    xaxis: {
                        categories: medicationName,
                    },
                },
                series: [
                    {
                        data: medicationValues,
                    },
                ],
            }));
        }
    }, [mostCommnlyPrescribedMedicationList])

    return (
        <Card
            sx={{
                p: {
                    xs: '10px',
                    sm: '10px',
                    lg: '20px',
                },
                width: '100%'
            }}
        >
            <CardContent>
                <Typography sx={{
                    flex: '1 1 100%', p: {
                        xs: '10px',
                        sm: '10px',
                        lg: '10px',
                    },
                    color: '#464646',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px'
                }} variant="h4" id="" fontWeight="500" component="div">
                    <img src={MonthIcon} className="tabIcon" alt="logoImg" />
                    Medicine analysis
                </Typography>
                {
                    // mostCommnlyPrescribedMedicationList.length > 0 &&
                    <Chart
                        options={chartData.options}
                        series={chartData.series}
                        type="line"
                        height="500"
                    />
                }
            </CardContent>
        </Card>
    );
};

export default MostCommonlyPrescribedMedication;
