import axios from 'axios';
import {
    SHOW_SPINNER,
    APPOINTMENTLIST

} from '../constants';
import { URL } from '../../config';

// Get All Appointment Action but backend API doesn't exsit
export const getAllAppointment = (data) => async (dispatch) => {
    try {

        // dispatch({
        //     type: SHOW_SPINNER,
        //     data: true
        // });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'appointment/all', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: APPOINTMENTLIST,
            data: response.data.data
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const addAppointment = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'appointment/add', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const updateAppointment = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'appointment/update', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const deleteAppointment = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'appointment/delete', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const getUpcomingAppointment = () => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.get(URL + 'appointment/upcoming', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: APPOINTMENTLIST,
            data: response.data.data.upcomingAppointment
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const getPatientAppointment = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'appointment/patient', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: APPOINTMENTLIST,
            data: response.data.data.patientAppointment
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}