import {
    HOSPITALLIST, HOSPITALSELECTED,
} from '../constants';

const INIT_STATE = {
    hospitalList: [],
    hospitalSelected: {}
};

const HospitalReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case HOSPITALLIST:
            return {
                ...state,
                hospitalList: action.data.hospitalsList,
            };
        case HOSPITALSELECTED:
            return {
                ...state,
                hospitalSelected: action.data,
            };
        default:
            return state;
    }
};

export default HospitalReducer;
