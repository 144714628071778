import React from "react";
import PageContainer from "../../container/PageContainer";
import AppointmentCalendar from '../../Appoinment/Appoinment'
import { useMemo } from "react";
import Breadcrumb from "../../../layouts/full-layout/breadcrumb/Breadcrumb";

import { useLocation, useNavigate } from "react-router";
import { useSelector } from 'react-redux';

const PatientAppointments = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { id, firstName, lastName, dob } = state ? state : {};

    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const rootRoute = useMemo(() => {
        if (userinfo && userinfo.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])

    let breadtitle = (firstName && firstName[0] && (firstName[0].toUpperCase() + firstName.slice(1))) + ' ' +
        (lastName && lastName[0] && (lastName[0].toUpperCase() + lastName.slice(1)))

    const BCrumb = [
        {
            to: rootRoute + '/patient',
            title: 'Patients',
        },
        {
            onclick: () => { navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName, dob } }) },
            title: breadtitle,
        },
        {
            title: 'Appointments',
        },
    ];

    return <>
        <PageContainer title="Patient Appointments" description="this is Patient Appointments page">
            <Breadcrumb items={BCrumb} />

            <div className="patientDetailPage">
                <AppointmentCalendar showAppointment='patient' patientId={id} />
            </div>
        </PageContainer>
    </>
}

export default PatientAppointments 