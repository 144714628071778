import {
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    FormControlLabel,
    TextField,
    Autocomplete,
    Box,
    Tooltip,
} from "@mui/material"
import FeatherIcon from 'feather-icons-react';
import moment from "moment";

import { useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"
import { addBatchNo, addNewVaccination, getBatchList, getMedicalSummary, getVaccineDose, updateVaccinationVisit } from "../../../redux/patients/AsyncActions"
import { showNotificationAction } from "../../../redux/pushnotification/Action"
import CustomFormLabel from '../../forms/custom-elements/CustomFormLabel';
import CustomRadio from '../../forms/custom-elements/CustomRadio';
import CustomTextField from "../../forms/custom-elements/CustomTextField";

const NewVaccination = ({ changeopenNewVaccine, updateVaccineData }) => {
    const { state } = useLocation();
    const { id } = state ? state : {};

    const dispatch = useDispatch()

    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)

    const [openNewVaccine, setopenNewVaccine] = useState(true)

    const [vaccineAdded, setvaccineAdded] = useState({ vaccinationDate: moment().format("DD-MMM-YYYY"), vaccination: '', dose: 0, batchNumber: '', routeOfAdministration: '', siteOfInjection: '', notes: '' })
    const [openBatch, setOpenBatch] = useState(false)
    const [newBatchNo, setnewBatchNo] = useState('')
    const [doseList, setdoseList] = useState([])
    const [noDose, setnoDose] = useState(false)

    const batchListState = useSelector((state) => state.patientReducer.batchList);
    const batchList = useMemo(() => {
        if (batchListState) {
            return batchListState.map(b => {
                return { label: b.batchNumber, value: b.batchNumber }
            })
        } else {
            return []
        }
    }, [batchListState])

    const vaccineDoseList = useSelector((state) => state.patientReducer.vaccineDoseList);
    const VaccinationList = useMemo(() => {
        if (vaccineDoseList && vaccineDoseList.length > 0) {
            return vaccineDoseList
        } else {
            return []
        }
    }, [vaccineDoseList])

    useEffect(() => {
        getVaccineDoseHandler()
        getBatchListHandler()
    }, [])

    useEffect(() => {
        if (updateVaccineData) {
            setvaccineAdded({ ...updateVaccineData })
            let vaccineIs = VaccinationList.find(f => f.label === updateVaccineData.vaccination)
            if (vaccineIs && vaccineIs.doseArr) {
                setdoseList(vaccineIs.doseArr)
                if (vaccineIs.doseArr.length > 0)
                    setnoDose(false)
            }

        }
    }, [updateVaccineData, VaccinationList])

    const getVaccineDoseHandler = async () => {
        const result = await dispatch(getVaccineDose({ patientId: id }))
        if (result && result.status === true) {
        } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
            dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
        } else {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
        }
    }
    const saveBatchHandler = async () => {
        if (newBatchNo && newBatchNo.length > 0) {
            let data = {
                batchNumber: newBatchNo,
                hospitalId: userinfo?.hospitalId
            }
            let result = await dispatch(addBatchNo(data))
            if (result && result.status === true) {
                handleCloseBatch()
                setnewBatchNo("")
                dispatch(showNotificationAction(true, { type: 'success', content: 'Batch Number added.' }))
            } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
                dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
            } else {
                dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
            }
        } else {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please enter batch number.' }))
        }
    }
    const handleCloseNewVaccine = () => {
        setopenNewVaccine(false)
        setvaccineAdded({ vaccinationDate: moment().format("DD-MMM-YYYY"), vaccination: '', dose: 0, batchNumber: '', routeOfAdministration: '', siteOfInjection: '', notes: '' })
        changeopenNewVaccine(false)
    }
    const handleCloseBatch = () => {
        setnewBatchNo("")
        setOpenBatch(false)
    }

    const saveVaccinationHandler = async () => {
        if (!vaccineAdded.vaccination || (vaccineAdded.vaccination && vaccineAdded.vaccination === "")) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select vaccination.' }))
        } else if (!vaccineAdded.dose || vaccineAdded.dose === 0 || vaccineAdded.dose === "") {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select correct dose number.' }))
        } else {
            let data = {
                "patientId": id,
                "hospitalId": userinfo?.hospitalId,
                "userId": userinfo?.userId,
                "userName": userinfo?.name,
                ...vaccineAdded
            };
            let result = await dispatch(addNewVaccination(data))
            if (result.status === true) {
                dispatch(getMedicalSummary(id))
                handleCloseNewVaccine()
                return { status: true, message: "Vaccination added" }
            } else {
                return { status: false, message: "Failed to add new vaccination data. Try again." }
            }
        }
    }
    const updateVaccinationHandler = async () => {
        if (!vaccineAdded.vaccination || (vaccineAdded.vaccination && vaccineAdded.vaccination === "")) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select vaccination.' }))
        } else if (!vaccineAdded.dose || vaccineAdded.dose === 0 || vaccineAdded.dose === "") {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select correct dose number.' }))
        } else {
            let data = {
                "patientId": id,
                "hospitalId": userinfo?.hospitalId,
                "userId": userinfo?.userId,
                "userName": userinfo?.name,
                ...vaccineAdded
            };
            let result = await dispatch(updateVaccinationVisit(data))
            if (result.status === true) {
                handleCloseNewVaccine()
                dispatch(showNotificationAction(true, { type: 'success', content: "Vaccination updated." }))
            } else {
                dispatch(showNotificationAction(false, { type: 'error', content: "Failed to add new vaccination data. Try again." }))
            }
        }
    }
    const getBatchListHandler = async () => {
        const result = await dispatch(getBatchList())
        if (result && result.code === 200) {
        } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
            dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
        } else {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
        }
    }
    return <>
        <Dialog open={openNewVaccine} onClose={() => handleCloseNewVaccine()} fullWidth>
            <DialogContent className='appointmentForm' sx={{ padding: '20px 16px' }}>
                <Typography sx={{ mb: 2, ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} id="" component="div">
                    <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                        {updateVaccineData ? 'Update Vaccination' : 'Add new vaccination'}
                    </Typography>
                    <FeatherIcon icon="x" style={{
                        width: 20, height: 20, marginLeft: '5px', color: 'white', borderRadius: '50%', background: '#1990FC', padding: '2px'
                    }}
                        onClick={() => handleCloseNewVaccine()}
                    />
                </Typography>
                <div style={{ width: '100%', marginTop: '10px' }}>
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel>Vaccination Date</CustomFormLabel>
                        <CustomTextField
                            id="Vaccination Date"
                            type={'date'}
                            placeholder=""
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            value={vaccineAdded.vaccinationDate}
                            onChange={(e) => {
                                setvaccineAdded({ ...vaccineAdded, vaccinationDate: e.target.value })
                            }}
                            className="border-g1"
                        />
                    </FormControl>
                </div>
                <div style={{ width: '100%', marginTop: '10px' }}>
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel htmlFor="demo-simple-select">Vaccination</CustomFormLabel>
                        <Autocomplete
                            disablePortal
                            value={vaccineAdded.vaccination}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setvaccineAdded({ ...vaccineAdded, vaccination: newValue.label, dose: '' })
                                    setdoseList(newValue.doseArr)
                                    if (newValue.doseArr.length > 0)
                                        setnoDose(false)
                                    else
                                        setnoDose(true)
                                }
                                else {
                                    setvaccineAdded({ ...vaccineAdded, vaccination: '', dose: '' })
                                    setdoseList([])
                                }
                            }}
                            id="combo-box-demo"
                            options={VaccinationList}
                            fullWidth
                            renderInput={(params) => (
                                <TextField {...params} size="small" placeholder="" aria-label="Select" />
                            )}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    border: 'none !important'
                                },
                                '& .MuiOutlinedInput-root': {
                                    padding: '16.5px 14px !important'
                                }
                            }}
                            className="border-g1"
                        />
                    </FormControl>
                </div>
                <div style={{ width: '100%', marginTop: '10px' }}>
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel htmlFor="demo-simple-select">Dose</CustomFormLabel>
                        <Autocomplete
                            disablePortal
                            value={vaccineAdded.dose}
                            onChange={(event, newValue) => {
                                if (newValue) setvaccineAdded({ ...vaccineAdded, dose: newValue })
                                else setvaccineAdded({ ...vaccineAdded, dose: '' })
                            }}
                            id="combo-box-demo"
                            options={doseList}
                            fullWidth
                            renderInput={(params) => (
                                <TextField {...params} size="small" placeholder="" aria-label="Select" />
                            )}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    border: 'none !important'
                                },
                                '& .MuiOutlinedInput-root': {
                                    padding: '16.5px 14px !important'
                                }
                            }}
                            className="border-g1"
                        />
                        {noDose && <CustomFormLabel htmlFor="demo-simple-select" sx={{ textAlign: 'center', color: 'red' }}>No dose left</CustomFormLabel>}
                    </FormControl>
                </div>
                <div style={{ width: '100%', marginTop: '10px' }}>
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel htmlFor="demo-simple-select" sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>Batch Number &nbsp;
                            <Tooltip title='Add new batch'>
                                <span>   <FeatherIcon icon="plus" width="20"
                                    style={{ width: 16, height: 16, fontWeight: '700', marginRight: '5px', background: 'rgba(0, 0, 0, 0.4)', color: 'white', borderRadius: '50%', padding: '2px' }}
                                    onClick={() => setOpenBatch(true)} />
                                </span>
                            </Tooltip>
                        </CustomFormLabel>
                        <Autocomplete
                            disablePortal
                            value={vaccineAdded.batchNumber}
                            onChange={(event, newValue) => {
                                if (newValue) setvaccineAdded({ ...vaccineAdded, batchNumber: newValue.value })
                                else setvaccineAdded({ ...vaccineAdded, batchNumber: '' })
                            }}
                            id="combo-box-demo"
                            options={batchList}
                            fullWidth
                            renderInput={(params) => (
                                <TextField {...params} size="small" placeholder="" aria-label="Select" />
                            )}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    border: 'none !important'
                                },
                                '& .MuiOutlinedInput-root': {
                                    padding: '16.5px 14px !important'
                                }
                            }}
                            className="border-g1"
                        />
                    </FormControl>
                </div>
                <div style={{ width: '100%', marginTop: '10px' }}  >
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel>Route of Administration</CustomFormLabel>
                        <Box>
                            <FormControlLabel
                                checked={vaccineAdded.routeOfAdministration === 'SC'}
                                onChange={(e) => {
                                    setvaccineAdded({ ...vaccineAdded, routeOfAdministration: e.target.value })
                                }}
                                value="SC"
                                label="SC"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'A' }}
                            />
                            <FormControlLabel
                                checked={vaccineAdded.routeOfAdministration === 'IM'}
                                onChange={(e) => {
                                    setvaccineAdded({ ...vaccineAdded, routeOfAdministration: e.target.value })
                                }}
                                value="IM"
                                label="IM"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'B' }}
                            />
                            <FormControlLabel
                                checked={vaccineAdded.routeOfAdministration === 'Oral'}
                                onChange={(e) => {
                                    setvaccineAdded({ ...vaccineAdded, routeOfAdministration: e.target.value })
                                }}
                                value="Oral"
                                label="Oral"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'C' }}
                            />
                        </Box>
                    </FormControl>
                </div>
                <div style={{ width: '100%', marginTop: '10px' }}  >
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel>Site of Injection</CustomFormLabel>
                        <Box>
                            <FormControlLabel
                                checked={vaccineAdded.siteOfInjection === "Left arm"}
                                onChange={(e) => {
                                    setvaccineAdded({ ...vaccineAdded, siteOfInjection: e.target.value })
                                }}
                                label="Left arm"
                                value="Left arm"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'A' }}
                            />
                            <FormControlLabel
                                checked={vaccineAdded.siteOfInjection === 'Right arm'}
                                onChange={(e) => {
                                    setvaccineAdded({ ...vaccineAdded, siteOfInjection: e.target.value })
                                }}
                                value="Right arm"
                                label="Right arm"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'B' }}
                            />
                            <FormControlLabel
                                checked={vaccineAdded.siteOfInjection === "Left thigh"}
                                onChange={(e) => {
                                    setvaccineAdded({ ...vaccineAdded, siteOfInjection: e.target.value })
                                }}
                                value="Left thigh"
                                label="Left thigh"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'C' }}
                            />
                            <FormControlLabel
                                checked={vaccineAdded.siteOfInjection === 'Right thigh'}
                                onChange={(e) => {
                                    setvaccineAdded({ ...vaccineAdded, siteOfInjection: e.target.value })
                                }}
                                value="Right thigh"
                                label="Right thigh"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'D' }}
                            />
                        </Box>
                    </FormControl>
                </div>
                <div style={{ width: '100%', marginTop: '10px' }}>
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel>Notes </CustomFormLabel>
                        <CustomTextField
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            size="small"
                            placeholder="Notes"
                            value={vaccineAdded.notes}
                            onChange={(e) => {
                                setvaccineAdded({ ...vaccineAdded, notes: e.target.value })
                            }}
                            className="border-g1"
                        />
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions className='appointmentFormActions'>
                <Box>
                    <Button type="button"
                        variant="contained"
                        onClick={() => handleCloseNewVaccine()}
                        sx={{
                            mr: '8px',
                            background: "#888888"

                        }}
                    >Cancel</Button>
                    <Button variant="contained" className='registerButton' color="primary"
                        onClick={updateVaccineData ? updateVaccinationHandler : saveVaccinationHandler}>
                        {updateVaccineData ? 'Update' : 'Add'}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
        <Dialog open={openBatch} onClose={() => handleCloseBatch()} fullWidth>
            <DialogContent className='appointmentForm' sx={{ padding: '20px 16px' }}>
                <Typography sx={{ mb: 2, ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} id="" component="div"  >
                    <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                        Add new batch number
                    </Typography>
                    <FeatherIcon icon="x" style={{
                        width: 20, height: 20, marginLeft: '5px', color: 'white', borderRadius: '50%', background: '#1990FC', padding: '2px'
                    }}
                        onClick={() => handleCloseBatch()}
                    />
                </Typography>

                <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel>Batch No. </CustomFormLabel>
                        <CustomTextField
                            variant="outlined"
                            fullWidth
                            value={newBatchNo}
                            onChange={(e) => {
                                setnewBatchNo(e.target.value)
                            }}
                        />
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions className='appointmentFormActions'>
                <Box>
                    <Button type="button"
                        variant="contained"
                        onClick={() => handleCloseBatch()}
                        sx={{
                            mr: '8px',
                            background: "#888888"

                        }}
                    >Cancel</Button>
                    <Button variant="contained" className='registerButton' color="primary"
                        onClick={saveBatchHandler}>
                        Add
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    </>
}

export default NewVaccination;