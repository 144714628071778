import {
    SHOW_NOTIFICATION,
} from '../constants';

const INIT_STATE = {
    showNotification: false,
    notificationContent: {},
    origin: ""
};

const PushNotificationReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return {
                ...state,
                showNotification: action.isShow,
                notificationContent: action.content,
                origin: action.origin,
            };
        default:
            return state;
    }
};
export default PushNotificationReducer
