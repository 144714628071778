import {
    MODAL_MESSAGE
} from '../constants';

export const showMessageModal = (data) => (dispatch) => {
    dispatch({
        type: MODAL_MESSAGE,
        data: data
    });

};