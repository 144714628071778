import {
    MODAL_MESSAGE,
} from '../constants';

const INIT_STATE = {
    showModal: false,
    modalContent: {},
};

const MessageModalReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case MODAL_MESSAGE:
            return {
                ...state,
                modalContent: action.data.modalContent,
                showModal: action.data.showModal
            };

        default:
            return state;
    }
};

export default MessageModalReducer;
