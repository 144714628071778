import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import CustomCheckbox from "../../../forms/custom-elements/CustomCheckbox";
import CustomTextField from "../../../forms/custom-elements/CustomTextField";

const FamilyHistory = ({ setFamilyHistoryValues, familyHistoryValues }) => {
  const [otherValue, setOtherValue] = useState(
    familyHistoryValues?.other || ""
  );
  const [value, setValue] = useState("");
  const [selectedValues, setSelectedValues] = useState(
    Object.keys(familyHistoryValues).filter((val) => val !== "other")
  );
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) });
  useEffect(() => {
    const data = {};
    selectedValues.forEach((val) => {
      data[val] = true;
    });
    const valueTrim = otherValue.trim();
    if (valueTrim) {
      data.other = valueTrim;
    }

    setFamilyHistoryValues(data);
  }, [selectedValues, setFamilyHistoryValues, otherValue]);
  useEffect(() => {
    if (!value.trim()) return;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `https://clinicaltables.nlm.nih.gov/api/conditions/v3/search?terms=${value}`
        );
        const results = data[3].map((item) => item[0]);
        console.log(results);
        setOptions(results);
        setOpen(true);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    const countId = setTimeout(() => {
      fetchData();
    }, 500);
    return () => {
      clearTimeout(countId);
    };
  }, [value]);
  const onSelect = (val) => {
    if (selectedValues.includes(val)) {
      setSelectedValues((prevS) => prevS.filter((item) => item !== val));
    } else {
      setSelectedValues([...selectedValues, val]);
    }
  };
  return (
    <div className="tabpanelContent">
      <Typography className="panelHeading">Family History</Typography>
      <Box ref={ref} sx={{ position: "relative", width: "500px !important" }}>
        <CustomTextField
          id="family-history"
          variant="outlined"
          placeholder="Type medication"
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="border-g1"
          onFocus={() => setOpen(true)}
        />
        {isLoading && (
          <Paper
            sx={{
              position: "absolute",
              top: 60,
              zIndex: 100,
              width: "100%",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Paper>
        )}
        {!isLoading && options.length > 0 && open && (
          <Paper
            sx={{ position: "absolute", top: 60, zIndex: 100, width: "100%" }}
          >
            <MenuList>
              {options.map((opt) => (
                <MenuItem
                  onClick={() => onSelect(opt)}
                  selected={selectedValues.includes(opt)}
                  key={opt}
                >
                  <CustomCheckbox
                    checked={selectedValues.includes(opt)}
                    name={opt}
                    color="primary"
                  />
                  {opt}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        )}
      </Box>
      <List
        sx={{
          display: "flex",
          flexWrap: "wrap",
          listStyle: "none",
          p: 0.5,
          m: 0,
        }}
      >
        {selectedValues.map((data, index) => (
          <ListItem sx={{ width: "fit-content" }} key={index}>
            <Chip
              label={data}
              onDelete={() =>
                setSelectedValues((prevS) =>
                  prevS.filter((item) => item !== data)
                )
              }
            />
          </ListItem>
        ))}
      </List>
      <Box sx={{ position: "relative", width: "500px !important" }}>
        <CustomTextField
          id="medication-other"
          variant="outlined"
          placeholder="Other"
          fullWidth
          value={otherValue}
          onChange={(e) => setOtherValue(e.target.value)}
          className="border-g1"
        />
      </Box>
    </div>
  );
};

export default FamilyHistory;
