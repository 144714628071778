import React, { useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Toolbar,
  Tabs,
  Tab,
  Grid,
} from '@mui/material';
import PageContainer from '../../container/PageContainer';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import { useEffect } from 'react';
import { getLastVisitDetail } from '../../../redux/patients/AsyncActions';
import {
  saveAllergyData,
  saveDiagnosisData,
  saveDocumentData,
  saveExaminationData,
  saveGrowthData,
  saveObservationData,
  saveVaccinationData,
} from '../../../redux/patients/Action';
import TextareaAutosize from 'react-textarea-autosize';
import VisitObservationIcon from '../../../assets/icons/VisitObservationIcon.png';
import DiagnosisIcon from '../../../assets/icons/DiagnosisIcon.png';
import AllergyIcon from '../../../assets/icons/AllergyIcon.png';
import VaccineIcon from '../../../assets/icons/VaccineIcon.png';
import ExamIcon from '../../../assets/icons/ExamIcon.png';
import GrowthIcon from '../../../assets/icons/GrowthIcon.png';
import moment from 'moment';
import MedicationPreviewOld from './MedicationPreviewOld';
import MedicationPreviewNew from './MedicationPreviewNew';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const VisitDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id, firstName, lastName, dob, row } = state ? state : {};
  const [value, setValue] = React.useState(0);

  const userinfo = useSelector((state) => state.AuthenticationReducer.userinfo);
  const rootRoute = useMemo(() => {
    if (userinfo && userinfo.role === 'admin') {
      return '/admin';
    } else {
      return '';
    }
  }, [userinfo]);

  let breadtitle =
    (firstName &&
      firstName[0] &&
      firstName[0].toUpperCase() + firstName.slice(1)) +
    ' ' +
    (lastName && lastName[0] && lastName[0].toUpperCase() + lastName.slice(1));

  const BCrumb = [
    {
      to: rootRoute + '/patient',
      title: 'Patients',
    },
    {
      onclick: () => {
        navigate(rootRoute + '/patient/detail', {
          state: { id, firstName, lastName, dob },
        });
      },
      title: breadtitle,
    },
    {
      title: 'Visit Detail',
    },
  ];
  const observation = useSelector(
    (state) => state.patientReducer.observationData
  );

  const allergy = useSelector((state) => state.patientReducer.allergyData);
  const vaccination = useSelector(
    (state) => state.patientReducer.vaccinationData
  );
  const growth = useSelector((state) => state.patientReducer.growthData);
  const examination = useSelector(
    (state) => state.patientReducer.examinationData
  );
  const diagnosis = useSelector((state) => state.patientReducer.diagnosisData);

  const observationRef = useRef(null);
  const allergyRef = useRef(null);
  const vaccinationRef = useRef(null);
  const examinationRef = useRef(null);
  const growthRef = useRef(null);
  const diagnosisRef = useRef(null);

  useEffect(() => {
    let data = {
      visitId: row.visitId,
      patientId: id,
    };
    dispatch(getLastVisitDetail(data, 'updatevisit'));
    return () => {
      dispatch(saveObservationData({}));
      dispatch(saveAllergyData({}));
      dispatch(saveVaccinationData([]));
      dispatch(saveExaminationData({}));
      dispatch(saveGrowthData({}));
      dispatch(saveDocumentData({}));
      dispatch(saveDiagnosisData({}));
    };
  }, [dispatch, row.visitId, id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const scrollDown = (ref) => {
    if (ref.current) {
      let scrollToPx = ref.current.offsetTop - 100;
      window.scrollTo({
        top: scrollToPx,
        behavior: 'smooth',
      });
    }
  };

  // const formatText1 = (text) => {
  //     let rowsNo = 2
  //     let colNo = text.split('\n').length
  //     if (colNo && typeof colNo === 'number' && colNo > 0) {
  //         rowsNo = colNo + 1
  //     } else if (colNo && typeof colNo === 'number' && colNo === 0) {
  //         let sentenceNo = text.split('. ').length
  //         rowsNo = sentenceNo + 1
  //     }
  //     return <textarea className='visitTextArea' rows={rowsNo} disabled value={text}></textarea>
  // }
  const formatText = (text) => {
    return (
      <TextareaAutosize
        maxRows={7}
        value={text}
        className='visitAutoSizeTextArea'
        disabled
      />
    );
  };
  const getAge = (dobValue, visitDate) => {
    if (dobValue !== undefined) {
      let year = Math.trunc(
        moment(visitDate).diff(moment(dobValue, 'YYYY-MM-DD'), 'months') / 12
      );
      let month =
        moment(visitDate).diff(moment(dobValue, 'YYYY-MM-DD'), 'months') % 12;
      let agrStr = [];
      if (year && year > 1) {
        agrStr.push(' ' + year + ' Years');
      } else if (year === 1) {
        agrStr.push(' ' + year + ' Year');
      } else {
      }
      if (month && month > 1) {
        agrStr.push(' ' + month + ' Months');
      } else if (month === 1) {
        agrStr.push(' ' + month + ' Month');
      } else {
      }
      if (month === 0 && year === 0) {
        let days = moment(visitDate).diff(
          moment(dobValue, 'YYYY-MM-DD'),
          'days'
        );
        if (days > 1) {
          agrStr.push(' ' + days + ' Days');
        } else {
          agrStr.push(' 1 Day');
        }
      }
      return agrStr.join(',');
    } else {
      return '';
    }
  };
  return (
    <PageContainer title='Visit Detail' description='this is view Patient page'>
      <Breadcrumb title={breadtitle} items={BCrumb} />
      <Card
        sx={{
          p: {
            xs: '0px',
            sm: '0px',
            lg: '0px',
          },
          width: '100%',
        }}
        className='newPatientCard'
      >
        <CardContent
          sx={{
            p: 0,
          }}
        >
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 2, sm: 2 },
              bgcolor: (theme) => '#1990FC',
            }}
          >
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant='h6'
              id='newpatientTitle'
              component='span'
            >
              Visit Detail
            </Typography>
          </Toolbar>
          <Box
            sx={{
              width: '100%',
              p: {
                xs: '20px',
                sm: '35px',
                lg: '35px',
              },
              pt: {
                xs: '10px',
                sm: '10px',
                lg: '10px',
              },
            }}
            className='newPatientCardBox visitDetailBox'
          >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='basic tabs example'
                textColor='secondary'
                allowScrollButtonsMobile
                scrollButtons
                indicatorColor='secondary'
              >
                <Tab
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  label='Observations'
                  {...a11yProps(0)}
                  onClick={() => scrollDown(observationRef)}
                />
                <Tab
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  label='Allergy'
                  {...a11yProps(1)}
                  onClick={() => scrollDown(allergyRef)}
                />
                <Tab
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  label='Vaccination'
                  {...a11yProps(2)}
                  onClick={() => scrollDown(vaccinationRef)}
                />
                <Tab
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  label='Examination Findings'
                  {...a11yProps(3)}
                  onClick={() => scrollDown(examinationRef)}
                />
                <Tab
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  label='Growth Parameters & Vitals'
                  {...a11yProps(4)}
                  onClick={() => scrollDown(growthRef)}
                />
                <Tab
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  label='Diagnosis & Plan'
                  {...a11yProps(5)}
                  onClick={() => scrollDown(diagnosisRef)}
                />
              </Tabs>
            </Box>
            {observation.selectedVisitType === 'First Visit' ||
            observation.selectedVisitType === 'Follow Up Visit' ? (
              <TabPanel value={value} index={0} component='div'>
                <div ref={observationRef}>
                  <Typography
                    className='panelHeading'
                    sx={{
                      marginTop: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <img
                      src={VisitObservationIcon}
                      className='tabIcon'
                      alt='logoImg'
                      style={{ margin: 0 }}
                    />
                    Visit Observations-{' '}
                    {observation.selectedVisitType
                      ? observation.selectedVisitType
                      : 'Not available.'}
                  </Typography>
                  <Grid
                    container
                    spacing={0}
                    className='fileGrid'
                    style={{ width: '100%', marginTop: '20px' }}
                  >
                    <Grid item xs={12} lg={7}>
                      <Typography variant='h5' fontWeight='600'>
                        <Typography
                          variant='h5'
                          fontWeight='600'
                          color='textSecondary'
                          component='span'
                        >
                          Age:
                        </Typography>
                        {observation.dob && observation.dob !== ''
                          ? getAge(observation.dob, observation.visitDate)
                          : ' Not available.'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <Typography
                        variant='h5'
                        fontWeight='600'
                        textAlign='right'
                      >
                        <Typography
                          variant='h5'
                          fontWeight='600'
                          color='textSecondary'
                          component='span'
                        >
                          Date:
                        </Typography>
                        {observation.visitDate
                          ? ' ' + observation.visitDate
                          : ' Not available.'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className='observationtabpanelContent'>
                    <Typography className='' variant='h5' fontWeight='500'>
                      {' '}
                      Overview{' '}
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        Informant:{' '}
                      </Typography>
                      {observation.informant
                        ? observation.informant
                        : 'Not available.'}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='p'>
                        Current issues:{' '}
                      </Typography>
                      {observation.currentIssues
                        ? formatText(observation.currentIssues)
                        : 'Not available.'}
                    </Box>
                    {/* <Typography className='' color="" variant="h5" fontWeight="400"> 
                            <Typography color="textSecondary" component="span">Status:  </Typography>{observation.selectedStatusType ? observation.selectedStatusType : 'Not available.'} </Typography> */}
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        {' '}
                        Medications:{' '}
                      </Typography>
                      {observation.medications
                        ? formatText(observation.medications)
                        : 'Not available.'}
                    </Box>
                  </div>
                  {observation.selectedVisitType === 'First Visit' && (
                    <>
                      <div className='observationtabpanelContent'>
                        <Typography
                          className=''
                          color=''
                          variant='h5'
                          fontWeight='500'
                        >
                          Antenatal History
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Mother’s age:{' '}
                          </Typography>
                          {observation.mothersAge
                            ? observation.mothersAge
                            : 'Not available.'}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Parity:{' '}
                          </Typography>
                          {observation.gravida
                            ? observation.gravida
                            : 'Not available.'}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Complications & Medications:{' '}
                          </Typography>
                          {observation.compMed
                            ? formatText(observation.compMed)
                            : 'Not available.'}
                        </Box>
                        <Box>
                          <Typography color='textSecondary'>
                            Blood investigations:{' '}
                          </Typography>
                          <Typography
                            className=''
                            color=''
                            variant='h5'
                            fontWeight='400'
                            component='ul'
                          >
                            {observation.bloodInvestigationValues
                              ? Object.keys(
                                  observation.bloodInvestigationValues
                                ).map((el, i) => {
                                  return (
                                    <Typography
                                      className=''
                                      color=''
                                      variant='h5'
                                      fontWeight='400'
                                      component='li'
                                      key={i}
                                    >
                                      {el} -{' '}
                                      {observation.bloodInvestigationValues[el]
                                        ? observation.bloodInvestigationValues[
                                            el
                                          ]
                                        : 'Not available.'}
                                    </Typography>
                                  );
                                })
                              : 'Not available.'}
                          </Typography>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Details:{' '}
                          </Typography>
                          {observation.bloodDetail
                            ? formatText(observation.bloodDetail)
                            : 'Not available.'}
                        </Box>
                      </div>
                      <div className='observationtabpanelContent'>
                        <Typography
                          className=''
                          color=''
                          variant='h5'
                          fontWeight='500'
                        >
                          Birth History
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Gestational Age:{' '}
                          </Typography>
                          {observation.gestationalAge
                            ? observation.gestationalAge
                            : 'Not available.'}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Mode of delivery:
                          </Typography>{' '}
                          {observation.modeOfDelivery
                            ? observation.modeOfDelivery
                            : 'Not available.'}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Indication for LSCS:{' '}
                          </Typography>
                          {observation.indicationForLscs
                            ? observation.indicationForLscs
                            : 'Not available.'}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Details:{' '}
                          </Typography>
                          {observation.birthHistoryDetail
                            ? formatText(observation.birthHistoryDetail)
                            : 'Not available.'}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Birth weight:{' '}
                          </Typography>
                          {observation.birthWeight
                            ? observation.birthWeight + ' KG'
                            : 'Not available.'}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Length:{' '}
                          </Typography>
                          {observation.lengthBirth
                            ? observation.lengthBirth + ' CM'
                            : 'Not available.'}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Head Circumference:{' '}
                          </Typography>
                          {observation.headCircumference
                            ? observation.headCircumference
                            : 'Not available.'}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            APGAR score:{' '}
                          </Typography>
                          {observation.apgarScore
                            ? observation.apgarScore
                            : 'Not available.'}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Complications:{' '}
                          </Typography>
                          {observation.compBirth
                            ? formatText(observation.compBirth)
                            : 'Not available.'}
                        </Box>
                      </div>
                      <div className='observationtabpanelContent'>
                        <Typography
                          className=''
                          color=''
                          variant='h5'
                          fontWeight='500'
                        >
                          History
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Past medical History:{' '}
                          </Typography>
                          {observation.pastMedicalHistory
                            ? formatText(observation.pastMedicalHistory)
                            : 'Not available.'}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Family History:{' '}
                          </Typography>
                          {observation.familyHistory
                            ? formatText(observation.familyHistory)
                            : 'Not available.'}
                        </Box>
                      </div>
                    </>
                  )}
                  <div className='observationtabpanelContent'>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='500'
                    >
                      {' '}
                      Diet
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                      {observation.dietValues ? (
                        Object.keys(observation.dietValues).filter(
                          (f) => observation.dietValues[f] === true
                        ).length > 0 ? (
                          Object.keys(observation.dietValues).map((el) => {
                            return (
                              <>
                                {observation.dietValues[el] === true ? (
                                  el === 'Breastmilk' ? (
                                    <Typography
                                      variant='h5'
                                      fontWeight='400'
                                      component='li'
                                    >
                                      {' '}
                                      Breast milk
                                    </Typography>
                                  ) : el === 'Formulafeeds' ? (
                                    <Typography
                                      variant='h5'
                                      fontWeight='400'
                                      component='li'
                                    >
                                      {' '}
                                      Formula feeds
                                    </Typography>
                                  ) : el === 'Weaningontosolids' ? (
                                    <Typography
                                      variant='h5'
                                      fontWeight='400'
                                      component='li'
                                    >
                                      {' '}
                                      Weaning onto solids
                                    </Typography>
                                  ) : el === 'FamilyPot' ? (
                                    <Typography
                                      variant='h5'
                                      fontWeight='400'
                                      component='li'
                                    >
                                      {' '}
                                      Family Pot
                                    </Typography>
                                  ) : el === 'PickyEaterPoorEatingHabits' ? (
                                    <Typography
                                      variant='h5'
                                      fontWeight='400'
                                      component='li'
                                    >
                                      {' '}
                                      Picky Eater Poor Eating Habits
                                    </Typography>
                                  ) : el === 'EatsWell' ? (
                                    <Typography
                                      variant='h5'
                                      fontWeight='400'
                                      component='li'
                                    >
                                      {' '}
                                      Eats Well
                                    </Typography>
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  ''
                                )}
                              </>
                            );
                          })
                        ) : (
                          <>
                            {' '}
                            <Typography color='textSecondary' component='span'>
                              {' '}
                              Diet:{' '}
                            </Typography>
                            Not available.
                          </>
                        )
                      ) : (
                        <>
                          {' '}
                          <Typography color='textSecondary' component='span'>
                            {' '}
                            Diet:{' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        Dietary Details:{' '}
                      </Typography>
                      {observation.detailsDiet
                        ? formatText(observation.detailsDiet)
                        : 'Not available.'}
                    </Box>
                  </div>
                  <div className='observationtabpanelContent'>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='500'
                    >
                      {' '}
                      Excretion
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        {' '}
                        Urine:{' '}
                      </Typography>
                      {observation.selectedValueUrine
                        ? formatText(observation.selectedValueUrine)
                        : 'Not available.'}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        Stool:{' '}
                      </Typography>
                      {observation.selectedValueStool
                        ? formatText(observation.selectedValueStool)
                        : 'Not available.'}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        Details:{' '}
                      </Typography>
                      {observation.detailsExc
                        ? formatText(observation.detailsExc)
                        : 'Not available.'}
                    </Box>
                  </div>
                  <div className='observationtabpanelContent'>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='500'
                    >
                      {' '}
                      Behavioral Concerns{' '}
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        Behavioral Concerns:{' '}
                      </Typography>
                      {observation.behavioralConcerns
                        ? formatText(observation.behavioralConcerns)
                        : 'Not available.'}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        School:{' '}
                      </Typography>
                      {observation.school
                        ? formatText(observation.school)
                        : 'Not available.'}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        Comments:{' '}
                      </Typography>
                      {observation.commentsSc
                        ? formatText(observation.commentsSc)
                        : 'Not available.'}
                    </Box>
                  </div>
                  <div className='observationtabpanelContent'>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='500'
                    >
                      {' '}
                      Dental
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        Dental care:{' '}
                      </Typography>
                      {observation.dentalCare
                        ? formatText(observation.dentalCare)
                        : 'Not available.'}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        Comments:{' '}
                      </Typography>
                      {observation.detailsD
                        ? formatText(observation.detailsD)
                        : 'Not available.'}
                    </Box>
                  </div>
                </div>
              </TabPanel>
            ) : observation.selectedVisitType === 'Clinical Summary' ? (
              <TabPanel value={value} index={0} component='div'>
                <div ref={observationRef}>
                  <Typography
                    className='panelHeading'
                    sx={{
                      marginTop: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <img
                      src={VisitObservationIcon}
                      className='tabIcon'
                      alt='logoImg'
                      style={{ margin: 0 }}
                    />
                    Visit Observations-{' '}
                    {observation.selectedVisitType
                      ? observation.selectedVisitType
                      : 'Not available.'}
                  </Typography>

                  <>
                    <div className='observationtabpanelContent'>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='500'
                      >
                        Medical Record
                      </Typography>
                      <Box>
                        <Typography
                          className=''
                          color=''
                          variant='h5'
                          fontWeight='400'
                          component='ul'
                        >
                          {observation.medicalRecordsValues
                            ? Object.keys(observation.medicalRecordsValues).map(
                                (el) => {
                                  return (
                                    <Typography
                                      className=''
                                      color=''
                                      variant='h5'
                                      fontWeight='400'
                                      component='li'
                                    >
                                      {el} -{' '}
                                      {observation.medicalRecordsValues[el]
                                        ? observation.medicalRecordsValues[el]
                                        : 'Not available.'}
                                    </Typography>
                                  );
                                }
                              )
                            : 'Not available.'}
                        </Typography>
                      </Box>
                    </div>
                    {observation.clinicalSummaryMedicationData
                      ?.antihypertensives ? (
                      <MedicationPreviewOld
                        data={observation.clinicalSummaryMedicationData}
                      />
                    ) : observation.clinicalSummaryMedicationData ? (
                      <MedicationPreviewNew
                        data={observation.clinicalSummaryMedicationData}
                      />
                    ) : null}

                    <div className='observationtabpanelContent'>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='500'
                      >
                        Family History
                      </Typography>
                      <Box sx={{ width: '100%' }}>
                        {observation.familyHistoryValues ? (
                          Object.entries(observation.familyHistoryValues)
                            .filter(([medicine, value]) => value)
                            .map(([medicine]) => (
                              <Typography
                                variant='h5'
                                fontWeight='400'
                                component='li'
                              >
                                {medicine}
                                {medicine === 'other' &&
                                  ' - ' + observation.familyHistoryValues.other}
                              </Typography>
                            ))
                        ) : (
                          <>
                            {' '}
                            <Typography color='textSecondary' component='span'>
                              {' '}
                              Family History:{' '}
                            </Typography>
                            Not available.
                          </>
                        )}
                      </Box>
                    </div>
                    <div className='observationtabpanelContent'>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='500'
                      >
                        Medical History
                      </Typography>
                      <Box sx={{ width: '100%' }}>
                        {observation.medicationHistoryValues ? (
                          Object.entries(observation.medicationHistoryValues)
                            .filter(([medicine, value]) => value)
                            .map(([medicine]) => (
                              <Typography
                                variant='h5'
                                fontWeight='400'
                                component='li'
                              >
                                {medicine}
                                {medicine === 'other' &&
                                  ' - ' +
                                    observation.medicationHistoryValues.other}
                              </Typography>
                            ))
                        ) : (
                          <>
                            {' '}
                            <Typography color='textSecondary' component='span'>
                              {' '}
                              Medical History:{' '}
                            </Typography>
                            Not available.
                          </>
                        )}
                      </Box>
                    </div>
                    {observation.CheckMedicalHistoryOther && (
                      <div className='observationtabpanelContent'>
                        <Typography
                          className=''
                          color=''
                          variant='h5'
                          fontWeight='500'
                        >
                          Other Medical History
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                          <Typography color='textSecondary' component='span'>
                            Other Medical History:{' '}
                          </Typography>
                          {observation.CheckMedicalHistoryOther
                            ? formatText(observation.CheckMedicalHistoryOther)
                            : 'Not available.'}
                        </Box>
                      </div>
                    )}
                    <div className='observationtabpanelContent'>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='500'
                      >
                        Surgical History
                      </Typography>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Surgical History:{' '}
                        </Typography>
                        {observation.surgicalHistory
                          ? formatText(observation.surgicalHistory)
                          : 'Not available.'}
                      </Box>
                    </div>

                    <div className='observationtabpanelContent'>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='500'
                      >
                        Obstetric History
                      </Typography>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Obstetric History:{' '}
                        </Typography>
                        {observation.obstetricHistory
                          ? formatText(observation.obstetricHistory)
                          : 'Not available.'}
                      </Box>
                    </div>

                    <div className='observationtabpanelContent'>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='500'
                      >
                        Screening Tests
                      </Typography>
                      <Box sx={{ width: '100%' }}>
                        {observation.screeningTestsValues ? (
                          Object.entries(observation.screeningTestsValues)
                            .filter(([medicine, value]) => value)
                            .map(([medicine]) =>
                              medicine.endsWith('date') ? null : (
                                <Typography
                                  variant='h5'
                                  fontWeight='400'
                                  component='li'
                                >
                                  {medicine}{' '}
                                  {observation.screeningTestsValues[
                                    `${medicine}_date`
                                  ] && (
                                    <small style={{ color: 'gray' }}>
                                      <em>
                                        (
                                        {moment(
                                          observation.screeningTestsValues[
                                            `${medicine}_date`
                                          ]
                                        ).format('MMM, DD, YYYY')}
                                        )
                                      </em>
                                    </small>
                                  )}
                                </Typography>
                              )
                            )
                        ) : (
                          <>
                            {' '}
                            <Typography color='textSecondary' component='span'>
                              {' '}
                              Screening Tests:{' '}
                            </Typography>
                            Not available.
                          </>
                        )}
                      </Box>
                    </div>
                  </>
                </div>
              </TabPanel>
            ) : null}

            <TabPanel value={value} index={1} component='div'>
              <Typography
                className='panelHeading'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <img
                  src={AllergyIcon}
                  className='tabIcon'
                  alt='logoImg'
                  style={{ margin: 0 }}
                />
                Allergy -{' '}
                {observation.selectedVisitType
                  ? observation.selectedVisitType
                  : 'Not available.'}
              </Typography>
              <div className='observationtabpanelContent'>
                <Typography className='' color='' variant='h5' fontWeight='500'>
                  Allergy
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Allergen:{' '}
                  </Typography>
                  {allergy.allergenSelected
                    ? allergy.allergenSelected
                    : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Other allergen:{' '}
                  </Typography>
                  {allergy.allergenOtherExplain
                    ? allergy.allergenOtherExplain === 'none'
                      ? 'None'
                      : allergy.allergenOtherExplain
                    : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Reaction:{' '}
                  </Typography>
                  {allergy.reactionSelected
                    ? allergy.reactionSelected
                    : 'Not available.'}
                </Typography>
                <Box sx={{ width: '100%' }}>
                  <Typography color='textSecondary' component='span'>
                    Notes:{' '}
                  </Typography>
                  {allergy.notes ? formatText(allergy.notes) : 'Not available.'}
                </Box>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2} component='div'>
              <Typography
                className='panelHeading'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <img
                  src={VaccineIcon}
                  className='tabIcon'
                  alt='logoImg'
                  style={{ margin: 0 }}
                />
                Vaccination -{' '}
                {observation.selectedVisitType
                  ? observation.selectedVisitType
                  : 'Not available.'}
              </Typography>
              {vaccination &&
                vaccination?.length > 0 &&
                vaccination?.map((ele, i) => {
                  return (
                    <div className='observationtabpanelContent'>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='500'
                      >
                        Vaccination&nbsp;{Number(i) + 1}
                      </Typography>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='400'
                      >
                        <Typography color='textSecondary' component='span'>
                          Vaccination:{' '}
                        </Typography>
                        {ele.vaccination ? ele.vaccination : 'Not available.'}
                      </Typography>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='400'
                      >
                        <Typography color='textSecondary' component='span'>
                          Dose:{' '}
                        </Typography>
                        {ele.dose ? ele.dose : 'Not available.'}
                      </Typography>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='400'
                      >
                        <Typography color='textSecondary' component='span'>
                          Batch Number:{' '}
                        </Typography>
                        {ele.batchNumber ? ele.batchNumber : 'Not available.'}
                      </Typography>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='400'
                      >
                        <Typography color='textSecondary' component='span'>
                          Route of Administrator:{' '}
                        </Typography>
                        {ele.routeOfAdministration
                          ? ele.routeOfAdministration
                          : 'Not available.'}
                      </Typography>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='400'
                      >
                        <Typography color='textSecondary' component='span'>
                          Site Of injection:{' '}
                        </Typography>
                        {ele.siteOfInjection
                          ? ele.siteOfInjection
                          : 'Not available.'}
                      </Typography>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Notes:{' '}
                        </Typography>
                        {ele.notes ? formatText(ele.notes) : 'Not available.'}
                      </Box>
                    </div>
                  );
                })}
            </TabPanel>
            <TabPanel value={value} index={3} component='div'>
              <Typography
                className='panelHeading'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <img
                  src={ExamIcon}
                  className='tabIcon'
                  alt='logoImg'
                  style={{ margin: 0 }}
                />
                Examination Findings -{' '}
                {observation.selectedVisitType
                  ? observation.selectedVisitType
                  : 'Not available.'}
              </Typography>
              <div className='observationtabpanelContent'>
                <Typography className='' color='' variant='h5' fontWeight='500'>
                  Examination Findings
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    General:{' '}
                  </Typography>
                  {examination.general ? examination.general : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Skin:{' '}
                  </Typography>
                  {examination.skin ? examination.skin : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Head:{' '}
                  </Typography>
                  {examination.head ? examination.head : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Eyes:{' '}
                  </Typography>
                  {examination.eyes ? examination.eyes : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Ears:{' '}
                  </Typography>
                  {examination.ears ? examination.ears : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Nose:{' '}
                  </Typography>
                  {examination.nose ? examination.nose : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Throat:{' '}
                  </Typography>
                  {examination.throat ? examination.throat : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Mouth Teeth:{' '}
                  </Typography>
                  {examination.mouthTeeth
                    ? examination.mouthTeeth
                    : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Neck:{' '}
                  </Typography>
                  {examination.neck ? examination.neck : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Chest:{' '}
                  </Typography>
                  {examination.chest ? examination.chest : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Heart:{' '}
                  </Typography>
                  {examination.heart ? examination.heart : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Peripheral Pulses:{' '}
                  </Typography>
                  {examination.peripheralPulses
                    ? examination.peripheralPulses
                    : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Back:{' '}
                  </Typography>
                  {examination.back ? examination.back : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Abdomen:{' '}
                  </Typography>
                  {examination.abdomen ? examination.abdomen : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Genitalia:{' '}
                  </Typography>
                  {examination.genitalia
                    ? examination.genitalia
                    : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Extremeties:{' '}
                  </Typography>
                  {examination.extremeties
                    ? examination.extremeties
                    : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Neurological:{' '}
                  </Typography>
                  {examination.neurological
                    ? examination.neurological
                    : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Lymph Nodes:{' '}
                  </Typography>
                  {examination.lymphNodes
                    ? examination.lymphNodes
                    : 'Not available.'}
                </Typography>
                <Box sx={{ width: '100%' }}>
                  <Typography color='textSecondary' component='span'>
                    Abnormal Findings:
                  </Typography>
                  {examination.abnormalFindings
                    ? formatText(examination.abnormalFindings)
                    : ' Not available.'}
                </Box>
              </div>
            </TabPanel>
            <TabPanel value={value} index={4} component='div'>
              <Typography
                className='panelHeading'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <img
                  src={GrowthIcon}
                  className='tabIcon'
                  alt='logoImg'
                  style={{ margin: 0 }}
                />
                Growth Parameters & Vitals -{' '}
                {observation.selectedVisitType
                  ? observation.selectedVisitType
                  : 'Not available.'}
              </Typography>
              <div className='observationtabpanelContent'>
                <Typography className='' color='' variant='h5' fontWeight='500'>
                  Growth Parameters & Vitals
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Weight:{' '}
                  </Typography>
                  {growth.weight ? growth.weight : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Height:{' '}
                  </Typography>
                  {growth.height ? growth.height : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Head Circumference:{' '}
                  </Typography>
                  {growth.headCir ? growth.headCir : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    BMI:{' '}
                  </Typography>
                  {growth.bmi || growth.bmi === 0
                    ? growth.bmi
                    : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Temperature C:{' '}
                  </Typography>
                  {growth.tempC ? growth.tempC : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Pulse Rate (BPM):{' '}
                  </Typography>
                  {growth.pulseRate ? growth.pulseRate : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    Respiratory Rate:{' '}
                  </Typography>
                  {growth.respi ? growth.respi : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    BP:{' '}
                  </Typography>
                  {growth.bp ? growth.bp : 'Not available.'}
                </Typography>
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  <Typography color='textSecondary' component='span'>
                    LMP:{' '}
                  </Typography>
                  {growth.lmp ? growth.lmp : 'Not available.'}
                </Typography>
              </div>
            </TabPanel>
            <TabPanel value={value} index={5} component='div'>
              <div ref={diagnosisRef}>
                <Typography
                  className='panelHeading'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                >
                  <img
                    src={DiagnosisIcon}
                    className='tabIcon'
                    alt='logoImg'
                    style={{ margin: 0 }}
                  />
                  Diagnosis & Plan -{' '}
                  {observation.selectedVisitType
                    ? observation.selectedVisitType
                    : 'Not available.'}
                </Typography>
                <div className='observationtabpanelContent'>
                  <Typography
                    className=''
                    color='textSecondary'
                    variant='h5'
                    fontWeight='500'
                  >
                    {' '}
                    Diagnosis & Impression
                  </Typography>
                  <Box sx={{ width: '100%' }}>
                    {diagnosis.diagInformant
                      ? formatText(diagnosis.diagInformant)
                      : 'Diagnosis: Not available.'}
                  </Box>
                </div>
                <div className='observationtabpanelContent'>
                  <Typography
                    className=''
                    color='textSecondary'
                    variant='h5'
                    fontWeight='500'
                  >
                    {' '}
                    Plan{' '}
                  </Typography>
                  <Box sx={{ width: '100%' }}>
                    {diagnosis.planInformant
                      ? formatText(diagnosis.planInformant)
                      : 'Plan: Not available.'}
                  </Box>
                  <Typography
                    className=''
                    color=''
                    variant='h5'
                    fontWeight='400'
                  >
                    Next visit:
                    {diagnosis.nextVisit
                      ? ' ' + diagnosis.nextVisit
                      : ' Not available.'}
                  </Typography>
                </div>
              </div>
            </TabPanel>
          </Box>
        </CardContent>
      </Card>
      {/* <Card
            sx={{
                p: {
                    xs: '0px',
                    sm: '0px',
                    lg: '0px',
                },
                width: '100%'
            }}
            className="newPatientCard"
            style={{
                // marginLeft: '20px',
            }}
        >
            <CardContent
                sx={{
                    p: 0,
                }}
            >
                <Toolbar
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 2, sm: 2 },
                        bgcolor: (theme) => '#1990FC'
                    }}
                >
                    <Typography className='panelHeading' sx={{
                        marginTop: '10px', display: 'flex',
                        alignItems: 'center',
                        gap: '20px',
                        color: 'white'
                    }}>
                        <img src={VisitObservationIcon} className="tabIcon" alt="logoImg" style={{ margin: 0 }} />
                        Visit Detail- {row.visitType}
                    </Typography>
                  

                </Toolbar>
                <Box sx={{
                    width: '100%',
                    p: {
                        xs: '20px',
                        sm: '35px',
                        lg: '35px',
                    },
                    pt: {
                        xs: '10px',
                        sm: '10px',
                        lg: '10px',
                    }
                }}
                    className="newPatientCardBox"
                >

                    <div> 
                        <div className="observationtabpanelContent" >
                            <Typography className='' variant="h5" fontWeight="500"> Overview  </Typography>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Informant:  </Typography>{observation.informant ? formatText(observation.informant) : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Current issues:  </Typography>
                                {observation.currentIssues ? formatText(observation.currentIssues) : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span"> Medications:</Typography>
                                {observation.medications ? formatText(observation.medications) : 'Not available.'}
                            </Box>
                        </div>
                        <div className="observationtabpanelContent" >
                            <Typography className='' color="" variant="h5" fontWeight="500">Antenatal History</Typography>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Mother’s age:  </Typography>
                                {observation.mothersAge ? observation.mothersAge : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Parity:  </Typography>
                                {observation.gravida ? observation.gravida : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Complications & Medications:  </Typography>
                                {observation.compMed ? formatText(observation.compMed) : 'Not available.'}
                            </Box>
                            <Box>
                                <Typography color="textSecondary" >Blood investigations:  </Typography>
                                <Typography className='' color="" variant="h5" fontWeight="400" component="ul">
                                    {observation.bloodInvestigationValues
                                        ? Object.keys(observation.bloodInvestigationValues).map(el => {
                                            return <Typography className='' color="" variant="h5" fontWeight="400" component="li"  >
                                                {el} -. {observation.bloodInvestigationValues[el] ? observation.bloodInvestigationValues[el] : 'Not available.'}
                                            </Typography>

                                        }) : 'Not available.'}
                                </Typography>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Details:  </Typography>
                                {observation.bloodDetail ? formatText(observation.bloodDetail) : 'Not available.'}
                            </Box>
                        </div>
                        <div className="observationtabpanelContent" >
                            <Typography className='' color="" variant="h5" fontWeight="500">Birth History</Typography>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Gestational Age:  </Typography>
                                {observation.gestationalAge ? observation.gestationalAge : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Mode of delivery
                                    :  </Typography> {observation.modeOfDelivery ? observation.modeOfDelivery : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Indication for LSCS:  </Typography>
                                {observation.indicationForLscs ? observation.indicationForLscs : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Details:  </Typography>
                                {observation.birthHistoryDetail ? formatText(observation.birthHistoryDetail) : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Birth weight:  </Typography>
                                {observation.birthWeight ? observation.birthWeight + ' KG' : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Length:  </Typography>
                                {observation.lengthBirth ? observation.lengthBirth + ' CM' : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Head Circumference:  </Typography>
                                {observation.headCircumference ? observation.headCircumference : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">APGAR score:  </Typography>
                                {observation.apgarScore ? observation.apgarScore : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Complications:  </Typography>
                                {observation.compBirth ? formatText(observation.compBirth) : 'Not available.'}
                            </Box>
                        </div>
                        <div className="observationtabpanelContent" >
                            <Typography className='' color="" variant="h5" fontWeight="500">History</Typography>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Past medical History:  </Typography> {observation.pastMedicalHistory ? formatText(observation.pastMedicalHistory) : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Family History:  </Typography> {observation.familyHistory ? formatText(observation.familyHistory) : 'Not available.'}
                            </Box>
                        </div>
                        <div className="observationtabpanelContent" >
                            <Typography className='' color="" variant="h5" fontWeight="500"> Diet</Typography>
                            <Box sx={{ width: '100%' }}>
                                {observation.dietValues
                                    ? Object.keys(observation.dietValues).filter(f => observation.dietValues[f] === true).length > 0
                                        ? Object.keys(observation.dietValues).map(el => {
                                            return <>
                                                {(observation.dietValues[el] === true) ?
                                                    (el === 'Breastmilk')
                                                        ? <Typography variant="h5" fontWeight="400" component="li"> Breast milk</Typography>
                                                        : (el === 'Formulafeeds')
                                                            ? <Typography variant="h5" fontWeight="400" component="li"> Formula feeds</Typography>
                                                            : (el === 'Weaningontosolids')
                                                                ? <Typography variant="h5" fontWeight="400" component="li"> Weaning onto solids</Typography>
                                                                : (el === 'FamilyPot')
                                                                    ? <Typography variant="h5" fontWeight="400" component="li"> Family Pot</Typography>
                                                                    : (el === 'PickyEaterPoorEatingHabits')
                                                                        ? <Typography variant="h5" fontWeight="400" component="li"> Picky Eater Poor Eating Habits</Typography>
                                                                        : (el === 'EatsWell')
                                                                            ? <Typography variant="h5" fontWeight="400" component="li"> Eats Well</Typography>
                                                                            : ''
                                                    : ''
                                                }
                                            </>
                                        }) : <>  <Typography color="textSecondary" component="span"> Diet: </Typography>Not available.</>
                                    : <>  <Typography color="textSecondary" component="span"> Diet: </Typography>Not available.</>
                                }
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Dietary Details: </Typography>
                                {observation.detailsDiet ? formatText(observation.detailsDiet) : 'Not available.'}
                            </Box>
                        </div>
                        <div className="observationtabpanelContent" >
                            <Typography className='' color="" variant="h5" fontWeight="500"> Excretion</Typography>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span"> Urine:  </Typography>
                                {observation.selectedValueUrine ? observation.selectedValueUrine : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Stool:  </Typography>
                                {observation.selectedValueStool ? observation.selectedValueStool : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Details: </Typography>
                                {observation.detailsExc ? formatText(observation.detailsExc) : 'Not available.'}
                            </Box>
                        </div>
                        <div className="observationtabpanelContent" >
                            <Typography className='' color="" variant="h5" fontWeight="500"> Behavioral Concerns  </Typography>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Behavioral Concerns: </Typography>
                                {observation.behavioralConcerns ? formatText(observation.behavioralConcerns) : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">School:  </Typography>
                                {observation.school ? formatText(observation.school) : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Comments:  </Typography>
                                {observation.commentsSc ? formatText(observation.commentsSc) : 'Not available.'}
                            </Box>
                        </div>
                        <div className="observationtabpanelContent" >
                            <Typography className='' color="" variant="h5" fontWeight="500"> Dental</Typography>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Dental care:  </Typography>
                                {observation.dentalCare ? formatText(observation.dentalCare) : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Comments:  </Typography>
                                {observation.detailsD ? formatText(observation.detailsD) : 'Not available.'}
                            </Box>
                        </div>

                        <div className="observationtabpanelContent" >
                            <Typography className='' color="" variant="h5" fontWeight="500">Allergy</Typography>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Allergen:  </Typography>
                                {allergy.allergenSelected ? allergy.allergenSelected : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Other allergen:  </Typography>
                                {allergy.allergenOtherExplain ? allergy.allergenOtherExplain === 'none' ? 'None' : allergy.allergenOtherExplain : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Reaction:  </Typography>
                                {allergy.reactionSelected ? allergy.reactionSelected : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Notes:  </Typography>
                                {allergy.notes ? formatText(allergy.notes) : 'Not available.'}
                            </Box>
                        </div>
                        <div className="observationtabpanelContent" >
                            <Typography className='' color="" variant="h5" fontWeight="500">Vaccination</Typography>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Vaccination:  </Typography>
                                {vaccination.vaccination ? vaccination.vaccination : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Batch Number:  </Typography>
                                {vaccination.batchNumber ? vaccination.batchNumber : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Route of Administrator:  </Typography>
                                {vaccination.routeOfAdministration ? vaccination.routeOfAdministration : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Site Of injection:  </Typography>
                                {vaccination.siteOfInjection ? vaccination.siteOfInjection : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Notes:  </Typography>
                                {vaccination.notes ? formatText(vaccination.notes) : 'Not available.'}
                            </Box>
                        </div>
                        <div className="observationtabpanelContent" >
                            <Typography className='' color="" variant="h5" fontWeight="500">Examination Findings</Typography>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">General:  </Typography>
                                {examination.general ? examination.general : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Skin:  </Typography>
                                {examination.skin ? examination.skin : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Head:  </Typography>
                                {examination.head ? examination.head : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Eyes:  </Typography>
                                {examination.eyes ? examination.eyes : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Ears:  </Typography>
                                {examination.ears ? examination.ears : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Nose:  </Typography>
                                {examination.nose ? examination.nose : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Throat:  </Typography>
                                {examination.throat ? examination.throat : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Mouth Teeth:  </Typography>
                                {examination.mouthTeeth ? examination.mouthTeeth : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Neck:  </Typography>
                                {examination.neck ? examination.neck : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Chest:  </Typography>
                                {examination.chest ? examination.chest : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Heart:  </Typography>
                                {examination.heart ? examination.heart : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Peripheral Pulses:  </Typography>
                                {examination.peripheralPulses ? examination.peripheralPulses : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Back:  </Typography>
                                {examination.back ? examination.back : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Abdomen:  </Typography>
                                {examination.abdomen ? examination.abdomen : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Genitalia:  </Typography>
                                {examination.genitalia ? examination.genitalia : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Extremeties:  </Typography>
                                {examination.extremeties ? examination.extremeties : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Neurological:  </Typography>
                                {examination.neurological ? examination.neurological : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Lymph Nodes:  </Typography>
                                {examination.lymphNodes ? examination.lymphNodes : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Abnormal Findings: </Typography>
                                {examination.abnormalFindings ? formatText(examination.abnormalFindings) : 'Not available.'}
                            </Box>
                        </div>
                        <div className="observationtabpanelContent" >
                            <Typography className='' color="" variant="h5" fontWeight="500">Growth Parameters & Vitals</Typography>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Weight:  </Typography>
                                {growth.weight ? growth.weight : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Height:  </Typography>
                                {growth.height ? growth.height : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Head Circumference:  </Typography>
                                {growth.headCir ? growth.headCir : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">BMI:  </Typography>
                                {growth.bmi || growth.bmi === 0 ? growth.bmi : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Temperature C:  </Typography>
                                {growth.tempC ? growth.tempC : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Pulse Rate (BPM):  </Typography>
                                {growth.pulseRate ? growth.pulseRate : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Respiratory Rate:  </Typography>
                                {growth.respi ? growth.respi : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">BP:  </Typography>
                                {growth.bp ? growth.bp : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">LMP:  </Typography>
                                {growth.lmp ? growth.lmp : 'Not available.'}
                            </Box>
                        </div>
                        <div className="observationtabpanelContent" >
                            <Typography className='' color="" variant="h5" fontWeight="500"> Diagnosis & Plan</Typography>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Diagnosis & Impression: </Typography>
                                {diagnosis.diagInformant ? formatText(diagnosis.diagInformant) : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Plan: </Typography>
                                {diagnosis.planInformant ? formatText(diagnosis.planInformant) : 'Not available.'}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography color="textSecondary" component="span">Next visit: </Typography>
                                {diagnosis.nextVisit ? diagnosis.nextVisit : 'Not available.'}
                            </Box>
                        </div>
                    </div>
                </Box>
            </CardContent>
        </Card> */}
    </PageContainer>
  );
};

export default VisitDetail;
