import { URL } from '../../config';
import {
    GET_ACTIVE_USER,
    GET_PENDING_USER,
    GET_INACTIVE_USER,
    SHOW_SPINNER,
    GET_DOCTORS_LIST,
    SHOW_NOTIFICATION
} from '../constants';
import axios from 'axios'

export const getUserList = (data) => async (dispatch) => {
    try {
        let token = sessionStorage.getItem('token')
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        const userList = await axios.post(URL + 'user/all', data, {
            headers: {
                "authorization": `Bearer ${token}`
            }
        })
        if (userList) {
            if (userList.status === 200) {
                dispatch({ type: GET_ACTIVE_USER, userList: userList.data.data.activeUser })
                dispatch({ type: GET_PENDING_USER, userList: userList.data.data.pendingUser })
                dispatch({ type: GET_INACTIVE_USER, userList: userList.data.data.inActiveUser })
            }
        } else {

        }
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return userList.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return err
    }
}

export const updateUserStatus = (data) => async (dispatch) => {
    try {
        let token = sessionStorage.getItem('token')
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        const userList = await axios.post(URL + 'user/verify', data, {
            headers: {
                "authorization": `Bearer ${token}`
            }
        })

        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return userList.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return err
    }
}

export const getDoctorList = () => async (dispatch) => {
    try {
        let token = sessionStorage.getItem('token')
        // dispatch({
        //     type: SHOW_SPINNER,
        //     data: true
        // });
        const doctorList = await axios.get(URL + 'user/doctor', {
            headers: {
                "authorization": `Bearer ${token}`
            }
        })
        dispatch({
            type: GET_DOCTORS_LIST,
            data: doctorList.data.data.doctorList
        })
        // dispatch({
        //     type: SHOW_SPINNER,
        //     data: false
        // });
        return doctorList.data
    } catch (err) {
        // dispatch({
        //     type: SHOW_SPINNER,
        //     data: false
        // });
        return err.response
    }
}

export const updateUserAction = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'user/update', data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        dispatch({
            type: SHOW_NOTIFICATION,
            isShow: true,
            content: { type: 'error', content: 'Something went wrong.' },
            origin: 'updateUserAction'
        })
        return err.response
    }
}

export const deleteuser = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'user/delete', data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        dispatch({
            type: SHOW_NOTIFICATION,
            isShow: true,
            content: { type: 'error', content: 'Something went wrong.' },
            origin: 'deleteuser'
        })
        return err.response
    }
}