import React, { useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardContent, Typography } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import MonthIcon from '../../assets/icons/MonthIcon.png'
import { useEffect } from 'react';
import moment from 'moment/moment';

const AppointmentMonthWise = () => {

    // const theme = useTheme(); 
    const agesList = useSelector(state => state.ReportReducer.PagetientAges)
    const [patientAgesArray, setPatientAgesArray] = useState([]);
    const [chartData, setChartData] = useState({
        options: {
            chart: {
                id: "bar",
                height: 500
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: false,
                }
            },
            title: {
                text: 'Patient Ages',
                align: 'left'
            },
            xaxis: {
                categories: ["0-10", "10-20", "20-30", '30-40', "40-50", "50-60", "60-70", "70-80", "80-90", "90-100"],
                title: {
                    text: 'Patient Age',
                },
            },
            yaxis: {
                title: {
                    text: 'Patient Age Count',
                },
            },
        },
        series: [
            {
                name: "Patient Count :-",
                data: []
            }
        ]
    });

    useEffect(() => {
        if (agesList) {
            const agesArray = Object.values(agesList).filter(value => value !== null);
            setChartData(prevChartData => ({
                ...prevChartData,
                series: [
                    {
                        data: agesArray,
                    },
                ],
            }));
        }
    }, [agesList])

    return (
        <Card
            sx={{
                p: {
                    xs: '10px',
                    sm: '10px',
                    lg: '20px',
                },
                width: '100%'
            }}
        >
            <CardContent>
                <Typography sx={{
                    flex: '1 1 100%', p: {
                        xs: '10px',
                        sm: '10px',
                        lg: '10px',
                    },
                    color: '#464646',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px'
                }} variant="h4" id="" fontWeight="500" component="div">
                    <img src={MonthIcon} className="tabIcon" alt="logoImg" />
                    Patient Age Analysis
                </Typography>
                <Chart
                    options={chartData.options}
                    series={chartData.series}
                    type="bar"
                    height="600"
                />
            </CardContent>
        </Card>
    );
};

export default AppointmentMonthWise;
