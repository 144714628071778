import React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import Breadcrumb from "../../layouts/full-layout/breadcrumb/Breadcrumb";
import PageContainer from "../container/PageContainer";
import ChooseHospital from "../Hospitals/ChooseHospital";
import AppointmentCalendar from './Appoinment'

const Appointments = () => {
    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const rootRoute = useMemo(() => {
        if (userinfo && userinfo.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])
    const BCrumb = [
        {
            to: rootRoute + '/appointment',
            title: 'Appointment',
        },
        // {
        //     title: 'Pagination Table',
        // },
    ];
    return <>
        <PageContainer title="Appointments" description="this is Appointments page">
            <Breadcrumb title="Appointment Calendar" items={BCrumb} children={userinfo?.role === 'admin' && <ChooseHospital />} />
            <div className="patientDetailPage appointmentPage">

                <AppointmentCalendar showAppointment="All" />
            </div>
        </PageContainer>
    </>
}

export default Appointments 