import React, { useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardContent, Typography } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import MonthIcon from '../../assets/icons/MonthIcon.png'
import { useEffect } from 'react';
import moment from 'moment/moment';

const AppointmentMonthWise = () => {

    // const theme = useTheme(); 
    const monthwiseAppointmentList = useSelector(state => state.DashboardReducer.monthwiseAppointmentList)
    const [approvedData, setApprovedData] = useState([])
    const [pendingData, setpendingData] = useState([])
    const [rescheduledData, setrescheduledData] = useState([])
    // const [monthsList, setmonthsList] = useState([])

    useMemo(() => {
        if (monthwiseAppointmentList) {
            setApprovedData([])
            setpendingData([])
            setrescheduledData([])
            // setmonthsList([])
            for (let i = 0; i < 12; i++) {
                if (monthwiseAppointmentList[i]) {
                    setApprovedData(a => [...a, monthwiseAppointmentList[i].approved])
                    setpendingData(a => [...a, monthwiseAppointmentList[i].pending])
                    setrescheduledData(a => [...a, monthwiseAppointmentList[i].rescheduled])
                    // setmonthsList(a => [...a, moment().month(i).format('MMM')])
                } else {
                    setApprovedData(a => [...a, 0])
                    setpendingData(a => [...a, 0])
                    setrescheduledData(a => [...a, 0])
                    // setmonthsList(a => [...a, moment().month(i).format('MMM')])
                }

            }
            // monthwiseAppointmentList.forEach(element => {
            //     setApprovedData(a => [...a, element.approved])
            //     setpendingData(a => [...a, element.pending])
            //     setrescheduledData(a => [...a, element.rescheduled])
            //     setmonthsList(a => [...a, element.month])

            // })
        } else {
            return []
        }
    }, [monthwiseAppointmentList])
    useEffect(() => {
        setApprovedData([])
        setpendingData([])
        setrescheduledData([])
        // setmonthsList([])
    }, [])

    // const optionsrevenue = {
    //     grid: {
    //         show: true,
    //         // borderColor: 'rgba(0, 0, 0, .2)',
    //         color: 'black',
    //         strokeDashArray: 2,
    //         xaxis: {
    //             lines: {
    //                 show: true,
    //             },
    //         },
    //         yaxis: {
    //             lines: {
    //                 show: true,
    //             },
    //         },
    //     },
    //     dataLabels: {
    //         enabled: true,
    //         style: {
    //             colors: ['#333']
    //         },
    //     },
    //     chart: {
    //         fontFamily: 'DM Sans',
    //         foreColor: '#adb0bb',
    //         toolbar: {
    //             show: true,
    //         },
    //         stacked: true,
    //     },
    //     // stroke: {
    //     //     curve: 'smooth',
    //     //     width: 3,
    //     // },
    //     colors: ['#6183FB', '#8EE486', '#FFE55C'],

    //     xaxis: {
    //         categories: monthsList,
    //     },
    //     markers: {
    //         size: 4,
    //         border: 3,
    //     },
    //     tooltip: {
    //         x: {
    //             format: 'dd/MM/yy HH:mm',
    //         },
    //         theme: 'light',
    //     },
    //     legend: {
    //         show: true,
    //     },
    // };
    // const optionslinechart = {
    //     chart: {
    //         height: 350,
    //         type: 'line',
    //         fontFamily: "'DM Sans', sans-serif",
    //         foreColor: '#adb0bb',
    //         zoom: {
    //             type: 'x',
    //             enabled: false,
    //         },
    //         toolbar: {
    //             show: false,
    //         },
    //         shadow: {
    //             enabled: true,
    //             color: '#000',
    //             top: 18,
    //             left: 7,
    //             blur: 10,
    //             opacity: 1,
    //         },
    //     },
    //     xaxis: {
    //         categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    //         title: {
    //             text: 'Month (' + moment().format('YYYY') + ')',
    //         },
    //     },
    //     yaxis: {
    //         title: {
    //             text: 'Appointments',
    //         },
    //     },
    //     grid: {
    //         show: true,
    //     },
    //     colors: ['#6183FB', '#8EE486', '#FFE55C'],
    //     dataLabels: {
    //         enabled: true,
    //     },
    //     stroke: {
    //         curve: 'straight',
    //         width: '2',
    //     },
    //     legend: {
    //         position: 'top',
    //         horizontalAlign: 'right',
    //         floating: true,
    //         offsetY: -25,
    //         offsetX: -5
    //     },
    //     tooltip: {
    //         theme: 'dark',
    //     },
    // };
    const optionslinechart = {
        chart: {
            height: 350,
            type: 'line',
            // dropShadow: {
            //     enabled: true,
            //     color: '#000',
            //     top: 18,
            //     left: 7,
            //     blur: 10,
            //     opacity: 0.2
            // },
            toolbar: {
                show: false
            }
        },
        title: {
            text: 'Appointments Per Month',
            align: 'left'
        },
        colors: ['#6183FB', '#8EE486', '#FFE55C'],
        dataLabels: {
            enabled: true,
            style: {
                colors: ['lightblue']
            },
        },
        stroke: {
            // curve: 'smooth'
            color: 'black',
            curve: 'straight',
            width: 3,
        },
        // title: {
        //     text: 'Average High & Low Temperature',
        //     align: 'left'
        // },
        grid: {
            color: 'black',
            // borderColor: '#e7e7e7',
            // row: {
            //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            //     opacity: 0.3
            // },
            show: true
        },
        markers: {
            size: 2
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            title: {
                text: 'Month (' + moment().format('YYYY') + ')',
            }
        },
        yaxis: {
            title: {
                text: 'Appointments',
            },
            // min: 0,
            // max: 40
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
        }
    }


    const seriesAppointment = [
        {
            name: 'Confirm',
            data: approvedData,
        },
        {
            name: 'Pending',
            data: pendingData,
        },
        {
            name: 'Rescheduled',
            data: rescheduledData,
        },

    ];

    return (
        <Card
            sx={{
                p: {
                    xs: '10px',
                    sm: '10px',
                    lg: '20px',
                },
                width: '100%'
            }}
        >
            <CardContent>
                <Typography sx={{
                    flex: '1 1 100%', p: {
                        xs: '10px',
                        sm: '10px',
                        lg: '10px',
                    },
                    color: '#464646',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px'
                }} variant="h4" id="" fontWeight="500" component="div">
                    <img src={MonthIcon} className="tabIcon" alt="logoImg" />
                    Appointments Monthwise
                </Typography>

                <Chart options={optionslinechart} series={seriesAppointment} type="line" height="285" />

            </CardContent>
        </Card>
    );
};

export default AppointmentMonthWise;
