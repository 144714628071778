import { ListAlt, BlurCircular, PersonSearch, NoteAdd, FactCheck, Analytics, Medication } from '@mui/icons-material';

const VisitMenuitems = [
    {
        title: 'Observations',
        icon: <ListAlt />,
    },
    {
        title: 'Allergy',
        icon: <BlurCircular />,
    },
    {
        title: 'Vaccination',
        icon: <Medication />,
    },
    {
        title: 'Examination Findings',
        icon: <PersonSearch />,
    },
    {
        title: 'Growth Parameters',
        icon: <Analytics />,
    },
    {
        title: 'Document Uploads',
        icon: <NoteAdd />,
    },
    {
        title: 'Diagnosis & Plans',
        icon: <FactCheck />,
    },
]

export default VisitMenuitems;
