import React, { useState } from 'react';
import {
    InputAdornment,
    OutlinedInput,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';

const CustomPasswordField = ({ value, onChange, placeholder, ...props }) => {
    const [showpassword, setshowpassword] = useState(false)

    return <OutlinedInput
        {...props}
        endAdornment={
            <InputAdornment position="end" onClick={() => { setshowpassword(!showpassword) }}>
                {showpassword ? <FeatherIcon icon="eye-off" width="20" /> : <FeatherIcon icon="eye" width="20" />}
            </InputAdornment>
        }
        id="cpwd2-text"
        placeholder={placeholder}
        fullWidth
        type={showpassword ? "text" : "password"}
        // size="small"
        value={value}
        onChange={(e) => {
            onChange(e.target.value)
        }}
        className="login-content"
    />
}

export default CustomPasswordField