import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Toolbar,
  FormControl,
  Button,
  Autocomplete,
  TextField,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';

import CustomTextField from '../../../forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../forms/custom-elements/CustomFormLabel';
import AllergyIcon from '../../../../assets/icons/AllergyIcon.png';
import VisitSidebar from '../VisitSidebar';
import {
  changeTabNo,
  openMblVisitSidebar,
  saveAllergyData,
} from '../../../../redux/patients/Action';
import { useMemo } from 'react';

const Allergy = () => {
  const dispatch = useDispatch();
  const tabNoValue = useSelector((state) => state.patientReducer.tabNo);
  const tabNo = useMemo(() => {
    if (tabNoValue) return tabNoValue;
    else return 0;
  }, [tabNoValue]);
  const allergy = useSelector((state) => state.patientReducer.allergyData);

  const allergenType = [
    'Cows Milk',
    'Eggs',
    'Peanuts',
    'Tree nuts',
    'Shellfish',
    'Latex',
    'Penicillin',
    'Insect bites',
    'Costume Jewelry',
    'Oxybutynin',
    'Latex',
    'Other',
  ];
  const reactionType = [
    { label: 'Anaphylactic', value: 'Anaphylactic' },
    { label: 'Wheal & Flare', value: 'Wheal and Flare' },
    { label: 'Vomit/Diarrhoea', value: 'Vomit or Diarrhoea' },
    { label: 'Other', value: 'Other' },
  ];

  const [allergenSelected, setAllergenSelected] = useState('');
  const [allergenOtherExplain, setallergenOtherExplain] = useState('');
  const [reactionSelected, setReactionSelected] = useState('');
  const [notes, setNotes] = useState('');

  const [showOtherAllergenField, setshowOtherAllergenField] = useState(false);

  const customizer = useSelector((state) => state.CustomizerReducer);

  // const [isSidebarOpen, setSidebarOpen] = useState(true);

  const getTabSelected = (no) => {
    saveData();
    dispatch(changeTabNo(no));
  };

  useEffect(() => {
    setAllergenSelected(allergy.allergenSelected);
    setallergenOtherExplain(allergy.allergenOtherExplain);
    setshowOtherAllergenField(allergy.allergenSelected === 'Other' ? true : false);
    setReactionSelected(allergy.reactionSelected);
    setNotes(allergy.notes);
  }, [allergy]);

  // useEffect(() => {
  //     allergyDataDetail({ allergenSelected, allergenOtherExplain, reactionSelected, notes })
  // }, [allergenSelected, allergenOtherExplain, reactionSelected, notes])

  const saveData = () => {
    dispatch(
      saveAllergyData({
        ...allergy,
        allergenSelected,
        allergenOtherExplain,
        reactionSelected,
        notes,
      }),
    );
  };
  const clearFunction = () => {
    setAllergenSelected('');
    setallergenOtherExplain('');
    setReactionSelected('');
    setNotes('');
  };

  return (
    <>
      <VisitSidebar
        isSidebardir={customizer.activeDir === 'rtl' ? 'left' : 'right'}
        isSidebarOpen={true}
        onSidebarClose={() => dispatch(openMblVisitSidebar(false))}
        getTabSelected={getTabSelected}
        // tabIndex={showTabNo}
      />
      <Card
        sx={{
          p: {
            xs: '0px',
            sm: '0px',
            lg: '0px',
          },
          width: '100%',
        }}
        className="newPatientCard newVisitCard"
      >
        <CardContent
          sx={{
            p: 0,
          }}
        >
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 2, sm: 2 },
              // ...(numSelected > 0 && {
              bgcolor: (theme) => '#1990FC',
              // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
              // }),
            }}
          >
            <Typography
              sx={{
                flex: '1 1 100%',
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
              variant="h6"
              id="newpatientTitle"
              component="span"
            >
              <img src={AllergyIcon} className="tabIcon" alt="logoImg" />
              Allergy
            </Typography>
            <Typography
              sx={{
                flex: '1 1 100%',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-end',
                cursor: 'pointer',
              }}
              variant="h6"
              id="newpatientTitle"
              component="span"
              onClick={clearFunction}
            >
              Clear
              <FeatherIcon
                icon="x"
                style={{
                  width: 14,
                  height: 14,
                  marginLeft: '5px',
                  color: '#1990FC',
                  borderRadius: '50%',
                  background: 'white',
                }}
              />
            </Typography>
          </Toolbar>
          <Box
            sx={{
              width: '100%',
              p: {
                xs: '20px',
                sm: '35px',
                lg: '35px',
              },
              pt: {
                xs: '10px',
                sm: '10px',
                lg: '10px',
              },
            }}
            className="newPatientCardBox"
          >
            {' '}
            <div>
              <div className="tabpanelContent">
                <Typography className="panelHeading">
                  {/* <img src={logo1} className="tabIcon" /> */}
                  Details
                </Typography>
                <div style={{ width: '100%' }}>
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <CustomFormLabel htmlFor="demo-simple-select">Allergen</CustomFormLabel>
                    <Autocomplete
                      disablePortal
                      value={allergenSelected}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setAllergenSelected(newValue);
                          if (newValue === 'Other') setshowOtherAllergenField(true);
                          else setshowOtherAllergenField(false);
                        } else {
                          setAllergenSelected('');
                          setshowOtherAllergenField(false);
                        }
                      }}
                      id="combo-box-demo"
                      options={allergenType}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder=""
                          aria-label="Select Allergen"
                        />
                      )}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          border: 'none !important',
                        },
                        '& .MuiOutlinedInput-root': {
                          padding: '16.5px 14px !important',
                        },
                      }}
                      className="border-g1"
                    />
                  </FormControl>
                </div>
                {/* <div style={{ width: '100%' }} ref={mnameRef} onClick={() => { removeError(mnameRef) }}> */}
                {showOtherAllergenField && (
                  <div style={{ width: '100%' }}>
                    <CustomFormLabel>Other allergen</CustomFormLabel>
                    <CustomTextField
                      id="otherAllergen"
                      placeholder="Enter other allergen"
                      variant="outlined"
                      fullWidth
                      value={allergenOtherExplain}
                      onChange={(e) => {
                        setallergenOtherExplain(e.target.value);
                      }}
                    />
                  </div>
                )}
                <div style={{ width: '100%' }}>
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <CustomFormLabel htmlFor="demo-simple-select">Reaction</CustomFormLabel>
                    <Autocomplete
                      disablePortal
                      value={reactionSelected}
                      onChange={(event, newValue) => {
                        if (newValue) setReactionSelected(newValue.value);
                        else setReactionSelected('');
                      }}
                      id="combo-box-demo"
                      options={reactionType}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder=""
                          aria-label="Select Allergen"
                        />
                      )}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          border: 'none !important',
                        },
                        '& .MuiOutlinedInput-root': {
                          padding: '16.5px 14px !important',
                        },
                      }}
                      className="border-g1"
                    />
                  </FormControl>
                </div>
                <div style={{ width: '100%' }}>
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <CustomFormLabel htmlFor="demo-simple-select">Notes</CustomFormLabel>

                    <CustomTextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      // placeholder="Notes"
                      className="border-g1"
                    />
                  </FormControl>
                </div>
              </div>
              <div style={{ float: 'right', margin: '15px 0px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  className="visitTopButton"
                  onClick={() => {
                    saveData();
                    dispatch(changeTabNo(tabNo + 1));
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default Allergy;
