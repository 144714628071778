import {
    NEW_UPDATES_MODAL_DATA,
    OPEN_NEW_UPDATES_MODAL_DATA
} from '../constants';

const INIT_STATE = {
    showModal: false,
    modalContent: [],
};

const NewUpdateModalReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case NEW_UPDATES_MODAL_DATA:
            return {
                ...state,
                modalContent: action.data,
            };
        case OPEN_NEW_UPDATES_MODAL_DATA:
            return {
                ...state,
                showModal: action.data,
            };
        default:
            return state;
    }
};
export default NewUpdateModalReducer
