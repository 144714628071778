import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Card } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { showMessageModal } from '../../redux/MessageModal/Action';

const MessageModal = () => {
    const dispatch = useDispatch()
    const content = useSelector(state => state.MessageModalReducer.modalContent)
    const handleClose = () => {
        dispatch(showMessageModal({
            showModal: false,
            modalContent: {}
        }))
    }
    return <div className='modalMainClass'>
        <Card sx={{ minWidth: '350px', minHeight: '200px' }}>
            <FeatherIcon icon="x" style={{ float: 'right' }} onClick={handleClose} />
            <Box sx={{ display: 'flex', minHeight: '200px', alignItems: 'center', justifyContent: 'center' }}>
                {content.message}
            </Box>
        </Card>
    </div>
}
export default MessageModal