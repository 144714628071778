import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Card, CardContent, Toolbar, Button, Tabs, Tab, Grid } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { deleteDocuments, getAllUploadedDocuments } from '../../../../redux/patients/AsyncActions';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import DocumentUploadIcon from '../../../../assets/icons/DocumentUploadIcon.png';
import { showNotificationAction } from '../../../../redux/pushnotification/Action';
import VisitSidebar from '../VisitSidebar';
import { changeTabNo, openMblVisitSidebar } from '../../../../redux/patients/Action';
import { useMemo } from 'react';
import { showConfirmationModalAction } from '../../../../redux/confirmationModal/ActionConfirmationModal';
import ViewImage from '../../../../views/ViewImage/ViewImage';
import { URL } from '../../../../config';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const DocumentUpload = ({ documentDataDetail }) => {
    const dispatch = useDispatch()

    const tabNoValue = useSelector((state) => state.patientReducer.tabNo);
    const tabNo = useMemo(() => {
        if (tabNoValue)
            return tabNoValue
        else
            return 0
    }, [tabNoValue])

    const document = useSelector((state) => state.patientReducer.documentData);

    const { state } = useLocation();
    const { id } = state ? state : {};
    const [selectedFiles, setSelectedFiles] = useState([])
    const [savedFiles, setSavedFiles] = useState([])
    const [value, setValue] = React.useState(0);
    const [viewImage, setViewImage] = useState({ open: false, imgUrl: "" })

    const fileInput = useRef();
    const selectFile = () => {
        fileInput.current.click();
    }

    const customizer = useSelector((state) => state.CustomizerReducer);
    // const [isSidebarOpen, setSidebarOpen] = useState(true);

    const getTabSelected = (no) => {
        saveData()
        dispatch(changeTabNo(no))
    }

    useEffect(() => {
        if (document)
            setSelectedFiles(document.selectedFiles ? document.selectedFiles : [])
    }, [document])

    const saveData = () => {
        if (selectedFiles.length > 0) {
            documentDataDetail({ selectedFiles })
        } else {
            documentDataDetail()
        }
    }
    const getSelectedFile = (e) => {
        let sp = []
        dispatch(showNotificationAction(false, { type: '', content: {}, origin: 'get select file' }))
        Object.keys(e.target.files).forEach((fileEle, i) => {
            // const fileSizeKiloBytes = e.target.files[fileEle].size / (1024 * 1024)
            // if (fileSizeKiloBytes < 5) {
            let inde = selectedFiles.findIndex(a => a.name === e.target.files[i].name)
            if (inde < 0) {
                sp.push(e.target.files[fileEle])
            }
            // } else {
            //     dispatch(showNotificationAction(true, { type: 'error', content: e.target.files[fileEle]?.name + ' file size should not be more than 5MB.' }))
            // }

        })

        setSelectedFiles([...selectedFiles, ...sp])
    }

    const removeSelectedfile = (element) => {
        let selFiles = [...selectedFiles]
        selFiles.splice(element, 1)
        setSelectedFiles([...selFiles])

    }

    const clearFunction = () => {
        setSelectedFiles([])
    }
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const getAllUploadedDocumentsHandler = () => {
        dispatch(getAllUploadedDocuments(id)).then((result) => {
            if (result?.code === 200)
                setSavedFiles(result.data)
        }).catch((err) => {

        })
    }
    const deleteDocumentHandler = (documentId) => {
        dispatch(showConfirmationModalAction(true, {
            modalContent: "Are you sure you want to delete this document?",
            button1: true,
            button2: true,
            buttonText1: 'Delete',
            buttonText2: 'Cancel',
            buttonAction1: () => {
                dispatch(deleteDocuments({ documentId: documentId }))
                    .then((result) => {
                        if (result.status === true) {
                            getAllUploadedDocumentsHandler()
                            dispatch(showConfirmationModalAction(false, {
                                modalContent: "",
                                button1: false,
                                button2: false,
                                buttonText1: '',
                                buttonText2: '',
                                buttonAction1: () => { },
                                buttonAction2: () => { },
                                origin: "Deleted document"
                            }))
                        }
                    })
            },
            buttonAction2: () => {
                dispatch(showConfirmationModalAction(false, {
                    modalContent: "",
                    button1: false,
                    button2: false,
                    buttonText1: '',
                    buttonText2: '',
                    buttonAction1: () => { },
                    buttonAction2: () => { },
                    origin: "Cancel delete document"
                }))
            },
            origin: "delete document"
        }))
    }
    const debugBase64 = (base64URL, originalName) => {
        var win = window.open("", originalName);
        win.document.write('<title>' + originalName + '</title><iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen ></iframe>');

    }

    return (
        <>
            <VisitSidebar
                isSidebardir={customizer.activeDir === 'rtl' ? 'left' : 'right'}
                isSidebarOpen={true}
                onSidebarClose={() => dispatch(openMblVisitSidebar(false))}
                getTabSelected={getTabSelected}
            />
            <Card
                sx={{
                    p: {
                        xs: '0px',
                        sm: '0px',
                        lg: '0px',
                    },
                    width: '100%'
                }}
                className="newPatientCard newVisitCard"
            >
                <CardContent
                    sx={{
                        p: 0,
                    }}
                >
                    <Toolbar
                        sx={{
                            pr: { xs: 2, sm: 2 },
                            pl: { sm: 2 },
                            bgcolor: (theme) => '#1990FC'
                        }}
                    >
                        <Typography sx={{
                            flex: '1 1 100%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '20px'
                        }} variant="h6" id="newpatientTitle" component="span">
                            <img src={DocumentUploadIcon} className="tabIcon" alt="logoImg" />
                            Document Uploads
                        </Typography>
                        <Typography sx={{
                            flex: '1 1 100%', alignItems: 'center', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer'
                        }} variant="h6" id="newpatientTitle" component="span" onClick={clearFunction}>
                            Clear
                            <FeatherIcon icon="x" style={{
                                width: 14, height: 14, marginLeft: '5px', color: '#1990FC', borderRadius: '50%', background: 'white'
                            }} />
                        </Typography>
                    </Toolbar>
                    <Box sx={{
                        width: '100%',
                        p: {
                            xs: '20px',
                            sm: '35px',
                            lg: '35px',
                        },
                        pt: {
                            xs: '10px',
                            sm: '10px',
                            lg: '10px',
                        }
                    }}
                        className="newPatientCardBox"
                    >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                textColor="secondary"
                                allowScrollButtonsMobile
                                scrollButtons
                                indicatorColor="secondary"
                            >
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="Upload New Document"
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="Uploaded Document"
                                    {...a11yProps(1)}
                                    onClick={() => {
                                        getAllUploadedDocumentsHandler()
                                    }}
                                />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0} component="div">
                            <div className="tabpanelContent">
                                <Typography className='panelHeading' style={{ justifyContent: "space-between" }}>
                                    Upload Documents
                                </Typography>
                                <Typography style={{ margin: 'auto', textAlign: 'center', marginTop: '30px' }} variant="h6" id="" component="div">
                                    <input type="file" multiple accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pdf" style={{ "display": "none" }} ref={fileInput} onChange={getSelectedFile} />
                                    <Button onClick={selectFile} style={{ margin: '10px' }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="black" className="bi bi-cloud-upload m-1" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                            <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
                                        </svg>
                                    </Button><br />
                                    Upload new document
                                </Typography>
                                <Grid container spacing={0} className="fileGrid" style={{ width: '100%' }}>
                                    {
                                        selectedFiles && Object.keys(selectedFiles).map((element, i) => {
                                            return <Grid item xs={6} lg={4} key={i}>
                                                <Card sx={{ position: 'relative' }}>
                                                    <FeatherIcon icon="x" style={{
                                                        position: 'absolute', top: '10px', right: "10px",
                                                        width: 14, height: 14, color: '#1990FC', borderRadius: '50%', background: 'white'
                                                    }} onClick={() => {
                                                        removeSelectedfile(i)
                                                    }} />
                                                    <FeatherIcon icon="file" style={{
                                                        width: 40, height: 40, color: '#1990FC', background: 'white'
                                                    }} />
                                                    <Typography textAlign="center" fontSize="14px" fontWeight="500" >{selectedFiles[element].name}</Typography>
                                                </Card>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </div>
                            <div style={{ float: 'right', margin: '15px 0px' }}>
                                <Button variant="contained" color="primary" className='visitTopButton' onClick={() => {
                                    saveData()
                                    dispatch(changeTabNo(tabNo + 1))
                                }}>Continue</Button>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1} component="div">
                            {
                                savedFiles.map((element, i) => {
                                    return <div key={i} className="tabpanelContent">
                                        <Grid container spacing={0} className="fileGrid" style={{ width: '100%', marginTop: '20px' }}>
                                            <Grid item xs={12} lg={12}>
                                                <Typography variant="h5" fontWeight="600" textAlign="left" >
                                                    <Typography variant="h5" fontWeight="600" color="textSecondary" component="span">
                                                        Date: &nbsp;
                                                    </Typography>
                                                    {element.documents[0].createdAt}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {element.documents.map((doc, index) => {
                                            return <div key={index} style={{
                                                display: 'flex',
                                                alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%'
                                            }}>
                                                {doc.originalName.endsWith('.png') || doc.originalName.endsWith('.jpg') || doc.originalName.endsWith('.jpeg') || doc.originalName.endsWith('.gif')
                                                    ? <>
                                                        <Typography textAlign="center" fontSize="14px" fontWeight="500" component="div" sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}>
                                                            {doc.exists
                                                                ? <img src={`data:image/png;base64,${doc.fileName}`} alt="doc" style={{
                                                                    width: 20, height: 20, color: '#1990FC', margin: '0 10px'
                                                                }} />
                                                                : <img src={`${URL}${doc.fileName}`} alt="oldDoc" style={{
                                                                    width: 20, height: 20, color: '#1990FC', margin: '0 10px'
                                                                }} />}
                                                            {doc.originalName}
                                                        </Typography>
                                                        <Box>
                                                            <Button variant="contained" className='registerButton' color="secondary" sx={{ marginLeft: '10px', float: 'right' }}
                                                                onClick={() => { if (doc.documentId) { deleteDocumentHandler(doc.documentId) } }}>
                                                                Delete
                                                            </Button>
                                                            {doc.exists ? <Button variant="contained" className='registerButton' sx={{ marginLeft: '30px', float: 'right' }} onClick={() => {
                                                                setViewImage({ open: true, imgUrl: `data:image/${doc.originalName?.split('.')[1]};base64,${doc.fileName}` })
                                                            }}>
                                                                View
                                                            </Button> :
                                                                <Button variant="contained" className='registerButton' sx={{ marginLeft: '30px', float: 'right' }} onClick={() => {
                                                                    setViewImage({ open: true, imgUrl: `${URL}${doc.fileName}` })
                                                                }}>
                                                                    View
                                                                </Button>}
                                                        </Box>
                                                    </>
                                                    : doc.originalName.endsWith('.pdf')
                                                        ? <>
                                                            <Typography textAlign="center" fontSize="14px" fontWeight="500" component="div" sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}>
                                                                <FeatherIcon icon="file" style={{
                                                                    width: 20, height: 20, color: '#1990FC', margin: '0 10px'
                                                                }} />
                                                                {doc.originalName}
                                                            </Typography>
                                                            <Box>
                                                                <Button variant="contained" className='registerButton' color="secondary" sx={{ marginLeft: '10px', float: 'right' }}
                                                                    onClick={() => { if (doc.documentId) { deleteDocumentHandler(doc.documentId) } }}>
                                                                    Delete
                                                                </Button>
                                                                {doc.exists ? <Button variant="contained" className='registerButton' sx={{ marginLeft: '30px', float: 'right' }}
                                                                    onClick={() => {
                                                                        debugBase64(`data:application/pdf;base64,${doc.fileName}`, doc.originalName)
                                                                    }} >
                                                                    View
                                                                </Button> :
                                                                    <Button variant="contained" className='registerButton' sx={{ marginLeft: '30px', float: 'right' }}>
                                                                        <a href={`${URL}${doc.fileName}`} target='blank'
                                                                            download={doc.originalName} style={{ textDecoration: 'none', color: 'white' }}>
                                                                            {doc.fileName.endsWith('.pdf') || doc.fileName.endsWith('.png') || doc.fileName.endsWith('.jpg') || doc.fileName.endsWith('.jpeg') || doc.fileName.endsWith('.gif') || doc.fileName.endsWith('.psd') || doc.fileName.endsWith('.js') || doc.fileName.endsWith('.json') ? 'View' : 'Download'}
                                                                        </a>
                                                                    </Button>}
                                                            </Box>
                                                        </> : <>
                                                            <Typography textAlign="center" fontSize="14px" fontWeight="500" component="div" sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}>
                                                                <FeatherIcon icon="file" style={{
                                                                    width: 20, height: 20, color: '#1990FC', margin: '0 10px'
                                                                }} />
                                                                {doc.originalName}
                                                            </Typography>
                                                            <Box>
                                                                <Button variant="contained" className='registerButton' color="secondary" sx={{ marginLeft: '10px', float: 'right' }}
                                                                    onClick={() => { if (doc.documentId) { deleteDocumentHandler(doc.documentId) } }}>
                                                                    Delete
                                                                </Button>
                                                                {doc.exists ? <Button variant="contained" className='registerButton' sx={{ marginLeft: '30px', float: 'right' }}  >
                                                                    <a href={`data:application/${doc.originalName?.split('.')[1]};base64,${doc.fileName}`} target='blank'
                                                                        download={doc.originalName} style={{ textDecoration: 'none', color: 'white' }}>
                                                                        Download
                                                                    </a>
                                                                </Button> :
                                                                    <Button variant="contained" className='registerButton' sx={{ marginLeft: '30px', float: 'right' }}>
                                                                        <a href={`${URL}${doc.fileName}`} target='blank' download={doc.originalName}
                                                                            style={{ textDecoration: 'none', color: 'white' }}>
                                                                            {doc.fileName.endsWith('.pdf') || doc.fileName.endsWith('.png') || doc.fileName.endsWith('.jpg') || doc.fileName.endsWith('.jpeg') || doc.fileName.endsWith('.gif') || doc.fileName.endsWith('.psd') || doc.fileName.endsWith('.js') || doc.fileName.endsWith('.json') ? 'View' : 'Download'}
                                                                        </a>
                                                                    </Button>}
                                                            </Box>
                                                        </>
                                                }
                                            </div>
                                        })}
                                    </div>
                                })
                            }
                        </TabPanel>
                    </Box>
                </CardContent>
            </Card >
            <ViewImage viewImage={viewImage} setViewImage={setViewImage} />
        </>
    );
};

export default DocumentUpload;
