import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Button } from '@mui/material';
import CustomTextField from '../../forms/custom-elements/CustomTextField';
import FeatherIcon from 'feather-icons-react';
import { useRef } from 'react';
import logo2 from '../../../assets/images/newpatientIcon/Rectangle 4 (2).png'
import CustomFormLabel from '../../forms/custom-elements/CustomFormLabel';
import { deleteGuardian } from '../../../redux/patients/AsyncActions';
import { showConfirmationModalAction } from '../../../redux/confirmationModal/ActionConfirmationModal';
import { getPatientEmergecyContactsDetail } from "../../../redux/patients/AsyncActions";


const GuardiansPanel = ({ detail, scrollToField, returnPanelData, patientId }) => {
    const dispatch = useDispatch()
    const [guardianD, setGuardianD] = useState([{ firstName: '', lastName: '', relation: '', phone: '', secPhone: '', secPhoneTwo: '' }])

    useEffect(() => {
        if (scrollToField && scrollToField !== "") {
            if (scrollToField.refName === "myRefs1") {
                scrollDown(myRefs1.current[scrollToField.refIndex + '1'])
                // changeBorderColor(myRefs1.current[scrollToField.refIndex + '1'])
            } else if (scrollToField.refName === "myRefs2") {
                scrollDown(myRefs1.current[scrollToField.refIndex + '2'])
                // changeBorderColor(myRefs1.current[scrollToField.refIndex + '2'])
            } else if (scrollToField.refName === "myRefs3") {
                scrollDown(myRefs1.current[scrollToField.refIndex + '3'])
                // changeBorderColor(myRefs1.current[scrollToField.refIndex + '3'])
            } else if (scrollToField.refName === "myRefs4") {
                scrollDown(myRefs1.current[scrollToField.refIndex + '4'])
                // changeBorderColor(myRefs1.current[scrollToField.refIndex + '4'])
            } else {

            }
        }
    }, [scrollToField])

    useEffect(() => {
        if (patientId) {
            getEmergencyContactData();
        }
    }, [])
    const [index, setIndex] = useState(0);
    // const arr = [...new Array(guardianD.length)].map(
    //     (_, index) => index
    // );
    const myRefs1 = useRef({});

    const getEmergencyContactData = async () => {
        let guardianInfoResult = await dispatch(getPatientEmergecyContactsDetail(patientId));
        let guardianInfoResponse = guardianInfoResult.data;
        if (guardianInfoResponse.length > 0)
            var finalArray = [];
        guardianInfoResponse.map((item, index) => {
            finalArray.push({
                firstName: item.firstName,
                lastName: item.lastName,
                relation: item.relation,
                phone: item.phoneNumber,
                secPhone: item.secondaryPhoneNumber,
                secPhoneTwo: item.secondaryPhoneNumberTwo,
            })
            if (index === guardianInfoResponse.length - 1) {
                returnPanelData('guardianDetail', finalArray)
                setGuardianD(finalArray)
            }
        })
    };


    const saveThisRef1 = (index) => (element) => {
        myRefs1.current[index] = element;
    };

    const scrollDown = (ref) => {
        if (ref) {
            let scrollToPx = ref.offsetTop - 100
            window.scrollTo({
                top: scrollToPx,
                behavior: 'smooth',
            });
        }
    }

    // const changeBorderColor = (ref) => {
    //     if (ref) {
    //         ref.style.border = "2px solid red";
    //         ref.style.borderRadius = "5px";
    //     }
    // }

    const removeError = (key) => {
        myRefs1.current[key].style.border = "unset";
        myRefs1.current[key].style.borderRadius = "unset";
        // dispatch(showNotificationAction(false, { type: '', content: '' }))
    }

    const returnGuardianUI = (m, i) => {
        return <>
            <div>
                {i !== 0 || m.guardianId ? <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px', borderTop: '1px solid rgb(218 218 218)' }}>
                    <Button variant="contained" color="primary" onClick={() => {
                        if (guardianD[i]?.guardianId) {
                            dispatch(showConfirmationModalAction(true, {
                                modalContent: "Are you sure you want to remove this guardian?",
                                button1: true,
                                button2: true,
                                buttonText1: 'Remove',
                                buttonText2: 'Cancel',
                                buttonAction1: () => {
                                    dispatch(deleteGuardian({ guardianId: guardianD[i].guardianId }))
                                        .then((result) => {
                                            if (result.status === true) {
                                                let ar = [...guardianD]
                                                ar.splice(i, 1)
                                                setGuardianD([...ar])
                                                returnPanelData('guardianDetail', [...ar])
                                                dispatch(showConfirmationModalAction(false, {
                                                    modalContent: "",
                                                    button1: false,
                                                    button2: false,
                                                    buttonText1: '',
                                                    buttonText2: '',
                                                    buttonAction1: () => { },
                                                    buttonAction2: () => { },
                                                    origin: "Deleted guardian"
                                                }))
                                            }
                                        })
                                },
                                buttonAction2: () => {
                                    dispatch(showConfirmationModalAction(false, {
                                        modalContent: "",
                                        button1: false,
                                        button2: false,
                                        buttonText1: '',
                                        buttonText2: '',
                                        buttonAction1: () => { },
                                        buttonAction2: () => { },
                                        origin: "Cancel delete guardian"
                                    }))
                                },
                                origin: "delete guardian"
                            }))
                            // dispatch(deleteGuardian({ guardianId: guardianD[i].guardianId }))
                            //     .then((result) => {
                            //         if (result.status === true) {
                            //             let ar = [...guardianD]
                            //             ar.splice(i, 1)
                            //             setGuardianD([...ar])
                            //             returnPanelData('guardianDetail', [...ar])
                            //         }
                            //     })
                        } else {
                            let ar = [...guardianD]
                            ar.splice(i, 1)
                            setGuardianD([...ar])
                            returnPanelData('guardianDetail', [...ar])
                        }
                    }} >
                        <FeatherIcon icon="delete" style={{ width: 15, height: 15, marginRight: '5px' }} /> Remove
                    </Button>
                </div> : ''}

                <div style={{ width: '100%' }} ref={saveThisRef1(i + '1')}
                    onClick={() => { removeError(i + '1') }}
                >
                    <CustomFormLabel>First Name</CustomFormLabel>
                    <CustomTextField
                        id="Guardian"
                        placeholder="First Name"
                        variant="outlined"
                        fullWidth
                        value={guardianD[i].firstName}
                        onChange={(e) => {
                            guardianD[i].firstName = e.target.value
                            returnPanelData('guardianDetail', guardianD)
                        }}
                    />
                </div>
            </div>
            <div style={{ width: '100%' }} ref={saveThisRef1(i + '2')} onClick={() => { removeError(i + '2') }}>
                <CustomFormLabel>Last Name</CustomFormLabel>
                <CustomTextField
                    id="Relation"
                    placeholder="Last Name"
                    variant="outlined"
                    fullWidth
                    value={guardianD[i].lastName}
                    onChange={(e) => {
                        guardianD[i].lastName = e.target.value
                        returnPanelData('guardianDetail', guardianD)
                    }}
                />
            </div>
            <div style={{ width: '100%' }} ref={saveThisRef1(i + '3')} onClick={() => { removeError(i + '3') }}>
                <CustomFormLabel>Relation</CustomFormLabel>
                <CustomTextField
                    id="Relation"
                    type='text'
                    placeholder="Relation"
                    variant="outlined"
                    fullWidth
                    value={guardianD[i].relation}
                    onChange={(e) => {
                        guardianD[i].relation = e.target.value
                        returnPanelData('guardianDetail', guardianD)
                    }}
                />
            </div>
            <div style={{ width: '100%' }} ref={saveThisRef1(i + '4')} onClick={() => { removeError(i + '4') }}>
                <CustomFormLabel>Phone</CustomFormLabel>
                <CustomTextField
                    id="Phone"
                    placeholder="Phone"
                    variant="outlined"
                    fullWidth
                    value={guardianD[i].phone}
                    onChange={(e) => {
                        guardianD[i].phone = e.target.value
                        returnPanelData('guardianDetail', guardianD)
                    }}
                />
            </div>
            <div style={{ width: '100%' }} ref={saveThisRef1(i + '4')} onClick={() => { removeError(i + '4') }}>
                <CustomFormLabel>Secondary Phone-1</CustomFormLabel>
                <CustomTextField
                    id="secPhone"
                    placeholder="Secondary Phone-1"
                    variant="outlined"
                    fullWidth
                    value={guardianD[i].secPhone}
                    onChange={(e) => {
                        guardianD[i].secPhone = e.target.value
                        returnPanelData('guardianDetail', guardianD)
                    }}
                />
            </div>
            <div style={{ width: '100%' }} ref={saveThisRef1(i + '5')} onClick={() => { removeError(i + '5') }}>
                <CustomFormLabel>Secondary Phone-2</CustomFormLabel>
                <CustomTextField
                    id="secPhoneTwo"
                    placeholder="Secondary Phone-2"
                    variant="outlined"
                    fullWidth
                    value={guardianD[i].secPhoneTwo}
                    onChange={(e) => {
                        guardianD[i].secPhoneTwo = e.target.value
                        returnPanelData('guardianDetail', guardianD)
                    }}
                />
            </div>
        </>
    }

    return <div className="tabpanelContent guardianPanel">
        <Typography className='panelHeading' component="div">
            <img src={logo2} className="tabIcon" alt="logoImg" />
            Next of Kin and Emergency Contacts</Typography>
        {guardianD.map((m, i) => {
            return <div className='guardianPanelFields' key={i}>{returnGuardianUI(m, i)}</div>
        })}

        <Typography className='panelAddButton' component="div">
            <Button variant="contained" color="primary" onClick={() => {
                setIndex(index + 1);
                setGuardianD((a) => [...a, { firstName: '', lastName: '', relation: '', phone: '', secPhone: '' }])
                returnPanelData('guardianDetail', [...guardianD, { firstName: '', lastName: '', relation: '', phone: '', secPhone: '' }])
            }} >
                <FeatherIcon icon="plus" style={{ width: 15, height: 15, marginRight: '5px' }} />
                ADD NEW GUARDIAN
            </Button>
        </Typography>
    </div>
}
export default GuardiansPanel