import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Typography, Card, CardContent, Tabs, Tab, Toolbar, FormControlLabel, FormControl, Button } from '@mui/material';
import FeatherIcon from 'feather-icons-react';

import CustomTextField from '../../../forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../forms/custom-elements/CustomFormLabel';
import CustomRadio from '../../../forms/custom-elements/CustomRadio';
import ExamIcon from '../../../../assets/icons/ExamIcon.png';
import VisitSidebar from '../VisitSidebar';
import { changeTabNo, openMblVisitSidebar, saveExaminationData } from '../../../../redux/patients/Action';
import { useMemo } from 'react';
import { getExaminationHistory } from '../../../../redux/patients/AsyncActions';
import { useLocation } from 'react-router';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const ExaminationFindings = () => {

    const dispatch = useDispatch()

    const { state } = useLocation();
    const { id, visitId } = state ? state : {};

    const tabNoValue = useSelector((state) => state.patientReducer.tabNo);
    const tabNo = useMemo(() => {
        if (tabNoValue)
            return tabNoValue
        else
            return 0
    }, [tabNoValue])

    const examination = useSelector((state) => state.patientReducer.examinationData);
    const examinationHistory = useSelector((state) => state.patientReducer.examinationHistory)
    // const [general, setgeneral] = useState('Normal')
    // const [skin, setskin] = useState('Normal')
    // const [head, sethead] = useState('Normal')
    // const [throat, setthroat] = useState('Normal')
    // const [mouth, setmouth] = useState('Normal')
    // const [neck, setneck] = useState('Normal')

    const [examinationFields, setexaminationFields] = useState({
        general: '', skin: '', head: '', eyes: '', ears: '', nose: '', throat: '', mouthTeeth: '', neck: '', chest: '', heart: '',
        peripheralPulses: '', back: '', abdomen: '', genitalia: '', extremeties: '', neurological: '', lymphNodes: '',
    })
    const [inputFieldArr, setInputFieldArr] = useState([])

    const [value, setValue] = useState(0);
    const DetailRef = useRef(null)
    const historyRef = useRef(null)
    const [abnormalFindings, setAbnormalFindings] = useState('')

    const customizer = useSelector((state) => state.CustomizerReducer);

    // const [isSidebarOpen, setSidebarOpen] = useState(true);

    const getTabSelected = (no) => {
        saveData()
        dispatch(changeTabNo(no))
    }

    useEffect(() => {
        setexaminationFields({
            general: examination.general ? examination.general : 'Normal',
            skin: examination.skin ? examination.skin : 'Normal',
            head: examination.head ? examination.head : 'Normal',
            eyes: examination.eyes ? examination.eyes : 'Normal',
            ears: examination.ears ? examination.ears : 'Normal',
            nose: examination.nose ? examination.nose : 'Normal',
            throat: examination.throat ? examination.throat : 'Normal',
            mouthTeeth: examination.mouthTeeth ? examination.mouthTeeth : 'Normal',
            neck: examination.neck ? examination.neck : 'Normal',
            chest: examination.chest ? examination.chest : 'Normal',
            heart: examination.heart ? examination.heart : 'Normal',
            peripheralPulses: examination.peripheralPulses ? examination.peripheralPulses : 'Normal',
            back: examination.back ? examination.back : 'Normal',
            abdomen: examination.abdomen ? examination.abdomen : 'Normal',
            genitalia: examination.genitalia ? examination.genitalia : 'Normal',
            extremeties: examination.extremeties ? examination.extremeties : 'Normal',
            neurological: examination.neurological ? examination.neurological : 'Normal',
            lymphNodes: examination.lymphNodes ? examination.lymphNodes : 'Normal',
        })
        setAbnormalFindings(examination.abnormalFindings ? examination.abnormalFindings : '',
        )
    }, [examination])

    // useEffect(() => {
    //     examinationDataDetail({ ...examinationFields, abnormalFindings, pastFindings: examination.pastFindings, pastAbnormalFindings: examination.pastAbnormalFindings })
    // }, [examinationFields, abnormalFindings])
    const saveData = () => {
        dispatch(saveExaminationData({ ...examination, ...examinationFields, abnormalFindings }))
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const clearFunction = () => {
        setexaminationFields({
            general: '', skin: '', head: '', eyes: '', ears: '', nose: '', throat: '', mouthTeeth: '', neck: '', chest: '', heart: '',
            peripheralPulses: '', back: '', abdomen: '', genitalia: '', extremeties: '', neurological: '', lymphNodes: '',
        })
        setAbnormalFindings('')
    }

    useEffect(() => {
        setInputFieldArr([
            {
                name: 'General',
                value: examinationFields.general,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, general: data })
                }
            },
            {
                name: 'Skin',
                value: examinationFields.skin,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, skin: data })
                }
            },
            {
                name: 'Head',
                value: examinationFields.head,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, head: data })
                }
            },
            {
                name: 'Eyes',
                value: examinationFields.eyes,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, eyes: data })
                }
            },
            {
                name: 'Ears',
                value: examinationFields.ears,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, ears: data })
                }
            },
            {
                name: 'Nose',
                value: examinationFields.nose,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, nose: data })
                }
            },
            {
                name: 'Throat',
                value: examinationFields.throat,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, throat: data })
                }
            },
            {
                name: 'Mouth Teeth',
                value: examinationFields.mouthTeeth,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, mouthTeeth: data })
                }
            },
            {
                name: 'Neck',
                value: examinationFields.neck,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, neck: data })
                }
            },
            {
                name: 'Chest',
                value: examinationFields.chest,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, chest: data })
                }
            },
            {
                name: 'Heart',
                value: examinationFields.heart,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, heart: data })
                }
            },
            {
                name: 'Peripheral Pulses',
                value: examinationFields.peripheralPulses,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, peripheralPulses: data })
                }
            },
            {
                name: 'Back',
                value: examinationFields.back,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, back: data })
                }
            },
            {
                name: 'Abdomen',
                value: examinationFields.abdomen,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, abdomen: data })
                }
            },
            {
                name: 'Genitalia',
                value: examinationFields.genitalia,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, genitalia: data })
                }
            },
            {
                name: 'Extremeties',
                value: examinationFields.extremeties,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, extremeties: data })
                }
            },
            {
                name: 'Neurological',
                value: examinationFields.neurological,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, neurological: data })
                }
            },
            {
                name: 'Lymph Nodes',
                value: examinationFields.lymphNodes,
                onChangeHandler: (data) => {
                    setexaminationFields({ ...examinationFields, lymphNodes: data })
                }
            },
        ])
    }, [examinationFields])

    return (
        <>
            <VisitSidebar
                isSidebardir={customizer.activeDir === 'rtl' ? 'left' : 'right'}
                isSidebarOpen={true}
                onSidebarClose={() => dispatch(openMblVisitSidebar(false))}
                getTabSelected={getTabSelected}
            />

            <Card
                sx={{
                    p: {
                        xs: '0px',
                        sm: '0px',
                        lg: '0px',
                    },
                    width: '100%'
                }}
                className="newPatientCard newVisitCard"
            >
                <CardContent
                    sx={{
                        p: 0,
                    }}
                >
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 2, sm: 2 },
                            // ...(numSelected > 0 && {
                            bgcolor: (theme) => '#1990FC'
                            // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            // }),
                        }}
                    >
                        <Typography sx={{
                            flex: '1 1 100%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '20px'
                        }} variant="h6" id="newpatientTitle" component="span">
                            <img src={ExamIcon} className="tabIcon" alt="logoImg" />
                            Examination Findings
                        </Typography>
                        <Typography sx={{
                            flex: '1 1 100%', alignItems: 'center', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer'
                        }} variant="h6" id="newpatientTitle" component="span" onClick={clearFunction} >
                            Clear
                            <FeatherIcon icon="x" style={{
                                width: 14, height: 14, marginLeft: '5px', color: '#1990FC', borderRadius: '50%', background: 'white'
                            }} />
                        </Typography>
                    </Toolbar>
                    <Box sx={{
                        width: '100%',
                        p: {
                            xs: '20px',
                            sm: '35px',
                            lg: '35px',
                        },
                        pt: {
                            xs: '10px',
                            sm: '10px',
                            lg: '10px',
                        }
                    }}
                        className="newPatientCardBox"
                    >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                textColor="secondary"
                                allowScrollButtonsMobile
                                scrollButtons
                                indicatorColor="secondary"
                            >
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="Details"
                                    {...a11yProps(0)}
                                // onClick={() => scrollDown(DetailRef)}
                                />
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="History"
                                    {...a11yProps(1)}
                                    onClick={() => {
                                        let data = {
                                            visitId: visitId,
                                            patientId: id
                                        }
                                        dispatch(getExaminationHistory(data))
                                    }}
                                />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0} component="div">
                            <div ref={DetailRef}>
                                <div className="tabpanelContent">
                                    <Typography className='panelHeading'>
                                        {/* <img src={logo1} className="tabIcon" /> */}
                                        Details
                                    </Typography>
                                    {inputFieldArr.map((we, i) => {
                                        return <div key={i} style={{ width: '100%' }}  >
                                            <FormControl
                                                sx={{
                                                    width: '100%',
                                                }}
                                            >
                                                <CustomFormLabel>{we.name}</CustomFormLabel>
                                                <Box>
                                                    <FormControlLabel
                                                        checked={we.value === 'Normal'}
                                                        onChange={e => we.onChangeHandler(e.target.value)}
                                                        value='Normal'
                                                        label="Normal"
                                                        name="radio-button-demo"
                                                        control={<CustomRadio />}
                                                        inputprops={{ 'aria-label': 'A' }}
                                                    />
                                                    <FormControlLabel
                                                        checked={we.value === 'Abnormal'}
                                                        onChange={e => we.onChangeHandler(e.target.value)}
                                                        value='Abnormal'
                                                        label="Abnormal"
                                                        name="radio-button-demo"
                                                        control={<CustomRadio />}
                                                        inputprops={{ 'aria-label': 'B' }}
                                                    />

                                                </Box>
                                            </FormControl>
                                        </div>
                                    })}
                                    <div style={{ width: '100%' }} >
                                        <CustomFormLabel>Abnormal Findings</CustomFormLabel>
                                        <CustomTextField
                                            type="text"
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            fullWidth
                                            value={abnormalFindings}
                                            onChange={(e) => { setAbnormalFindings(e.target.value) }}
                                            className="border-g1"
                                        />
                                    </div>
                                </div>

                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1} component="div">

                            <div ref={historyRef}>
                                <div className="tabpanelContent">
                                    <Typography className='panelHeading'>
                                        {/* <img src={logo1} className="tabIcon" /> */}
                                        History
                                    </Typography>
                                    <div style={{ width: '100%' }} className="historyCard">
                                        < Card
                                            sx={{
                                                p: {
                                                    xs: '10px',
                                                    sm: '10px',
                                                    lg: '20px',
                                                },
                                                width: '100%'
                                            }}

                                        >
                                            <Typography variant="h6" fontWeight="600" color="textSecondary" component="div">Past Abnormal Findings: </Typography>
                                            {examinationHistory.pastAbnormalFindings && examinationHistory.pastAbnormalFindings.length > 0
                                                ? examinationHistory.pastAbnormalFindings.map((m, i) => {
                                                    return <Typography variant="h6" fontWeight="600" component="li">
                                                        {m[0].toUpperCase() + m.substring(1)}
                                                    </Typography>
                                                })
                                                : <Typography variant="h6" fontWeight="600">
                                                    None
                                                </Typography>}
                                        </Card>
                                    </div>
                                    <div style={{
                                        width: '100%'
                                    }} className="historyCard">
                                        < Card
                                            sx={{
                                                p: {
                                                    xs: '10px',
                                                    sm: '10px',
                                                    lg: '20px',
                                                },
                                                width: '100%'
                                            }}
                                        >
                                            <Typography variant="h6" fontWeight="600" color="textSecondary" >Past Findings: </Typography>
                                            <Typography variant="h6" fontWeight="600">
                                                {examinationHistory.pastFindings ? examinationHistory.pastFindings : 'None'}
                                            </Typography>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <div style={{ textAlign: 'right', margin: '15px 0px', width: '100%' }}>
                            <Button variant="contained" color="primary" className='visitTopButton' onClick={() => {
                                saveData()
                                dispatch(changeTabNo(tabNo + 1))
                            }}>Continue</Button>
                        </div>
                    </Box>
                </CardContent>
            </Card >
        </>
    );

};

export default ExaminationFindings;
