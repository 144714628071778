import React, { useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import CustomTextField from '../../forms/custom-elements/CustomTextField';
import logo2 from '../../../assets/images/newpatientIcon/Rectangle 4 (4).png'
import CustomFormLabel from '../../forms/custom-elements/CustomFormLabel';


const CoveragePanel = ({ detail, returnPanelData, scrollToField }) => {
    const inssuaranceRef = useRef(null);
    const policyNoRef = useRef(null);
    const expirationRef = useRef(null);


    const scrollDown = (ref) => {
        if (ref.current) {
            let scrollToPx = ref.current.offsetTop - 100
            window.scrollTo({
                top: scrollToPx,
                behavior: 'smooth',
            });
        }
    };
    const removeError = (ref) => {
        ref.current.style.border = "unset";
        ref.current.style.borderRadius = "unset";
        // dispatch(showNotificationAction(false, { type: '', content: '' }))
    }

    useEffect(() => {
        if (scrollToField && scrollToField !== "") {
            if (scrollToField === "inssuaranceRef") {
                scrollDown(inssuaranceRef)
                // changeBorderColor(inssuaranceRef)
            } else if (scrollToField === "policyNoRef") {
                scrollDown(policyNoRef)
                // changeBorderColor(policyNoRef)
            } else if (scrollToField === "expirationRef") {
                scrollDown(expirationRef)
                // changeBorderColor(expirationRef)
            } else {
            }
        }
    }, [scrollToField])

    return <div className="tabpanelContent">
        <Typography className='panelHeading'>
            <img src={logo2} className="tabIcon" alt="logoImg" />
            Coverage</Typography>
        <div style={{ width: '100%' }} ref={inssuaranceRef} onClick={() => { removeError(inssuaranceRef) }}>
            <CustomFormLabel>Insurance Company</CustomFormLabel>
            <CustomTextField
                id="insurancecompany"
                placeholder="Insurance Company"
                variant="outlined"
                fullWidth
                value={detail.insuranceCompany}
                onChange={(e) => returnPanelData('insuranceCompany', e.target.value)}
            />
        </div>
        <div style={{ width: '100%' }} ref={policyNoRef} onClick={() => { removeError(policyNoRef) }}>
            <CustomFormLabel>Policy Number</CustomFormLabel>
            <CustomTextField
                id="policy"
                type='number'
                placeholder="Policy Number"
                variant="outlined"
                fullWidth
                value={detail.policyNumber}
                onChange={(e) => returnPanelData('policyNumber', e.target.value)}
            />
        </div>
        {/* <div ref={expirationRef} className="dateDiv"
            onClick={() => {
                removeError(expirationRef)
                setShowDate(true)
            }}
            onBlur={(e) => {
                if (e.target.value === '') {
                    setShowDate(false)
                }
            }}
        > */}
        <div style={{ width: '100%' }} ref={expirationRef} onClick={() => { removeError(expirationRef) }}>
            <CustomFormLabel>Expiration Date</CustomFormLabel>
            <CustomTextField
                id="expirationdate"
                type={'date'}
                placeholder="Expiration Date"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                value={detail.expirationDate}
                onChange={(e) => returnPanelData('expirationDate', e.target.value)}

            />
            {/* {showDate ? '' : <FeatherIcon icon="calendar" className="calendaricon" />} */}
        </div>
    </div>
}
export default CoveragePanel