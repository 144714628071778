
import { useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router';
import {
    getAllPatientAges,
    getMonthlyPatientCount,
    getMostCommonDiagnosis,
    mostCommnlyPrescribedMedication,
    getTypesOfVaccineAdministration
} from "../../redux/reports/AsyncAction"
import MostCommonlyPrescribedMedication from "./mostCommnlyPrescribedMedication";
import PageContainer from "../container/PageContainer"
import AppointmentMonthWise from "./AppointmentMonthWise"
import TypesOfVaccineAdministration from "./typesOfVaccinesAdministered";
import CommonDiagnoses from "./CommonDianoses";
import ReportCard from "./ReportCard"
import {
    Toolbar,
    Typography,
    CardContent,
    Card,
    Button
} from '@mui/material';
import CustomTextField from '../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';
import Breadcrumb from "../../layouts/full-layout/breadcrumb/Breadcrumb"
import { showNotificationAction } from '../../redux/pushnotification/Action';
import { resetVisitFilter } from "../../redux/reports/AsyncAction"

const Reports = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const typesOfVaccinessList = useSelector(state => state.ReportReducer.getTypesOfVaccineAdministrationData)
    const mostCommonDiagnoses = useSelector(state => state.ReportReducer.mostCommonDiagnosis)

    const [startDate, SetStartDate] = useState("");
    const [endDate, SetEndDate] = useState("");
    const [selectGraphValue, setSelectGraphValue] = useState({ "label": "Medicine Analysis", "value": "medicineAnalysis" });
    const [selectGraphArray, setSelectGraphArray] = useState([
        // { "label": "Allergy Analysis", "value": "allergyAnalysis" },
        { "label": "Medicine Analysis", "value": "medicineAnalysis" },
        { "label": "Vaccine Analysis", "value": "vaccineAnalysis" },
        { "label": "Patient Age Analysis", "value": "patientAgeAnalysis" },
        { "label": "Most Common Diagnoses", "value": "mostCommonDiagnoses" }
    ]);

    const rootRoute = useMemo(() => {
        if (userinfo && userinfo.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])


    useEffect(() => {
        if (Object.entries(userinfo).length > 0) {

            const currentDate = moment();
            const firstDayOfMonth = moment(currentDate).startOf('month');
            const lastDayOfMonth = moment(currentDate).endOf('month');

            const formattedStartDate = currentDate.format('YYYY-MM-DD');
            const formattedEndDate = lastDayOfMonth.format('YYYY-MM-DD');
            SetStartDate(formattedStartDate); SetEndDate(formattedStartDate);

            getMonthlyPatientCountDetails(formattedStartDate, formattedStartDate);
            getMostCommnlyPrescribedMedication(formattedStartDate, formattedStartDate);
            getMostCommonDiagnosisDetails(formattedStartDate, formattedStartDate);
            getTypesOfVaccineAdministrationDetails(formattedStartDate, formattedStartDate);
            getAllPatientAgesDetails(formattedStartDate, formattedStartDate);

        }
    }, [userinfo])

    const getMonthlyPatientCountDetails = (startDate, endDate) => {

        let dataObj = {
            "startDate": startDate,
            "endDate": endDate,
            "userId": userinfo.userId,
            "hospitalId": userinfo.hospitalId,
        }
        dispatch(getMonthlyPatientCount(dataObj));
    }

    const getMostCommnlyPrescribedMedication = (startDate, endDate) => {

        let dataObj = {
            "startDate": startDate,
            "endDate": endDate,
            "userId": userinfo.userId,
            "hospitalId": userinfo.hospitalId,
        }
        dispatch(mostCommnlyPrescribedMedication(dataObj));
    }

    const getMostCommonDiagnosisDetails = (startDate, endDate) => {

        let dataObj = {
            "startDate": startDate,
            "endDate": endDate,
            "userId": userinfo.userId,
            "hospitalId": userinfo.hospitalId,
        }
        dispatch(getMostCommonDiagnosis(dataObj));
    }

    const getTypesOfVaccineAdministrationDetails = (startDate, endDate) => {

        let dataObj = {
            "startDate": startDate,
            "endDate": endDate,
            "userId": userinfo.userId,
            "hospitalId": userinfo.hospitalId,
        }
        dispatch(getTypesOfVaccineAdministration(dataObj));
    }

    const getAllPatientAgesDetails = (startDate, endDate) => {

        let dataObj = {
            "startDate": startDate,
            "endDate": endDate,
            "userId": userinfo.userId,
            "hospitalId": userinfo.hospitalId,
        }
        dispatch(getAllPatientAges(dataObj));
    }


    return <PageContainer title="Reports" description="This is Dashboard page">
        <div className="patientDetailPage">
            <Card
                sx={{
                    p: {
                        xs: '10px',
                        sm: '10px',
                        lg: '20px',
                    },
                    width: '100%'
                }}
            >
                <CardContent>

                    <div className="d-flex date-count-content align-items-center" style={{ alignItems: 'center' }}>

                        <div style={{}} >
                            <CustomFormLabel>Start Date</CustomFormLabel>
                            <CustomTextField
                                id="dobdate"
                                type={'date'}
                                placeholder="Start Date"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={startDate}
                                onChange={(e) => {
                                    SetStartDate(e.target.value)
                                    let selectedDate = e.target.value;
                                    let oneMonthAfterDate = moment(selectedDate).add(1, 'months').format('YYYY-MM-DD')
                                    // SetEndDate(oneMonthAfterDate);
                                }}
                                className="border-g1"
                            />
                        </div>

                        <div style={{ marginLeft: '10px' }} >
                            <CustomFormLabel>End Date</CustomFormLabel>
                            <CustomTextField
                                id="dobdate"
                                type={'date'}
                                placeholder="End Date"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={endDate}
                                onChange={(e) => {
                                    SetEndDate(e.target.value);
                                }}
                                className="border-g1"
                            />
                        </div>

                        <Typography className='panelAddButton' style={{ marginLeft: '10px' }} component="div">
                            <lable className="d-block">&nbsp;</lable>
                            <Button variant="contained" color="primary" onClick={() => {
                                getMonthlyPatientCountDetails(startDate, endDate);
                                getMostCommnlyPrescribedMedication(startDate, endDate);
                                getMostCommonDiagnosisDetails(startDate, endDate);
                                getTypesOfVaccineAdministrationDetails(startDate, endDate);
                                getAllPatientAgesDetails(startDate, endDate);
                            }} >
                                <FeatherIcon icon="plus" style={{ width: 15, height: 15, marginRight: '5px' }} />
                                Show Count
                            </Button>
                        </Typography>

                    </div>

                </CardContent>
            </Card >

            <ReportCard />
            <div className="d-flex justify-content-between align-items-center" style={{ width: '100%', }} >
                <Select
                    value={selectGraphValue}
                    onChange={(e) => {
                        setSelectGraphValue(e);
                    }}
                    options={selectGraphArray}
                    placeholder={"Select Country"}
                    className={'form-select p-0 border-0'}
                    styles={{ width: '70%', }}
                />

                <Typography className='panelAddButton' style={{ marginLeft: '10px', width: 200, }} component="div">
                    <Button variant="contained" color="primary" onClick={() => {
                        dispatch(resetVisitFilter())
                    }} >
                        <a href={'/#' + rootRoute + '/custom/reports'} style={{ color: 'white', textDecoration: 'none' }}> Customize Report</a>
                    </Button>
                </Typography>
            </div>
            {/* {userinfo?.role !== 'admin' && <AppointmentCard />} */}
            {
                selectGraphValue.value === "medicineAnalysis" ?
                    <MostCommonlyPrescribedMedication /> :
                    selectGraphValue.value === "mostCommonDiagnoses" ?
                        <CommonDiagnoses /> :
                        selectGraphValue.value === "vaccineAnalysis" ?
                            <TypesOfVaccineAdministration /> :
                            selectGraphValue.value === "patientAgeAnalysis" ?
                                <AppointmentMonthWise /> : null

            }
        </div>
    </PageContainer>
}
export default Reports