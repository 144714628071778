import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

import { Box, Button } from '@mui/material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const FilteredLastUpdatedBy = ({
  open,
  toggle,
  updateFilterLastUpdateBy,
  currLastUpdateBy,
  users,
}) => {
  const [value, setValue] = useState(currLastUpdateBy);
  const onSubmitHandler = () => {
    updateFilterLastUpdateBy(value);
    toggle();
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={toggle}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
              <label>User</label>
              <select value={value} onChange={(e) => setValue(e.target.value)}>
                <option value=''>All</option>
                {users.map((user) => (
                  <option key={user.userId} value={user.name}>
                    {user.name}
                  </option>
                ))}
              </select>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: '10px',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={onSubmitHandler}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default FilteredLastUpdatedBy;
