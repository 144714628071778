import {
    NOTIFICATION_LIST,
    NOTIFICATION_COUNT,
} from '../constants';

const INIT_STATE = {
    notificationList: [],
    notificationCount: 0,
};

const NotificationReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case NOTIFICATION_LIST:
            return {
                ...state,
                notificationList: action.data
            };
        case NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: action.data
            };
        default:
            return state;
    }
};
export default NotificationReducer
