import React, { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button, IconButton, DialogTitle, Dialog, TextField } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import PageContainer from '../../container/PageContainer';
import { uploadDocuments, saveNewVisit, getBatchList } from '../../../redux/patients/AsyncActions';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import moment from 'moment/moment';
import Observation from './VisitPages/Observation';
import Allergy from './VisitPages/Allergy';
import Vaccination from './VisitPages/Vaccination';
import ExaminationFindings from './VisitPages/ExaminationFindings';
import DiagnosisPlan from './VisitPages/DiagnosisPlan';
import VisitObservation from './VisitPages/VisitObservation';
import GrowthParameters from './VisitPages/GrowthParameters';
import DocumentUpload from './VisitPages/DocumentUpload';
import { changeTabNo, openMblVisitSidebar, saveAllergyData, saveDiagnosisData, saveDocumentData, saveExaminationData, saveGrowthData, saveObservationData, saveVaccinationData } from '../../../redux/patients/Action';
import { useEffect } from 'react';
import { showNotificationAction } from '../../../redux/pushnotification/Action';
import ReactRouterPrompt from "react-router-prompt";
import { Warning, Edit, Done } from '@mui/icons-material';


const NewVisit = () => {
    const dispatch = useDispatch()

    const { state } = useLocation();
    const navigate = useNavigate();
    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const rootRoute = useMemo(() => {
        if (userinfo && userinfo?.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])

    const tabNoValue = useSelector((state) => state.patientReducer.tabNo);
    const showTabNo = useMemo(() => {
        if (tabNoValue)
            return tabNoValue
        else
            return 0
    }, [tabNoValue])

    const { id, firstName, lastName, dob } = state ? state : {};
    const allergyStore = useSelector((state) => state.patientReducer.allergyData);
    const vaccinationStore = useSelector((state) => state.patientReducer.vaccinationData);
    const examinationStore = useSelector((state) => state.patientReducer.examinationData);
    const growthStore = useSelector((state) => state.patientReducer.growthData);
    const diagnosisStore = useSelector((state) => state.patientReducer.diagnosisData);
    // const documentStore = useSelector((state) => state.patientReducer.documentData);
    const observationStore = useSelector((state) => state.patientReducer.observationData);

    const [showVisitObservation, setShowVisitObservation] = useState(false)
    const [editDate, seteditDate] = useState(false)
    const [documentData, setdocumentData] = useState({})
    const [visitDate, setVisitDate] = useState(moment().format('YYYY-MM-DD'))
    const [disableSave, setdisableSave] = useState(false)
    // const dateInput = useRef()

    let breadtitle = (firstName && firstName[0] && (firstName[0].toUpperCase() + firstName.slice(1))) + ' ' +
        (lastName && lastName[0] && (lastName[0].toUpperCase() + lastName.slice(1)))

    const BCrumb = [
        {
            to: rootRoute + '/patient',
            title: 'Patients',
        },
        {
            onclick: () => { navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName, dob } }) },
            title: breadtitle,
        },
        {
            title: 'Record a new visit',
        },
    ];

    useEffect(() => {
        localStorage.setItem("patientId", id)
        dispatch(getBatchList())
        dispatch(saveObservationData({ ...observationStore, visitDate: visitDate }))

        return () => {
            dispatch(saveObservationData({}))
            dispatch(saveAllergyData({}))
            dispatch(saveVaccinationData([]))
            dispatch(saveExaminationData({}))
            dispatch(saveGrowthData({}))
            dispatch(saveDocumentData({}))
            dispatch(saveDiagnosisData({}))
        }
    }, [dispatch, id])

    const documentDataDetail = (data) => {
        if (data === undefined) {
        } else {
            setdocumentData(data)
            dispatch(saveDocumentData(data))
        }
    }

    const saveNewVisitHandle = () => {
        let noDoseForVaccine = vaccinationStore.filter(ele => ele.vaccination !== "" && (!ele.dose || ele.dose === 0 || ele.dose === ""))
        if (noDoseForVaccine.length > 0) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select correct dose number for vaccines.' }))
        } else {

            let data = {
                "patientId": id,
                "hospitalId": userinfo?.hospitalId,
                "userId": userinfo?.userId,
                "userName": userinfo?.name,
                "visit": {
                    "visitDate": visitDate,
                    "visitType": observationStore.selectedVisitType,
                    "recordedBy": userinfo?.name,
                    "informant": observationStore.informant,
                    "motherAge": observationStore.mothersAge,
                    "gravidaPara": observationStore.gravida,
                    "complications": observationStore.compMed,
                    "hiv": observationStore.bloodInvestigationValues?.HIV,
                    "vdrl": observationStore.bloodInvestigationValues?.VDRL,
                    "gbs": observationStore.bloodInvestigationValues?.GBS,
                    "ogtt": observationStore.bloodInvestigationValues?.OGTT,
                    "hepatitis": observationStore.bloodInvestigationValues?.HEPATITIS,
                    "sickleCell": observationStore.bloodInvestigationValues?.SickleCell,
                    "metarnalBloodGroup": observationStore.bloodInvestigationValues?.MaternalBloodGroup,
                    "patientBloodGroup": observationStore.bloodInvestigationValues?.PatientBloodGroup,
                    "bloodInvestigationDetails": observationStore.bloodDetail,
                    "gestationalAge": observationStore.gestationalAge,
                    "modeOfDelivery": observationStore.modeOfDelivery,
                    "indicationForLscs": observationStore.indicationForLscs,
                    "birthHistoryDetail": observationStore.birthHistoryDetail,
                    "birthWeight": observationStore.birthWeight,
                    "length": observationStore.lengthBirth,
                    "headCircumference": observationStore.headCircumference,
                    "apgarScore": observationStore.apgarScore,
                    "complicationsBox": observationStore.compBirth,
                    "pastMedicalHistory": observationStore.pastMedicalHistory,
                    "familyHistory": observationStore.familyHistory,
                    "currentIssues": observationStore.currentIssues,
                    "medications": observationStore.medications,
                    "diet": observationStore.dietValues ? JSON.stringify(observationStore.dietValues) : '',
                    "dietaryDetail": observationStore.detailsDiet,
                    "urine": observationStore.selectedValueUrine,
                    "stool": observationStore.selectedValueStool,
                    "urineDetails": observationStore.detailsExc,
                    "behavioralConcerns": observationStore.behavioralConcerns,
                    "school": observationStore.school,
                    "comments": observationStore.commentsSc,
                    "dentalCare": observationStore.dentalCare,
                    "dentalComments": observationStore.detailsD,
                    "medicalRecord": observationStore.medicalRecordsValues ? JSON.stringify(observationStore.medicalRecordsValues) : '',
                    "familyHistoryTwo": observationStore.familyHistoryValues ? JSON.stringify(observationStore.familyHistoryValues) : '',
                    "medicalHistory": observationStore.medicationHistoryValues ? JSON.stringify(observationStore.medicationHistoryValues) : '',
                    "checkMedicalHistoryOther": observationStore.CheckMedicalHistoryOther,
                    "surgicalHistory": observationStore.surgicalHistory,
                    "obstetricHistory": observationStore.obstetricHistory,
                    "screeningTest": observationStore.screeningTestsValues ? JSON.stringify(observationStore.screeningTestsValues) : '',
                    "clinicalSummaryMedicationData": observationStore.clinicalSummaryMedicationData ? JSON.stringify(observationStore.clinicalSummaryMedicationData) : '',
                },
                "allergy": {
                    "allergen": allergyStore.allergenSelected,
                    "allergenOtherExplain": allergyStore.allergenOtherExplain ? allergyStore.allergenOtherExplain : 'none',
                    "reaction": allergyStore.reactionSelected,
                    "notes": allergyStore.notes
                },
                "vaccination": [...vaccinationStore],
                "examination": {
                    "general": examinationStore.general,
                    "skin": examinationStore.skin,
                    "head": examinationStore.head,
                    "eyes": examinationStore.eyes,
                    "ears": examinationStore.ears,
                    "nose": examinationStore.nose,
                    "throat": examinationStore.throat,
                    "mouth": examinationStore.mouthTeeth,
                    "neck": examinationStore.neck,
                    "chest": examinationStore.chest,
                    "heart": examinationStore.heart,
                    "peripheralPulses": examinationStore.peripheralPulses,
                    "back": examinationStore.back,
                    "abdomen": examinationStore.abdomen,
                    "genitalia": examinationStore.genitalia,
                    "extremeties": examinationStore.extremeties,
                    "neurological": examinationStore.neurological,
                    "lymphNodes": examinationStore.lymphNodes,
                    "abnormalFindings": examinationStore.abnormalFindings ? examinationStore.abnormalFindings : ''
                },
                "growthAndParam": {
                    "temprature": growthStore.tempC,
                    "headCircumferences": growthStore.headCir,
                    "pulseRate": growthStore.pulseRate,
                    "respiratoryRate": growthStore.respi,
                    "bp": growthStore.bp,
                    "weight": growthStore.weight,
                    "height": growthStore.height,
                    "bmi": growthStore.bmi ? Number(growthStore.bmi).toFixed(3) : Number(0),
                    "lmp": growthStore.lmp
                },
                "diagnosisAndPlans": {
                    "diagnosisInformant": diagnosisStore.diagInformant,
                    "planInformant": diagnosisStore.planInformant,
                    "nextVisit": diagnosisStore.nextVisit,
                }
            }
            setdisableSave(true)
            dispatch(saveNewVisit(data))
                .then(result => {
                    if (result?.code === 200) {
                        let formData = new FormData()
                        formData.append("patientId", id)
                        formData.append("visitId", result.data.visitId)
                        if (documentData.selectedFiles && documentData.selectedFiles.length > 0) {
                            Object.keys(documentData.selectedFiles).forEach(m => {
                                formData.append("fileToImport", documentData.selectedFiles[m])
                            })
                            dispatch(uploadDocuments(formData))
                                .then(uploadResult => {
                                    if (uploadResult.code === 200) {
                                        dispatch(showNotificationAction(true, { type: 'success', content: 'Visit added.' }))

                                        setTimeout(() => {
                                            // clear store before navigate
                                            dispatch(saveObservationData({}))
                                            dispatch(saveAllergyData({}))
                                            dispatch(saveVaccinationData([]))
                                            dispatch(saveExaminationData({}))
                                            dispatch(saveGrowthData({}))
                                            dispatch(saveDocumentData({}))
                                            dispatch(saveDiagnosisData({}))
                                            navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName, dob } })
                                        }, 2000);
                                    } else {
                                        dispatch(showNotificationAction(true, { type: 'success', content: 'Visit added but document uploading failed.' }))
                                        setTimeout(() => {
                                            // clear store before navigate
                                            dispatch(saveObservationData({}))
                                            dispatch(saveAllergyData({}))
                                            dispatch(saveVaccinationData([]))
                                            dispatch(saveExaminationData({}))
                                            dispatch(saveGrowthData({}))
                                            dispatch(saveDocumentData({}))
                                            dispatch(saveDiagnosisData({}))
                                            navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName, dob } })
                                        }, 2000);
                                    }

                                })
                        } else {
                            dispatch(showNotificationAction(true, { type: 'success', content: 'Visit added.' }))
                            setTimeout(() => {
                                dispatch(saveObservationData({}))
                                dispatch(saveAllergyData({}))
                                dispatch(saveVaccinationData([]))
                                dispatch(saveExaminationData({}))
                                dispatch(saveGrowthData({}))
                                dispatch(saveDocumentData({}))
                                dispatch(saveDiagnosisData({}))
                                navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName, dob } })
                                // dispatch(showNotificationAction(false, { type: '', content: '' }))
                            }, 2000);
                        }

                    } else if (result && result.status === 400 && result.data.data) {
                        setdisableSave(false)
                        dispatch(showNotificationAction(true, { type: 'error', content: typeof result.data.data === 'string' ? result.data.data : result.data.data[0].msg }))
                    } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
                        setdisableSave(false)
                        dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
                    } else {
                        setdisableSave(false)
                        dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
                    }
                })
        }
    }

    const stopTabChange = (no) => {
        dispatch(changeTabNo(no))
        dispatch(showNotificationAction(true, { type: 'error', content: 'Please choose the visit type.' }))
    }

    return (
        <PageContainer title="New Visit" description="This is New Visit page" className="newPatientPage">
            <ReactRouterPrompt when={observationStore?.selectedVisitType?.length > 0}>
                {({ isActive, onConfirm, onCancel }) => isActive && (
                    <Dialog aria-labelledby="simple-dialog-title" open={isActive} >
                        <DialogTitle id="simple-dialog-title" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '20px', margin: '20px' }}>
                            <Box sx={{ mt: 2, textAlign: 'center' }}>
                                <Warning style={{ width: '40px' }} sx={{ mt: 2, color: 'warning.main' }} />
                                <Typography variant="h4" sx={{ m: 2 }}>
                                    Do you really want to leave <br /> without saving the visit?
                                </Typography>
                            </Box>
                            <Box sx={{ m: 'auto' }}>
                                <Button variant="contained" color="warning" onClick={onCancel} sx={{ marginRight: '10px' }}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary" onClick={onConfirm}>
                                    Confirm
                                </Button>
                            </Box>
                        </DialogTitle>
                    </Dialog>
                )
                }
            </ReactRouterPrompt>
            <IconButton
                size="large"
                aria-label="menu"
                onClick={() => { dispatch(openMblVisitSidebar(true)) }}
                sx={{
                    display: {
                        lg: 'none',
                        xs: 'flex',
                    },
                }}
            >
                <FeatherIcon icon="menu" width="18" color="#949db2" />
            </IconButton>

            <div className="newVisitPage">
                <Breadcrumb
                    title={
                        <Typography
                            fontWeight="500"
                            variant="h3"
                            sx={{
                                lineHeight: '1.235',
                                color: '#1990FC',
                                // fontSize:'20px'
                                // display: 'flex', alignItems: 'center'
                            }}
                        >
                            {breadtitle}
                            <Typography color="#7CB8EE" fontWeight="400" variant='span' sx={{
                                display: 'inline-flex', alignItems: 'center'
                            }}>
                                - New Visit&nbsp;{editDate
                                    ? <Typography color="#7CB8EE" fontWeight="400" variant='span' sx={{
                                        display: 'flex', alignItems: 'center'
                                    }}>
                                        <TextField className='visitDateField' type="date" sx={{ border: 'none' }} onChange={(e) => setVisitDate(e.target.value)} />
                                        <Done onClick={(e) => {
                                            seteditDate(false)
                                            dispatch(saveObservationData({ ...observationStore, visitDate: visitDate }))
                                        }} />
                                    </Typography>
                                    : <Typography color="#7CB8EE" fontWeight="400" variant='span' sx={{
                                        display: 'flex', alignItems: 'center'
                                    }}>
                                        ({moment(visitDate, 'YYYY-MM-DD').format('DD MMMM YYYY')})
                                        <Edit style={{ height: '20px' }} onClick={(e) => seteditDate(true)} />
                                    </Typography>
                                }
                            </Typography>
                        </Typography>
                    }
                    children={
                        <div style={{ textAlign: 'right', margin: '15px 0px', width: '100%' }}>
                            {showVisitObservation
                                ? <Button variant="contained" color="primary" className='visitTopButton' disabled={disableSave} onClick={() => {
                                    saveNewVisitHandle()
                                }}>SAVE & EXIT</Button>
                                : <Button variant="contained" color="primary" className='visitTopButton' onClick={() => {
                                    navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName, dob } })
                                }}>EXIT</Button>
                            }
                        </div>
                    }
                    items={BCrumb}
                />
            </div>

            <Box sx={{
                display: 'flex',
                alignItems: 'baseline'
            }}
                className="newVisitBox">
                {showVisitObservation
                    ? <VisitObservation changeShowVisitObservation={(val) => {
                        if (val === false) {
                            setShowVisitObservation(val)
                        }
                    }} />
                    : <>
                        {showTabNo === 0 ? <Observation /> :
                            showTabNo === 1 ? observationStore.selectedVisitType ? <Allergy /> : stopTabChange(0) :
                                showTabNo === 2 ? observationStore.selectedVisitType ? <Vaccination /> : stopTabChange(0) :
                                    showTabNo === 3 ? observationStore.selectedVisitType ? <ExaminationFindings /> : stopTabChange(0) :
                                        showTabNo === 4 ? observationStore.selectedVisitType ? <GrowthParameters /> : stopTabChange(0) :
                                            showTabNo === 5 ? observationStore.selectedVisitType ? <DocumentUpload documentDataDetail={documentDataDetail} /> : stopTabChange(0) :
                                                showTabNo === 6 ? observationStore.selectedVisitType ? <DiagnosisPlan changeShowVisitOb={(val) => setShowVisitObservation(val)} /> : stopTabChange(0)
                                                    : <></>
                        }
                    </>
                }
            </Box>

        </PageContainer >
    );

};

export default NewVisit;
