import React, { useEffect, useState } from 'react';
import {
    Drawer,
    FormControl,
    Divider,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Toolbar,
    Tooltip,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import NotesList from './NotesList';
import CustomTextField from '../../../forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../forms/custom-elements/CustomFormLabel';
// import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import { addNotes, getPatientNotes, updateNotes } from '../../../../redux/notes/AsyncActions';
import StickyNotesIcon from '../../../../assets/icons/StickyNotesIcon.jpg'
import { useLocation } from 'react-router';
import { showNotificationAction } from '../../../../redux/pushnotification/Action';
import SignatureCanvasComp from '../../../../views/SignatureCanvas';
import { URL } from '../../../../config';
import { getNote } from '../../../../redux/notes/Action';

const SidebarWidth = '320px';

const Notes = ({ drawerOpen }) => {
    const dispatch = useDispatch();
    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)

    const { state } = useLocation();
    const { id, firstName, lastName } = state ? state : {};

    const [showDrawer, setShowDrawer] = useState(false);
    const [open, setOpen] = useState(false);
    const [noteContent, setNoteContent] = useState("")
    const [noteTitle, setNoteTitle] = useState("")
    const [editNote, seteditNote] = useState(null)
    const [drawnNote, setdrawnNote] = useState(null)

    useEffect(() => {
        setShowDrawer(drawerOpen)
    }, [drawerOpen])

    const handleClose = () => {
        setOpen(false);
        seteditNote(null)
        setNoteTitle("")
        setNoteContent("")
        setdrawnNote(null)
    };
    const addNoteHandler = async () => {
        let data = {
            "note": noteContent,
            "subject": noteTitle,
            "createdBy": userinfo?.name,
            "userId": userinfo?.userId,
            "patientId": id,
            "hospitalId": userinfo?.hospitalId,
            "firstName": firstName,
            "lastName": lastName
        }
        if (drawnNote) {
            data["fileToImport"] = drawnNote
        }
        let result = await dispatch(addNotes(data))
        if (result.status === true) {
            dispatch(getPatientNotes({ "patientId": id }));
            handleClose()
        } else {
            handleClose()

        }
    }

    const editNoteHandler = async (noteEle) => {
        seteditNote(noteEle)
        setOpen(true);
    }

    const updateNoteHandler = async () => {
        if (drawnNote) {
            editNote["fileToImport"] = drawnNote
            editNote["fileName"] = null
        }
        let result = await dispatch(updateNotes(editNote))
        if (result.status === true) {
            if (drawnNote) {
                // window.location.reload();
                dispatch(getPatientNotes({ "patientId": id }));
                handleClose()
            } else {
                dispatch(getPatientNotes({ "patientId": id }));
                handleClose()
            }
        } else {
            handleClose()
        }
    }

    const getImgUrl = (imgUrl) => {
        setdrawnNote(imgUrl)
    }

    return (
        <div>
            {/* <Tooltip title="Notes">
                <Fab
                    aria-label="settings"
                    sx={{
                        position: 'fixed', right: '15px', bottom: '15px', background: '#1990FC', color: 'white',
                        '&:hover': {
                            backgroundColor: '#1990FC'
                        }
                    }}
                    onClick={() => setShowDrawer(true)}
                >
                    <NoteIcon />
                </Fab>
            </Tooltip> */}
            <Toolbar
                sx={{
                    pl: { sm: 3 },
                    pr: { xs: 2, sm: 2 },
                    bgcolor: (theme) => 'white',
                    borderRadius: '4px',
                    boxShadow: '0px 4px 4px rgba(19, 83, 140, 0.15)',
                    width: '100%'
                }}
            >
                <Typography sx={{ flex: '1 1 100%', cursor: 'pointer' }} variant="h6" id="newVisitTitle" className="darkTitle" component="div" fontSize="20px"
                    onClick={() => {
                        dispatch(showNotificationAction(false, { type: '', content: '', origin: 'show drawer' }))
                        setShowDrawer(true)
                    }} >
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: '20px' }} variant="h6" id="" component="div" fontSize="20px">
                        <img src={StickyNotesIcon} className="tabIcon" alt="logoImg" width={'50px'} />
                        Notes
                    </Typography>
                    <FeatherIcon icon="arrow-right" color='#1990FC' />
                </Typography>
            </Toolbar>
            <Drawer
                anchor="right"
                open={showDrawer}
                onClose={() => {
                    setShowDrawer(false)
                    dispatch(getNote([]))
                }}
                PaperProps={{
                    sx: {
                        width: SidebarWidth,
                        '& .MuiBox-root': {
                            margin: '10px !important',
                            boxShadow: 'none !important',
                            padding: '8px !important'
                        }
                    },
                }}
            >
                <Box p={2}>
                    <Typography sx={{
                        flex: '1 1 100%', cursor: 'pointer', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        lineHeight: '19px',
                        width: '100%'
                    }}
                        variant="h6" component="div">
                        <Typography sx={{ display: 'flex', alignItems: 'center' }} fontSize="20px" fontWeight='700'>
                            Notes
                        </Typography>
                        <Tooltip title='Add note'>
                            <span>
                                <FeatherIcon icon="plus" onClick={() => setOpen(true)} />
                            </span>
                        </Tooltip>
                    </Typography>
                </Box>
                <Divider />
                <NotesList editNoteHandler={editNoteHandler} />
            </Drawer>
            <Dialog open={open} onClose={handleClose} fullWidth >
                <DialogContent className='appointmentForm'>
                    <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                        {editNote ? 'Update Note' : 'New Note'}
                    </Typography>
                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <CustomFormLabel>Title </CustomFormLabel>

                            <CustomTextField
                                id="title"
                                variant="outlined"
                                className="border-g1"
                                fullWidth
                                placeholder=""
                                value={editNote ? editNote.subject : noteTitle}
                                onChange={(e) => { editNote ? seteditNote({ ...editNote, subject: e.target.value }) : setNoteTitle(e.target.value) }}
                            />
                        </FormControl>
                    </div>
                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <CustomFormLabel>Note</CustomFormLabel>

                            <CustomTextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder=""
                                className="border-g1"
                                value={editNote ? editNote.note : noteContent}
                                onChange={(e) => {
                                    editNote ? seteditNote({ ...editNote, note: e.target.value }) : setNoteContent(e.target.value)
                                }}
                            />
                        </FormControl>
                    </div>
                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                        {editNote && editNote.fileName && <>
                            <CustomFormLabel>Saved Sketch</CustomFormLabel>
                            <Typography
                                variant="h6"
                                fontWeight="400"
                                sx={{ textAlign: 'center' }}
                            >
                                <img src={URL + editNote.fileName} alt="noteImg" style={{ width: '100%', border: '1px solid rgba(233, 99, 186, 0.1)' }} />
                                {/* <img src={URL + editNote.fileName} alt="noteImg" style={{ width: '100%', border: '1px solid rgba(233, 99, 186, 0.1)' }} /> */}
                            </Typography>
                        </>}
                    </div>
                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                        <SignatureCanvasComp getImgUrl={getImgUrl} />
                    </div>

                    {/* {showDraw
                        ? <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                            <CustomFormLabel>Draw</CustomFormLabel>
                            <SignatureCanvasComp />
                        </div>
                        : <Button variant="contained" className='' color="primary" sx={{ marginTop: '10px', float: 'right' }}
                            onClick={() => setshowDraw(true)}>
                            Draw
                        </Button>
                    } */}
                </DialogContent>
                <DialogActions className='appointmentFormActions'>
                    <Box>
                        <Button type="button"
                            variant="contained"
                            onClick={handleClose}
                            sx={{
                                mr: '8px',
                                background: "#888888",
                                '&:hover': {
                                    background: "#888888",
                                }
                            }}
                        >Cancel</Button>

                        <Button variant="contained" className='registerButton' color="primary"
                            onClick={editNote ? updateNoteHandler : addNoteHandler}>
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default Notes;
