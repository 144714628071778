import { Autocomplete, TextField } from "@mui/material";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHospitalSelected } from "../../redux/Hospital/Action";
import { getAllHospital } from "../../redux/Hospital/AsyncAction";
// import CustomSelect from "../forms/custom-elements/CustomSelect";

const ChooseHospital = () => {
    const dispatch = useDispatch()
    const hospitalArr = useSelector((state) => state.HospitalReducer.hospitalList);
    const [hospitalList, setHospitalList] = useState([])
    const [selectedHospital, setselectedHospital] = useState([])

    useMemo(() => {
        if (hospitalArr.activeHosp || hospitalArr.inactiveHosp) {
            setHospitalList([])
            hospitalArr?.activeHosp.forEach(element => {
                setHospitalList(ho => [...ho, { label: element.hospitalName, hospitalCode: element.hospitalCode, hospitalName: element.hospitalName, hospitalId: element.hospitalId, }])
            })
            hospitalArr?.inactiveHosp.forEach(element => {
                setHospitalList(ho => [...ho, { label: element.hospitalName, hospitalCode: element.hospitalCode, hospitalName: element.hospitalName, hospitalId: element.hospitalId, }])
            })
        }
    }, [hospitalArr])

    useEffect(() => {
        (async () => {
            setHospitalList([])
            await dispatch(getAllHospital())
        })()
    }, [dispatch])

    useEffect(() => {
        (async () => {
            setselectedHospital(hospitalList[0])
            dispatch(setHospitalSelected(hospitalList[0]))

            // if (hospitalList[0])
            //     dispatch(getUserList(hospitalList[0]))
        })()
    }, [hospitalList, dispatch])

    return <div className="chooseHospitalDiv" style={{}}>
        {/* <CustomFormLabel htmlFor="demo-simple-select">User Type</CustomFormLabel> */}
        {/* <CustomSelect
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // disabled={!updateUser}
            value={selectedHospital}
            onChange={(e) => {
                dispatch(setHospitalSelected(e.target.value))
                setselectedHospital(e.target.value)
            }}
            fullWidth
            size="small"
            style={{ background: 'white' }}
        >
            {hospitalList.map(m => <MenuItem key={m.hospitalCode} value={m}>{m.hospitalName}</MenuItem>)}
        </CustomSelect> */}
        <Autocomplete
            disablePortal
            value={selectedHospital}
            onChange={(event, newValue) => {
                if (newValue) {
                    dispatch(setHospitalSelected(newValue))
                    setselectedHospital(newValue.label)
                } else {
                    setselectedHospital("")
                }
            }}
            id="combo-box-demo"
            options={hospitalList}
            fullWidth
            renderInput={(params) => (
                <TextField {...params} size="small" placeholder="Select Hospital" aria-label="Select Hospital" />
            )}
            sx={{
                '& .MuiOutlinedInput-root': {
                    background: 'white'
                }
            }}
        />
    </div>
}

export default ChooseHospital 