import {
    APPOINTMENTLIST,
} from '../constants';

const INIT_STATE = {
    appointmentList: []
};

const AppointmentReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case APPOINTMENTLIST:
            return {
                ...state,
                appointmentList: action.data,
            };
        default:
            return state;
    }
};

export default AppointmentReducer;
