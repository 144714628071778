import { URL } from '../../config';
import axios from 'axios';
import {
    SHOW_NOTIFICATION,
    SHOW_SPINNER,
    NOTIFICATION_LIST,
    NOTIFICATION_COUNT
} from '../constants';

export const fetchNotificationCount = () => async (dispatch) => {
    try {
        let token = sessionStorage.getItem('token')
        let response = await axios.get(URL + 'notification/count', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: NOTIFICATION_COUNT,
            data: response.data.data.notiCount,
        });
    } catch (err) {
        dispatch({
            type: NOTIFICATION_COUNT,
            data: 0,
        });
        return err.response
    }
};

export const fetchNotificationList = () => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        let response = await axios.get(URL + 'notification/all', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: NOTIFICATION_LIST,
            data: response.data.data.notiList,
        });
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return;
    } catch (err) {
        dispatch({
            type: NOTIFICATION_LIST,
            data: [],
        });
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return err.response
    }
};

export const notificationSeen = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        let response = await axios.post(URL + 'notification/update/seen', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch(fetchNotificationList())
        dispatch(fetchNotificationCount())
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data

    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        dispatch({
            type: SHOW_NOTIFICATION,
            isShow: true,
            content: { type: 'error', content: 'Something went wrong.' },
            origin: 'notificationSeen'
        })
        return err.response
    }
};
