import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider, DialogTitle, Dialog, Button, Typography } from '@mui/material';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import { showNotificationAction } from './redux/pushnotification/Action';
import MessageModal from './components/MessageModal/MessageModal';
import { ErrorBoundary } from "react-error-boundary";

import 'react-perfect-scrollbar/dist/css/styles.css';
import './App.css';
import Spinner from './views/spinner/Spinner';
import { Box } from '@mui/system';


const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const dispatch = useDispatch()
  const showMessageModal = useSelector((state) => state.MessageModalReducer.showModal);
  const showSpinner = useSelector((state) => state.SpinnerReducer.showSpinner)
  const customizer = useSelector((state) => state.CustomizerReducer);
  // const showNotification = useSelector(state => state.pushNotificationReducer.showNotification)

  // dispatch(showNotificationAction(false, { type: '', content: '' })) // App.js run only on each page loading, so we can reset notification here
  // useEffect(() => {
  // document.addEventListener('click', handleOutsideClick);
  // }, [])


  //   const handleOutsideClick = (e) => {
  //     if (showNotification) {
  //     dispatch(showNotificationAction(false, { type: '', content: '' }))
  //   }
  // }
  const showSpinnerUI = useMemo(() => {
    if (showSpinner)
      return <Spinner />
    else return
  }, [showSpinner])

  useEffect(() => {
    dispatch(showNotificationAction(false, { type: '', content: '', origin: 'useeffect' })) // App.js run only on each page loading, so we can reset notification here
  })

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <Dialog aria-labelledby="simple-dialog-title" open={error} >
        <DialogTitle id="simple-dialog-title" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '20px', margin: '20px' }}>
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Typography variant="h4" sx={{ m: 2 }}>
              Something went wrong:
            </Typography>
            <Typography variant="h4" sx={{ m: 2 }}>
              {error.message}
            </Typography>
          </Box>
          <Box sx={{ m: 'auto' }}>
            <Button variant="contained" color="primary" onClick={resetErrorBoundary}>
              Try again
            </Button>
          </Box>
        </DialogTitle>
      </Dialog>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        {/* {showSpinner && <Spinner />} */}
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {showSpinnerUI}
          {showMessageModal && <MessageModal />}
          <CssBaseline />
          {routing}
        </ErrorBoundary>

      </RTL>
    </ThemeProvider>
  );
};

export default App;

