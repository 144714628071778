
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDashboardData } from "../../redux/dashboard/AsyncActions"
import PageContainer from "../container/PageContainer"
import AppointmentCard from "./AppointmentCard"
import AppointmentMonthWise from "./AppointmentMonthWise"
import DashboardCard from "./DashboardCard"

const Dashboard = () => {
    const dispatch = useDispatch()
    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)

    useEffect(() => {
        if (userinfo && userinfo.role)
            dispatch(getDashboardData(userinfo.role))
    }, [userinfo, dispatch])

    return <PageContainer title="Dashboard" description="This is Dashboard page">
        <div className="patientDetailPage">
            <DashboardCard />
            {userinfo?.role !== 'admin' && <AppointmentCard />}
            {userinfo?.role !== 'admin' && <AppointmentMonthWise />}
        </div>
    </PageContainer>
}
export default Dashboard