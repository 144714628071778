import axios from 'axios';
import {
    GET_VISUALIZATION_OF_PATIENT_AGES,
    SHOW_SPINNER,
    GET_MONTHLEY_PATIENT_COUNT,
    GET_MOST_COMMON_DIAGNOSIS,
    GET_MOST_COMMNLY_PRESCRIBED_MEDICATION,
    GET_TYPES_OF_VACCINE_ADMINNISTRATION,
    GET_CLINICAL_SUMMARY_VISIT_FILTER,
    GET_FIRST_VISIT_FILTER,
    GET_FOLLOW_UP_VISIT,
    RESET_VISIT_FILTER
} from '../constants';
import { URL } from '../../config';
import MostCommonlyPrescribedMedication from '../../components/commonFunction/getMostCommonMedication'

export const getAllPatientAges = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'reports/visualizationOfPatientAges', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: GET_VISUALIZATION_OF_PATIENT_AGES,
            data: response.data
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const getMonthlyPatientCount = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'reports/monthlyPatient/Count', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: GET_MONTHLEY_PATIENT_COUNT,
            data: response.data
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return err.response
    }
}

export const getMostCommonDiagnosis = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'reports/mostCommonDiagnoses', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: GET_MOST_COMMON_DIAGNOSIS,
            data: response.data
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const mostCommnlyPrescribedMedication = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'reports/mostCommnlyPrescribedMedication', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        let medicationData = await MostCommonlyPrescribedMedication(response.data.data)
        dispatch({
            type: GET_MOST_COMMNLY_PRESCRIBED_MEDICATION,
            data: medicationData
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const getTypesOfVaccineAdministration = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'reports/typesOfVaccinesAdministered', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: GET_TYPES_OF_VACCINE_ADMINNISTRATION,
            data: response.data
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

//*Filter
export const getClinicalSummaryFilter = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'reports/filter/clinicalSummary', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: GET_CLINICAL_SUMMARY_VISIT_FILTER,
            data: response.data
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const getFirstVisitFilter = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'reports/filter/firstVisit', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: GET_FIRST_VISIT_FILTER,
            data: response.data
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const getFolloWUpVisitFilter = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'reports/filter/followUpVisit', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: GET_FOLLOW_UP_VISIT,
            data: response.data
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const resetVisitFilter = (data) => async (dispatch) => {
    try {

        dispatch({
            type: RESET_VISIT_FILTER,
            data: {}
        })

    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

