import {
    SHOW_SPINNER,
} from '../constants';

const INIT_STATE = {
    showSpinner: false,
};

const SpinnerReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOW_SPINNER:
            return {
                ...state,
                showSpinner: action.data
            };

        default:
            return state;
    }
};

export default SpinnerReducer;
