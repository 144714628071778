import {
    HOSPITALLIST,
    HOSPITALSELECTED
} from '../constants';



export const setHospitalList = (data) => ({
    type: HOSPITALLIST,
    data: data
});

export const setHospitalSelected = (data) => ({
    type: HOSPITALSELECTED,
    data: data
});

