import {
    GET_VISUALIZATION_OF_PATIENT_AGES,
    GET_MONTHLEY_PATIENT_COUNT,
    GET_MOST_COMMON_DIAGNOSIS,
    GET_MOST_COMMNLY_PRESCRIBED_MEDICATION,
    GET_TYPES_OF_VACCINE_ADMINNISTRATION,
    GET_CLINICAL_SUMMARY_VISIT_FILTER,
    GET_FIRST_VISIT_FILTER,
    GET_FOLLOW_UP_VISIT,
    RESET_VISIT_FILTER
} from '../constants';

const INIT_STATE = {
    PagetientAges: {},
    monthlyPatientCount: 0,
    mostCommonDiagnosis: [],
    getTypesOfVaccineAdministrationData: [],
    mostCommnlyPrescribedMedication: {},
    getClinicalSummaryVisitData: [],
    getFirstVisitData: [],
    getFollowUpVisitData: [],
};

const ReportReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_VISUALIZATION_OF_PATIENT_AGES:
            return {
                ...state,
                PagetientAges: action.data.data,
            };
        case GET_MONTHLEY_PATIENT_COUNT:
            return {
                ...state,
                monthlyPatientCount: action.data.data,
            };
        case GET_MOST_COMMON_DIAGNOSIS:
            return {
                ...state,
                mostCommonDiagnosis: action.data.data,
            };
        case GET_MOST_COMMNLY_PRESCRIBED_MEDICATION:
            return {
                ...state,
                mostCommnlyPrescribedMedication: action.data,
            };
        case GET_TYPES_OF_VACCINE_ADMINNISTRATION:
            return {
                ...state,
                getTypesOfVaccineAdministrationData: action.data.data,
            };
        case GET_CLINICAL_SUMMARY_VISIT_FILTER:
            return {
                ...state,
                getClinicalSummaryVisitData: action.data.data,
            };
        case GET_FIRST_VISIT_FILTER:
            return {
                ...state,
                getFirstVisitData: action.data.data,
            };
        case GET_FOLLOW_UP_VISIT:
            return {
                ...state,
                getFollowUpVisitData: action.data.data,
            };
        case RESET_VISIT_FILTER:
            return {
                ...state,
                getClinicalSummaryVisitData: [],
                getFirstVisitData: [],
                getFollowUpVisitData: [],
            };
        default:
            return state;
    }
};

export default ReportReducer;
