import {
    SHOW_NOTIFICATION,
} from '../constants';



export const showNotificationAction = (isShow, content, origin) => ({
    type: SHOW_NOTIFICATION,
    isShow, content, origin
});
