import {
    GET_DASHBOARD_COUNT,
    GET_UPCOMING_APPOINTMENTS,
    MONTHWISE_APPOINTMENT
} from '../constants'

const INIT_STATE = {
    dashboardData: [],
    upcomingAppointment: [],
    monthwiseAppointmentList: []
};

const DashboardReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DASHBOARD_COUNT:
            return {
                ...state,
                dashboardData: action.data,
            };
        case GET_UPCOMING_APPOINTMENTS:
            return {
                ...state,
                upcomingAppointment: action.data,
            };
        case MONTHWISE_APPOINTMENT:
            return {
                ...state,
                monthwiseAppointmentList: action.data
            }
        default:
            return state;
    }
};

export default DashboardReducer;