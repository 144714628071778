import React, { useMemo, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import {
    Card,
    CardContent,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    MenuItem,
    FormControlLabel,
    FormControl,
    Grid,
    Toolbar,
    Autocomplete,
    TextField,
} from '@mui/material';
import {
    LocalizationProvider,
    TimePicker,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
// import Events from './EventData';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Appointment.css';

import CustomTextField from '../forms/custom-elements/CustomTextField';
import CustomFormLabel from '../forms/custom-elements/CustomFormLabel';
import CustomSelect from '../forms/custom-elements/CustomSelect';
import CustomRadio from '../forms/custom-elements/CustomRadio';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAppointment, deleteAppointment, getPatientAppointment, getAllAppointment, getUpcomingAppointment, updateAppointment } from '../../redux/appointment/AsyncActions';
import { getPatientList } from '../../redux/patients/AsyncActions';
import { getDoctorList } from '../../redux/users/AsyncActions';
import { ColorList } from './ColorList';
import { showNotificationAction } from '../../redux/pushnotification/Action';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import CalendarAddIcon from '../../assets/icons/CalendarAddIcon.png'
import FormIcon from '../../assets/icons/FormIcon.png'
import { setAppointmentList } from '../../redux/appointment/Action';
import { fetchNotificationCount } from '../../redux/notification/AsyncActions';

const today = new Date();
const y = today.getFullYear();
const m = today.getMonth();
const d = today.getDate();

moment.locale('en-GB');
const localizer = momentLocalizer(moment);

const AppointmentCalendar = ({ showAppointment, patientId }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const hospitalSelectedState = useSelector((state) => state.HospitalReducer.hospitalSelected)
    // const [calevents, setCalEvents] = useState([]);
    const [open, setOpen] = React.useState(false);
    // const [patientNewValue, setpatientNewValue] = useState(null)
    const [update, setUpdate] = useState();
    const [newApp, setnewApp] = useState({
        "patientId": "",
        "patientName": "",
        "patientLabel": "",
        "userId": "",
        "doctorName": "",
        "appointmentDate": "",
        "appointmentStartTime": new Date(y, m, d, 0, 0),
        "appointmentEndTime": new Date(y, m, d, 0, 30),
        "serviceType": "",
        "appStatus": "",
        "notes": "",
        "createdBy": ""
    });

    const doctorState = useSelector(state => state.UsersReducer.doctorList)
    const doctorList = useMemo(() => {
        if (doctorState && doctorState.length > 0) {
            return doctorState.map(m => {
                return { label: m?.name[0] + m?.name.slice(1), value: m.userId }
            })
        } else {
            return []
        }
    }, [doctorState])

    const patientState = useSelector(state => state.patientReducer.patientList)
    const patientList = useMemo(() => {
        if (patientState && patientState.length > 0) {
            return patientState.map(m => {
                // return { label: m?.name[0].toUpperCase() + m?.name.slice(1), value: m.patientId }
                return { label: `${m.name[0]}${m.name.slice(1)} (${m.patientId})`, value: m.patientId, name: m.name }
                // return { label: String(m?.patientId), value: m?.patientId }

            })
            // return patientState
        } else {
            return []
        }
    }, [patientState])

    const appState = useSelector(state => state.AppointmentReducer.appointmentList)
    const appoinmentList = useMemo(() => {
        if (appState && appState.length > 0) {
            let list = []
            appState.map((element, i) => {
                let sDate = new Date(element.appointmentStartTime)
                let eDate = new Date(element.appointmentEndTime)
                return list.push({
                    ...element,
                    title: element.patientName,
                    start: sDate,
                    end: eDate,
                    // appointmentDate:sDate
                })
            });
            return list
        } else {
            return []
        }
    }, [appState])

    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const rootRoute = useMemo(() => {
        if (userinfo && userinfo?.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])

    const serviceTypeList = ["Consult", "Consult + ECHO", "Consult + Vaccines", "ECHO", "Ear Piercing", "Hear Screen", "Hearing Screen", "Routine Visit", "Vaccines Only", "Vision Screen"]
    // const [appoinmentList, setAppointmentList] = useState([])

    // useEffect(() => {
    //     getDoctorListHandler()
    //     getPatientListHandler()
    // }, [])

    useEffect(() => {
        if (userinfo?.role) {
            getAppointmentHandler()
            console.log("getAppointmentHandler has triggered");
        }
    }, [hospitalSelectedState, userinfo])

    useEffect(() => {
        getDoctorListHandler()
        getPatientListHandler()
        console.log("getDoctorListHandler has triggered!!!!!!!!!!!");
        }, [hospitalSelectedState])

    const getDoctorListHandler = () => {
        dispatch(getDoctorList()).then((result) => {
            if (result && result.code === 200) {

            } else if (result && result.status === 404) {

            } else if (result && result.status === 401) {
                dispatch(showNotificationAction(true, { type: 'error', content: 'Token expired.' }))
                setTimeout(() => {
                    dispatch(showNotificationAction(false, { type: '', content: '', origin: 'getDoctorListHandler' }))
                    navigate('/auth/login')
                }, 3000);
            } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
                dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
            } else {
                dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
            }
        })
    }
    const getPatientListHandler = () => {
        dispatch(getPatientList()).then((result) => {
            if (result && result.code === 200) {

            } else if (result && result.status === 404) {
            } else if (result && result.status === 401) {
                dispatch(showNotificationAction(true, { type: 'error', content: 'Token expired.' }))
                setTimeout(() => {
                    dispatch(showNotificationAction(false, { type: '', content: '', origin: 'getPatientListHandler' }))
                    navigate('/auth/login')
                }, 3000);
            } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
                dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
            } else {
                dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
            }
        })
    }

    // Get All Appointment Action but backend API doesn't exsit
    const getAllAppointmentAction = (data) => {
        dispatch(getAllAppointment(data)).then((result) => {
            if (result && result.code === 200) {
                dispatch(fetchNotificationCount())
            } else if (result && result.status === 404) {

            } else if (result && result.status === 401) {
                dispatch(showNotificationAction(true, { type: 'error', content: 'Token expired.' }))
                setTimeout(() => {
                    dispatch(showNotificationAction(false, { type: '', content: '', origin: 'getAllAppointment 1' }))
                    navigate('/auth/login')
                }, 3000);
            } else if (result && result.status === 500) {
                dispatch(showNotificationAction(true, { type: 'error', content: 'Connection error.' }))
                setTimeout(() => {
                    dispatch(showNotificationAction(false, { type: '', content: '', origin: 'getAllAppointment 2' }))
                }, 3000);
            } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
                dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
            } else {
                dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
            }
        })
    }
    const getAppointmentHandler = () => {
        dispatch(setAppointmentList([]))
        if (showAppointment === 'All') {
            if (userinfo?.role === 'admin') {
                if (hospitalSelectedState?.hospitalId)
                    getAllAppointmentAction({ hospitalId: hospitalSelectedState?.hospitalId })
            } else {
                getAllAppointmentAction()
            }
        } else if (showAppointment === 'upcoming') {
            dispatch(getUpcomingAppointment()).then((result) => {
                if (result && result.code === 200) {

                } else if (result && result.status === 404) {

                } else if (result && result.status === 401) {
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Token expired.' }))
                    setTimeout(() => {
                        dispatch(showNotificationAction(false, { type: '', content: '', origin: 'getUpcomingAppointment 1' }))
                        navigate('/auth/login')
                    }, 3000);
                } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
                    dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
                    setTimeout(() => {
                        dispatch(showNotificationAction(false, { type: '', content: '', origin: 'getUpcomingAppointment 2' }))
                    }, 3000);
                } else {
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
                    setTimeout(() => {
                        dispatch(showNotificationAction(false, { type: '', content: '', origin: 'getUpcomingAppointment 3' }))
                    }, 3000);
                }
            })
        } else if (showAppointment === 'patient') {
            let data = { patientId: patientId }
            dispatch(getPatientAppointment(data)).then((result) => {
                if (result && result.code === 200) {

                } else if (result && result.status === 404) {

                } else if (result && result.status === 401) {
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Token expired.' }))
                    setTimeout(() => {
                        dispatch(showNotificationAction(false, { type: '', content: '', origin: 'getPatientAppoint 1' }))
                        navigate('/auth/login')
                    }, 3000);
                } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
                    dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
                    setTimeout(() => {
                        dispatch(showNotificationAction(false, { type: '', content: '', origin: 'getPatientAppoint 2' }))
                    }, 3000);
                } else {
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
                    setTimeout(() => {
                        dispatch(showNotificationAction(false, { type: '', content: '', origin: 'getPatientAppoint 3' }))
                    }, 3000);
                }
            })
        }
    }

    const addNewEvent = () => {
        setOpen(true);
    };
    const editEvent = (event) => {

        setOpen(true);
        // const newEditEvent = calevents.find((elem) => elem.title === event.title);
        event["patientLabel"] = `${event.patientName[0]}${event.patientName.slice(1)} (${event.patientId})`
        setUpdate(event);
    };
    const removeError = () => {
        dispatch(showNotificationAction(false, { type: '', content: '', origin: 'remove error appointment' }))
    }
    const submitEventHandler = (e) => {
        e.preventDefault();

        if (newApp.doctorName === "") {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select Doctor Name.' }))
        } else if (new Date(newApp.appointmentDate).getTime() !== new Date(newApp.appointmentDate).getTime() || newApp.appointmentDate === "") {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select valid Appointment Date.' }))
        } else if (newApp.patientName === "") {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select Patient Name.' }))
        } else if (new Date(newApp.appointmentStartTime).getTime() !== new Date(newApp.appointmentStartTime).getTime() || newApp.appointmentStartTime === "") {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select valid Appointment start time.' }))
        } else if (new Date(newApp.appointmentEndTime).getTime() !== new Date(newApp.appointmentEndTime).getTime() || newApp.appointmentEndTime === "") {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select valid Appointment end time.' }))
        } else if (newApp.serviceType === "") {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select Service Type.' }))
        } else if (newApp.appStatus === "") {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select App Status.' }))
        } else if (newApp.notes.length > 250) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Notes should have maximum 250 characters.' }))
        } else {
            // setCalEvents(newEvents);
            let data = {
                "patientId": newApp.patientId,
                "patientName": newApp.patientName,
                "doctorName": newApp.doctorName,
                "userId": newApp.userId,
                "serviceType": newApp.serviceType,
                "appStatus": newApp.appStatus,
                "notes": newApp.notes,
                "appointmentDate": "",
                "appointmentStartTime": "",
                "appointmentEndTime": "",
                "hospitalId": userinfo?.hospitalId
            }
            let appDate = moment(newApp.appointmentDate).format('YYYY-MM-DD')
            let sTime = moment(newApp.appointmentStartTime).format("H:mm:ss")
            let eTime = moment(newApp.appointmentEndTime).format("H:mm:ss")
            if (moment(eTime, "H:mm:ss").diff(moment(sTime, "H:mm:ss"), 'minutes') < 0) {
                let addDay = moment(newApp.appointmentDate).add(1, 'days').format('YYYY-MM-DD')
                let endDate = moment(addDay + ' ' + eTime, 'YYYY-MM-DD H:mm:ss')
                data.appointmentEndTime = endDate.utc()
            } else {
                data.appointmentEndTime = moment(appDate + ' ' + eTime, 'YYYY-MM-DD H:mm:ss').utc()
            }
            data.appointmentDate = moment(appDate + ' ' + sTime, 'YYYY-MM-DD H:mm:ss').utc()
            data.appointmentStartTime = moment(appDate + ' ' + sTime, 'YYYY-MM-DD H:mm:ss').utc()

            dispatch(addAppointment(data)).then((result) => {
                if (result && result.code === 200) {
                    dispatch(showNotificationAction(true, { type: 'success', content: 'Appointment added.' }))
                    // to show notification, because app.js function removes the notification
                    dispatch(showNotificationAction(true, { type: 'success', content: 'Appointment added.' }))
                    // setTimeout(() => {
                    getAppointmentHandler()
                    // }, 2000);
                    handleClose()
                } else if (result && result.status === 404) {

                } else if (result && result.status === 500) {
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Validation error.' }))
                    // to show notification, because app.js function removes the notification
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Validation error.' }))
                    // setTimeout(() => {
                    // dispatch(showNotificationAction(false, { type: '', content: '' }))
                    // }, 5000);
                } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
                    dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
                    // to show notification, because app.js function removes the notification
                    dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
                    // setTimeout(() => {
                    // dispatch(showNotificationAction(false, { type: '', content: '' }))
                    // }, 5000);
                } else {
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
                    // to show notification, because app.js function removes the notification
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
                    // setTimeout(() => {
                    // dispatch(showNotificationAction(false, { type: '', content: '' }))
                    // }, 5000);
                }
            })
        }
    };

    const updateEventHandler = (e) => {
        e.preventDefault();
        if (update.doctorName === "") {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select Doctor Name.' }))
        } else if (update.appointmentDate === "" || update.appointmentDate === null) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select Appointment Date.' }))
        } else if (update.patientName === "") {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select Patient Name.' }))
        } else if (update.appointmentStartTime === "" || update.appointmentStartTime === null) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select Appointment start time.' }))
        } else if (update.appointmentEndTime === "" || update.appointmentEndTime === null) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select Appointment end time.' }))
        } else if (update.serviceType === "") {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select Service Type.' }))
        } else if (update.appStatus === "") {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select App Status.' }))
        } else if (update.notes.length > 250) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Notes should have maximum 250 characters.' }))
        } else {
            let data = {
                "patientId": update.patientId,
                "doctorName": update.doctorName,
                "userId": update.userId,
                "serviceType": update.serviceType,
                "appStatus": update.appStatus,
                "notes": update.notes,
                "appointmentId": update.appointmentId,
                "appointmentDate": "",
                "appointmentStartTime": "",
                "appointmentEndTime": "",
                "hospitalId": userinfo?.hospitalId
            }
            let appDate = moment(update.appointmentDate).format('YYYY-MM-DD')
            let sTime = moment(update.appointmentStartTime).format("H:mm:ss")
            let eTime = moment(update.appointmentEndTime).format("H:mm:ss")


            if (moment(eTime, "H:mm:ss").diff(moment(sTime, "H:mm:ss"), 'minutes') < 0) {
                let addDay = moment(update.appointmentDate).add(1, 'days').format('YYYY-MM-DD')
                let endDate = moment(addDay + ' ' + eTime, 'YYYY-MM-DD H:mm:ss')
                data.appointmentEndTime = endDate.utc()
            } else {
                data.appointmentEndTime = moment(appDate + ' ' + eTime, 'YYYY-MM-DD H:mm:ss').utc()
            }
            data.appointmentDate = moment(appDate + ' ' + sTime, 'YYYY-MM-DD H:mm:ss').utc()
            data.appointmentStartTime = moment(appDate + ' ' + sTime, 'YYYY-MM-DD H:mm:ss').utc()
            // data.appointmentDate = moment(update.appointmentDate).format('YYYY-MM-DD')
            // data.appointmentStartTime = moment(update.appointmentStartTime).format("H:mm:ss")
            // data.appointmentEndTime = moment(update.appointmentEndTime).format("H:mm:ss")


            dispatch(updateAppointment(data)).then((result) => {
                if (result && result.code === 200) {
                    dispatch(showNotificationAction(true, { type: 'success', content: 'Appointment updated.' }))
                    dispatch(showNotificationAction(true, { type: 'success', content: 'Appointment updated.' }))
                    // setTimeout(() => {
                    getAppointmentHandler()
                    // }, 2000);
                    handleClose()
                } else if (result && result.status === 404) {

                } else if (result && result.status === 500) {
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Validation error.' }))
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Validation error.' }))
                    setTimeout(() => {
                        // dispatch(showNotificationAction(false, { type: '', content: '' }))
                    }, 5000);
                } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
                    dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
                    dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
                    setTimeout(() => {
                        // dispatch(showNotificationAction(false, { type: '', content: '' }))
                    }, 5000);
                } else {
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
                    setTimeout(() => {
                        // dispatch(showNotificationAction(false, { type: '', content: '' }))
                    }, 5000);
                }
            })
        }
    };

    const deleteHandler = (event) => {
        let data = {
            appointmentId: event.appointmentId
        }
        dispatch(deleteAppointment(data))
            .then(result => {
                // const updatecalEvents = calevents.filter((ind) => ind.appointmentId !== event.appointmentId);
                // setCalEvents(updatecalEvents);
                if (result && result.code === 200) {
                    // setTimeout(() => {
                    handleClose()
                    getAppointmentHandler()
                    dispatch(showNotificationAction(true, { type: 'success', content: 'Appointment deleted.' }))
                    dispatch(showNotificationAction(true, { type: 'success', content: 'Appointment deleted.' }))
                    // }, 2000);
                } else if (result && result.status === 404) {

                } else if (result && result.status === 500) {
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Validation error.' }))
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Validation error.' }))
                    setTimeout(() => {
                        // dispatch(showNotificationAction(false, { type: '', content: '' }))
                    }, 5000);
                } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
                    dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
                    dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
                    setTimeout(() => {
                        // dispatch(showNotificationAction(false, { type: '', content: '' }))
                    }, 5000);
                } else {
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
                    dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
                    setTimeout(() => {
                        // dispatch(showNotificationAction(false, { type: '', content: '' }))
                    }, 5000);
                }
            })

    };
    const handleClose = () => {
        setOpen(false);
        setUpdate(null);
        setnewApp({
            "patientId": "",
            "patientName": "",
            "patientLabel": "",
            "userId": "",
            "doctorName": "",
            "appointmentDate": "",
            "appointmentStartTime": new Date(y, m, d, 0, 0),
            "appointmentEndTime": new Date(y, m, d, 0, 30),
            "serviceType": "",
            "appStatus": "",
            "notes": "",
            "createdBy": ""
        })

    };

    const customEvent = ({ event }) => {
        return <>
            <div>
                {event.doctorName ? event.doctorName.includes('Dr.') ? event.doctorName : 'Dr. ' + event.doctorName : ''}
                {event.userId ? ' (' + event.userId + ')' : ''};
            </div>
            <div>
                {event.patientName ? event.patientName : event.firstName + ' ' + event.lastName} ({event.patientId})
            </div>
        </>
    }

    return (
        <>
            {(showAppointment === "All" && userinfo?.role !== 'admin') && <Toolbar
                sx={{
                    pl: { sm: 3 },
                    pr: { xs: 2, sm: 2 },
                    bgcolor: (theme) => '#1990FC',
                    borderRadius: '4px',
                    boxShadow: '0px 4px 4px rgba(19, 83, 140, 0.15)',
                    width: '100%'
                }}
            >
                <Typography sx={{ flex: '1 1 100%' }} id="newVisitTitle" component="div" onClick={() => {
                    addNewEvent()
                }} >
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: '20px' }} variant="h6" id="" component="div" fontSize="20px">
                        <img src={CalendarAddIcon} className="tabIcon" alt="logoImg" />
                        Add Appointment
                    </Typography>
                    <FeatherIcon icon="arrow-right" />
                </Typography>
            </Toolbar>}
            <Card sx={{ width: '100%' }}>

                <CardContent>
                    <Calendar
                        selectable
                        events={appoinmentList}
                        defaultView="month"
                        scrollToTime={new Date(1970, 1, 1, 6)}
                        defaultDate={new Date()}
                        localizer={localizer}
                        style={{ height: 'calc(100vh - 350px' }}
                        onSelectEvent={(event) => { editEvent(event) }}
                        // onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
                        eventPropGetter={(event) => {
                            let doctorId = doctorList.find(a => a.label === event.doctorName)?.value
                            var makeColorCode = '0123456789ABCDEF';
                            var code = '#';
                            for (var count = 0; count < 6; count++) {
                                code = code + makeColorCode[Math.floor(Math.random() * 16)];
                            }
                            return {
                                style: { backgroundColor: doctorId ? ColorList[doctorId % 25] : '#3174ad' }
                            }
                        }}
                        components={{
                            // dateCellWrapper: eventWrapper,
                            // toolbar: CustomToolbar
                            event: customEvent
                        }}
                    />
                </CardContent>
            </Card>

            <Dialog open={open} onClose={handleClose} fullWidth >
                <DialogContent className='appointmentForm' sx={{ padding: '20px 16px' }}>
                    <Typography sx={{ mb: 2, ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} id="" component="div"  >
                        <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            <img src={FormIcon} className="tabIcon" alt="logoImg" />
                            {userinfo?.role !== 'admin' && showAppointment === 'All' ? update ? 'Update Appointment' : 'Appointment Form' : 'View Appointment'}
                        </Typography>
                        <FeatherIcon icon="x" style={{
                            width: 20, height: 20, marginLeft: '5px', color: 'white', borderRadius: '50%', background: '#1990FC', padding: '2px'
                        }}
                            onClick={handleClose} />
                    </Typography>
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={6}
                            sx={{
                                background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#EBF5FF'}`,
                                p: 1
                            }}
                        >
                            <CustomFormLabel htmlFor="demo-simple-select">Doctor Name</CustomFormLabel>

                            <Autocomplete
                                disabled={showAppointment !== 'All' || userinfo?.role === 'admin'}
                                disablePortal
                                value={update ? update.doctorName : newApp.doctorName}
                                isOptionEqualToValue={(e) => {
                                    if (update) return e.label === update.doctorName ? true : false
                                    else return newApp.doctorName === "" || e.label === newApp.doctorName ? true : false
                                }
                                }
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        if (update)
                                            setUpdate({ ...update, userId: newValue.value, doctorName: newValue.label })
                                        else
                                            setnewApp({ ...newApp, userId: newValue.value, doctorName: newValue.label })
                                    } else {
                                        if (update)
                                            setUpdate({ ...update, userId: "", doctorName: "" })
                                        else
                                            setnewApp({ ...newApp, userId: "", doctorName: "" })
                                    }
                                }}
                                id="combo-box-demo"
                                options={doctorList}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField {...params} size="small" placeholder="Select Doctor" aria-label="Select Doctor" />
                                )}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        padding: '14.5px 14px !important'
                                    }
                                }}
                                className="border-g1"
                            />
                        </Grid>
                        <Grid item
                            xs={12}
                            sm={6}
                            lg={6}
                            sx={{
                                background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#EBF5FF'}`,
                                p: 1
                            }} >
                            <CustomFormLabel>Appointment Date</CustomFormLabel>
                            <CustomTextField
                                id="Appointment Date"
                                type={'date'}
                                placeholder=""
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled={showAppointment !== 'All' || userinfo?.role === 'admin'}
                                value={update ? moment(update.appointmentDate).format('YYYY-MM-DD') : newApp.appointmentDate}
                                onChange={(e) => {
                                    if (update)
                                        setUpdate({ ...update, appointmentDate: e.target.value })
                                    else
                                        setnewApp({ ...newApp, appointmentDate: e.target.value })
                                }}
                                className="border-g1"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} sx={{}}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={6}
                            sx={{
                                background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#EBF5FF'}`,
                                p: 1
                            }}
                        >
                            <CustomFormLabel htmlFor="demo-simple-select">Patient Name</CustomFormLabel>
                            <Autocomplete
                                disabled={update || showAppointment !== 'All' || userinfo?.role === 'admin'}
                                disablePortal
                                value={update ? update.patientLabel : newApp.patientLabel}
                                isOptionEqualToValue={(e) => {
                                    if (update) return e.value === update.patientId ? true : false
                                    else return newApp.patientId === "" || e.value === newApp.patientId ? true : false
                                }
                                }
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        if (update)
                                            setUpdate({ ...update, patientId: newValue.value, patientName: newValue.name, patientLabel: newValue.label })
                                        else
                                            setnewApp({ ...newApp, patientId: newValue.value, patientName: newValue.name, patientLabel: newValue.label })
                                    } else {
                                        if (update)
                                            setUpdate({ ...update, patientId: "", patientName: "", patientLabel: "" })
                                        else
                                            setnewApp({ ...newApp, patientId: "", patientName: "", patientLabel: "" })
                                    }
                                }}
                                id="combo-box-demo"
                                options={patientList}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField {...params} size="small" placeholder="Select Patient" aria-label="Select Patient" />
                                )}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        padding: '14.5px 14px !important'
                                    }
                                }}
                                className="border-g1"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} sx={{}}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={6}
                            sx={{
                                background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#EBF5FF'}`,
                                p: 1
                            }}
                        >
                            <CustomFormLabel>Appointment Start Time</CustomFormLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    disabled={showAppointment !== 'All' || userinfo?.role === 'admin'}
                                    value={update ? update.appointmentStartTime : newApp.appointmentStartTime}
                                    onChange={(newValue) => {
                                        var newDate = new Date(newValue);
                                        // add 30 minutes to end time by default
                                        if (update)
                                            setUpdate({ ...update, appointmentStartTime: newValue, appointmentEndTime: new Date(newDate.getTime() + 30 * 60000) })
                                        else
                                            setnewApp({ ...newApp, appointmentStartTime: newValue, appointmentEndTime: new Date(newDate.getTime() + 30 * 60000) })
                                    }}
                                    onClick={() => { removeError() }}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            size="small"
                                            {...params}
                                            fullWidth
                                            sx={{
                                                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                                                    padding: '8.5px 14px'
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    width: '18px',
                                                    height: '18px',
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    display: 'none',
                                                },
                                            }}
                                            className="border-g1"
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={6}
                            sx={{
                                background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#EBF5FF'}`,
                                p: 1
                            }}
                            className='dateeeee'
                        >
                            <CustomFormLabel>Appointment End Time</CustomFormLabel>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    disabled={showAppointment !== 'All' || userinfo?.role === 'admin'}
                                    value={update ? update.appointmentEndTime : newApp.appointmentEndTime}
                                    onChange={(newValue) => {
                                        var newDate = new Date(newValue);
                                        if (update)
                                            setUpdate({ ...update, appointmentEndTime: new Date(newDate.getTime()) })
                                        else
                                            setnewApp({ ...newApp, appointmentEndTime: new Date(newDate.getTime()) })
                                    }}
                                    onClick={() => { removeError() }}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            size="small"
                                            {...params}
                                            fullWidth
                                            sx={{
                                                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                                                    padding: '8.5px 14px'
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    width: '18px',
                                                    height: '18px',
                                                },
                                                '& .MuiFormHelperText-root': {
                                                    display: 'none',
                                                },
                                            }}
                                            className="border-g1"
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}  >
                        <CustomFormLabel htmlFor="demo-simple-select">Service Type</CustomFormLabel>
                        <CustomSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disabled={showAppointment !== 'All' || userinfo?.role === 'admin'}
                            value={update ? update.serviceType : newApp.serviceType}
                            onChange={(e) => {
                                if (update)
                                    setUpdate({ ...update, serviceType: e.target.value })
                                else
                                    setnewApp({ ...newApp, serviceType: e.target.value })
                            }}
                            onClick={() => { removeError() }}
                            fullWidth
                            size="small"
                            className="login-content"
                            // className="border-g1"
                        >
                            {serviceTypeList.map(m => <MenuItem key={m} value={m}>{m}</MenuItem>)}
                        </CustomSelect>
                    </div>
                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}  >
                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <CustomFormLabel>App Status</CustomFormLabel>
                            <Box>
                                <FormControlLabel
                                    checked={(update && update.appStatus === 'pending') || newApp.appStatus === 'pending'}
                                    onChange={(e) => {
                                        if (update)
                                            setUpdate({ ...update, appStatus: e.target.value })
                                        else
                                            setnewApp({ ...newApp, appStatus: e.target.value })
                                    }}
                                    onClick={() => { removeError() }}
                                    disabled={showAppointment !== 'All' || userinfo?.role === 'admin'}
                                    value="pending"
                                    label="Pending"
                                    name="radio-button-demo"
                                    control={<CustomRadio />}
                                    inputprops={{ 'aria-label': 'A' }}
                                />
                                <FormControlLabel
                                    checked={(update && update.appStatus === 'approved') || newApp.appStatus === 'approved'}
                                    onChange={(e) => {
                                        if (update)
                                            setUpdate({ ...update, appStatus: e.target.value })
                                        else
                                            setnewApp({ ...newApp, appStatus: e.target.value })
                                    }}
                                    onClick={() => { removeError() }}
                                    disabled={showAppointment !== 'All' || userinfo?.role === 'admin'}
                                    value="approved"
                                    label="Confirmed"
                                    name="radio-button-demo"
                                    control={<CustomRadio />}
                                    inputprops={{ 'aria-label': 'B' }}
                                />

                                <FormControlLabel
                                    checked={(update && update.appStatus === 'rescheduled') || newApp.appStatus === 'rescheduled'}
                                    onChange={(e) => {
                                        if (update)
                                            setUpdate({ ...update, appStatus: e.target.value })
                                        else
                                            setnewApp({ ...newApp, appStatus: e.target.value })
                                    }}
                                    onClick={() => { removeError() }}
                                    disabled={showAppointment !== 'All' || userinfo?.role === 'admin'}
                                    value="rescheduled"
                                    label="Rescheduled"
                                    name="radio-button-demo"
                                    control={<CustomRadio />}
                                    inputprops={{ 'aria-label': 'C' }}
                                />
                            </Box>
                        </FormControl>
                    </div>
                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <CustomFormLabel>Notes </CustomFormLabel>

                            <CustomTextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder=""
                                disabled={showAppointment !== 'All' || userinfo?.role === 'admin'}
                                value={update ? update.notes : newApp.notes}
                                onChange={(e) => {
                                    if (update)
                                        setUpdate({ ...update, notes: e.target.value })
                                    else
                                        setnewApp({ ...newApp, notes: e.target.value })
                                }}
                                onClick={() => { removeError() }}
                                className="border-g1"
                            />
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions className='appointmentFormActions'>
                    {update ? (
                        <Typography
                            component={Link}
                            // target="_blank"
                            to={rootRoute + "/patient/detail"}
                            state={{
                                id: update.patientId, firstName: update.firstName, lastName: update.lastName, dob: update.dob,
                            }}
                            fontWeight="500"
                            sx={{
                                display: 'block',
                                // textDecoration: 'none',
                                color: '#1990FC',
                                // ml: '24px'
                            }}
                        >
                            View Patient Detail
                        </Typography>
                    ) : (
                        <div style={{ color: '#EBF5FF' }}>None</div>
                    )}
                    <Box>
                        <Button type="button"
                            variant="contained"
                            onClick={handleClose}
                            sx={{
                                mr: '8px',
                                background: "#888888"

                            }}
                        >Cancel</Button>

                        {update && showAppointment === 'All' && userinfo?.role !== 'admin'
                            ? (
                                <Button
                                    type="button"
                                    color="error"
                                    variant="contained"
                                    onClick={() => deleteHandler(update)}
                                    sx={{
                                        mr: '8px'
                                    }}
                                >
                                    Delete
                                </Button>
                            ) : (
                                ''
                            )
                        }
                        {showAppointment === 'All' && userinfo?.role !== 'admin' && <Button variant="contained" className='registerButton' color="primary"
                            onClick={update ? updateEventHandler : submitEventHandler}>
                            {update ? 'Update' : 'Add'}
                        </Button>
                        }
                    </Box>
                </DialogActions>
            </Dialog >
        </>
    )
};

export default AppointmentCalendar
