import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Drawer,
    useMediaQuery,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { SidebarWidth } from '../../../assets/global/Theme-variable';

// import LogoIcon from '../../../assets/images/logos/logo';
// import LogoIcon from '../logo/LogoIcon';
import VisitMenuitems from './VisitMenuItems';
import Scrollbar from '../../../components/custom-scroll/Scrollbar';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const VisitSidebar = ({ onSidebarClose, isSidebarOpen, getTabSelected }) => {
    const [open, setOpen] = React.useState(true);
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const isMobileVisitSidebarOpenValue = useSelector((state) => state.patientReducer.isMobileVisitSidebarOpen);
    const isMobileSidebarOpen = useMemo(() => {
        if (isMobileVisitSidebarOpenValue)
            return isMobileVisitSidebarOpenValue
        else
            return false
    }, [isMobileVisitSidebarOpenValue])

    const tabNoValue = useSelector((state) => state.patientReducer.tabNo);
    const tabNo = useMemo(() => {
        if (tabNoValue)
            return tabNoValue
        else
            return 0
    }, [tabNoValue])

    const handleClick = (index) => {
        if (open === index) {
            setOpen((prevopen) => !prevopen);
        } else {
            setOpen(index);
        }
        getTabSelected(index)
    };
    const SidebarContent = (
        <Scrollbar className="visitScrollbar" style={{
            height: 'calc(100vh - 125px)',
            maxHeight: '380px'
        }}>
            <Box sx={{ p: 2, pt: 0, pb: 0 }}>
                <Box>
                    <List>
                        {VisitMenuitems.map((item, index) => {
                            // {/********SubHeader**********/}

                            return (
                                <List component="li" disablePadding key={item.title}>
                                    <ListItem
                                        onClick={() => handleClick(index)}
                                        button
                                        selected={(tabNo === index)}
                                        sx={{
                                            mb: 0,
                                            ...((tabNo === index) && {
                                                color: 'white',
                                                backgroundColor: (theme) => `${theme.palette.primary.main}!important`,
                                            }),
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                ...((tabNo === index) && { color: 'white' }),
                                            }}
                                        >
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText onClick={onSidebarClose}>{item.title}</ListItemText>
                                    </ListItem>
                                </List>
                            );

                        })}
                    </List>
                </Box>
            </Box>
        </Scrollbar >
    );
    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open={isSidebarOpen}
                variant="persistent"
                PaperProps={{
                    sx: {
                        width: 'fit-content',
                        border: '0 !important',
                        '& .MuiDrawer-docked': {
                            position: 'fixed !important'
                        },
                    }
                }}
                className="visitDrawer"
            >
                {SidebarContent}
            </Drawer>
        );
    }
    return (
        <Drawer
            anchor="left"
            open={isMobileSidebarOpen}
            onClose={onSidebarClose}
            PaperProps={{
                sx: {
                    width: SidebarWidth,
                    border: '0 !important',
                    marginTop: '100px'
                },
            }}
            variant="temporary"
            className='visitSidebar'
        >
            {SidebarContent}
        </Drawer>
    );
};

VisitSidebar.propTypes = {
    isMobileSidebarOpen: PropTypes.bool,
    onSidebarClose: PropTypes.func,
    isSidebarOpen: PropTypes.bool,
};

export default VisitSidebar;
