import { Box, Card, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "../../../api/axios";

const LIMIT = 5;

const oldDataShow = (data, createdAt) => {
  return Object.keys(data).map((key) => (
    <Typography
      key={key}
      variant="h6"
      fontWeight="600"
      component={"span"}
      sx={{
        fontSize: {
          xs: "12px",
          md: "12px",
          lg: "13px",
        },
      }}
    >
      {Object.keys(data[key]).filter((f) => data[key][f]).length > 0 ? (
        <>
          <Typography variant="body2">{key}:</Typography>
          {Object.keys(data[key])
            .filter((f) => data[key][f] && data[key] !== "other")
            .map((str) => (str === "other" ? data[str] : `${str}, `))}
          <Typography
            variant="h6"
            fontWeight="500"
            component={"span"}
            fontSize="12px"
          >
            at {moment(createdAt).format("DD/MM/YYYY")}
          </Typography>
        </>
      ) : null}
    </Typography>
  ));
};

const MedicalSummaryMedication = () => {
  const { state } = useLocation();
  const { id } = state || {};
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `patient/medical/summary/current-medication/${id}?page=${page}&limit=${LIMIT}`
      )
      .then((res) => {
        setIsLoading(false);
        setData(res.data.data.medications);
        setTotal(res.data.data.totalMedication);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [page, id]);
  const incPage = () => {
    setPage((prevS) => prevS + 1);
  };
  const decPage = () => {
    setPage((prevS) => prevS - 1);
  };
  const totalPage = Math.ceil(total / LIMIT);
  return (
    <Grid item xs={12} md={6} lg={6}>
      <Card
        sx={{
          p: {
            xs: "10px",
            sm: "10px",
            lg: "20px",
          },
        }}
      >
        <Typography
          component="div"
          color="textSecondary"
          variant="h5"
          fontWeight="500"
          sx={{
            mr: "2px",
          }}
        >
          Medication
        </Typography>
        <Box sx={{ width: "100%", height: "150px", overflow: "auto" }}>
          {isLoading && <Typography>Loading...</Typography>}
          {!isLoading &&
            data.length > 0 &&
            data
              .map((item) => ({
                ...item,
                clinicalSummaryMedicationData: JSON.parse(
                  item.clinicalSummaryMedicationData
                ),
              }))
              .map((item, index) => (
                <Box
                  sx={{
                    borderBottom: "1px soldi #fff",
                  }}
                  key={index}
                >
                  <Typography
                    variant="h6"
                    fontWeight="600"
                    component={"span"}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        md: "12px",
                        lg: "13px",
                      },
                    }}
                  >
                    {item.clinicalSummaryMedicationData?.antihypertensives ? (
                      oldDataShow(
                        item.clinicalSummaryMedicationData,
                        item.createdAt
                      )
                    ) : (
                      <>
                        {Object.keys(item.clinicalSummaryMedicationData)
                          .filter(
                            (k) => item.clinicalSummaryMedicationData?.[k]
                          )
                          .map((str) =>
                            str === "other"
                              ? "Other: " +
                                item.clinicalSummaryMedicationData["other"]
                              : `${str}, `
                          )}
                        <Typography
                          variant="h6"
                          fontWeight="500"
                          component={"span"}
                          fontSize="12px"
                        >
                          {" "}
                          at {moment(item.createdAt).format("DD/MM/YYYY")}
                        </Typography>
                      </>
                    )}
                  </Typography>
                </Box>
              ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="500"
            component={"span"}
            fontSize="12px"
          >
            page {page}/{totalPage}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <button
              onClick={decPage}
              disabled={page === 1}
              style={{ cursor: "pointer" }}
            >
              &lt;
            </button>
            <button
              onClick={incPage}
              disabled={page === totalPage}
              style={{ cursor: "pointer" }}
            >
              &gt;
            </button>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

export default MedicalSummaryMedication;
