import React, { useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardContent, Typography } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import MonthIcon from '../../assets/icons/MonthIcon.png'
import { useEffect } from 'react';
import moment from 'moment/moment';

const TypesOfVaccineAdministration = () => {

    // const theme = useTheme(); 
    const typesOfVaccinessList = useSelector(state => state.ReportReducer.getTypesOfVaccineAdministrationData)
    const [patientAgesArray, setPatientAgesArray] = useState([]);
    const [chartData, setChartData] = useState({
        options: {
            chart: {
                id: "bar",
                height: 500
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: false,
                }
            },
            title: {
                text: 'Types Of Vaccines',
                align: 'left'
            },
            xaxis: {
                categories: ["SC", "IM", "ORAL"],
                title: {
                    text: 'Vaccine',
                },
            },
            yaxis: {
                title: {
                    text: 'No Of Vaccines Use',
                },
            },
        },
        series: [
            {
                name: "Vaccines Count :-",
                data: []
            }
        ]
    });

    useEffect(() => {
        if (typesOfVaccinessList) {
            var typesOfVaccinessArray = typesOfVaccinessList.map((item, index) => { return item.vaccination });
            var typesOfVaccinessValueArray = typesOfVaccinessList.map((item, index) => { return item.count });
            setChartData(prevChartData => ({
                ...prevChartData,
                options:
                {
                    xaxis: {
                        categories: typesOfVaccinessArray,
                    },
                },
                series: [
                    {
                        data: typesOfVaccinessValueArray,
                    },
                ],
            }));
        }
    }, [typesOfVaccinessList])

    return (
        <Card
            sx={{
                p: {
                    xs: '10px',
                    sm: '10px',
                    lg: '20px',
                },
                width: '100%'
            }}
        >
            <CardContent>
                <Typography sx={{
                    flex: '1 1 100%', p: {
                        xs: '10px',
                        sm: '10px',
                        lg: '10px',
                    },
                    color: '#464646',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px'
                }} variant="h4" id="" fontWeight="500" component="div">
                    <img src={MonthIcon} className="tabIcon" alt="logoImg" />
                    Vaccine analysis
                </Typography>
                {
                    // typesOfVaccinessList.length > 0 &&
                    <Chart
                        options={chartData.options}
                        series={chartData.series}
                        type="bar"
                        height="500"
                    />
                }
            </CardContent>
        </Card>
    );
};

export default TypesOfVaccineAdministration;
