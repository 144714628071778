import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Typography, Card, CardContent, Tabs, Tab, Toolbar, FormControl, Button } from '@mui/material';
import FeatherIcon from 'feather-icons-react';

import CustomTextField from '../../../forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../forms/custom-elements/CustomFormLabel';

import moment from 'moment';
import { showNotificationAction } from '../../../../redux/pushnotification/Action';
import DiagnosisIcon from '../../../../assets/icons/DiagnosisIcon.png';
import VisitSidebar from '../VisitSidebar';
import { changeTabNo, openMblVisitSidebar, saveDiagnosisData } from '../../../../redux/patients/Action';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const DiagnosisPlan = ({ changeShowVisitOb }) => {

    const diagnosis = useSelector((state) => state.patientReducer.diagnosisData);
    const dispatch = useDispatch()

    // const [showDate, setShowDate] = useState(false);
    const [nextVisit, setnextVisit] = React.useState('');
    const [diagInformant, setdiagInformant] = useState('')
    const [planInformant, setplanInformant] = useState('')


    //  const [selectedValueType, setSelectedValueType] = useState('')
    const [value, setValue] = React.useState(0);

    const DiagnosisRef = useRef(null)
    const PlanRef = useRef(null)


    // const handleChange = (event) => {
    // }
    // const tabNoValue = useSelector((state) => state.patientReducer.tabNo);
    // const tabNo = useMemo(() => {
    //     if (tabNoValue)
    //         return tabNoValue
    //     else
    //         return 0
    // }, [tabNoValue])

    const customizer = useSelector((state) => state.CustomizerReducer);
    // const [isSidebarOpen, setSidebarOpen] = useState(true);

    const getTabSelected = (no) => {
        saveData()
        dispatch(changeTabNo(no))
    }

    useEffect(() => {
        setdiagInformant(diagnosis.diagInformant ? diagnosis.diagInformant : '')
        setplanInformant(diagnosis.planInformant ? diagnosis.planInformant : '')
        setnextVisit(diagnosis.nextVisit ? diagnosis.nextVisit : '')
    }, [diagnosis])

    // useEffect(() => {

    //     diagnosisDataDetail({ diagInformant, planInformant, nextVisit })
    // }, [diagInformant, planInformant, nextVisit])

    useEffect(() => {
        return () => dispatch(showNotificationAction(false, { type: '', content: '', origin: 'diagnosis useeffect' }))
    }, [dispatch])

    const saveData = () => {
        dispatch(saveDiagnosisData({ ...diagnosis, diagInformant, planInformant, nextVisit }))
    }

    const scrollDown = (ref) => {
        if (ref.current) {
            let scrollToPx = ref.current.offsetTop - 100
            window.scrollTo({
                top: scrollToPx,
                behavior: 'smooth',
            });

        }
    };

    const clearFunction = () => {
        setdiagInformant('')
        setplanInformant('')
        setnextVisit('')
    }

    return (
        <>
            <VisitSidebar
                isSidebardir={customizer.activeDir === 'rtl' ? 'left' : 'right'}
                isSidebarOpen={true}
                onSidebarClose={() => dispatch(openMblVisitSidebar(false))}
                getTabSelected={getTabSelected}
            />

            <Card
                sx={{
                    p: {
                        xs: '0px',
                        sm: '0px',
                        lg: '0px',
                    },
                    width: '100%'
                }}
                className="newPatientCard newVisitCard"
            >
                <CardContent
                    sx={{
                        p: 0,
                    }}
                >
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 2, sm: 2 },
                            // ...(numSelected > 0 && {
                            bgcolor: (theme) => '#1990FC'
                            // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            // }),
                        }}
                    >
                        <Typography sx={{
                            flex: '1 1 100%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '20px'
                        }} variant="h6" id="newpatientTitle" component="span">
                            <img src={DiagnosisIcon} className="tabIcon" alt="logoImg" />
                            Diagnosis & Plan
                        </Typography>
                        <Typography sx={{
                            flex: '1 1 100%', alignItems: 'center', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer'
                        }} variant="h6" id="newpatientTitle" component="span" onClick={clearFunction}>
                            Clear
                            <FeatherIcon icon="x" style={{
                                width: 14, height: 14, marginLeft: '5px', color: '#1990FC', borderRadius: '50%', background: 'white'
                            }} />
                        </Typography>
                    </Toolbar>
                    <Box sx={{
                        width: '100%',
                        p: {
                            xs: '20px',
                            sm: '35px',
                            lg: '35px',
                        },
                        pt: {
                            xs: '10px',
                            sm: '10px',
                            lg: '10px',
                        }
                    }}
                        className="newPatientCardBox"
                    >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                // onChange={handleChange}
                                aria-label="basic tabs example"
                                textColor="secondary"
                                allowScrollButtonsMobile
                                scrollButtons
                                indicatorColor="secondary"
                            >
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="Diagnosis & Impression"
                                    {...a11yProps(0)}
                                    onClick={() => {
                                        scrollDown(DiagnosisRef)
                                        setValue(0)
                                    }}
                                />
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="Plan"
                                    {...a11yProps(1)}
                                    onClick={() => {
                                        scrollDown(PlanRef)
                                        setValue(1)
                                    }}
                                />
                            </Tabs>
                        </Box>

                        <div ref={DiagnosisRef}>
                            {/* <Typography className='panelHeading'> */}
                            {/* <img src={logo1} className="tabIcon" /> */}
                            {/* Details */}
                            {/* </Typography> */}
                            <div className="tabpanelContent">
                                <Typography className='panelHeading'>
                                    {/* <img src={logo1} className="tabIcon" /> */}
                                    Diagnosis & Impression
                                </Typography>
                                <div style={{ width: '100%' }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <CustomTextField
                                            id="outlined-multiline-static"
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            placeholder="Diagnosis & Impression"
                                            value={diagInformant}
                                            onChange={e => {
                                                setdiagInformant(e.target.value)
                                            }}
                                            className="border-g1"
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div ref={PlanRef}>
                            <div className="tabpanelContent">
                                <Typography className='panelHeading'>
                                    {/* <img src={logo1} className="tabIcon" /> */}
                                    Plan
                                </Typography>
                                <div style={{ width: '100%' }}>
                                    <FormControl
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <CustomTextField
                                            id="outlined-multiline-static"
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            placeholder="Plan"
                                            value={planInformant}
                                            onChange={e => setplanInformant(e.target.value)}
                                            className="border-g1"
                                        />
                                    </FormControl>
                                </div>

                                <div style={{ width: '100%' }}  >
                                    <CustomFormLabel>Next Visit</CustomFormLabel>

                                    {/* <div style={{ width: '100%' }}
                                    onClick={() => {
                                        setShowDate(true)
                                    }}
                                    onFocus={() => {
                                        setShowDate(true)
                                    }}

                                    onBlur={() => {
                                        if (nextVisit === '') {
                                            setShowDate(false)
                                        }
                                    }}
                                    className="dateDiv"
                                > */}
                                    <CustomTextField
                                        id="nextVisit"
                                        type={'date'}
                                        placeholder="Next Visit"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        value={nextVisit}
                                        onChange={(e) => {
                                            if (moment(e.target.value).diff(moment(), 'days') >= 0) {
                                                setnextVisit(e.target.value)
                                                // dispatch(showNotificationAction(false, { type: '', content: '' }))
                                            } else {
                                                dispatch(showNotificationAction(true, { type: 'error', content: 'Next Visit should be the future date.' }))
                                            }

                                        }}
                                    />
                                    {/* {showDate ? '' : <FeatherIcon icon="calendar" className="calendaricon" />}
                                </div> */}
                                </div>
                            </div>
                        </div>
                        <div style={{ float: 'right', margin: '15px 0px' }}>
                            <Button variant="contained" color="primary" className='visitTopButton' onClick={() => {
                                saveData()
                                changeShowVisitOb(true)
                                dispatch(changeTabNo(0))
                            }}>Review</Button>
                        </div>
                    </Box>
                </CardContent>
            </Card >
        </>
    );

};

export default DiagnosisPlan;
