import React, { useRef, useEffect, useState } from 'react';
import { Typography, } from '@mui/material';
import CustomTextField from '../../forms/custom-elements/CustomTextField';
import logo2 from '../../../assets/images/newpatientIcon/Rectangle 4 (1).png'
import CustomFormLabel from '../../forms/custom-elements/CustomFormLabel';
import { Country, State, City } from 'country-state-city';
import Select from 'react-select';

const AddressPanel = ({ detail, scrollToField, returnPanelData }) => {

    const addline1Ref = useRef(null);
    const addline2Ref = useRef(null);
    const cityRef = useRef(null);
    const stateRef = useRef(null);
    const countryRef = useRef(null);

    const [countryArray, setCountryArray] = useState([]);
    const [countryValue, setCountryValue] = useState(null);
    const [stateArray, setStateArray] = useState([]);
    const [stateValue, setStateValue] = useState(null);

    const scrollDown = (ref) => {
        if (ref.current) {
            let scrollToPx = ref.current.offsetTop - 100
            window.scrollTo({
                top: scrollToPx,
                behavior: 'smooth',
            });
        }
    };

    const removeError = (ref) => {
        ref.current.style.border = "unset";
        ref.current.style.borderRadius = "unset";
        // dispatch(showNotificationAction(false, { type: '', content: '' }))
    }
    useEffect(() => {
        if (scrollToField && scrollToField !== "") {
            if (scrollToField === "addline1Ref") {
                scrollDown(addline1Ref)
                // changeBorderColor(addline1Ref)
            } else if (scrollToField === "addline2Ref") {
                scrollDown(addline2Ref)
                // changeBorderColor(addline2Ref)
            } else if (scrollToField === "cityRef") {
                scrollDown(cityRef)
                // changeBorderColor(cityRef)
            } else if (scrollToField === "stateRef") {
                scrollDown(stateRef)
                // changeBorderColor(stateRef)
            } else if (scrollToField === "countryRef") {
                scrollDown(countryRef)
                // changeBorderColor(countryRef)
            } else {

            }
        }
    }, [scrollToField])

    useEffect(() => {
        let getAllCountries = Country.getAllCountries();
        const countryResponse = getAllCountries.map(item => ({ "label": item.name, "value": item.isoCode }));
        setCountryArray(countryResponse);
    }, [])

    useEffect(() => {
        if (detail.country !== "" && detail.country !== undefined) {
            let getAllCountries = Country.getAllCountries();
            let getSpecificCountry = getAllCountries.find((item) => item.name === detail.country.trim());
            if (getSpecificCountry) {
              setCountryValue({ "label": getSpecificCountry.name, "value": getSpecificCountry.isoCode });
              onClickState(getSpecificCountry.isoCode);
            }
        }
        if (detail.state !== "" && detail.state !== undefined) { setStateValue({ "label": detail.state, "value": detail.state }) }
    }, [detail.country, detail.state])

    const onClickState = async (countryCode) => {
        let getStateOfCountries = State.getStatesOfCountry(countryCode)
        const stateResponse = getStateOfCountries.map(item => ({ "label": item.name, "value": item.isoCode }));
        setStateArray(stateResponse);
    }

    return <div className="tabpanelContent">
        <Typography className='panelHeading'>
            <img src={logo2} className="tabIcon" alt="logoImg" />
            Address
        </Typography>
        <div style={{ width: '100%' }} ref={addline1Ref} onClick={() => { removeError(addline1Ref) }}>
            <CustomFormLabel>Address line 1</CustomFormLabel>
            <CustomTextField
                id="addressone"
                placeholder="Address line 1"
                variant="outlined"
                fullWidth
                // size="small"
                value={detail.addline1}
                onChange={(e) => returnPanelData('addline1', e.target.value)}
            />
        </div>
        <div style={{ width: '100%' }} ref={addline2Ref} onClick={() => { removeError(addline2Ref) }}>
            <CustomFormLabel>Address line 2</CustomFormLabel>
            <CustomTextField
                id="addresstwo"
                placeholder="Address line 2"
                variant="outlined"
                fullWidth
                // size="small"
                value={detail.addline2}
                onChange={(e) => returnPanelData('addline2', e.target.value)}
            />
        </div>
        <div style={{ width: '100%' }} ref={cityRef} onClick={() => { removeError(cityRef) }}>
            <CustomFormLabel>City</CustomFormLabel>
            <CustomTextField
                id="city"
                placeholder="City"
                variant="outlined"
                fullWidth
                // size="small"
                value={detail.city}
                onChange={(e) => returnPanelData('city', e.target.value)}
            />
        </div>
        <div style={{ width: '100%' }} >
            <CustomFormLabel>ZIP</CustomFormLabel>
            <CustomTextField
                type='number'
                id="zip"
                placeholder="ZIP"
                variant="outlined"
                fullWidth
                value={detail.zip}
                onChange={(e) => returnPanelData('zip', e.target.value)}
            />
        </div>
        {/* <div style={{ width: '100%' }} ref={countryRef} onClick={() => { removeError(countryRef) }}>
            <CustomFormLabel>Country</CustomFormLabel>
            <CustomTextField
                id="country"
                placeholder="Country"
                variant="outlined"
                fullWidth
                value={detail.country}
                onChange={(e) => returnPanelData('country', e.target.value)}
            />
        </div> */}
        <div style={{ width: '100%' }} ref={countryRef} onClick={() => { removeError(countryRef) }}>
            <CustomFormLabel>Country</CustomFormLabel>
            <Select
                value={countryValue}
                onChange={(e) => {
                    setCountryValue(e);
                    returnPanelData('country', e.label)
                    onClickState(e.value);
                }}
                options={countryArray}
                placeholder={"Select Country"}
                className={'form-select p-0 border-0'}
            />
        </div>
        {/* <div style={{ width: '100%' }} ref={stateRef} onClick={() => { removeError(stateRef) }}>
            <CustomFormLabel>State</CustomFormLabel>
            <CustomTextField
                id="state"
                placeholder="State"
                variant="outlined"
                fullWidth
                // size="small"
                value={detail.state}
                onChange={(e) => returnPanelData('state', e.target.value)}
            />
        </div> */}
        <div style={{ width: '100%' }} ref={stateRef} onClick={() => { removeError(stateRef) }}>
            <CustomFormLabel>State</CustomFormLabel>
            <Select
                value={stateValue}
                onChange={(e) => {
                    setStateValue(e);
                    returnPanelData('state', e.label)
                }}
                options={stateArray}
                placeholder={"Select State"}
                className={'form-select p-0 border-0'}
            />
        </div>

    </div>
}
export default AddressPanel