import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Appoinment from '../components/Appoinment';
import Dashboard from '../components/Dashboard/index';
import Reports from '../components/Reports/index.js';
import CustomReports from '../components/Reports/CustomReports.js';
import NewPatient from '../components/Patient/NewPatient';
import NewVisit from '../components/Patient/NewVisit/NewVisit';
import Patient from '../components/Patient/Patient';
import PatientAppointments from '../components/Patient/PatientDetail/PatientAppointments';
import DocumentUploads from '../components/Patient/PatientDetail/DocumentUploaded';
import GrowthParams from '../components/Patient/PatientDetail/GrowthParams';
import PatientDetail from '../components/Patient/PatientDetail/PatientDetail.js';
import Profile from '../components/Patient/PatientDetail/Profile';
import Siblings from '../components/Patient/PatientDetail/Siblings/Siblings.js';
import VisitDetail from '../components/Patient/PatientDetail/VisitDetail';
import Users from '../components/Users/Index';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import UpcomingAppointments from '../components/Dashboard/UpcomingAppointments';
import HospitalPage from '../components/Hospitals';
import EditPatient from '../components/Patient/PatientDetail/EditPatient';
import UpdateVisit from '../components/Patient/PatientDetail/UpdateVisit';
import PatientVaccination from '../components/Patient/PatientDetail/PatientVaccinations';
// import Notes from '../views/apps/notes/Notes.js';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '/dashboard', element: <Dashboard /> },
      { path: '/reports', element: <Reports /> },
      { path: '/custom/reports', element: <CustomReports /> },
      { path: '/patient', element: <Patient /> },
      { path: '/patient/add', element: <NewPatient /> },
      { path: '/patient/detail', element: <PatientDetail /> },
      { path: '/patient/newvisit', element: <NewVisit /> },
      { path: '/patient/updatevisit', element: <UpdateVisit /> },
      { path: '/patient/visitDetail', element: <VisitDetail /> },
      { path: '/patient/growthparams', element: <GrowthParams /> },
      { path: '/patient/appoinments', element: <PatientAppointments /> },
      { path: '/patient/vaccinations', element: <PatientVaccination /> },
      { path: '/patient/documentuploaded', element: <DocumentUploads /> },
      { path: '/patient/profile', element: <Profile /> },
      { path: '/patient/siblings', element: <Siblings /> },
      { path: '/patient/edit', element: <EditPatient /> },
      { path: '/appointment', element: <Appoinment /> },
      { path: '/users', element: <Users /> },
      { path: '/dashboard/upcomingappointment', element: <UpcomingAppointments /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: 'admin',
    element: <FullLayout />,
    children: [
      { path: '', element: <Navigate to="dashboard" /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'reports', element: <Reports /> },
      { path: 'custom/reports', element: <CustomReports /> },
      { path: 'patient', element: <Patient /> },
      { path: 'patient/add', element: <NewPatient /> },
      { path: 'patient/detail', element: <PatientDetail /> },
      { path: 'patient/newvisit', element: <NewVisit /> },
      { path: 'patient/updatevisit', element: <UpdateVisit /> },
      { path: 'patient/visitDetail', element: <VisitDetail /> },
      { path: 'patient/growthparams', element: <GrowthParams /> },
      { path: 'patient/appoinments', element: <PatientAppointments /> },
      { path: 'patient/vaccinations', element: <PatientVaccination /> },
      { path: 'patient/documentuploaded', element: <DocumentUploads /> },
      { path: 'patient/profile', element: <Profile /> },
      { path: 'patient/edit', element: <EditPatient /> },
      { path: 'appointment', element: <Appoinment /> },
      { path: 'users', element: <Users /> },
      { path: 'dashboard/upcomingappointment', element: <UpcomingAppointments /> },
      { path: 'hospitals', element: <HospitalPage /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
