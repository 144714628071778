import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Grid,
  Card,
  Button,
  Tab,
  Tabs,
  Toolbar,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import PageContainer from '../../../container/PageContainer';
import Breadcrumb from '../../../../layouts/full-layout/breadcrumb/Breadcrumb';
import PatientList from './SiblingList';
import {
  fetchEditedPatients,
  fetchSiblings,
} from '../../../../redux/siblings/AsyncActions';
import CustomTextField from '../../../forms/custom-elements/CustomTextField';
import SearchSiblings from './SearchSiblings';

const Siblings = () => {
  const [openSearchSiblings, setOpenSearchSiblings] = useState(false);

  const [perPage, setperPage] = useState(10);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { state } = useLocation();
  const userinfo = useSelector((state) => state.AuthenticationReducer.userinfo);
  const { id, firstName, lastName, dob } = state ? state : {};
  const hospitalSelectedState = useSelector(
    (state) => state.HospitalReducer.hospitalSelected
  );
  const rootRoute = useMemo(() => {
    if (userinfo && userinfo.role === 'admin') {
      return '/admin';
    } else {
      return '';
    }
  }, [userinfo]);
  useEffect(() => {
    getPatientLists(search);
  }, [page, perPage, value, hospitalSelectedState]);

  const toggleSearchSiblings = () => setOpenSearchSiblings((prevS) => !prevS);

  const getPatientLists = (searchTxt) => {
    dispatch(
      fetchSiblings({
        patientId: id,
        page,
        perPage,
        search: searchTxt,
      })
    );
  };
  const handleChange = (event, newValue) => {
    setPage(1);
    setperPage(10);
    setValue(newValue);
  };
  const getPageInfoFun = (page, perPage) => {
    setPage(page + 1);
    setperPage(perPage);
  };
  const handleSearch = (searchTxt) => getPatientLists(searchTxt);
  const BCrumb = [
    {
      to: rootRoute + '/patient',
      title: 'Patients',
    },
    {
      onclick: () => {
        navigate(rootRoute + '/patient/detail', {
          state: { id, firstName, lastName, dob },
        });
      },
      title: 'Detail',
    },
    {
      title: 'Siblings',
    },
  ];
  let breadtitle =
    (firstName &&
      firstName[0] &&
      firstName[0].toUpperCase() + firstName.slice(1)) +
    ' ' +
    (lastName && lastName[0] && lastName[0].toUpperCase() + lastName.slice(1));
  return (
    <>
      <PageContainer
        title='Patient Detail'
        description='this is view Patient page'
      >
        <Breadcrumb
          title={breadtitle}
          items={BCrumb}
          children={
            <div
              style={{ textAlign: 'right', margin: '15px 0px', width: '100%' }}
            >
              <Button
                variant='contained'
                color='primary'
                className='addpatientBtn'
                onClick={toggleSearchSiblings}
              >
                <FeatherIcon icon='plus' style={{ width: 15, height: 15 }} />
                ADD NEW SIBLING
              </Button>
            </div>
          }
        />
        <div
          className='patientDetailPage patientlist'
          style={{ gap: 0, gridGap: 0 }}
        >
          {/* Search */}

          {/* <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              // ...(numSelected > 0 && {
              // bgcolor: (theme) => 'white'
              bgcolor: (theme) => '#1990FC',
              // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
              // }),
              borderRadius: '4px 4px 0 0',
              boxShadow: 'none',
              width: '100%',
            }}
            className='patientlistHeader'
          >
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant='h6'
              id='patientTableTitle'
              component='div'
            >
              <FeatherIcon icon={'search'} className='searchIcon' />
              <Typography sx={{ mr: { lg: 4, xs: 1 } }}> Search </Typography>
              <CustomTextField
                id='searchpatient'
                placeholder='Enter text'
                variant='outlined'
                //  fullWidth
                size='small'
                value={search}
                onChange={(e) => {
                  setPage(1);
                  setperPage(10);
                  setSearch(e.target.value);
                  handleSearch(e.target.value);
                }}
              />
              <FeatherIcon
                icon={'x'}
                style={{ marginLeft: '-30px', zIndex: 2 }}
                onClick={() => {
                  setSearch('');
                  getPatientLists('');
                }}
              />{' '}
              &nbsp;
            </Typography>
          </Toolbar> */}
          {/* List */}
          <div
            className=''
            style={{ width: '100%', backgroundColor: 'white', padding: '20px' }}
          >
            <PatientList
              type='all'
              search={search}
              getPageInfo={getPageInfoFun}
              getPatientLists={getPatientLists}
            />
          </div>
        </div>
      </PageContainer>
      <SearchSiblings
        patientId={id}
        toggle={toggleSearchSiblings}
        open={openSearchSiblings}
        getPatientLists={getPatientLists}
      />
    </>
  );
};

export default Siblings;
