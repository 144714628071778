import { Typography, Card, CardContent, Box } from '@mui/material';
import React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DashboardIcon from '../../assets/icons/DashboardIcon.png'
import DoctorCircleIcon from '../../assets/icons/DoctorCircleIcon.png'
import ReceptionistIcon from '../../assets/icons/ReceptionistIcon.jpg'
import PatientCircleIcon from '../../assets/icons/PatientCircleIcon.png'
import CalendarCircleIcon from '../../assets/icons/CalendarCircleIcon.png'
import HospitalIcon from '../../assets/icons/HospitalIcon.png'

const DashboardCard = () => {
    const dashboardData = useSelector(state => state.DashboardReducer.dashboardData)

    const cardList = useMemo(() => {
        if (dashboardData) return dashboardData
        else return []
    }, [dashboardData])

    return <>
        <Card
            sx={{
                p: {
                    xs: '10px',
                    sm: '10px',
                    lg: '10px',
                },
                width: '100%'
            }}
        >
            <CardContent>
                <Typography sx={{
                    flex: '1 1 100%',
                    p: {
                        xs: '10px',
                        sm: '10px',
                        lg: '10px',
                    },
                    color: '#464646',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px'
                }} variant="h4" id="" fontWeight="500" component="div">
                    <img src={DashboardIcon} className="tabIcon" alt="logoImg" />
                    Dashboard
                </Typography>

                <div style={{ display: 'flex', gap: '20px', overflow: 'auto' }}>
                    {cardList.map((cardEle, i) => {
                        return <Box key={i} sx={{
                            width: '90%',
                            maxWidth: '230px',
                            height: '208px',
                            background: '#F5FAFF',
                            boxShadow: '0px 1px 3px rgba(40, 53, 147, 0.25)',
                            borderRadius: '4px',
                            margin: 'auto',
                            marginBottom: '2px',
                            marginTop: '2px'
                        }}>
                            <Box sx={{
                                height: '50%', display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                padding: '0 12px',
                                gap: '0px',
                                isolation: 'isolate',
                            }}>
                                {cardEle.title === 'Hospitals'
                                    ? <img src={HospitalIcon} className="tabIcon" alt="logoImg" width={'65px'} style={{ border: "3px solid #CFE2F4", borderRadius: "50%", padding: '2px' }} />
                                    : cardEle.title === 'Doctors'
                                        ? <img src={DoctorCircleIcon} className="tabIcon" alt="logoImg" />
                                        : cardEle.title === 'Receptionist'
                                            ? <img src={ReceptionistIcon} className="tabIcon" alt="logoImg" width={'65px'} style={{ border: "3px solid #CFE2F4", borderRadius: "50%", padding: '2px' }} />
                                            : cardEle.title === 'Patients'
                                                ? <img src={PatientCircleIcon} className="tabIcon" alt="logoImg" />
                                                : cardEle.title === 'Appointments'
                                                    ? <img src={CalendarCircleIcon} className="tabIcon" alt="logoImg" />
                                                    : ''}
                            </Box>
                            <Box sx={{
                                height: '50%', display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'start',
                                alignItems: 'center',
                                padding: '12px',
                                gap: '0px',
                                isolation: 'isolate',
                            }}>

                                <Typography variant="h6" fontWeight="600" sx={{
                                    fontSize: {
                                        xs: '15px',
                                        lg: '25px',
                                    }

                                }}>
                                    {cardEle.count}
                                </Typography>
                                <Typography variant="h6" fontWeight="600" color="textSecondary" sx={{
                                    fontSize: {
                                        xs: '10px',
                                        sm: '12px',
                                        md: '15px',
                                        lg: '15px',
                                    }
                                }}>
                                    {cardEle.title}
                                </Typography>
                            </Box>
                        </Box>
                    })
                    }
                </div>
            </CardContent>
        </Card >
    </>
}
export default DashboardCard