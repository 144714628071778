import { Box, Typography } from '@mui/material';
import React from 'react';

const MedicationPreviewOld = ({ data }) => {
  return (
    <div className='observationtabpanelContent'>
      <Typography className='' color='' variant='h5' fontWeight='500'>
        {' '}
        Medication
      </Typography>
      {Object.keys(data?.antihypertensives).filter(
        (key) => data?.antihypertensives[key]
      ).length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Anti-hypertensives
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.antihypertensives ? (
              Object.entries(data?.antihypertensives)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.antiDiabetics).filter(
        (key) => data?.antiDiabetics[key]
      ).length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Anti-diabetics
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.antiDiabetics ? (
              Object.entries(data?.antiDiabetics)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.thyroid).filter((key) => data?.thyroid[key]).length >
        0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Thyroid
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.thyroid ? (
              Object.entries(data?.thyroid)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.bloodThinners).filter(
        (key) => data?.bloodThinners[key]
      ).length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Blood thinners
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.bloodThinners ? (
              Object.entries(data?.bloodThinners)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.antimicrobials).filter(
        (key) => data?.antimicrobials[key]
      ).length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Antimicrobials
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.antimicrobials ? (
              Object.entries(data?.antimicrobials)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.lipidLowering).filter(
        (key) => data?.lipidLowering[key]
      ).length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Lipid lowering
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.lipidLowering ? (
              Object.entries(data?.lipidLowering)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.migraine).filter((key) => data?.migraine[key]).length >
        0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Migraine
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.migraine ? (
              Object.entries(data?.migraine)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.respiratory).filter((key) => data?.respiratory[key])
        .length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Respiratory
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.respiratory ? (
              Object.entries(data?.respiratory)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.allergicRhinitis).filter(
        (key) => data?.allergicRhinitis[key]
      ).length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Allergic Rhinitis
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.allergicRhinitis ? (
              Object.entries(data?.allergicRhinitis)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.contraceptives).filter(
        (key) => data?.contraceptives[key]
      ).length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Contraceptives
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.contraceptives ? (
              Object.entries(data?.contraceptives)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.acidReflux).filter((key) => data?.acidReflux[key])
        .length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Acid reflux
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.acidReflux ? (
              Object.entries(data?.acidReflux)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.topicalSteroids).filter(
        (key) => data?.topicalSteroids[key]
      ).length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Topical Steroids
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.topicalSteroids ? (
              Object.entries(data?.topicalSteroids)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.analgesia).filter((key) => data?.analgesia[key])
        .length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Analgesia
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.analgesia ? (
              Object.entries(data?.analgesia)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.parkinsons).filter((key) => data?.parkinsons[key])
        .length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Parkinsons
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.parkinsons ? (
              Object.entries(data?.parkinsons)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.prostate).filter((key) => data?.prostate[key]).length >
        0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Prostate
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.prostate ? (
              Object.entries(data?.prostate)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.psychiatric).filter((key) => data?.psychiatric[key])
        .length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Psychiatric
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.psychiatric ? (
              Object.entries(data?.psychiatric)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.epilepsy).filter((key) => data?.epilepsy[key]).length >
        0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Epilepsy
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.epilepsy ? (
              Object.entries(data?.epilepsy)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
      {Object.keys(data?.injectableDrugs).filter(
        (key) => data?.injectableDrugs[key]
      ).length > 0 && (
        <>
          <Box sx={{ width: '100%' }}>
            <Typography color='textSecondary' component='span'>
              Injectable drugs
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {data?.injectableDrugs ? (
              Object.entries(data?.injectableDrugs)
                .filter(([medicine, value]) => value)
                .map(([medicine]) => (
                  <Typography variant='h5' fontWeight='400' component='li'>
                    {medicine}
                  </Typography>
                ))
            ) : (
              <>
                {' '}
                <Typography color='textSecondary' component='span'>
                  {' '}
                </Typography>
                Not available.
              </>
            )}
          </Box>
        </>
      )}
    </div>
  );
};

export default MedicationPreviewOld;
