import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Typography, Card, CardContent, Tabs, Tab, Toolbar, Button } from '@mui/material';
import PageContainer from '../../container/PageContainer';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import { addGuardianDetail, updateGuardianDetail, updatePatientDetail, deletePatientEmergecyContacts, saveNewPatientEmergecyContacts } from '../../../redux/patients/AsyncActions';
import { showNotificationAction } from '../../../redux/pushnotification/Action';
import { useMemo } from 'react';
import { useEffect } from 'react';
import IdentificationPanel from '../TabPanel/IdentificationPanel';
import AddressPanel from '../TabPanel/AddressPanel';
import GuardiansPanel from '../TabPanel/GuardiansPanel';
import MedicalInfoPanel from '../TabPanel/MedicalInfoPanel';
import CoveragePanel from '../TabPanel/CoveragePanel';
import OtherPanel from '../TabPanel/OtherPanel';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};


const EditPatient = () => {
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const { state } = useLocation();
    const { id, firstName, lastName, dob, patientDetail, patientEmergencyContactDetail } = state ? state : {};

    const hospitalSelectedState = useSelector((state) => state.HospitalReducer.hospitalSelected)
    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const rootRoute = useMemo(() => {
        if (userinfo && userinfo.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])
    const hospitalId = useMemo(() => {
        if (userinfo?.role === 'admin') {
            return hospitalSelectedState.hospitalId
        } else if (userinfo?.hospitalId) {
            return userinfo.hospitalId
        } else {
            return ""
        }
    }, [userinfo, hospitalSelectedState])

    const identificationRef = useRef(null)
    const addressRef = useRef(null)
    const guardianRef = useRef(null)
    const medicalInfoRef = useRef(null)
    const coverageRef = useRef(null)
    const otherRef = useRef(null)

    const [value, setValue] = React.useState(0);

    // const [identificationDetail, setIdentificationDetail] = useState({
    //     fName: 'F', mName: 'M', lName: 'L', dob: '', gender: 'Female', status: 'active', phone: "121212120", email: "as6@asd.dmd",
    // })
    // const [addressDetail, setAddressDetail] = useState({ addline1: 's', addline2: 'd', city: 'd', zip: '121212', state: 'd', country: 'sa' })

    const [identificationDetail, setIdentificationDetail] = useState({ fName: '', mName: '', lName: '', dob: '', gender: '', status: '', phone: "", secPhone: "", email: "" })
    const [addressDetail, setAddressDetail] = useState({ addline1: '', addline2: '', city: '', zip: '', state: '', country: '' })
    const [guardianDetail, setGuardianDetail] = useState([])
    const [coverageDetail, setCoverageDetail] = useState({ insuranceCompany: '', policyNumber: '', expirationDate: '' })
    const [medicalInfoDetail, setMedicalInfoDetail] = useState({ medicalProbelm: '', otherMedicalProblem: '', surgicalYear: '', surgicalProcedure: '', reason: '', familyHistory: '', other: '', medicationName: '', dosage: '', frequency: '', })
    const [otherDetail, setOtherDetail] = useState({ otherRefferedBy: '', notes: '' })
    // const [errMsg, setErrMsg] = useState("")
    // const [successMsg, setsuccessMsg] = useState("")
    const [scrollToField, setScrollToField] = useState("")
    const [scrollToGuadianField, setScrollToGuadianField] = useState({})

    const BCrumb = [
        {
            to: rootRoute + '/patient',
            title: 'Patients',
        },
        {
            onclick: () => { navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName, dob } }) },
            title: 'Detail',
        },
        {
            title: 'Update Patient',
        },
    ];

    useEffect(() => {
        if (patientDetail) {
            setIdentificationDetail({ fName: patientDetail.firstName, mName: patientDetail.middleName, lName: patientDetail.lastName, dob: patientDetail.dob, gender: patientDetail.gender, status: patientDetail.status, phone: patientDetail.phone, secPhone: patientDetail.secondaryPhone, email: patientDetail.email })
            setAddressDetail({ addline1: patientDetail.address1, addline2: patientDetail.address2, city: patientDetail.city, zip: patientDetail.zip, state: patientDetail.state, country: patientDetail.country })
            setCoverageDetail({ insuranceCompany: patientDetail.insuranceCompany, policyNumber: patientDetail.policyNumber, expirationDate: patientDetail.expirationDate })
            setMedicalInfoDetail({ medicalProbelm: patientDetail.medicalProblem, otherMedicalProblem: patientDetail.otherMedicalProblem, surgicalYear: patientDetail.surgicalYear, surgicalProcedure: patientDetail.surgicalProcedure, reason: patientDetail.surgicalReason, familyHistory: patientDetail.familyHistory, other: patientDetail.other, medicationName: patientDetail.medicationName, dosage: patientDetail.medicationDosage, frequency: patientDetail.medicationFrequency, })
            setOtherDetail({ otherRefferedBy: patientDetail.otherRefferedBy, notes: patientDetail.notes })
        }
    }, [patientDetail])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const submitFormHandle = async () => {
        setScrollToField("")
        // let emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i
        // let domainName = identificationDetail.email.split('@')
        // let domainNameList = []
        // if (domainName.length > 1)
        //     domainNameList = domainName[1].split('.')
        if (identificationDetail?.fName?.trim().length > 15 || identificationDetail?.fName?.trim().length < 1) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'First Name should have minimum 1 letter and maximum 15 characters.' }))
            setScrollToField('fnameRef')
            // } else if (identificationDetail?.mName?.trim().length > 15) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Middle Name should not have more than 15 characters.' }))
            //     setScrollToField('mnameRef')
            // } else if (identificationDetail?.lName?.trim().length > 15 || identificationDetail?.lName?.trim().length < 1) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Last Name should have minimum 1 letter and maximum 15 characters.' }))
            //     setScrollToField('lnameRef')
            // } else if (identificationDetail?.email?.trim() !== "" && (identificationDetail?.email?.trim().length > 50 || identificationDetail?.email?.trim().length < 1)) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Email should have minimum 1 letter and maximum 50 characters.' }))
            //     setScrollToField('emailRef')
            // } else if (identificationDetail?.email?.trim() !== "" && !emailValidator.test(identificationDetail?.email)) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Invalid email provided.' }))
            //     setScrollToField('invalidemailRef')
            // } else if (identificationDetail?.email?.trim() !== "" && domainNameList.length > 3) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Invalid email provided.' }))
            //     setScrollToField('invalidemailRef')
            // } else if (identificationDetail?.email?.trim() !== "" && domainNameList.filter(f => f === domainNameList[1]).length > 1) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Invalid email provided.' }))
            //     setScrollToField('invalidemailRef')
            // } else if (identificationDetail?.phone?.trim() !== "" && (identificationDetail?.phone?.trim().length > 15 || identificationDetail?.phone?.trim().length < 7)) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Phone number should have minimum 7 letter and maximum 15 characters.' }))
            //     setScrollToField('phoneRef')
            // } else if (identificationDetail?.dob?.trim() === "") {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'DOB is required field.' }))
            //     setScrollToField('dobRef')
            // } else if (identificationDetail?.gender?.trim() === "") {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Gender is required field.' }))
            //     setScrollToField('genderRef')
            // } else if (identificationDetail?.status?.trim() === "") {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Status is required field.' }))
            //     setScrollToField('statusRef')
            // } else if (addressDetail.addline1?.trim().length > 50 || addressDetail.addline1?.trim().length < 1) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Address Line 1 should have minimum 1 letter and maximum 50 characters.' }))
            //     setScrollToField('addline1Ref')
            // } else if (addressDetail.addline2?.trim().length > 50) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Address Line 2 should not have more than 50 characters.' }))
            //     setScrollToField('addline2Ref')
            // } else if (addressDetail.city?.trim().length > 50 || addressDetail.city?.trim().length < 1) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'City should have minimum 1 letter and maximum 50 characters.' }))
            //     setScrollToField('cityRef')
            //     // } else if (addressDetail.state?.trim().length > 30 || addressDetail.state?.trim().length < 1) {
            //     //     dispatch(showNotificationAction(true, { type: 'error', content: 'State should have minimum 1 letter and maximum 30 characters.' }))
            //     //     setScrollToField('stateRef')
            // } else if (addressDetail.country?.trim().length > 30 || addressDetail.country?.trim().length < 1) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Country should have minimum 1 letter and maximum 30 characters.' }))
            //     setScrollToField('countryRef')
            // } else if (medicalInfoDetail?.medicalIssues?.trim().length > 250) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Medical issues should not have more than 250 characters.' }))
            //     setScrollToField('issuesRef')
            // } else if (medicalInfoDetail?.presentMedicalIssues?.trim().length > 250) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Present medical issues should not have more than 250 characters.' }))
            //     setScrollToField('presentissuesRef')
            // } else if (medicalInfoDetail?.currentMedication?.trim().length > 250) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Current Medication should not have more than 250 characters.' }))
            //     setScrollToField('medicationRef')
            // } else if (medicalInfoDetail?.allergies?.trim().length > 250) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Allergies should not have more than 250 characters.' }))
            //     setScrollToField('allergiesRef')
            // } else if (medicalInfoDetail?.medicalRefferedBy?.trim().length > 250) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Referred By should not have more than 250 characters.' }))
            //     setScrollToField('referredbyRef')
            // } else if (coverageDetail?.insuranceCompany?.trim().length > 250 || (coverageDetail?.insuranceCompany?.trim() === "" && (coverageDetail?.policyNumber !== '' && coverageDetail?.policyNumber !== null))) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Insurance Company should not have more than 250 characters.' }))
            //     setScrollToField('inssuaranceRef')
            // } else if (coverageDetail?.policyNumber && coverageDetail?.policyNumber.length > 50) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Policy Number should not have more than 50 digits.' }))
            //     setScrollToField('policyNoRef')
            // } else if (coverageDetail?.insuranceCompany?.trim() !== '' && coverageDetail?.policyNumber !== '' && coverageDetail?.policyNumber !== null && (coverageDetail?.expirationDate === null || coverageDetail?.expirationDate?.trim() === '')) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Expiration Date is required field.' }))
            //     setScrollToField('expirationRef')
            // } else if (otherDetail?.otherRefferedBy?.trim().length > 250) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Referred By should not have more than 250 characters.' }))
            //     setScrollToField('referredByRef')
            // } else if (otherDetail?.notes?.trim().length > 250) {
            //     dispatch(showNotificationAction(true, { type: 'error', content: 'Notes should not have more than 250 characters.' }))
            //     setScrollToField('notesRef')
            // } else if (guardianDetail?.length > 0) {
            //     let fullNameInd = guardianDetail.findIndex(f => f.fullName?.trim().length > 50 || f.fullName?.trim().length < 1)
            //     let relationInd = guardianDetail.findIndex(f => f.relation?.trim().length > 50 || f.relation?.trim().length < 1)
            //     let phoneInd = guardianDetail.findIndex(f => f.phone?.trim().length > 15 || f.phone?.trim().length < 7)
            //     let occupationInd = guardianDetail.findIndex(f => f.occupation?.trim().length > 50 || f.occupation?.trim().length < 1)

            //     if (fullNameInd >= 0) {
            //         dispatch(showNotificationAction(true, { type: 'error', content: 'Full name should have minimum 1 letter and maximum 50 characters.' }))
            //         setScrollToGuadianField({ refName: 'myRefs1', refIndex: fullNameInd })
            //     } else if (relationInd >= 0) {
            //         dispatch(showNotificationAction(true, { type: 'error', content: 'Relation should have minimum 1 letter and maximum 50 characters.' }))
            //         setScrollToGuadianField({ refName: 'myRefs2', refIndex: relationInd })
            //     } else if (phoneInd >= 0) {
            //         dispatch(showNotificationAction(true, { type: 'error', content: 'Phone should have minimum 7 letter and maximum 15 numbers.' }))
            //         setScrollToGuadianField({ refName: 'myRefs3', refIndex: phoneInd })
            //     } else if (occupationInd >= 0) {
            //         dispatch(showNotificationAction(true, { type: 'error', content: 'Occupation should have minimum 1 letter and maximum 50 characters.' }))
            //         setScrollToGuadianField({ refName: 'myRefs4', refIndex: occupationInd })
            //     } else {
            //         if (hospitalId && hospitalId !== "")
            //             callUpdate()
            //     }
        } else {
            if (hospitalId && hospitalId !== "")
                callUpdate()
        }
        function callUpdate() {
            let data = {
                "patientId": id,
                "firstName": identificationDetail.fName,
                "lastName": identificationDetail.lName,
                "middleName": identificationDetail.mName,
                "dob": identificationDetail.dob,
                "gender": identificationDetail.gender,
                "status": identificationDetail.status,
                "secondaryPhone": identificationDetail.secPhone,
                "phone": identificationDetail.phone,
                "email": identificationDetail.email.toLowerCase(),
                "address1": addressDetail.addline1,
                "address2": addressDetail.addline2,
                "city": addressDetail.city,
                "zip": addressDetail.zip,
                "state": addressDetail.state,
                "country": addressDetail.country,
                // "medicalIssues": medicalInfoDetail?.medicalIssues,
                // "presentMedicalIssues": medicalInfoDetail.presentMedicalIssues,
                // "currentMedication": medicalInfoDetail.currentMedication,
                // "allergies": medicalInfoDetail.allergies,
                // "medicalRefferedBy": medicalInfoDetail.medicalRefferedBy,

                "insuranceCompany": coverageDetail.insuranceCompany,
                "otherRefferedBy": otherDetail.otherRefferedBy,
                "notes": otherDetail.notes,
                // "medicalProblem": medicalInfoDetail.medicalProbelm,
                // "otherMedicalProblem": medicalInfoDetail?.otherMedicalProblem,
                // "surgicalYear": medicalInfoDetail?.surgicalYear,
                // "surgicalProcedure": medicalInfoDetail?.surgicalProcedure,
                // "surgicalReason": medicalInfoDetail?.reason,
                // "familyHistory": medicalInfoDetail?.familyHistory,
                // "other": medicalInfoDetail?.other,
                // "medicationName": medicalInfoDetail?.medicationName,
                // "medicationDosage": medicalInfoDetail?.dosage,
                // "medicationFrequency": medicalInfoDetail?.frequency,
                // "guardianArr": guardianDetail,
                "hospitalId": hospitalId,
                "userId": userinfo?.userId
            }
            if (coverageDetail.expirationDate !== "")
                data["expirationDate"] = coverageDetail.expirationDate
            if (coverageDetail.policyNumber !== "")
                data["policyNumber"] = coverageDetail.policyNumber

            let updateGuardianData = guardianDetail.filter(f => f.guardianId)
            let addGuardianData = guardianDetail.filter(f => !f.guardianId)
            updateGuardianData.forEach(element => {
                let data = element
                dispatch(updateGuardianDetail(data))
            });
            addGuardianData.forEach(element => {
                let data = element;
                data["patientId"] = id;

                dispatch(addGuardianDetail(data))
            });
            dispatch(updatePatientDetail(data))
                .then(async (response) => {
                    // setErrMsg("")
                    if (response.code === 200) {
                        let obj = {
                            "patientId": id
                        }
                        if (patientEmergencyContactDetail.length > 0) {
                            dispatch(deletePatientEmergecyContacts(obj)).then(async (deleteResponse) => {
                                if (deleteResponse.code === 200) {
                                    savePatientEmergencyContact();
                                }
                            })
                        } else {
                            savePatientEmergencyContact();
                        }

                    } else if (response.status === 400) {
                        dispatch(showNotificationAction(true, { type: 'error', content: (response.data && typeof response.data.message === 'string' ? response.data.message : 'Validation error.') }))
                        setTimeout(() => {
                            // dispatch(showNotificationAction(false, { type: '', content: '' }))
                        }, 5000);
                    } else if (response.status === 403) {
                        dispatch(showNotificationAction(true, { type: 'error', content: 'Email already exist.' }))
                        setTimeout(() => {
                            // dispatch(showNotificationAction(false, { type: '', content: '' }))
                        }, 5000);
                    } else {
                        // let eMsg = err.response ? err.response.data.data ? err.response.data.data[0].msg : err.response.data.message
                        //     : (!err.message || err.message === '') ? 'Something went wrong.' : err.message;
                        dispatch(showNotificationAction(true, { type: 'error', content: typeof response.data.message === 'string' ? response.data.message : 'Something went wrong.' }))
                        setTimeout(() => {
                            // dispatch(showNotificationAction(false, { type: '', content: '' }))
                        }, 5000);
                    }
                })
                .catch((err) => {
                    let eMsg = err.response ? err.response.data.data ? err.response.data.data[0].msg : err.response.data.message
                        : (!err.message || err.message === '') ? 'Something went wrong.' : err.message;
                    dispatch(showNotificationAction(true, { type: 'error', content: eMsg }))
                    setTimeout(() => {
                        // dispatch(showNotificationAction(false, { type: '', content: '' }))
                    }, 5000);
                    // setErrMsg(err.response ? err.response.data.data ? err.response.data.data[0].msg : err.response.data.message : err.message)
                })
        }

    }

    const savePatientEmergencyContact = async () => {

        await Promise.all(guardianDetail.map((item, index) => {
            let obj = {
                "patientId": id, "hospitalId": hospitalId, "userId": userinfo?.userId, "firstName": item.firstName,
                "lastName": item.lastName, "relation": item.relation, "phoneNumber": item.phone,
                "secondaryPhoneNumber": item.secPhone, "secondaryPhoneNumberTwo": item.secPhoneTwo,
            }
            dispatch(saveNewPatientEmergecyContacts(obj));

        })).then(() => {

            dispatch(showNotificationAction(true, { type: 'success', content: 'Patient updated.' }))
            setTimeout(() => {
                navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName, dob } })
                // dispatch(showNotificationAction(false, { type: '', content: '' }))
            }, 2000);
        });
    }

    const scrollDown = (ref) => {
        let scrollToPx = ref.current.offsetTop - 100
        window.scrollTo({
            top: scrollToPx,
            behavior: 'smooth',
        });
    };

    const IdentificationPanelFun = (key, value) => {
        identificationDetail[key] = value;
        setIdentificationDetail({
            ...identificationDetail
        })
    }

    const AddressPanelFun = (key, value) => {
        addressDetail[key] = value;
        setAddressDetail({
            ...addressDetail
        })
    }
    const CoveragePanelFun = (key, value) => {
        coverageDetail[key] = value;
        setCoverageDetail({
            ...coverageDetail
        })
    }
    const MedicalInfoPanelFun = (key, value) => {
        medicalInfoDetail[key] = value;
        setMedicalInfoDetail({
            ...medicalInfoDetail
        })
    }
    const OtherPanelFun = (key, value) => {
        otherDetail[key] = value;
        setOtherDetail({
            ...otherDetail
        })
    }
    const GuardianPanelFun = (key, value) => {
        // guardianDetail[key] = value;
        setGuardianDetail(value)
        setGuardianDetail(r => [...r])
    }
    let breadtitle = (firstName && firstName[0] && (firstName[0].toUpperCase() + firstName.slice(1))) + ' ' +
        (lastName && lastName[0] && (lastName[0].toUpperCase() + lastName.slice(1)))

    return (
        <PageContainer title="Update Patient" description="This is update Patient page" className="newPatientPage">
            <Breadcrumb title={breadtitle} items={BCrumb} />
            <Card
                sx={{
                    p: {
                        xs: '0px',
                        sm: '0px',
                        lg: '0px',
                    },
                }}
                className="newPatientCard"

            >
                <CardContent
                    sx={{
                        p: 0,
                    }}
                >
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            // ...(numSelected > 0 && {
                            bgcolor: (theme) => '#1990FC'
                            // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            // }),
                        }}
                    >

                        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="newpatientTitle" component="div">
                            Update patient
                        </Typography>
                    </Toolbar>
                    <Box sx={{
                        width: '100%',
                        p: {
                            xs: '20px',
                            sm: '35px',
                            lg: '35px',
                        },
                        pt: {
                            xs: '10px',
                            sm: '10px',
                            lg: '10px',
                        }
                    }}
                        className="newPatientCardBox"
                    >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="patientTabs">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                textColor="secondary"
                                allowScrollButtonsMobile
                                scrollButtons
                                indicatorColor="secondary"
                            >
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="Identification"
                                    {...a11yProps(0)}
                                    onClick={() => scrollDown(identificationRef)}
                                />
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="Address"
                                    {...a11yProps(1)}
                                    onClick={() => scrollDown(addressRef)}
                                />
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="Guardians"
                                    {...a11yProps(2)}
                                    onClick={() => scrollDown(guardianRef)}
                                />
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="Medical Info"
                                    {...a11yProps(3)}
                                    onClick={() => scrollDown(medicalInfoRef)}
                                />
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="Coverage Details"
                                    {...a11yProps(4)}
                                    onClick={() => scrollDown(coverageRef)}
                                />
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="Other"
                                    {...a11yProps(5)}
                                    onClick={() => scrollDown(otherRef)}
                                />
                            </Tabs>
                        </Box>
                        <div ref={identificationRef}>
                            <IdentificationPanel detail={identificationDetail} returnPanelData={IdentificationPanelFun} scrollToField={scrollToField} />
                        </div>
                        <div ref={addressRef}>
                            <AddressPanel detail={addressDetail} returnPanelData={AddressPanelFun} scrollToField={scrollToField} />
                        </div>
                        <div ref={guardianRef}>
                            <GuardiansPanel detail={guardianDetail} patientId={id} returnPanelData={GuardianPanelFun} scrollToField={scrollToGuadianField} />
                        </div>
                        {/* <div ref={medicalInfoRef}>
                            <MedicalInfoPanel detail={medicalInfoDetail} returnPanelData={MedicalInfoPanelFun} scrollToField={scrollToField} />
                        </div> */}
                        <div ref={coverageRef}>
                            <CoveragePanel detail={coverageDetail} returnPanelData={CoveragePanelFun} scrollToField={scrollToField} />
                        </div>
                        <div ref={otherRef}>
                            <OtherPanel detail={otherDetail} returnPanelData={OtherPanelFun} scrollToField={scrollToField} />
                        </div>
                        <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div">
                            <Button variant="contained" color="primary" className='registerButton' onClick={(e) => {
                                e.preventDefault();
                                submitFormHandle()
                            }}>
                                UPDATE PATIENT
                            </Button>

                            {/* {errMsg !== "" ? <Alert variant="filled" severity="error" style={{ width: 'fit-content', margin: 'auto' }}>
                                {errMsg}
                            </Alert> : ''}
                            {successMsg !== "" ? <Alert variant="filled" severity="success" style={{ width: 'fit-content', margin: 'auto' }}>
                                {successMsg}
                            </Alert> : ''} */}
                        </Typography>

                    </Box>

                </CardContent>
            </Card>
        </PageContainer>
    );

};

export default EditPatient;
