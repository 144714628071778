import {
  SELECTED_PATIENTS,
  SEARCH_PATIENTS,
  UPDATE_PATIENT,
  DELETE_PATIENT,
  ADD_PATIENT,
  FETCH_PATIENTS_SUCCESS,
  MEDICAL_SUMMARY,
  PAST_VISITS,
  SAVE_OBSERVATION,
  SAVE_ALLERGY,
  SAVE_VACCINATION,
  SAVE_EXAMINATION,
  SAVE_GROWTH,
  SAVE_DOCUMENTUPLOAD,
  SAVED_DOCUMENTUPLOAD,
  SAVE_DIAGNOSIS,
  LAST_VISIT_ID,
  PATIENT_LIST,
  BATCH_LIST,
  TAB_NO,
  EXAMINATION_HISTORY,
  MOBILEVISITSIDEBAROPEN,
  EDITED_PATIENT_LIST,
  VACCINE_DOSE_LIST,
  VACCINE_HISTORY_BY_AGE,
  TOTAL_PATIENT
} from '../constants';

const INIT_STATE = {
  patients: [],
  editedpatients: [],
  totalPatient: 0,
  patientContent: 1,
  patientearch: '',
  medicalSummary: {},
  pastVisits: [],
  lastVisitDetail: {},
  observationData: {},
  allergyData: {},
  vaccinationData: [],
  examinationData: {},
  growthData: {},
  documentData: {},
  documentSaved: {},
  diagnosisData: {},
  examinationHistory: {},
  lastVisitId: '',
  patientList: [],
  batchList: [],
  tabNo: 0,
  isMobileVisitSidebarOpen: false,
  vaccineDoseList: [],
  vaccinationHistoryByAge: {}
};

const PatientReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.patients,
      };
    case EDITED_PATIENT_LIST:
      return {
        ...state,
        editedpatients: action.patients,
      };
    case TOTAL_PATIENT:
      return {
        ...state,
        totalPatient: action.totalPatient
      }
    case MEDICAL_SUMMARY:
      return {
        ...state,
        medicalSummary: action.data,
      };

    case PAST_VISITS:
      return {
        ...state,
        pastVisits: action.data,
      };
    case LAST_VISIT_ID:
      return {
        ...state,
        lastVisitId: action.id
      }
    case SELECTED_PATIENTS:
      return {
        ...state,
        patientsContent: action.id,
      };

    case SEARCH_PATIENTS:
      return {
        ...state,
        patientsearch: action.searchTerm,
      };

    case UPDATE_PATIENT:
      return {
        ...state,
        patients: state.patients.map((patient) =>
          patient.id === action.id ? { ...patient, [action.field]: action.value } : patient,
        ),
      };
    case DELETE_PATIENT:
      return {
        ...state,
        patients: state.patients.map((patient) =>
          patient.id === action.id ? { ...patient, deleted: !patient.deleted } : patient,
        ),
      };
    case ADD_PATIENT:
      return {
        ...state,
        patients: [
          action.data,
        ],
      };
    case SAVE_OBSERVATION:
      return {
        ...state,
        observationData:
          action.data,
      };
    case SAVE_ALLERGY:
      return {
        ...state,
        allergyData:
          action.data,
      };
    case SAVE_VACCINATION:
      return {
        ...state,
        vaccinationData:
          action.data,
      };
    case SAVE_EXAMINATION:
      // let data = action.data
      // return {
      //   ...state,
      //   examinationData:
      //     { ...state.examinationData, data },
      // };
      return {
        ...state,
        examinationData:
          action.data,
      };
    case SAVE_GROWTH:
      return {
        ...state,
        growthData:
          action.data,
      };
    case SAVE_DOCUMENTUPLOAD:
      return {
        ...state,
        documentData:
          action.data,
      };
    case SAVED_DOCUMENTUPLOAD:
      return {
        ...state,
        documentSaved:
          action.data,
      };
    case SAVE_DIAGNOSIS:
      return {
        ...state,
        diagnosisData:
          action.data,
      };
    case EXAMINATION_HISTORY:
      return {
        ...state,
        examinationHistory: action.data
      }
    case PATIENT_LIST:
      return {
        ...state,
        patientList: action.data
      }
    case VACCINE_DOSE_LIST:
      return {
        ...state,
        vaccineDoseList: action.data
      }
    case VACCINE_HISTORY_BY_AGE:
      return {
        ...state,
        vaccinationHistoryByAge: action.data
      }
    case BATCH_LIST:
      return {
        ...state,
        batchList: action.data
      }
    case TAB_NO:
      return {
        ...state,
        tabNo: action.data
      }
    case MOBILEVISITSIDEBAROPEN:
      return {
        ...state,
        isMobileVisitSidebarOpen: action.data
      }
    default:
      return state;
  }
};
// export default combineReducers({
//   PatientReducer
// });
export default PatientReducer;
