export const ColorList = [
    "#1990FC",
    '#FCA119',
    '#3174ad',
    '#4CD651',
    '#0000FF',
    '#595993',
    '#F08080',
    '#FA8072',
    '#6495ED',
    '#40E0D0',
    '#0000FF',
    '#008000',
    '#000080',
    '#800080',
    '#FF00FF',
    '#808000',
    '#3CB043',
    '#028A0F',
    '#03C04A',
    '#74B72E',
    '#607D3B',
    '#C49102',
    '#E3B778',
    '#EC7625',
    '#ED2939'
]