import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ObservationIcon from "../../../../assets/icons/ObservationIcon.png";
import {
  changeTabNo,
  openMblVisitSidebar,
  saveObservationData,
} from "../../../../redux/patients/Action";
import { getLastVisitDetail } from "../../../../redux/patients/AsyncActions";
import { showNotificationAction } from "../../../../redux/pushnotification/Action";
import CustomCheckbox from "../../../forms/custom-elements/CustomCheckbox";
import CustomFormLabel from "../../../forms/custom-elements/CustomFormLabel";
import CustomRadio from "../../../forms/custom-elements/CustomRadio";
import CustomTextField from "../../../forms/custom-elements/CustomTextField";
import VisitSidebar from "../VisitSidebar";
import FamilyHistory from "./FamilyHistory";
import MedicalHistory from "./MedicalHistory";
import Medication from "./Medication";
import ScreeningTest from "./ScreeningTest";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Observation = ({ type }) => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const { id, visitId, dob } = state ? state : {};

  const observation = useSelector(
    (state) => state.patientReducer.observationData
  );

  const tabNoValue = useSelector((state) => state.patientReducer.tabNo);
  const tabNo = useMemo(() => {
    if (tabNoValue) return tabNoValue;
    else return 0;
  }, [tabNoValue]);

  const [selectedVisitType, setselectedVisitType] = React.useState("");
  const [visitTypeToSave, setvisitTypeToSave] = React.useState("");

  const [showDetail, setShowDetail] = useState(false);
  const [showClinicalSummaryDetail, setShowClinicalSummaryDetail] =
    useState(false);

  const [informant, setInformant] = useState("");
  const [currentIssues, setcurrentIssues] = useState("");
  const [selectedValueUrine, setselectedValueUrine] = useState("");
  const [selectedValueStool, setselectedValueStool] = useState("");
  const [detailsExc, setdetailsExc] = useState("");
  const [detailsD, setdetailsD] = useState("");
  const [dentalCare, setdentalCare] = useState("");
  const [detailsDiet, setdetailsDiet] = useState("");
  const [school, setschool] = useState("");
  const [behavioralConcerns, setbehavioralConcerns] = useState("");
  const [commentsSc, setcommentsSc] = useState("");
  const [gravida, setgravida] = useState("");
  const [mothersAge, setmothersAge] = useState("");
  const [modeOfDelivery, setmodeOfDelivery] = useState("");
  const [gestationalAge, setgestationalAge] = useState("");
  const [indicationForLscs, setindicationForLscs] = useState("");
  const [birthHistoryDetail, setbirthHistoryDetail] = useState("");
  const [headCircumference, setheadCircumference] = useState("");
  const [birthWeight, setbirthWeight] = useState("");
  const [lengthBirth, setlengthBirth] = useState("");
  const [apgarScore, setapgarScore] = useState("");
  const [pastMedicalHistory, setpastMedicalHistory] = useState("");
  const [familyHistory, setfamilyHistory] = useState("");
  const [medications, setmedications] = useState("");
  const [compMed, setcompMed] = useState("");
  const [compBirth, setcompBirth] = useState("");
  const [bloodDetail, setBloodDetail] = useState("");
  const [bloodInvestigationValues, setbloodInvestigationValues] = useState({
    HIV: "Negative",
    VDRL: "Negative",
    GBS: "Negative",
    OGTT: "Negative",
    HEPATITIS: "Negative",
    SickleCell: "",
    MaternalBloodGroup: "",
    PatientBloodGroup: "",
  });
  // const [bloodInvestigationFields, setbloodInvestigationFields] = useState([])

  //* state clinical summary
  const [medicalRecordsValues, setMedicalRecordsValues] = useState({
    Smoking: "No",
    Alcohol: "No",
    Drugs: "No",
    Exercise: "No",
    Diet: "No",
    Immunisations: "No",
    allergies: "No",
  });

  const [medicationHistoryValues, setMedicationHistoryValues] = useState({});
  const [clinicalSummaryMedicationData, setClinicalSummaryMedicationData] =
    useState({});

  const [isCheckMedicalHistoryOther, setIsCheckMedicalHistoryOther] =
    useState(false);
  const [CheckMedicalHistoryOther, setCheckMedicalHistoryOther] = useState("");

  const [familyHistoryValues, setFamilyHistoryValues] = useState({});

  const screeningTestsOptions = [
    { label: "lipid profile", key: "lipidProfile" },
    { label: "Hba1c", key: "hba1c" },
    { label: "RFT", key: "rFT" },
    { label: "LFT", key: "lFT" },
    { label: "CBC", key: "cBC" },
    { label: "PSA", key: "pSA" },

    { label: "ACR", key: "aCR" },
    { label: "ABI", key: "aBI" },
    { label: "Colonoscopy", key: "colonoScopy" },
    { label: "Endoscopy", key: "endoscopy" },
    { label: "FIT test", key: "fITTest" },
    { label: "Pap smear", key: "papSmear" },

    { label: "Co-Test", key: "coTest" },
    { label: "ECG", key: "eCG" },
    { label: "Calcium score", key: "calciumScore" },
    { label: "HRCT", key: "hRCT" },
    { label: "Spirometry", key: "spirometry" },
    { label: "Breast screening", key: "breastScreening" },

    { label: "Eye screening", key: "eyeScreening" },
    { label: "Dental screen", key: "dentalScreen" },
    { label: "Bone density", key: "boneDensity" },

    { label: "Thyroid Function", key: "thyroidFunction" },
    {
      label: "Erythrocyte Sedimentation Rate (ESR)",
      key: "erythrocyteSedimentationRate",
    },
    { label: "Ferritin Levels", key: "ferritinLevels" },
    { label: "Mammogram", key: "mammogram" },
    { label: "Stool FIT test", key: "stoolFITTest" },
    { label: "Blood group and Rh", key: "bloodGroupAndRh" },
    { label: "Hb Electrophoresis", key: "HbElectrophoresis" },
    { label: "Rubella Immunity", key: "rubellaImmunity" },
    { label: "VDRL", key: "vdrl" },
    { label: "HIV", key: "hiv" },
    { label: "Hepatitis B", key: "hepatitisB" },
    { label: "Hepatitis C", key: "hepatitisC" },
  ];

  const [screeningTestsValues, setScreeningTestsValues] = useState({
    lipidProfile: false,
    lipidProfile_date: null,
    hba1c: false,
    hba1c_date: null,
    rFT: false,
    rFT_date: null,
    lFT: false,
    lFT_date: null,
    cBC: false,
    cBC_date: null,
    pSA: false,
    pSA_date: null,
    aCR: false,
    aCR_date: null,
    aBI: false,
    aBI_date: null,
    colonoScopy: false,
    colonoScopy_date: null,
    endoscopy: false,
    endoscopy_date: null,
    fITTest: false,
    fITTest_date: null,
    papSmear: false,
    papSmear_date: null,
    coTest: false,
    coTest_date: null,
    eCG: false,
    eCG_date: null,
    calciumScore: false,
    calciumScore_date: null,
    hRCT: false,
    hRCT_date: null,
    spirometry: false,
    spirometry_date: null,
    breastScreening: false,
    breastScreening_date: null,
    eyeScreening: false,
    eyeScreening_date: null,
    dentalScreend: false,
    dentalScreend_date: null,
    boneDensity: false,
    boneDensity_date: null,
    thyroidFunction: false,
    thyroidFunction_date: null,
    erythrocyteSedimentationRate: false,
    erythrocyteSedimentationRate_date: null,
    ferritinLevels: false,
    ferritinLevels_date: null,
  });

  const [surgicalHistory, setSurgicalHistory] = useState("");
  const [obstetricHistory, setObstetricHistory] = useState("");

  const [value, setValue] = React.useState(0);
  const dietOptions = [
    { label: "Breastmilk", key: "Breastmilk" },
    { label: "Formula feeds", key: "Formulafeeds" },
    { label: "Weaning onto solids", key: "Weaningontosolids" },
    { label: "Family Pot", key: "FamilyPot" },
    {
      label: "Picky eater/Poor eating habits",
      key: "PickyEaterPoorEatingHabits",
    },
    { label: "Eats well", key: "EatsWell" },
  ];
  const [dietValues, setdietValues] = useState({
    Breastmilk: false,
    Formulafeeds: false,
    Weaningontosolids: false,
    FamilyPot: false,
    PickyEaterPoorEatingHabits: false,
    EatsWell: false,
  });

  const [tabsList, settabsList] = useState([
    {
      label: "Antenatal History",
    },
    {
      label: "Birth History",
    },
    {
      label: "Current Issues",
    },
    {
      label: "Excretion",
    },
    {
      label: "Dental Care",
    },
  ]);

  const [clinicalSummaryTabsList, setClinicalSummaryTabsList] = useState([
    {
      label: "Medical Record",
    },
    {
      label: "Medication",
    },
    {
      label: "Family History",
    },
    {
      label: "Medical History",
    },
    {
      label: "Surgical History",
    },
    {
      label: "Obstetric History",
    },
    {
      label: "Screening Tests",
    },
  ]);

  const bloodInvestigationFields = useMemo(() => {
    return [
      {
        name: "HIV",
        value: bloodInvestigationValues.HIV,
        options: ["Positive", "Negative", "Unknown"],
        onChangeHandler: (data) => {
          setbloodInvestigationValues({
            ...bloodInvestigationValues,
            HIV: data,
          });
        },
      },
      {
        name: "VDRL",
        value: bloodInvestigationValues.VDRL,
        options: ["Positive", "Negative", "Unknown"],
        onChangeHandler: (data) => {
          setbloodInvestigationValues({
            ...bloodInvestigationValues,
            VDRL: data,
          });
        },
      },
      {
        name: "GBS",
        value: bloodInvestigationValues.GBS,
        options: ["Positive", "Negative", "Unknown"],
        onChangeHandler: (data) => {
          setbloodInvestigationValues({
            ...bloodInvestigationValues,
            GBS: data,
          });
        },
      },
      {
        name: "OGTT",
        value: bloodInvestigationValues.OGTT,
        options: ["Positive", "Negative", "Unknown"],
        onChangeHandler: (data) => {
          setbloodInvestigationValues({
            ...bloodInvestigationValues,
            OGTT: data,
          });
        },
      },
      {
        name: "HEPATITIS",
        value: bloodInvestigationValues.HEPATITIS,
        options: ["Positive", "Negative", "Unknown"],
        onChangeHandler: (data) => {
          setbloodInvestigationValues({
            ...bloodInvestigationValues,
            HEPATITIS: data,
          });
        },
      },
      {
        name: "Sickle Cell",
        value: bloodInvestigationValues.SickleCell,
        options: ["Negative", "HbAS", "HbSS"],
        onChangeHandler: (data) => {
          setbloodInvestigationValues({
            ...bloodInvestigationValues,
            SickleCell: data,
          });
        },
      },
      {
        name: "Maternal Blood Group",
        value: bloodInvestigationValues.MaternalBloodGroup,
        options: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
        onChangeHandler: (data) => {
          setbloodInvestigationValues({
            ...bloodInvestigationValues,
            MaternalBloodGroup: data,
          });
        },
      },
      {
        name: "Patient Blood Group",
        value: bloodInvestigationValues.PatientBloodGroup,
        options: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
        onChangeHandler: (data) => {
          setbloodInvestigationValues({
            ...bloodInvestigationValues,
            PatientBloodGroup: data,
          });
        },
      },
    ];
  }, [bloodInvestigationValues]);

  const medicalRecordsFields = useMemo(() => {
    return [
      {
        name: "Smoking",
        value: medicalRecordsValues.Smoking,
        options: ["Yes", "No"],
        onChangeHandler: async (data) => {
          setMedicalRecordsValues({
            ...medicalRecordsValues,
            Smoking: data,
          });
        },
      },
      {
        name: "Alcohol",
        value: medicalRecordsValues.Alcohol,
        options: ["Yes", "No"],
        onChangeHandler: (data) => {
          setMedicalRecordsValues({ ...medicalRecordsValues, Alcohol: data });
        },
      },
      {
        name: "Drugs",
        value: medicalRecordsValues.Drugs,
        options: ["Yes", "No"],
        onChangeHandler: (data) => {
          setMedicalRecordsValues({ ...medicalRecordsValues, Drugs: data });
        },
      },
      {
        name: "Exercise",
        value: medicalRecordsValues.Exercise,
        options: ["Yes", "No"],
        onChangeHandler: (data) => {
          setMedicalRecordsValues({ ...medicalRecordsValues, Exercise: data });
        },
      },
      {
        name: "Diet",
        value: medicalRecordsValues.Diet,
        options: ["Yes", "No"],
        onChangeHandler: (data) => {
          setMedicalRecordsValues({ ...medicalRecordsValues, Diet: data });
        },
      },
      {
        name: "Immunisations",
        value: medicalRecordsValues.Immunisations,
        options: ["Yes", "No"],
        onChangeHandler: (data) => {
          setMedicalRecordsValues({
            ...medicalRecordsValues,
            Immunisations: data,
          });
        },
      },
      {
        name: "Allergies",
        value: medicalRecordsValues.allergies,
        options: ["Yes", "No"],
        onChangeHandler: (data) => {
          setMedicalRecordsValues({ ...medicalRecordsValues, allergies: data });
        },
      },
    ];
  }, [medicalRecordsValues]);

  useEffect(() => {
    if (pathname.includes("updatevisit")) {
      setShowDetail(true); //show card 2 if this is update visit form
      if (visitId) {
        let data = {
          visitId: visitId,
          patientId: id,
        };
        dispatch(getLastVisitDetail(data, "updatevisit")).then((result) => {
          if (result) {
            setShowDetail(true);
          } else {
            setselectedVisitType("");
          }
        });
      }
    }
  }, [dispatch, pathname, visitId, id]);

  useEffect(() => {
    if (observation.selectedVisitType && observation.selectedVisit0Type !== "")
      setShowDetail(true);
  }, [observation]);

  useEffect(() => {
    if (selectedVisitType === "Follow Up Visit") {
      settabsList([
        {
          label: "Current Issues",
          reference: currentIssuesRef,
        },
        {
          label: "Diet",
          reference: dietaryRef,
        },
        {
          label: "Excretion",
          reference: excretionRef,
        },
        {
          label: "Behavioral Concerns",
          reference: behavioralConcernsRef,
        },
        {
          label: "Dental Care",
          reference: dentalRef,
        },
      ]);
    } else if (selectedVisitType === "Clinical Summary") {
      setClinicalSummaryTabsList([
        {
          label: "Medical Record",
          reference: medicalRef,
        },
        {
          label: "Medication",
          reference: medicationRef,
        },
        {
          label: "Family History",
          reference: familyHistoryRef,
        },
        {
          label: "Medical History",
          reference: medicalHistoryRef,
        },
        {
          label: "Surgical History",
          reference: surgicalHistoryRef,
        },
        {
          label: "Obstetric History",
          reference: obstetricHistoryRef,
        },
        {
          label: "Screening Tests",
          reference: screeningTestsRef,
        },
      ]);
    } else {
      settabsList([
        {
          label: "Antenatal History",
          reference: antenatalRef,
        },
        {
          label: "Birth History",
          reference: birthHistoryRef,
        },
        {
          label: "Current Issues",
          reference: currentIssuesRef,
        },
        {
          label: "Excretion",
          reference: excretionRef,
        },
        {
          label: "Dental Care",
          reference: dentalRef,
        },
      ]);
    }
  }, [selectedVisitType]);

  useEffect(() => {
    setvisitTypeToSave(
      observation.selectedVisitType ? observation.selectedVisitType : ""
    );
    setselectedVisitType(
      observation.selectedVisitType ? observation.selectedVisitType : ""
    );
    setInformant(observation.informant ? observation.informant : "");
    setcurrentIssues(
      observation.currentIssues ? observation.currentIssues : ""
    );
    setselectedValueUrine(
      observation.selectedValueUrine ? observation.selectedValueUrine : ""
    );
    setselectedValueStool(
      observation.selectedValueStool ? observation.selectedValueStool : ""
    );
    setdetailsD(observation.detailsD ? observation.detailsD : "");
    setdetailsDiet(observation.detailsDiet ? observation.detailsDiet : "");
    setdetailsExc(observation.detailsExc ? observation.detailsExc : "");
    setdentalCare(observation.dentalCare ? observation.dentalCare : "");
    setschool(observation.school ? observation.school : "");
    setbehavioralConcerns(
      observation.behavioralConcerns ? observation.behavioralConcerns : ""
    );
    setmothersAge(observation.mothersAge ? observation.mothersAge : "");
    setgravida(observation.gravida ? observation.gravida : "");
    setmodeOfDelivery(
      observation.modeOfDelivery ? observation.modeOfDelivery : ""
    );
    setgestationalAge(
      observation.gestationalAge ? observation.gestationalAge : ""
    );
    setindicationForLscs(
      observation.indicationForLscs ? observation.indicationForLscs : ""
    );
    setbirthHistoryDetail(
      observation.birthHistoryDetail ? observation.birthHistoryDetail : ""
    );
    setheadCircumference(
      observation.headCircumference ? observation.headCircumference : ""
    );
    setbirthWeight(observation.birthWeight ? observation.birthWeight : "");
    setlengthBirth(observation.lengthBirth ? observation.lengthBirth : "");
    setapgarScore(observation.apgarScore ? observation.apgarScore : "");
    setpastMedicalHistory(
      observation.pastMedicalHistory ? observation.pastMedicalHistory : ""
    );
    setfamilyHistory(
      observation.familyHistory ? observation.familyHistory : ""
    );
    setmedications(observation.medications ? observation.medications : "");
    setcommentsSc(observation.commentsSc ? observation.commentsSc : "");
    setcompMed(observation.compMed ? observation.compMed : "");
    setcompBirth(observation.compBirth ? observation.compBirth : "");

    setdietValues(
      observation.dietValues
        ? observation.dietValues
        : {
            Breastmilk: false,
            Formulafeeds: false,
            Weaningontosolids: false,
            FamilyPot: false,
            PickyEaterPoorEatingHabits: false,
            EatsWell: false,
          }
    );
    setbloodInvestigationValues(
      observation.bloodInvestigationValues
        ? observation.bloodInvestigationValues
        : {
            HIV: "Negative",
            VDRL: "Negative",
            GBS: "Negative",
            OGTT: "Negative",
            HEPATITIS: "Negative",
            SickleCell: "",
            MaternalBloodGroup: "",
            PatientBloodGroup: "",
          }
    );
    setBloodDetail(observation.bloodDetail ? observation.bloodDetail : "");

    setMedicalRecordsValues(
      observation.medicalRecordsValues
        ? observation.medicalRecordsValues
        : {
            Smoking: "No",
            Alcohol: "No",
            Drugs: "No",
            Exercise: "No",
            Diet: "No",
            Immunisations: "No",
            allergies: "No",
          }
    );

    setSurgicalHistory(
      observation.surgicalHistory ? observation.surgicalHistory : ""
    );
    setObstetricHistory(
      observation.obstetricHistory ? observation.obstetricHistory : ""
    );

    setScreeningTestsValues(
      observation.screeningTestsValues
        ? observation.screeningTestsValues
        : {
            lipidProfile: false,
            lipidProfile_date: null,
            hba1c: false,
            hba1c_date: null,
            rFT: false,
            rFT_date: null,
            lFT: false,
            lFT_date: null,
            cBC: false,
            cBC_date: null,
            pSA: false,
            pSA_date: null,
            aCR: false,
            aCR_date: null,
            aBI: false,
            aBI_date: null,
            colonoScopy: false,
            colonoScopy_date: null,
            endoscopy: false,
            endoscopy_date: null,
            fITTest: false,
            fITTest_date: null,
            papSmear: false,
            papSmear_date: null,
            coTest: false,
            coTest_date: null,
            eCG: false,
            eCG_date: null,
            calciumScore: false,
            calciumScore_date: null,
            hRCT: false,
            hRCT_date: null,
            spirometry: false,
            spirometry_date: null,
            breastScreening: false,
            breastScreening_date: null,
            eyeScreening: false,
            eyeScreening_date: null,
            dentalScreend: false,
            dentalScreend_date: null,
            boneDensity: false,
            boneDensity_date: null,
            thyroidFunction: false,
            thyroidFunction_date: null,
            erythrocyteSedimentationRate: false,
            erythrocyteSedimentationRate_date: null,
            ferritinLevels: false,
            ferritinLevels_date: null,
          }
    );

    if (observation.CheckMedicalHistoryOther) {
      setCheckMedicalHistoryOther(observation.CheckMedicalHistoryOther);
      setIsCheckMedicalHistoryOther(true);
    } else {
      setCheckMedicalHistoryOther("");
      setIsCheckMedicalHistoryOther(false);
    }

    if (observation.clinicalSummaryMedicationData) {
      setClinicalSummaryMedicationData(
        observation.clinicalSummaryMedicationData
      );
    }
    if (observation.medicationHistoryValues) {
      setMedicationHistoryValues(observation.medicationHistoryValues);
    }
    if (observation.familyHistoryValues) {
      setFamilyHistoryValues(observation.familyHistoryValues);
    }
  }, [observation]);

  const saveData = () => {
    dispatch(
      saveObservationData(
        {
          ...observation,
          selectedVisitType,
          informant,
          currentIssues,
          selectedValueUrine,
          selectedValueStool,
          detailsD,
          detailsExc,
          detailsDiet,
          dentalCare,
          school,
          behavioralConcerns,
          mothersAge,
          gravida,
          modeOfDelivery,
          gestationalAge,
          indicationForLscs,
          birthHistoryDetail,
          headCircumference,
          birthWeight,
          lengthBirth,
          apgarScore,
          pastMedicalHistory,
          familyHistory,
          medications,
          commentsSc,
          compMed: compMed,
          compBirth,
          dietValues,
          bloodInvestigationValues,
          bloodDetail,
          medicalRecordsValues,
          familyHistoryValues,
          medicationHistoryValues,
          surgicalHistory,
          obstetricHistory,
          screeningTestsValues,
          clinicalSummaryMedicationData,
          isCheckMedicalHistoryOther,
          CheckMedicalHistoryOther,
        },
        true
      )
    );
  };

  const birthHistoryRef = useRef(null);
  const currentIssuesRef = useRef(null);
  const dietaryRef = useRef(null);
  const dentalRef = useRef(null);
  const antenatalRef = useRef(null);
  const excretionRef = useRef(null);
  const behavioralConcernsRef = useRef(null);

  const medicalRef = useRef(null);
  const medicationRef = useRef(null);
  const familyHistoryRef = useRef(null);
  const medicalHistoryRef = useRef(null);
  const surgicalHistoryRef = useRef(null);
  const obstetricHistoryRef = useRef(null);
  const screeningTestsRef = useRef(null);

  const scrollDown = (ref) => {
    if (ref.current) {
      let scrollToPx = ref.current.offsetTop - 100;
      window.scrollTo({
        top: scrollToPx,
        behavior: "smooth",
      });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeType = (event) => {
    setvisitTypeToSave(event.target.value);
  };

  const addVisitType = () => {
    // return console.log('HIIIi');
    setselectedVisitType(visitTypeToSave);
    // dispatch(showNotificationAction(false, { type: '', content: '' }))

    if (visitTypeToSave && visitTypeToSave !== "") {
      dispatch(
        saveObservationData({
          ...observation,
          selectedVisitType: visitTypeToSave,
        })
      );
      dispatch(
        showNotificationAction(false, {
          type: "",
          content: "",
          origin: "add visit type if",
        })
      );
      // observationDataDetail({
      //     selectedVisitType: visitTypeToSave
      // }, false)
      if (visitTypeToSave === "Follow Up Visit") {
        if (visitId) {
          // dispatch(getLastVisitDetail(visitId, 'followUp'))
          //     .then((result) => {
          //         if (result) {
          //             setShowDetail(true)
          //         } else {
          //             setselectedVisitType('')
          //         }
          //     })
          setShowDetail(true);
          setShowClinicalSummaryDetail(false);
        } else {
          setShowDetail(true);
          setShowClinicalSummaryDetail(false);
        }
      } else if (visitTypeToSave === "Clinical Summary") {
        setShowClinicalSummaryDetail(true);
        setShowDetail(false);
      } else {
        setShowDetail(true);
        setShowClinicalSummaryDetail(false);
      }
    }
  };

  const clearFunction = (no) => {
    if (no === 1) {
      setselectedVisitType("");
    }
    if (no === 2) {
      setInformant("");
      setcurrentIssues("");
      setselectedValueUrine("");
      setselectedValueStool("");
      setdetailsD("");
      setdetailsExc("");
      setdetailsDiet("");
      setdentalCare("");
      setschool("");
      setbehavioralConcerns("");
      setmedications("");
      setdietValues({
        Breastmilk: false,
        Formulafeeds: false,
        Weaningontosolids: false,
        FamilyPot: false,
        PickyEaterPoorEatingHabits: false,
        EatsWell: false,
      });
      setcommentsSc("");
    }
  };

  const getAge = (dobValue) => {
    if (dobValue !== undefined) {
      let year = Math.trunc(
        moment().diff(moment(dobValue, "YYYY-MM-DD"), "months") / 12
      );
      let month = moment().diff(moment(dobValue, "YYYY-MM-DD"), "months") % 12;
      let agrStr = [];
      if (year && year > 1) {
        agrStr.push(" " + year + " Years");
      } else if (year === 1) {
        agrStr.push(" " + year + " Year");
      } else {
      }
      if (month && month > 1) {
        agrStr.push(" " + month + " Months");
      } else if (month === 1) {
        agrStr.push(" " + month + " Month");
      } else {
      }
      if (month === 0 && year === 0) {
        let days = moment().diff(moment(dobValue, "YYYY-MM-DD"), "days");
        if (days > 1) {
          agrStr.push(" " + days + " Days");
        } else {
          agrStr.push(" 1 Day");
        }
      }
      return agrStr.join(",");
    } else {
      return "";
    }
  };

  const customizer = useSelector((state) => state.CustomizerReducer);

  // const [isSidebarOpen, setSidebarOpen] = useState(true);

  const getTabSelected = (no) => {
    saveData();
    dispatch(changeTabNo(no));
  };
  return (
    <>
      <VisitSidebar
        isSidebardir={customizer.activeDir === "rtl" ? "left" : "right"}
        isSidebarOpen={true}
        onSidebarClose={() => dispatch(openMblVisitSidebar(false))}
        getTabSelected={getTabSelected}
        // tabIndex={showTabNo}
      />
      {!showDetail ? (
        <Card
          sx={{
            p: {
              xs: "0px",
              sm: "0px",
              lg: "0px",
            },
            width: "100%",
          }}
          className="newPatientCard newVisitCard"
        >
          <CardContent
            sx={{
              p: 0,
            }}
          >
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 2, sm: 2 },
                // ...(numSelected > 0 && {
                bgcolor: (theme) => "#1990FC",
                // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                // }),
              }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
                variant="h6"
                id="newpatientTitle"
                component="span"
              >
                <img src={ObservationIcon} className="tabIcon" alt="logoImg" />
                Observations
              </Typography>
              <Typography
                sx={{
                  flex: "1 1 100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                }}
                variant="h6"
                id="newpatientTitle"
                component="span"
                onClick={() => clearFunction(1)}
              >
                Clear
                <FeatherIcon
                  icon="x"
                  style={{
                    width: 14,
                    height: 14,
                    marginLeft: "5px",
                    color: "#1990FC",
                    borderRadius: "50%",
                    background: "white",
                  }}
                />
              </Typography>
            </Toolbar>
            <Box
              sx={{
                width: "100%",
                p: {
                  xs: "20px",
                  sm: "35px",
                  lg: "35px",
                },
                pt: {
                  xs: "10px",
                  sm: "10px",
                  lg: "10px",
                },
              }}
              className="newPatientCardBox"
            >
              Visit Type
              <div style={{ width: "100%" }}>
                <FormControl
                  sx={{
                    width: "100%",
                  }}
                >
                  {/* <CustomFormLabel>Feeder</CustomFormLabel> */}
                  <Box>
                    <FormControlLabel
                      checked={visitTypeToSave === "First Visit"}
                      onChange={handleChangeType}
                      value="First Visit"
                      label="First Visit"
                      name="radio-button-demo"
                      control={<CustomRadio />}
                      inputprops={{ "aria-label": "A" }}
                    />
                    <FormControlLabel
                      // disabled={!visitId ? true : false}
                      checked={visitTypeToSave === "Follow Up Visit"}
                      onChange={handleChangeType}
                      value="Follow Up Visit"
                      label="Follow Up Visit"
                      name="radio-button-demo"
                      control={<CustomRadio />}
                      inputprops={{ "aria-label": "B" }}
                    />
                    <FormControlLabel
                      // disabled={!visitId ? true : false}
                      checked={visitTypeToSave === "Clinical Summary"}
                      onChange={handleChangeType}
                      value="Clinical Summary"
                      label="Clinical Summary"
                      name="radio-button-demo"
                      control={<CustomRadio />}
                      inputprops={{ "aria-label": "B" }}
                    />
                  </Box>
                </FormControl>
              </div>
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                component="div"
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="registerButton"
                  onClick={(e) => {
                    addVisitType();
                  }}
                >
                  <FeatherIcon
                    icon="plus"
                    style={{ width: 14, height: 14, marginRight: "5px" }}
                  />
                  ADD VISIT DETAILS
                </Button>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ) : selectedVisitType === "Clinical Summary" ? (
        <Card
          sx={{
            p: {
              xs: "0px",
              sm: "0px",
              lg: "0px",
            },
            width: "100%",
          }}
          className="newPatientCard newVisitCard"
        >
          <CardContent
            sx={{
              p: 0,
            }}
          >
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 2, sm: 2 },
                // ...(numSelected > 0 && {
                bgcolor: (theme) => "#1990FC",
                // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                // }),
              }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
                variant="h6"
                id="newpatientTitle"
                component="span"
              >
                <img src={ObservationIcon} className="tabIcon" alt="logoImg" />
                Observations- {selectedVisitType}
              </Typography>
              <Typography
                sx={{
                  flex: "1 1 100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                }}
                variant="h6"
                id="newpatientTitle"
                component="span"
                onClick={() => clearFunction(2)}
              >
                Clear
                <FeatherIcon
                  icon="x"
                  style={{
                    width: 14,
                    height: 14,
                    marginLeft: "5px",
                    color: "#1990FC",
                    borderRadius: "50%",
                    background: "white",
                  }}
                />
              </Typography>
            </Toolbar>
            <Box
              sx={{
                width: "100%",
                p: {
                  xs: "20px",
                  sm: "35px",
                  lg: "35px",
                },
                pt: {
                  xs: "10px",
                  sm: "10px",
                  lg: "10px",
                },
              }}
              className="newPatientCardBox"
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  textColor="secondary"
                  allowScrollButtonsMobile
                  scrollButtons
                  indicatorColor="secondary"
                >
                  {clinicalSummaryTabsList.map((m, i) => {
                    return (
                      <Tab
                        key={i}
                        sx={{
                          textTransform: "capitalize",
                        }}
                        label={m.label}
                        {...a11yProps(i)}
                        onClick={() => scrollDown(m.reference)}
                      />
                    );
                  })}
                </Tabs>
              </Box>

              <Grid
                container
                spacing={0}
                className="fileGrid"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <Grid item xs={12} lg={7}>
                  <Typography variant="h5" fontWeight="600">
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color="textSecondary"
                      component="span"
                    >
                      Age:
                    </Typography>
                    {pathname.includes("updatevisit")
                      ? observation.age
                        ? observation.age
                        : ""
                      : getAge(dob)}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Typography variant="h5" fontWeight="600" textAlign="right">
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color="textSecondary"
                      component="span"
                    >
                      Date:
                    </Typography>
                    {moment(observation?.visitDate).format("DD-MMM-YYYY")}
                  </Typography>
                </Grid>
              </Grid>
              <div className="tabpanelContent" ref={medicalRef}>
                <Typography className="panelHeading">Medical Record</Typography>
                <div className="checkBoxGroup">
                  {medicalRecordsFields.map((we, i) => {
                    return (
                      <div key={i} style={{ width: "100%" }}>
                        <FormControl sx={{ width: "100%" }}>
                          <CustomFormLabel color="rgb(119, 126, 137)">
                            {we.name}
                          </CustomFormLabel>
                          <Box>
                            {we.options.map((op, i) => {
                              return (
                                <FormControlLabel
                                  key={i}
                                  checked={we.value === op}
                                  onChange={(e) => {
                                    we.onChangeHandler(e.target.value);
                                  }}
                                  value={op}
                                  label={op}
                                  name="radio-button-demo"
                                  control={<CustomRadio />}
                                  inputprops={{ "aria-label": i }}
                                />
                              );
                            })}
                          </Box>
                        </FormControl>
                      </div>
                    );
                  })}
                </div>
              </div>

              <Medication
                clinicalSummaryMedicationData={clinicalSummaryMedicationData}
                setClinicalSummaryMedicationData={
                  setClinicalSummaryMedicationData
                }
              />

              <FamilyHistory
                familyHistoryValues={familyHistoryValues}
                setFamilyHistoryValues={setFamilyHistoryValues}
              />
              <MedicalHistory
                setMedicationHistoryValues={setMedicationHistoryValues}
                medicationHistoryValues={medicationHistoryValues}
              />

              <div ref={surgicalHistoryRef}>
                <div className="tabpanelContent">
                  <Typography className="panelHeading">
                    Surgical History
                  </Typography>
                  <div style={{ width: "100%" }}>
                    {/* <CustomFormLabel>Current Issues</CustomFormLabel> */}

                    <CustomTextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      placeholder="Surgical History"
                      variant="outlined"
                      fullWidth
                      value={surgicalHistory}
                      onChange={(e) => {
                        setSurgicalHistory(e.target.value);
                      }}
                      className="border-g1"
                    />
                  </div>
                </div>
              </div>
              <div ref={obstetricHistoryRef}>
                <div className="tabpanelContent">
                  <Typography className="panelHeading">
                    Obstetric History
                  </Typography>
                  <div style={{ width: "100%" }}>
                    {/* <CustomFormLabel>Medications</CustomFormLabel> */}

                    <CustomTextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      placeholder="Obstetric History"
                      variant="outlined"
                      fullWidth
                      value={obstetricHistory}
                      onChange={(e) => {
                        setObstetricHistory(e.target.value);
                      }}
                      className="border-g1"
                    />
                  </div>
                </div>
              </div>
              <div ref={screeningTestsRef}>
                <ScreeningTest
                  screeningTestsOptions={screeningTestsOptions}
                  setScreeningTestsValues={setScreeningTestsValues}
                  screeningTestsValues={screeningTestsValues}
                />
              </div>
              <div style={{ float: "right", margin: "15px 0px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className="visitTopButton"
                  onClick={() => {
                    saveData();
                    dispatch(changeTabNo(tabNo + 1));

                    // if (showTabNo < 6) {
                    //     setShowTabNo(showTabNo + 1)
                    // } else {
                    //     setShowVisitObservation(true)
                    // }
                    // saveTabsData()
                  }}
                >
                  Continue
                </Button>
              </div>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Card
          sx={{
            p: {
              xs: "0px",
              sm: "0px",
              lg: "0px",
            },
            width: "100%",
          }}
          className="newPatientCard newVisitCard"
        >
          <CardContent
            sx={{
              p: 0,
            }}
          >
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 2, sm: 2 },
                // ...(numSelected > 0 && {
                bgcolor: (theme) => "#1990FC",
                // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                // }),
              }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
                variant="h6"
                id="newpatientTitle"
                component="span"
              >
                <img src={ObservationIcon} className="tabIcon" alt="logoImg" />
                Observations- {selectedVisitType}
              </Typography>
              <Typography
                sx={{
                  flex: "1 1 100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                }}
                variant="h6"
                id="newpatientTitle"
                component="span"
                onClick={() => clearFunction(2)}
              >
                Clear
                <FeatherIcon
                  icon="x"
                  style={{
                    width: 14,
                    height: 14,
                    marginLeft: "5px",
                    color: "#1990FC",
                    borderRadius: "50%",
                    background: "white",
                  }}
                />
              </Typography>
            </Toolbar>
            <Box
              sx={{
                width: "100%",
                p: {
                  xs: "20px",
                  sm: "35px",
                  lg: "35px",
                },
                pt: {
                  xs: "10px",
                  sm: "10px",
                  lg: "10px",
                },
              }}
              className="newPatientCardBox"
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  textColor="secondary"
                  allowScrollButtonsMobile
                  scrollButtons
                  indicatorColor="secondary"
                >
                  {tabsList.map((m, i) => {
                    return (
                      <Tab
                        key={i}
                        sx={{
                          textTransform: "capitalize",
                        }}
                        label={m.label}
                        {...a11yProps(i)}
                        onClick={() => scrollDown(m.reference)}
                      />
                    );
                  })}
                </Tabs>
              </Box>

              <Grid
                container
                spacing={0}
                className="fileGrid"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <Grid item xs={12} lg={7}>
                  <Typography variant="h5" fontWeight="600">
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color="textSecondary"
                      component="span"
                    >
                      Age:
                    </Typography>
                    {pathname.includes("updatevisit")
                      ? observation.age
                        ? observation.age
                        : ""
                      : getAge(dob)}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Typography variant="h5" fontWeight="600" textAlign="right">
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color="textSecondary"
                      component="span"
                    >
                      Date:
                    </Typography>
                    {moment(observation?.visitDate).format("DD-MMM-YYYY")}
                  </Typography>
                </Grid>
              </Grid>
              <div className="tabpanelContent">
                <Typography className="panelHeading">Informant</Typography>
                <div style={{ width: "100%" }}>
                  {/* <CustomFormLabel>Informant</CustomFormLabel> */}
                  <CustomTextField
                    id="search"
                    placeholder="Informant"
                    variant="outlined"
                    fullWidth
                    value={informant}
                    onChange={(e) => {
                      setInformant(e.target.value);
                    }}
                  />
                </div>
              </div>
              {selectedVisitType === "First Visit" && (
                <>
                  <div ref={antenatalRef}>
                    <div className="tabpanelContent">
                      <Typography className="panelHeading">
                        Antenatal History
                      </Typography>
                      <Grid
                        container
                        spacing={0}
                        className="fileGrid"
                        style={{ width: "100%" }}
                      >
                        <Grid
                          item
                          xs={12}
                          lg={6}
                          sx={{
                            padding: { lg: "0 10px", sm: "10px 0px" },
                            paddingLeft: { lg: "0px" },
                          }}
                        >
                          <CustomFormLabel>Mother’s Age</CustomFormLabel>
                          <CustomTextField
                            id="motherage"
                            type="number"
                            placeholder="Mother’s Age"
                            variant="outlined"
                            fullWidth
                            value={mothersAge}
                            onChange={(e) => {
                              if (e.target.value > 0) {
                                setmothersAge(e.target.value);
                              } else if (e.target.value < 0) {
                                setmothersAge(0);
                              } else {
                                setmothersAge("");
                              }
                            }}
                            onWheel={() => document.activeElement.blur()}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={6}
                          sx={{
                            padding: { lg: "0 10px", sm: "10px 0px" },
                            paddingRight: { lg: "0px" },
                          }}
                        >
                          <CustomFormLabel>Parity</CustomFormLabel>

                          <CustomTextField
                            id="gravida"
                            type="text"
                            placeholder="Parity"
                            variant="outlined"
                            fullWidth
                            value={gravida}
                            onChange={(e) => {
                              setgravida(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <div style={{ width: "100%" }}>
                        <CustomFormLabel>
                          Complications & Medications
                        </CustomFormLabel>
                        <CustomTextField
                          id="CompMed"
                          multiline
                          rows={4}
                          placeholder="Complications & Medications"
                          variant="outlined"
                          fullWidth
                          value={compMed}
                          onChange={(e) => {
                            setcompMed(e.target.value);
                          }}
                          className="border-g1"
                        />
                      </div>
                      {/* </div>
                                    <div className="tabpanelContent"> */}

                      <div style={{ width: "100%" }}>
                        {/* <Typography variant='h5'>
                                                    Blood investigations in Pregnancy
                                                </Typography> */}
                        <CustomFormLabel>
                          Blood investigations in Pregnancy{" "}
                        </CustomFormLabel>

                        <div className="checkBoxGroup">
                          {bloodInvestigationFields.map((we, i) => {
                            return (
                              <div key={i} style={{ width: "100%" }}>
                                <FormControl sx={{ width: "100%" }}>
                                  <CustomFormLabel color="rgb(119, 126, 137)">
                                    {we.name}
                                  </CustomFormLabel>
                                  <Box>
                                    {we.options.map((op, i) => {
                                      return (
                                        <FormControlLabel
                                          key={i}
                                          checked={we.value === op}
                                          onChange={(e) => {
                                            we.onChangeHandler(e.target.value);
                                          }}
                                          value={op}
                                          label={op}
                                          name="radio-button-demo"
                                          control={<CustomRadio />}
                                          inputprops={{ "aria-label": i }}
                                        />
                                      );
                                    })}
                                  </Box>
                                </FormControl>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <CustomFormLabel>Details</CustomFormLabel>
                        <CustomTextField
                          placeholder="Details"
                          variant="outlined"
                          fullWidth
                          value={bloodDetail}
                          onChange={(e) => {
                            setBloodDetail(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div ref={birthHistoryRef}>
                    <div className="tabpanelContent">
                      <Typography className="panelHeading">
                        Birth History
                      </Typography>
                      <div style={{ width: "100%" }}>
                        <CustomFormLabel>Gestational Age </CustomFormLabel>
                        <CustomTextField
                          placeholder="Gestational Age at Birth"
                          variant="outlined"
                          fullWidth
                          value={gestationalAge}
                          onChange={(e) => {
                            setgestationalAge(e.target.value);
                          }}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <FormControl sx={{ width: "100%" }}>
                          <CustomFormLabel>Mode of delivery</CustomFormLabel>
                          <Box>
                            <FormControlLabel
                              checked={modeOfDelivery === "NSVD"}
                              onChange={(e) => {
                                setmodeOfDelivery(e.target.value);
                              }}
                              value={"NSVD"}
                              label={"NSVD"}
                              name="radio-button-demo"
                              control={<CustomRadio />}
                              inputprops={{ "aria-label": "A" }}
                            />
                            <FormControlLabel
                              checked={
                                modeOfDelivery === "Induced Vaginal Delivery"
                              }
                              onChange={(e) => {
                                setmodeOfDelivery(e.target.value);
                              }}
                              value="Induced Vaginal Delivery"
                              label="Induced Vaginal Delivery"
                              name="radio-button-demo"
                              control={<CustomRadio />}
                              inputprops={{ "aria-label": "B" }}
                            />
                            <FormControlLabel
                              checked={modeOfDelivery === "Elective LSCS"}
                              onChange={(e) => {
                                setmodeOfDelivery(e.target.value);
                              }}
                              value="Elective LSCS"
                              label="Elective LSCS"
                              name="radio-button-demo"
                              control={<CustomRadio />}
                              inputprops={{ "aria-label": "C" }}
                            />
                            <FormControlLabel
                              checked={modeOfDelivery === "Emergency LSCS"}
                              onChange={(e) => {
                                setmodeOfDelivery(e.target.value);
                              }}
                              value="Emergency LSCS"
                              label="Emergency LSCS"
                              name="radio-button-demo"
                              control={<CustomRadio />}
                              inputprops={{ "aria-label": "D" }}
                            />
                          </Box>
                        </FormControl>
                      </div>
                      <div style={{ width: "100%" }}>
                        <FormControl sx={{ width: "100%" }}>
                          <CustomFormLabel>Indication for LSCS</CustomFormLabel>
                          <Box>
                            <FormControlLabel
                              checked={
                                indicationForLscs === "Failure to Progress"
                              }
                              onChange={(e) => {
                                setindicationForLscs(e.target.value);
                              }}
                              value="Failure to Progress"
                              label="Failure to Progress"
                              name="radio-button-demo"
                              control={<CustomRadio />}
                              inputprops={{ "aria-label": "A" }}
                            />
                            <FormControlLabel
                              checked={indicationForLscs === "Fetal Distress"}
                              onChange={(e) => {
                                setindicationForLscs(e.target.value);
                              }}
                              value="Fetal Distress"
                              label="Fetal Distress"
                              name="radio-button-demo"
                              control={<CustomRadio />}
                              inputprops={{ "aria-label": "B" }}
                            />
                            <FormControlLabel
                              checked={
                                indicationForLscs === "Medical Condition"
                              }
                              onChange={(e) => {
                                setindicationForLscs(e.target.value);
                              }}
                              value="Medical Condition"
                              label="Medical Condition"
                              name="radio-button-demo"
                              control={<CustomRadio />}
                              inputprops={{ "aria-label": "C" }}
                            />
                            <FormControlLabel
                              checked={indicationForLscs === "Maternal Request"}
                              onChange={(e) => {
                                setindicationForLscs(e.target.value);
                              }}
                              value="Maternal Request"
                              label="Maternal Request"
                              name="radio-button-demo"
                              control={<CustomRadio />}
                              inputprops={{ "aria-label": "D" }}
                            />

                            <FormControlLabel
                              checked={
                                indicationForLscs === "Previous C-section"
                              }
                              onChange={(e) => {
                                setindicationForLscs(e.target.value);
                              }}
                              value="Previous C-section"
                              label="Previous C-section"
                              name="radio-button-demo"
                              control={<CustomRadio />}
                              inputprops={{ "aria-label": "D" }}
                            />
                          </Box>
                        </FormControl>
                      </div>
                      <div style={{ width: "100%" }}>
                        <CustomFormLabel>Details</CustomFormLabel>

                        <CustomTextField
                          placeholder="Details"
                          variant="outlined"
                          fullWidth
                          value={birthHistoryDetail}
                          onChange={(e) => {
                            setbirthHistoryDetail(e.target.value);
                          }}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <CustomFormLabel>Birth Weight (KG)</CustomFormLabel>
                        <CustomTextField
                          type="number"
                          placeholder="Birth Weight"
                          variant="outlined"
                          fullWidth
                          value={birthWeight}
                          onChange={(e) => {
                            if (e.target.value >= 0) {
                              setbirthWeight(e.target.value);
                            } else if (e.target.value < 0) {
                              setbirthWeight(0);
                            } else {
                              setbirthWeight("");
                            }
                          }}
                          onWheel={() => document.activeElement.blur()}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <CustomFormLabel>Length (CM) </CustomFormLabel>

                        <CustomTextField
                          type="number"
                          placeholder="Length"
                          variant="outlined"
                          fullWidth
                          value={lengthBirth}
                          onChange={(e) => {
                            if (e.target.value >= 0) {
                              setlengthBirth(e.target.value);
                            } else if (e.target.value < 0) {
                              setlengthBirth(0);
                            } else {
                              setlengthBirth("");
                            }
                          }}
                          onWheel={() => document.activeElement.blur()}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <CustomFormLabel>
                          Head Circumference (CM)
                        </CustomFormLabel>

                        <CustomTextField
                          type="number"
                          placeholder="Head Circumference"
                          variant="outlined"
                          fullWidth
                          value={headCircumference}
                          // onWheel={event => event.currentTarget.blur()}
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => {
                            if (e.target.value >= 0) {
                              setheadCircumference(e.target.value);
                            } else if (e.target.value < 0) {
                              setheadCircumference(0);
                            } else {
                              setheadCircumference("");
                            }
                          }}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <CustomFormLabel>APGAR score</CustomFormLabel>

                        <CustomTextField
                          placeholder="APGAR score"
                          variant="outlined"
                          fullWidth
                          value={apgarScore}
                          onChange={(e) => {
                            setapgarScore(e.target.value);
                          }}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <CustomFormLabel>Complications</CustomFormLabel>

                        <CustomTextField
                          placeholder="Complications"
                          variant="outlined"
                          fullWidth
                          value={compBirth}
                          onChange={(e) => {
                            setcompBirth(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="tabpanelContent">
                      <Typography className="panelHeading">
                        Past Medical History
                      </Typography>
                      <div style={{ width: "100%" }}>
                        {/* <CustomFormLabel>Past Medical History</CustomFormLabel> */}

                        <CustomTextField
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          placeholder="Past Medical History"
                          variant="outlined"
                          fullWidth
                          value={pastMedicalHistory}
                          onChange={(e) => {
                            setpastMedicalHistory(e.target.value);
                          }}
                          className="border-g1"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="tabpanelContent">
                    <Typography className="panelHeading">
                      Family History
                    </Typography>
                    <div style={{ width: "100%" }}>
                      {/* <CustomFormLabel>Family History</CustomFormLabel> */}

                      <CustomTextField
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        placeholder="Family History"
                        variant="outlined"
                        fullWidth
                        value={familyHistory}
                        onChange={(e) => {
                          setfamilyHistory(e.target.value);
                        }}
                        className="border-g1"
                      />
                    </div>
                  </div>
                </>
              )}
              <div>
                <div className="tabpanelContent" ref={currentIssuesRef}>
                  <Typography className="panelHeading">
                    Current Issues
                  </Typography>
                  <div style={{ width: "100%" }}>
                    {/* <CustomFormLabel>Current Issues</CustomFormLabel> */}

                    <CustomTextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      placeholder="Current Issues"
                      variant="outlined"
                      fullWidth
                      value={currentIssues}
                      onChange={(e) => {
                        setcurrentIssues(e.target.value);
                      }}
                      className="border-g1"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="tabpanelContent">
                  <Typography className="panelHeading">Medications</Typography>
                  <div style={{ width: "100%" }}>
                    {/* <CustomFormLabel>Medications</CustomFormLabel> */}

                    <CustomTextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      placeholder="Medications"
                      variant="outlined"
                      fullWidth
                      value={medications}
                      onChange={(e) => {
                        setmedications(e.target.value);
                      }}
                      className="border-g1"
                    />
                  </div>
                </div>
              </div>
              <div ref={dietaryRef}>
                <div className="tabpanelContent">
                  <Typography className="panelHeading">Diet</Typography>

                  <div style={{ width: "100%" }}>
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                    >
                      {/* <CustomFormLabel htmlFor="demo-simple-select">Diet </CustomFormLabel> */}

                      <Grid
                        container
                        spacing={0}
                        sx={{
                          m: 0,
                        }}
                        className="dietGrid"
                      >
                        {dietOptions.map((item, i) => (
                          <Grid item lg={4} md={6} sm={12} key={i}>
                            <FormControlLabel
                              control={
                                <CustomCheckbox
                                  checked={dietValues[item.key]}
                                  onChange={(e) => {
                                    setdietValues({
                                      ...dietValues,
                                      [item.key]: e.target.checked,
                                    });
                                  }}
                                  name="checkedA"
                                  color="primary"
                                />
                              }
                              label={item.label}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormControl>
                  </div>

                  <div style={{ width: "100%" }}>
                    <CustomFormLabel>Dietary Details</CustomFormLabel>

                    <CustomTextField
                      id="search"
                      placeholder="Details"
                      variant="outlined"
                      fullWidth
                      value={detailsDiet}
                      onChange={(e) => {
                        setdetailsDiet(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div ref={excretionRef}>
                <div className="tabpanelContent">
                  <Typography className="panelHeading">Excretion</Typography>
                  <div style={{ width: "100%" }}>
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                    >
                      <CustomFormLabel>Urine</CustomFormLabel>
                      <Box>
                        <FormControlLabel
                          checked={selectedValueUrine === "Satisfactory"}
                          onChange={(e) => {
                            setselectedValueUrine(e.target.value);
                          }}
                          value="Satisfactory"
                          label="Satisfactory"
                          name="radio-button-demo"
                          control={<CustomRadio />}
                          inputprops={{ "aria-label": "A" }}
                        />
                        <FormControlLabel
                          checked={selectedValueUrine === "Unsatisfactory"}
                          onChange={(e) => {
                            setselectedValueUrine(e.target.value);
                          }}
                          value="Unsatisfactory"
                          label="Unsatisfactory"
                          control={<CustomRadio />}
                          name="radio-button-demo"
                          inputprops={{ "aria-label": "B" }}
                        />
                      </Box>
                    </FormControl>
                  </div>

                  <div style={{ width: "100%" }}>
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                    >
                      <CustomFormLabel>Stool</CustomFormLabel>
                      <Box>
                        <FormControlLabel
                          checked={
                            selectedValueStool ===
                            "Satisfactory bowel movements"
                          }
                          onChange={(e) => {
                            setselectedValueStool(e.target.value);
                          }}
                          value="Satisfactory bowel movements"
                          label="Satisfactory bowel movements"
                          name="radio-button-demo"
                          control={<CustomRadio />}
                          inputprops={{ "aria-label": "A" }}
                        />
                        <FormControlLabel
                          checked={selectedValueStool === "Unsatisfactory"}
                          onChange={(e) => {
                            setselectedValueStool(e.target.value);
                          }}
                          value="Unsatisfactory"
                          label="Unsatisfactory"
                          control={<CustomRadio />}
                          name="radio-button-demo"
                          inputprops={{ "aria-label": "B" }}
                        />
                      </Box>
                    </FormControl>
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomFormLabel>Details</CustomFormLabel>

                    <CustomTextField
                      id="search"
                      placeholder="Details"
                      variant="outlined"
                      fullWidth
                      value={detailsExc}
                      onChange={(e) => {
                        setdetailsExc(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div ref={behavioralConcernsRef}>
                <div className="tabpanelContent">
                  <Typography className="panelHeading">
                    Behavioral Concerns
                  </Typography>
                  <div style={{ width: "100%" }}>
                    {/* <CustomFormLabel>Behavioral Concerns</CustomFormLabel> */}
                    <Box>
                      <FormControlLabel
                        checked={behavioralConcerns === "Satisfactory"}
                        onChange={(e) => {
                          setbehavioralConcerns(e.target.value);
                        }}
                        value="Satisfactory"
                        label="Satisfactory"
                        name="radio-button-demo"
                        control={<CustomRadio />}
                        inputprops={{ "aria-label": "A" }}
                      />
                      <FormControlLabel
                        checked={behavioralConcerns === "Unsatisfactory"}
                        onChange={(e) => {
                          setbehavioralConcerns(e.target.value);
                        }}
                        value="Unsatisfactory"
                        label="Unsatisfactory"
                        control={<CustomRadio />}
                        name="radio-button-demo"
                        inputprops={{ "aria-label": "B" }}
                      />
                    </Box>
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomFormLabel>School</CustomFormLabel>

                    <CustomTextField
                      id="search"
                      placeholder="School"
                      variant="outlined"
                      fullWidth
                      value={school}
                      onChange={(e) => {
                        setschool(e.target.value);
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomFormLabel>Comments</CustomFormLabel>

                    <CustomTextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      variant="outlined"
                      placeholder="Comments"
                      fullWidth
                      value={commentsSc}
                      onChange={(e) => {
                        setcommentsSc(e.target.value);
                      }}
                      className="border-g1"
                    />
                  </div>
                </div>
              </div>

              <div ref={dentalRef}>
                <div className="tabpanelContent">
                  <Typography className="panelHeading">Dental Care</Typography>
                  <div style={{ width: "100%" }}>
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                    >
                      {/* <CustomFormLabel>Dental care</CustomFormLabel> */}
                      <Box>
                        <FormControlLabel
                          checked={dentalCare === "Good dental hygiene"}
                          onChange={(e) => {
                            setdentalCare(e.target.value);
                          }}
                          value="Good dental hygiene"
                          label="Good dental hygiene"
                          name="radio-button-demo"
                          control={<CustomRadio />}
                          inputprops={{ "aria-label": "A" }}
                        />
                        <FormControlLabel
                          checked={dentalCare === "Cavities"}
                          onChange={(e) => {
                            setdentalCare(e.target.value);
                          }}
                          value="Cavities"
                          label="Cavities"
                          control={<CustomRadio />}
                          name="radio-button-demo"
                          inputprops={{ "aria-label": "B" }}
                        />
                        {/* <FormControlLabel
                                                    checked={dentalCare === 'Other'}
                                                    onChange={(e) => {setdentalCare(e.target.value)
}}
                                                    value="Other"
                                                    label="Other"
                                                    control={<CustomRadio />}
                                                    name="radio-button-demo"
                                                    inputprops={{ 'aria-label': 'C' }}
                                                /> */}
                      </Box>
                    </FormControl>
                  </div>

                  <div style={{ width: "100%" }}>
                    <CustomFormLabel>Comments</CustomFormLabel>

                    <CustomTextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      variant="outlined"
                      placeholder="Comments"
                      fullWidth
                      value={detailsD}
                      onChange={(e) => {
                        setdetailsD(e.target.value);
                      }}
                      className="border-g1"
                    />
                  </div>
                </div>
              </div>
              <div style={{ float: "right", margin: "15px 0px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className="visitTopButton"
                  onClick={() => {
                    saveData();
                    dispatch(changeTabNo(tabNo + 1));

                    // if (showTabNo < 6) {
                    //     setShowTabNo(showTabNo + 1)
                    // } else {
                    //     setShowVisitObservation(true)
                    // }
                    // saveTabsData()
                  }}
                >
                  Continue
                </Button>
              </div>
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default Observation;
