import React, { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button, IconButton } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import PageContainer from '../../container/PageContainer';
import { uploadDocuments, updateObservationVisit, getBatchList, updatAllergyVisit, updatediagnosisVisit, updateGrowthVisit, updateExaminationVisit, updateVaccinationVisit, addNewVaccination, addExamination, addGrowth, addDiagnosisAndPlan, addAllergy } from '../../../redux/patients/AsyncActions';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import Observation from '../NewVisit/VisitPages/Observation';
import Allergy from '../NewVisit/VisitPages/Allergy';
import Vaccination from '../NewVisit/VisitPages/Vaccination';
import ExaminationFindings from '../NewVisit/VisitPages/ExaminationFindings';
import DiagnosisPlan from '../NewVisit/VisitPages/DiagnosisPlan';
import VisitObservation from '../NewVisit/VisitPages/VisitObservation';
import GrowthParameters from '../NewVisit/VisitPages/GrowthParameters';
import DocumentUpload from '../NewVisit/VisitPages/DocumentUpload';
import { changeTabNo, openMblVisitSidebar, saveAllergyData, saveDiagnosisData, saveDocumentData, saveExaminationData, saveGrowthData, saveObservationData, saveVaccinationData } from '../../../redux/patients/Action';
import { useEffect } from 'react';
import { showNotificationAction } from '../../../redux/pushnotification/Action';

const UpdateVisit = () => {
    const dispatch = useDispatch()

    const { state } = useLocation();
    const navigate = useNavigate();
    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const rootRoute = useMemo(() => {
        if (userinfo && userinfo?.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])

    const tabNoValue = useSelector((state) => state.patientReducer.tabNo);
    const showTabNo = useMemo(() => {
        if (tabNoValue)
            return tabNoValue
        else
            return 0
    }, [tabNoValue])

    const { id, firstName, lastName, dob, visitId } = state ? state : {};
    const allergyStore = useSelector((state) => state.patientReducer.allergyData);
    const vaccinationStore = useSelector((state) => state.patientReducer.vaccinationData);
    const examinationStore = useSelector((state) => state.patientReducer.examinationData);
    const growthStore = useSelector((state) => state.patientReducer.growthData);
    const diagnosisStore = useSelector((state) => state.patientReducer.diagnosisData);
    // const documentStore = useSelector((state) => state.patientReducer.documentData);
    const observationStore = useSelector((state) => state.patientReducer.observationData);

    const [showVisitObservation, setShowVisitObservation] = useState(false)
    const [documentData, setdocumentData] = useState({})

    const BCrumb = [
        {
            to: rootRoute + '/patient',
            title: 'Patients',
        },
        {
            onclick: () => { navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName, dob } }) },
            title: (firstName && firstName[0] && (firstName[0].toUpperCase() + firstName.slice(1))) + ' ' +
                (lastName && lastName[0] && (lastName[0].toUpperCase() + lastName.slice(1)))
        },
        {
            title: 'Update visit',
        },
    ];

    useEffect(() => {
        localStorage.setItem("patientId", id)
        dispatch(getBatchList())
        return () => {
            dispatch(saveObservationData({}))
            dispatch(saveAllergyData({}))
            dispatch(saveVaccinationData([]))
            dispatch(saveExaminationData({}))
            dispatch(saveGrowthData({}))
            dispatch(saveDocumentData({}))
            dispatch(saveDiagnosisData({}))
        }
    }, [dispatch, id])

    const documentDataDetail = (data) => {
        if (data === undefined) {
        } else {
            setdocumentData(data)
            dispatch(saveDocumentData(data))
        }
    }

    const updateVisitHandler = async () => {
        let noDoseForVaccine = vaccinationStore.filter(ele => ele.vaccination !== "" && (!ele.dose || ele.dose === 0 || ele.dose === ""))
        if (noDoseForVaccine.length > 0) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select correct dose number for vaccines.' }))
        } else {
            let observationdata = {
                "patientId": id,
                "hospitalId": userinfo?.hospitalId,
                "userId": userinfo?.userId,
                "userName": userinfo?.name,
                "visitId": visitId,
                "visitDate": observationStore.visitDate,
                "visitType": observationStore.selectedVisitType,
                "recordedBy": userinfo?.name,
                "informant": observationStore.informant,
                "motherAge": observationStore.mothersAge,
                "gravidaPara": observationStore.gravida,
                "complications": observationStore.compMed,
                "hiv": observationStore.bloodInvestigationValues?.HIV,
                "vdrl": observationStore.bloodInvestigationValues?.VDRL,
                "gbs": observationStore.bloodInvestigationValues?.GBS,
                "ogtt": observationStore.bloodInvestigationValues?.OGTT,
                "hepatitis": observationStore.bloodInvestigationValues?.HEPATITIS,
                "sickleCell": observationStore.bloodInvestigationValues?.SickleCell,
                "metarnalBloodGroup": observationStore.bloodInvestigationValues?.MaternalBloodGroup,
                "patientBloodGroup": observationStore.bloodInvestigationValues?.PatientBloodGroup,
                "bloodInvestigationDetails": observationStore.bloodDetail,
                "gestationalAge": observationStore.gestationalAge,
                "modeOfDelivery": observationStore.modeOfDelivery,
                "indicationForLscs": observationStore.indicationForLscs,
                "birthHistoryDetail": observationStore.birthHistoryDetail,
                "birthWeight": observationStore.birthWeight,
                "length": observationStore.lengthBirth,
                "headCircumference": observationStore.headCircumference,
                "apgarScore": observationStore.apgarScore,
                "complicationsBox": observationStore.compBirth,
                "pastMedicalHistory": observationStore.pastMedicalHistory,
                "familyHistory": observationStore.familyHistory,
                "currentIssues": observationStore.currentIssues,
                "medications": observationStore.medications,
                "diet": observationStore.dietValues ? JSON.stringify(observationStore.dietValues) : '',
                "dietaryDetail": observationStore.detailsDiet,
                "urine": observationStore.selectedValueUrine,
                "stool": observationStore.selectedValueStool,
                "urineDetails": observationStore.detailsExc,
                "behavioralConcerns": observationStore.behavioralConcerns,
                "school": observationStore.school,
                "comments": observationStore.commentsSc,
                "dentalCare": observationStore.dentalCare,
                "dentalComments": observationStore.detailsD,
                "medicalRecord": observationStore.medicalRecordsValues ? JSON.stringify(observationStore.medicalRecordsValues) : '',
                "familyHistoryTwo": observationStore.familyHistoryValues ? JSON.stringify(observationStore.familyHistoryValues) : '',
                "medicalHistory": observationStore.medicationHistoryValues ? JSON.stringify(observationStore.medicationHistoryValues) : '',
                "checkMedicalHistoryOther": observationStore.CheckMedicalHistoryOther,
                "surgicalHistory": observationStore.surgicalHistory,
                "obstetricHistory": observationStore.obstetricHistory,
                "screeningTest": observationStore.screeningTestsValues ? JSON.stringify(observationStore.screeningTestsValues) : '',
                "clinicalSummaryMedicationData": observationStore.clinicalSummaryMedicationData ? JSON.stringify(observationStore.clinicalSummaryMedicationData) : '',
            }
            let allergyData = {
                "patientId": id,
                "hospitalId": userinfo?.hospitalId,
                "userId": userinfo?.userId,
                "userName": userinfo?.name,
                "visitId": visitId,
                "allergyId": allergyStore.allergyId,
                "allergen": allergyStore.allergenSelected,
                "allergenOtherExplain": allergyStore.allergenOtherExplain ? allergyStore.allergenOtherExplain : 'none',
                "reaction": allergyStore.reactionSelected,
                "notes": allergyStore.notes
            }
            let examinationData = {
                "patientId": id,
                "hospitalId": userinfo?.hospitalId,
                "userId": userinfo?.userId,
                "userName": userinfo?.name,
                "visitId": visitId,
                "examinationId": examinationStore.examinationId,
                "general": examinationStore.general,
                "skin": examinationStore.skin,
                "head": examinationStore.head,
                "eyes": examinationStore.eyes,
                "ears": examinationStore.ears,
                "nose": examinationStore.nose,
                "throat": examinationStore.throat,
                "mouth": examinationStore.mouthTeeth,
                "neck": examinationStore.neck,
                "chest": examinationStore.chest,
                "heart": examinationStore.heart,
                "peripheralPulses": examinationStore.peripheralPulses,
                "back": examinationStore.back,
                "abdomen": examinationStore.abdomen,
                "genitalia": examinationStore.genitalia,
                "extremeties": examinationStore.extremeties,
                "neurological": examinationStore.neurological,
                "lymphNodes": examinationStore.lymphNodes,
                "abnormalFindings": examinationStore.abnormalFindings ? examinationStore.abnormalFindings : ''
            }
            let growthAndParamData = {
                "patientId": id,
                "hospitalId": userinfo?.hospitalId,
                "userId": userinfo?.userId,
                "userName": userinfo?.name,
                "visitId": visitId,
                "growthId": growthStore.growthId,
                "temprature": growthStore.tempC,
                "headCircumferences": growthStore.headCir,
                "pulseRate": growthStore.pulseRate,
                "respiratoryRate": growthStore.respi,
                "bp": growthStore.bp,
                "weight": growthStore.weight,
                "height": growthStore.height,
                "bmi": growthStore.bmi ? Number(growthStore.bmi).toFixed(3) : Number(0),
                "lmp": growthStore.lmp
            }
            let diagnosisAndPlansData = {
                "patientId": id,
                "hospitalId": userinfo?.hospitalId,
                "userId": userinfo?.userId,
                "userName": userinfo?.name,
                "visitId": visitId,
                "diagnosisAndPlanId": diagnosisStore.diagnosisAndPlanId,
                "diagnosisInformant": diagnosisStore.diagInformant,
                "planInformant": diagnosisStore.planInformant,
                "nextVisit": diagnosisStore.nextVisit,
            }
            let calls = []

            if (documentData.selectedFiles && documentData.selectedFiles.length > 0) {
                let formData = new FormData()
                formData.append("patientId", id)
                formData.append("visitId", visitId)
                Object.keys(documentData.selectedFiles).forEach(m => {
                    formData.append("fileToImport", documentData.selectedFiles[m])
                })
                // calls = [updateObservationVisitHandler(observationdata), updatAllergyVisitHandler(allergyData),
                // updateExaminationVisitHandler(examinationData), updateGrowthVisitHandler(growthAndParamData),
                // updatediagnosisVisitHandler(diagnosisAndPlansData), updateDocumentationVisitHandler(formData)]
                calls.push(updateObservationVisitHandler(observationdata))
                calls.push(updateDocumentationVisitHandler(formData))
                if (allergyStore.allergyId) {
                    calls.push(updatAllergyVisitHandler(allergyData))
                } else if (allergyStore.allergenSelected) {
                    calls.push(addAllergyVisitHandler(allergyData))
                }
                if (examinationData.examinationId) {
                    calls.push(updateExaminationVisitHandler(examinationData))
                } else if (examinationStore.skin) {
                    calls.push(addExaminationVisitHandler(examinationData))
                }
                if (growthAndParamData.growthId) {
                    calls.push(updateGrowthVisitHandler(growthAndParamData))
                } else if (growthStore.tempC) {
                    calls.push(addGrowthVisitHandler(growthAndParamData))
                }
                if (diagnosisAndPlansData.diagnosisAndPlanId) {
                    calls.push(updatediagnosisVisitHandler(diagnosisAndPlansData))
                } else if (diagnosisStore.diagInformant) {
                    calls.push(adddiagnosisVisitHandler(diagnosisAndPlansData))
                }
                let updateVaccineData = vaccinationStore.filter(f => f.vaccinationId)
                let addVaccineData = vaccinationStore.filter(f => !f.vaccinationId)
                for await (const element of updateVaccineData) {
                    // updateVaccineData.forEach(element => {
                    let data = {
                        "patientId": id,
                        "hospitalId": userinfo?.hospitalId,
                        "userId": userinfo?.userId,
                        "userName": userinfo?.name,
                        "visitId": visitId,
                        ...element
                        // "vaccinationId": vaccinationStore.vaccinationId,
                        // "vaccination": vaccinationStore.vaccination,
                        // "batchNumber": vaccinationStore.batchNumber,
                        // "routeOfAdministration": vaccinationStore.routeOfAdministration,
                        // "siteOfInjection": vaccinationStore.siteOfInjection,
                        // "notes": vaccinationStore.notes
                    }
                    calls.push(updateVaccinationVisitHandler(data))
                };
                for await (const element of addVaccineData) {
                    // addVaccineData.forEach(element => {
                    let data = {
                        "patientId": id,
                        "hospitalId": userinfo?.hospitalId,
                        "userId": userinfo?.userId,
                        "userName": userinfo?.name,
                        "visitId": visitId,
                        ...element
                    };
                    calls.push(addVaccinationVisitHandler(data))
                }
            } else {
                calls.push(updateObservationVisitHandler(observationdata))
                if (allergyStore.allergyId) {
                    calls.push(updatAllergyVisitHandler(allergyData))
                } else if (allergyStore.allergenSelected) {
                    calls.push(addAllergyVisitHandler(allergyData))
                }
                if (examinationData.examinationId) {
                    calls.push(updateExaminationVisitHandler(examinationData))
                } else if (examinationStore.skin) {
                    calls.push(addExaminationVisitHandler(examinationData))
                }
                if (growthAndParamData.growthId) {
                    calls.push(updateGrowthVisitHandler(growthAndParamData))
                } else if (growthStore.tempC) {
                    calls.push(addGrowthVisitHandler(growthAndParamData))
                }
                if (diagnosisAndPlansData.diagnosisAndPlanId) {
                    calls.push(updatediagnosisVisitHandler(diagnosisAndPlansData))
                } else if (diagnosisStore.diagInformant) {
                    calls.push(adddiagnosisVisitHandler(diagnosisAndPlansData))
                }
                let updateVaccineData = vaccinationStore.filter(f => f.vaccinationId)
                let addVaccineData = vaccinationStore.filter(f => !f.vaccinationId)
                for await (const element of updateVaccineData) {
                    let data = {
                        "patientId": id,
                        "hospitalId": userinfo?.hospitalId,
                        "userId": userinfo?.userId,
                        "userName": userinfo?.name,
                        "visitId": visitId,
                        ...element
                    }
                    calls.push(updateVaccinationVisitHandler(data))
                };
                for await (const element of addVaccineData) {
                    let data = {
                        "patientId": id,
                        "hospitalId": userinfo?.hospitalId,
                        "userId": userinfo?.userId,
                        "userName": userinfo?.name,
                        "visitId": visitId,
                        ...element
                    };
                    calls.push(addVaccinationVisitHandler(data))
                }
            }
            Promise.all(calls)
                .then((values) => {
                    let falseData = values.filter(f => f.status === false)
                    if (falseData.length > 0) {
                        dispatch(showNotificationAction(true, { type: 'error', content: falseData[0].message, origin: 'updateVisitHandler' }))
                    } else {
                        dispatch(showNotificationAction(true, { type: 'success', content: "Visit Updated.", origin: 'updateVisitHandler' }))

                        setTimeout(() => {
                            navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName, dob } })
                            // dispatch(showNotificationAction(false, { type: '', content: '' }))
                        }, 2000);
                    }
                });
        }
    }

    const addExaminationVisitHandler = async (examinationData) => {
        let result = await dispatch(addExamination(examinationData))
        if (result.status === true) {
            return { status: true, message: "Examination addd" }
        } else {
            return { status: false, message: "Failed to add Examination data. Try again." }
        }
    }
    const addGrowthVisitHandler = async (growthAndParamData) => {
        let result = await dispatch(addGrowth(growthAndParamData))
        if (result.status === true) {
            return { status: true, message: "Growth and parameters addd" }
        } else {
            return { status: false, message: "Failed to add Growth and parameters data. Try again." }
        }
    }
    // const addDocumentationVisitHandler = async (formData) => {
    //     let result = await dispatch(uploadDocuments(formData))
    //     if (result.status === true) {
    //         return { status: true, message: "Documents addd" }
    //     } else {
    //         return { status: false, message: "Failed to add Documents data. Try again." }
    //     }
    // }
    const adddiagnosisVisitHandler = async (diagnosisAndPlansData) => {
        let result = await dispatch(addDiagnosisAndPlan(diagnosisAndPlansData))
        if (result.status === true) {
            return { status: true, message: "Diagnosis and Plan addd" }
        } else {
            return { status: false, message: "Failed to add Diagnosis and Plan data. Try again." }
        }
    }
    const addVaccinationVisitHandler = async (vaccinationdata) => {
        let result = await dispatch(addNewVaccination(vaccinationdata))
        if (result.status === true) {
            return { status: true, message: "Vaccination added" }
        } else {
            return { status: false, message: "Failed to add new vaccination data. Try again." }
        }
    }
    const addAllergyVisitHandler = async (allergyData) => {
        let result = await dispatch(addAllergy(allergyData))
        if (result.status === true) {
            return { status: true, message: "Observation added" }
        } else {
            return { status: false, message: "Failed to add Allergy data. Try again." }
        }
    }
    const updateObservationVisitHandler = async (observationdata) => {
        let result = await dispatch(updateObservationVisit(observationdata))
        if (result.status === true) {
            return { status: true, message: "Observation updated" }
        } else if (result && result.status === 400 && result.data.data) {
            return { status: false, message: typeof result.data.data === 'string' ? result.data.data : result.data.data[0].msg }
        } else {
            return { status: false, message: "Failed to update Observation data. Try again." }
        }
    }
    const updatAllergyVisitHandler = async (allergyData) => {
        let result = await dispatch(updatAllergyVisit(allergyData))
        if (result.status === true) {
            return { status: true, message: "Observation updated" }
        } else {
            return { status: false, message: "Failed to update Allergy data. Try again." }
        }
    }
    const updateVaccinationVisitHandler = async (vaccinationData) => {
        let result = await dispatch(updateVaccinationVisit(vaccinationData))
        if (result.status === true) {
            return { status: true, message: "Vaccination updated" }
        } else {
            return { status: false, message: "Failed to update Vaccination data. Try again." }
        }
    }
    const updateExaminationVisitHandler = async (examinationData) => {
        let result = await dispatch(updateExaminationVisit(examinationData))
        if (result.status === true) {
            return { status: true, message: "Examination updated" }
        } else {
            return { status: false, message: "Failed to update Examination data. Try again." }
        }
    }
    const updateGrowthVisitHandler = async (growthAndParamData) => {
        let result = await dispatch(updateGrowthVisit(growthAndParamData))
        if (result.status === true) {
            return { status: true, message: "Growth and parameters updated" }
        } else {
            return { status: false, message: "Failed to update Growth and parameters data. Try again." }
        }
    }
    const updateDocumentationVisitHandler = async (formData) => {
        let result = await dispatch(uploadDocuments(formData))
        if (result.status === true) {
            return { status: true, message: "Documents updated" }
        } else {
            return { status: false, message: "Failed to update Documents data. Try again." }
        }
    }
    const updatediagnosisVisitHandler = async (diagnosisAndPlansData) => {
        let result = await dispatch(updatediagnosisVisit(diagnosisAndPlansData))
        if (result.status === true) {
            return { status: true, message: "Diagnosis and Plan updated" }
        } else {
            return { status: false, message: "Failed to update Diagnosis and Plan data. Try again." }
        }
    }
    const stopTabChange = (no) => {
        dispatch(changeTabNo(no))
        dispatch(showNotificationAction(true, { type: 'error', content: 'Please choose the visit type.', origin: 'stopTabChange' }))
    }

    let breadtitle = (firstName && firstName[0] && (firstName[0].toUpperCase() + firstName.slice(1))) + ' ' +
        (lastName && lastName[0] && (lastName[0].toUpperCase() + lastName.slice(1)))

    return (
        <PageContainer title="New Visit" description="This is New Visit page" className="newPatientPage">
            <IconButton
                size="large"
                aria-label="menu"
                onClick={() => { dispatch(openMblVisitSidebar(true)) }}
                sx={{
                    display: {
                        lg: 'none',
                        xs: 'flex',
                    },
                }}
            >
                <FeatherIcon icon="menu" width="18" color="#949db2"
                />
            </IconButton>
            <div className="newVisitPage">
                <Breadcrumb
                    title={
                        <Typography
                            fontWeight="500"
                            variant="h3"
                            sx={{
                                lineHeight: '1.235',
                                color: '#1990FC',
                                // fontSize:'20px'
                            }}
                        >
                            {breadtitle}
                            <Typography color="#7CB8EE" fontWeight="400" variant='span'>
                                {"- Update Visit"}
                            </Typography>
                        </Typography>
                    }
                    children={
                        <div style={{ textAlign: 'right', margin: '15px 0px', width: '100%' }}>
                            {showVisitObservation
                                ? <Button variant="contained" color="primary" className='visitTopButton' onClick={() => {
                                    updateVisitHandler()
                                }}>SAVE & EXIT</Button>
                                : <Button variant="contained" color="primary" className='visitTopButton' onClick={() => {
                                    navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName, dob } })
                                }}>EXIT</Button>
                            }
                        </div>
                    }
                    items={BCrumb}
                />
            </div>

            <Box sx={{
                display: 'flex',
                alignItems: 'baseline'
            }}
                className="newVisitBox">
                {showVisitObservation
                    ? <VisitObservation changeShowVisitObservation={(val) => {
                        if (val === false) {
                            setShowVisitObservation(val)
                        }
                    }} />
                    : <>
                        {showTabNo === 0 ? <Observation /> :
                            showTabNo === 1 ? observationStore.selectedVisitType ? <Allergy /> : stopTabChange(0) :
                                showTabNo === 2 ? observationStore.selectedVisitType ? <Vaccination /> : stopTabChange(0) :
                                    showTabNo === 3 ? observationStore.selectedVisitType ? <ExaminationFindings /> : stopTabChange(0) :
                                        showTabNo === 4 ? observationStore.selectedVisitType ? <GrowthParameters /> : stopTabChange(0) :
                                            showTabNo === 5 ? observationStore.selectedVisitType ? <DocumentUpload documentDataDetail={documentDataDetail} /> : stopTabChange(0) :
                                                showTabNo === 6 ? observationStore.selectedVisitType ? <DiagnosisPlan changeShowVisitOb={(val) => setShowVisitObservation(val)} /> : stopTabChange(0)
                                                    : <></>
                        }
                    </>
                }
            </Box>
        </PageContainer >
    );

};

export default UpdateVisit;
