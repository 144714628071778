import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Typography, Card, CardContent, Tabs, Tab, Toolbar, FormControlLabel, FormControl, Grid, Button, DialogActions, DialogContent, Dialog, Autocomplete, TextField } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import CustomTextField from '../../../forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../forms/custom-elements/CustomFormLabel';
import CustomRadio from '../../../forms/custom-elements/CustomRadio';
import { addBatchNo, deleteVaccination, getVaccineDose, getVaccineHistoryByAge } from '../../../../redux/patients/AsyncActions';
import VaccineHistory from './VaccineHistory';
import moment from 'moment';
import VaccineIcon from '../../../../assets/icons/VaccineIcon.png';
import VisitSidebar from '../VisitSidebar';
import { changeTabNo, openMblVisitSidebar, saveVaccinationData } from '../../../../redux/patients/Action';
import { useMemo } from 'react';
import { showConfirmationModalAction } from '../../../../redux/confirmationModal/ActionConfirmationModal';
import { showNotificationAction } from '../../../../redux/pushnotification/Action';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const Vaccination = () => {
    const dispatch = useDispatch()

    const tabNoValue = useSelector((state) => state.patientReducer.tabNo);
    const tabNo = useMemo(() => {
        if (tabNoValue)
            return tabNoValue
        else
            return 0
    }, [tabNoValue])

    const observationStore = useSelector((state) => state.patientReducer.observationData);
    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const vaccination = useSelector((state) => state.patientReducer.vaccinationData);
    const batchListState = useSelector((state) => state.patientReducer.batchList);
    const batchList = useMemo(() => {
        if (batchListState && batchListState.length > 0) {
            return batchListState.map(b => {
                return { label: b.batchNumber, value: b.batchNumber }
            })
        } else {
            return []
        }
    }, [batchListState])
    const { state } = useLocation();
    const { id } = state ? state : {};

    const vaccineDoseList = useSelector((state) => state.patientReducer.vaccineDoseList);
    const VaccinationList = useMemo(() => {
        if (vaccineDoseList && vaccineDoseList.length > 0) {
            return vaccineDoseList
        } else {
            return []
        }
    }, [vaccineDoseList])
    const [doseList, setdoseList] = useState([])

    const [vaccineDetail, setvaccineDetail] = useState({});
    const [newBatchNo, setnewBatchNo] = useState('')

    const [vaccineAdded, setvaccineAdded] = useState([{ vaccination: '', dose: 0, batchNumber: '', routeOfAdministration: '', siteOfInjection: '', notes: '' }])

    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false)

    const newVaccineRef = useRef(null)
    const historyRef = useRef(null)

    const customizer = useSelector((state) => state.CustomizerReducer);

    const getTabSelected = (no) => {
        saveData()
        dispatch(changeTabNo(no))
    }
    useMemo(() => {
        setvaccineAdded(vaccineAdded.map((obj, ind) => ({
            ...obj, vaccinationDate: observationStore?.visitDate
        })))
    }, [observationStore])

    useEffect(() => {
        if (vaccination && vaccination.length > 0)
            setvaccineAdded(vaccination)
    }, [vaccination])

    useEffect(() => {
        getVaccineDoseHandler()
    }, [])

    const getVaccineDoseHandler = async () => {
        const result = await dispatch(getVaccineDose({ patientId: id }))
        if (result && result.status === true) {
        } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
            dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
        } else {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
        }
    }

    const saveData = async () => {
        let noDoseForVaccine = vaccineAdded.filter(ele => ele.vaccination !== "" && (!ele.dose || ele.dose === 0 || ele.dose === ""))
        if (noDoseForVaccine.length > 0) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please select correct dose number for vaccines.' }))
        } else {
            dispatch(showNotificationAction(false, { type: '', content: '', origin: 'save data vaccination else.' }))
        }
        let vacArr = []
        for await (const ele of vaccineAdded) {
            if (ele.vaccination && ele.vaccination !== "") {
                vacArr.push(ele)
            }
        }
        dispatch(saveVaccinationData(vacArr))

    }

    const getHistory = () => {
        dispatch(getVaccineHistoryByAge(id)).then((result) => {
            setvaccineDetail(result.data)
        })
    }
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleClose = () => {
        setnewBatchNo("")
        setOpen(false)
    }
    const scrollDown = (ref) => {
        if (ref.current) {
            let scrollToPx = ref.current.offsetTop - 100
            window.scrollTo({
                top: scrollToPx,
                behavior: 'smooth',
            });

        }
    };

    const getAge = (dobValue) => {
        if (dobValue !== undefined) {
            let year = Math.trunc((moment().diff(moment(dobValue, 'YYYY-MM-DD'), 'months')) / 12)
            let month = moment().diff(moment(dobValue, 'YYYY-MM-DD'), 'months') % 12
            let agrStr = []
            if (year && year > 1) {
                agrStr.push(" " + year + ' Years')
            } else if (year === 1) {
                agrStr.push(" " + year + ' Year')

            } else {
            }
            if (month && month > 1) {
                agrStr.push(" " + month + ' Months')
            } else if (month === 1) {
                agrStr.push(" " + month + ' Month')
            } else {
            }
            if (month === 0 && year === 0) {
                let days = moment().diff(moment(dobValue, 'YYYY-MM-DD'), 'days')
                if (days > 1) {
                    agrStr.push(" " + days + ' Days')
                } else {
                    agrStr.push(' 1 Day')
                }
            }
            return agrStr.join(',')
        } else {
            return ""
        }
    }

    const saveBatchHandler = async () => {
        if (newBatchNo && newBatchNo.length > 0) {
            let data = {
                batchNumber: newBatchNo,
                hospitalId: userinfo?.hospitalId
            }
            let result = await dispatch(addBatchNo(data))
            if (result && result.status === true) {
                handleClose()
                setnewBatchNo("")
                dispatch(showNotificationAction(true, { type: 'success', content: 'Batch Number added.' }))
            } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
                dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
            } else {
                dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
            }
        } else {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Please enter batch number.' }))
        }
    }

    const clearFunction = () => {
        setvaccineAdded([{ vaccination: '', dose: 0, batchNumber: '', routeOfAdministration: '', siteOfInjection: '', notes: '', vaccinationDate: observationStore?.visitDate }])
    }

    const returnVaccineUI = (m, i) => {
        return <>
            <div className="tabpanelContent">
                {i !== 0 ? <div style={{ textAlign: 'right', width: '100%' }}>
                    <Button variant="contained" color="primary" onClick={() => {
                        if (vaccination[i]?.vaccinationId) {
                            dispatch(showConfirmationModalAction(true, {
                                modalContent: "Are you sure you want to remove this vaccine?",
                                button1: true,
                                button2: true,
                                buttonText1: 'Remove',
                                buttonText2: 'Cancel',
                                buttonAction1: () => {
                                    dispatch(deleteVaccination({ vaccinationId: vaccination[i].vaccinationId }))
                                        .then((result) => {
                                            if (result.status === true) {
                                                let ar = [...vaccination]
                                                ar.splice(i, 1)
                                                setvaccineAdded([...ar])
                                                dispatch(showConfirmationModalAction(false, {
                                                    modalContent: "",
                                                    button1: false,
                                                    button2: false,
                                                    buttonText1: '',
                                                    buttonText2: '',
                                                    buttonAction1: () => { },
                                                    buttonAction2: () => { },
                                                    origin: "Deleted Vaccine"
                                                }))
                                            }
                                        })
                                },
                                buttonAction2: () => {
                                    dispatch(showConfirmationModalAction(false, {
                                        modalContent: "",
                                        button1: false,
                                        button2: false,
                                        buttonText1: '',
                                        buttonText2: '',
                                        buttonAction1: () => { },
                                        buttonAction2: () => { },
                                        origin: "Cancel vaccine delete."
                                    }))
                                },
                                origin: "delete vaccine"
                            }))
                        } else {
                            let ar = [...vaccineAdded]
                            ar.splice(i, 1)
                            setvaccineAdded([...ar])
                        }
                    }} >
                        <FeatherIcon icon="delete" style={{ width: 15, height: 15, marginRight: '5px' }} /> Remove
                    </Button>
                </div> : ''}

                <Typography className='panelHeading'>
                    Vaccine {Number(i) + 1}
                </Typography>

                <div style={{ width: '100%' }}>
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel htmlFor="demo-simple-select">Vaccination</CustomFormLabel>
                        <Autocomplete
                            disablePortal
                            value={vaccineAdded[i].vaccination}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                        ...obj, vaccination: newValue.label, dose: ''
                                    })))
                                    setdoseList(newValue.doseArr)
                                }
                                else {
                                    setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                        ...obj, vaccination: "", dose: ''
                                    })))
                                    setdoseList([])
                                }
                            }}
                            id="combo-box-demo"
                            options={VaccinationList}
                            fullWidth
                            renderInput={(params) => (
                                <TextField {...params} size="small" placeholder="" aria-label="Select" />
                            )}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    border: 'none !important'
                                },
                                '& .MuiOutlinedInput-root': {
                                    padding: '16.5px 14px !important'
                                }
                            }}
                            className="border-g1"
                        />
                    </FormControl>
                </div>
                <div style={{ width: '100%' }}>
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel htmlFor="demo-simple-select">Dose</CustomFormLabel>
                        <Autocomplete
                            disablePortal
                            value={vaccineAdded[i].dose}
                            onChange={(event, newValue) => {
                                if (newValue)
                                    setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                        ...obj, dose: newValue
                                    })))
                                else setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                    ...obj, dose: 0
                                })))
                            }}
                            id="combo-box-demo"
                            options={doseList}
                            fullWidth
                            renderInput={(params) => (
                                <TextField {...params} size="small" placeholder="" aria-label="Select" />
                            )}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    border: 'none !important'
                                },
                                '& .MuiOutlinedInput-root': {
                                    padding: '16.5px 14px !important'
                                }
                            }}
                            className="border-g1"
                        />
                    </FormControl>
                </div>
                <div style={{ width: '100%' }}>
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel htmlFor="demo-simple-select" sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>Batch Number &nbsp;
                            <FeatherIcon icon="plus" width="20"
                                style={{ width: 16, height: 16, fontWeight: '700', marginRight: '5px', background: 'rgba(0, 0, 0, 0.4)', color: 'white', borderRadius: '50%', padding: '2px' }}
                                onClick={() => setOpen(true)} />
                        </CustomFormLabel>
                        <Autocomplete
                            disablePortal
                            value={vaccineAdded[i].batchNumber}
                            onChange={(event, newValue) => {
                                if (newValue)
                                    setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                        ...obj, batchNumber: newValue.value
                                    })))
                                else setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                    ...obj, batchNumber: ""
                                })))
                            }}
                            id="combo-box-demo"
                            options={batchList}
                            fullWidth
                            renderInput={(params) => (
                                <TextField {...params} size="small" placeholder="" aria-label="Select" />
                            )}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    border: 'none !important'
                                },
                                '& .MuiOutlinedInput-root': {
                                    padding: '16.5px 14px !important'
                                }
                            }}
                            className="border-g1"
                        />
                    </FormControl>
                </div>
                <div style={{ width: '100%' }}  >
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel>Route of Administration</CustomFormLabel>
                        <Box>
                            <FormControlLabel
                                checked={vaccineAdded[i].routeOfAdministration === 'SC'}
                                onChange={(e) => {
                                    setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                        ...obj, routeOfAdministration: e.target.value
                                    })))
                                }}
                                value="SC"
                                label="SC"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'A' }}
                            />
                            <FormControlLabel
                                checked={vaccineAdded[i].routeOfAdministration === 'IM'}
                                onChange={(e) => {
                                    setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                        ...obj, routeOfAdministration: e.target.value
                                    })))
                                }}
                                value="IM"
                                label="IM"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'B' }}
                            />
                            <FormControlLabel
                                checked={vaccineAdded[i].routeOfAdministration === 'Oral'}
                                onChange={(e) => {
                                    setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                        ...obj, routeOfAdministration: e.target.value
                                    })))
                                }}
                                value="Oral"
                                label="Oral"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'C' }}
                            />
                        </Box>
                    </FormControl>
                </div>
                <div style={{ width: '100%' }}  >
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel>Site of Injection</CustomFormLabel>
                        <Box>
                            <FormControlLabel
                                checked={vaccineAdded[i].siteOfInjection === "Left arm"}
                                onChange={(e) => {
                                    setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                        ...obj, siteOfInjection: e.target.value
                                    })))
                                }}
                                label="Left arm"
                                value="Left arm"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'A' }}
                            />
                            <FormControlLabel
                                checked={vaccineAdded[i].siteOfInjection === 'Right arm'}
                                onChange={(e) => {
                                    setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                        ...obj, siteOfInjection: e.target.value
                                    })))
                                }}
                                value="Right arm"
                                label="Right arm"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'B' }}
                            />
                            <FormControlLabel
                                checked={vaccineAdded[i].siteOfInjection === "Left thigh"}
                                onChange={(e) => {
                                    setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                        ...obj, siteOfInjection: e.target.value
                                    })))
                                }}
                                value="Left thigh"
                                label="Left thigh"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'C' }}
                            />
                            <FormControlLabel
                                checked={vaccineAdded[i].siteOfInjection === 'Right thigh'}
                                onChange={(e) => {
                                    setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                        ...obj, siteOfInjection: e.target.value
                                    })))
                                }}
                                value="Right thigh"
                                label="Right thigh"
                                name="radio-button-demo"
                                control={<CustomRadio />}
                                inputprops={{ 'aria-label': 'D' }}
                            />
                        </Box>
                    </FormControl>
                </div>
                <div style={{ width: '100%' }}>
                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomFormLabel>Notes </CustomFormLabel>
                        <CustomTextField
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            size="small"
                            placeholder="Notes"
                            value={vaccineAdded[i].notes}
                            onChange={(e) => {
                                setvaccineAdded(vaccineAdded.map((obj, ind) => ind !== i ? obj : ({
                                    ...obj, notes: e.target.value
                                })))
                            }}
                            className="border-g1"
                        />
                    </FormControl>
                </div>
            </div>
        </>
    }
    return (
        <>
            <VisitSidebar
                isSidebardir={customizer.activeDir === 'rtl' ? 'left' : 'right'}
                isSidebarOpen={true}
                onSidebarClose={() => dispatch(openMblVisitSidebar(false))}
                getTabSelected={getTabSelected}
            />
            <Card
                sx={{
                    p: {
                        xs: '0px',
                        sm: '0px',
                        lg: '0px',
                    },
                    width: '100%'
                }}
                className="newPatientCard newVisitCard"
            >
                <CardContent
                    sx={{
                        p: 0,
                    }}
                >
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 2, sm: 2 },
                            bgcolor: (theme) => '#1990FC'
                        }}
                    >
                        <Typography sx={{
                            flex: '1 1 100%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '20px'
                        }} variant="h6" id="newpatientTitle" component="span">
                            <img src={VaccineIcon} className="tabIcon" alt="logoImg" />
                            Vaccination
                        </Typography>
                        <Typography sx={{
                            flex: '1 1 100%', alignItems: 'center', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer'
                        }} variant="h6" id="newpatientTitle" component="span" onClick={clearFunction}>
                            Clear
                            <FeatherIcon icon="x" style={{
                                width: 14, height: 14, marginLeft: '5px', color: '#1990FC', borderRadius: '50%', background: 'white'
                            }} />
                        </Typography>
                    </Toolbar>
                    <Box sx={{
                        width: '100%',
                        p: {
                            xs: '20px',
                            sm: '35px',
                            lg: '35px',
                        },
                        pt: {
                            xs: '10px',
                            sm: '10px',
                            lg: '10px',
                        }
                    }}
                        className="newPatientCardBox"
                    >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                textColor="secondary"
                                allowScrollButtonsMobile
                                scrollButtons
                                indicatorColor="secondary"
                            >
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="New Vaccine"
                                    {...a11yProps(0)}
                                    onClick={() => scrollDown(newVaccineRef)}
                                />
                                <Tab
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    label="History"
                                    {...a11yProps(1)}
                                    onClick={() => {
                                        getHistory()
                                        scrollDown(historyRef)
                                    }}
                                />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0} component="div">
                            <div ref={newVaccineRef}>
                                {vaccineAdded.map((m, i) => returnVaccineUI(m, i))}
                                <div style={{ float: 'right', margin: '15px 10px', }}>
                                    <Button variant="contained" color="primary" className='visitTopButton' onClick={() => {
                                        saveData()
                                        dispatch(changeTabNo(tabNo + 1))
                                    }}>Continue</Button>
                                </div>
                                <div style={{ float: 'right', margin: '15px 0px', }}>
                                    <Button variant="contained" color="primary" className='visitTopButton' onClick={() => {
                                        setvaccineAdded(a => [...a, { vaccination: '', dose: 0, batchNumber: '', routeOfAdministration: '', siteOfInjection: '', notes: '', vaccinationDate: observationStore?.visitDate }])
                                    }}>New Vaccine</Button>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1} component="div">
                            <div ref={historyRef}>
                                <div className="tabpanelContent">
                                    <Typography className='panelHeading'>
                                        History
                                    </Typography>
                                    <Grid container spacing={0} className="fileGrid" style={{ width: '100%' }}>
                                        <Grid item xs={12} lg={6}>
                                            <Typography variant="h6" fontWeight="600">
                                                <Typography variant="h6" fontWeight="600" color="textSecondary" component="span">Current age: </Typography>
                                                {getAge(vaccineDetail["dob"])}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <Typography variant="h6" fontWeight="600" textAlign='right'>
                                                <Typography variant="h6" fontWeight="600" color="textSecondary" component="span">D.O.B: </Typography>
                                                {vaccineDetail && vaccineDetail["dob"]}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <VaccineHistory vaccineData={vaccineDetail && vaccineDetail.history} />
                                </div>
                            </div>
                        </TabPanel>
                    </Box>
                </CardContent>
            </Card >
            <Dialog open={open} onClose={() => handleClose()} fullWidth>
                <DialogContent className='appointmentForm' sx={{ padding: '20px 16px' }}>
                    <Typography sx={{ mb: 2, ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} id="" component="div"  >
                        <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            Add new batch number
                        </Typography>
                        <FeatherIcon icon="x" style={{
                            width: 20, height: 20, marginLeft: '5px', color: 'white', borderRadius: '50%', background: '#1990FC', padding: '2px'
                        }}
                            onClick={() => handleClose()}
                        />
                    </Typography>

                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <CustomFormLabel>Batch No. </CustomFormLabel>
                            <CustomTextField
                                variant="outlined"
                                fullWidth
                                value={newBatchNo}
                                onChange={(e) => {
                                    setnewBatchNo(e.target.value)
                                }}
                            />
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions className='appointmentFormActions'>
                    <Box>
                        <Button type="button"
                            variant="contained"
                            onClick={() => handleClose()}
                            sx={{
                                mr: '8px',
                                background: "#888888"

                            }}
                        >Cancel</Button>
                        <Button variant="contained" className='registerButton' color="primary"
                            onClick={saveBatchHandler}>
                            Add
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );

};

export default Vaccination;
