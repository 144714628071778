import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Typography, Toolbar, Grid } from '@mui/material';
import PageContainer from '../../container/PageContainer';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import FeatherIcon from 'feather-icons-react';
import MedicalSummary from './MedicalSummary';
import PastVisits from './PastVisits';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NewVisitIcon from '../../../assets/icons/NewVisitIcon.png';
import CalendarIcon from '../../../assets/icons/CalendarIcon.png';
import VaccineIcon from '../../../assets/icons/VaccineIcon.png';
import GrowthIcon from '../../../assets/icons/GrowthIcon.png';
import siblingsIcon from '../../../assets/icons/siblings.png';
import DocumentUploadIcon from '../../../assets/icons/DocumentUploadIcon.png';
import Notes from './Notes/index.js';
import ProfileIcon from '../../../assets/icons/ProfileIcon.png';

const PatientDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id, firstName, lastName, dob } = state ? state : {};

  const visitId = useSelector((state) => state.patientReducer.lastVisitId);
  const userinfo = useSelector((state) => state.AuthenticationReducer.userinfo);

  const rootRoute = useMemo(() => {
    if (userinfo && userinfo.role === 'admin') {
      return '/admin';
    } else {
      return '';
    }
  }, [userinfo]);

  const BCrumb = [
    {
      to: rootRoute + '/patient',
      title: 'Patients',
    },
    {
      title: 'Detail',
    },
  ];

  let breadtitle =
    (firstName &&
      firstName[0] &&
      firstName[0].toUpperCase() + firstName.slice(1)) +
    ' ' +
    (lastName && lastName[0] && lastName[0].toUpperCase() + lastName.slice(1));

  return (
    <>
      <PageContainer
        title='Patient Detail'
        description='this is view Patient page'
      >
        <Breadcrumb title={breadtitle} items={BCrumb} />

        <div className='patientDetailPage'>
          {userinfo?.role !== 'admin' && (
            <Toolbar
              sx={{
                pl: { sm: 3 },
                pr: { xs: 2, sm: 2 },
                // ...(numSelected > 0 && {
                bgcolor: (theme) => '#1990FC',
                // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                // }),
                borderRadius: '4px',
                boxShadow: '0px 4px 4px rgba(19, 83, 140, 0.15)',
                width: '100%',
              }}
            >
              <Typography
                sx={{ flex: '1 1 100%', cursor: 'pointer' }}
                variant='h6'
                id='newVisitTitle'
                component='div'
                fontSize='20px'
                onClick={() => {
                  navigate(rootRoute + '/patient/newvisit', {
                    state: {
                      id: id,
                      firstName: firstName,
                      lastName: lastName,
                      visitId: visitId,
                      dob: dob,
                    },
                  });
                }}
              >
                <Typography
                  sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}
                  variant='h6'
                  id=''
                  component='div'
                  fontSize='20px'
                >
                  <img src={NewVisitIcon} className='tabIcon' alt='logoImg' />
                  Record a New Visit
                </Typography>
                <FeatherIcon icon='arrow-right' />
              </Typography>
            </Toolbar>
          )}
          <MedicalSummary />
          <PastVisits />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Toolbar
                sx={{
                  pl: { sm: 3 },
                  pr: { xs: 2, sm: 2 },
                  bgcolor: (theme) => 'white',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 4px rgba(19, 83, 140, 0.15)',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{ flex: '1 1 100%', cursor: 'pointer' }}
                  variant='h6'
                  id='newVisitTitle'
                  className='darkTitle'
                  component='div'
                  fontSize='20px'
                  onClick={() => {
                    navigate(rootRoute + '/patient/growthparams', {
                      state: {
                        id: id,
                        firstName: firstName,
                        lastName: lastName,
                      },
                    });
                  }}
                >
                  <Typography
                    sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}
                    variant='h6'
                    id=''
                    component='div'
                    fontSize='20px'
                  >
                    <img src={GrowthIcon} className='tabIcon' alt='logoImg' />
                    Growth Parameters
                  </Typography>
                  <FeatherIcon icon='arrow-right' color='#1990FC' />
                </Typography>
              </Toolbar>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Toolbar
                sx={{
                  pl: { sm: 3 },
                  pr: { xs: 2, sm: 2 },
                  bgcolor: (theme) => 'white',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 4px rgba(19, 83, 140, 0.15)',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{ flex: '1 1 100%', cursor: 'pointer' }}
                  variant='h6'
                  id='newVisitTitle'
                  className='darkTitle'
                  component='div'
                  fontSize='20px'
                  onClick={() => {
                    navigate(rootRoute + '/patient/documentuploaded', {
                      state: {
                        id: id,
                        firstName: firstName,
                        lastName: lastName,
                        visitId: visitId,
                      },
                    });
                  }}
                >
                  <Typography
                    sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}
                    variant='h6'
                    id=''
                    component='div'
                    fontSize='20px'
                  >
                    <img
                      src={DocumentUploadIcon}
                      className='tabIcon'
                      alt='logoImg'
                    />
                    Document Upload
                  </Typography>
                  <FeatherIcon icon='arrow-right' color='#1990FC' />
                </Typography>
              </Toolbar>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Toolbar
                sx={{
                  pl: { sm: 3 },
                  pr: { xs: 2, sm: 2 },
                  bgcolor: (theme) => 'white',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 4px rgba(19, 83, 140, 0.15)',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{ flex: '1 1 100%', cursor: 'pointer' }}
                  variant='h6'
                  id='newVisitTitle'
                  className='darkTitle'
                  component='div'
                  fontSize='20px'
                  onClick={() => {
                    navigate(rootRoute + '/patient/appoinments', {
                      state: {
                        id: id,
                        firstName: firstName,
                        lastName: lastName,
                      },
                    });
                  }}
                >
                  <Typography
                    sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}
                    variant='h6'
                    id=''
                    component='div'
                    fontSize='20px'
                  >
                    <img src={CalendarIcon} className='tabIcon' alt='logoImg' />
                    Appointments
                  </Typography>
                  <FeatherIcon icon='arrow-right' color='#1990FC' />
                </Typography>
              </Toolbar>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Toolbar
                sx={{
                  pl: { sm: 3 },
                  pr: { xs: 2, sm: 2 },
                  bgcolor: (theme) => 'white',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 4px rgba(19, 83, 140, 0.15)',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{ flex: '1 1 100%', cursor: 'pointer' }}
                  variant='h6'
                  id='newVisitTitle'
                  className='darkTitle'
                  component='div'
                  fontSize='20px'
                  onClick={() => {
                    navigate(rootRoute + '/patient/vaccinations', {
                      state: {
                        id: id,
                        firstName: firstName,
                        lastName: lastName,
                      },
                    });
                  }}
                >
                  <Typography
                    sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}
                    variant='h6'
                    id=''
                    component='div'
                    fontSize='20px'
                  >
                    <img src={VaccineIcon} className='tabIcon' alt='logoImg' />
                    Vaccinations
                  </Typography>
                  <FeatherIcon icon='arrow-right' color='#1990FC' />
                </Typography>
              </Toolbar>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Notes />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Toolbar
                sx={{
                  pl: { sm: 3 },
                  pr: { xs: 2, sm: 2 },
                  bgcolor: (theme) => 'white',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 4px rgba(19, 83, 140, 0.15)',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{ flex: '1 1 100%', cursor: 'pointer' }}
                  variant='h6'
                  id='newVisitTitle'
                  className='darkTitle'
                  component='div'
                  fontSize='20px'
                  onClick={() => {
                    navigate(rootRoute + '/patient/profile', {
                      state: {
                        id: id,
                        firstName: firstName,
                        lastName: lastName,
                      },
                    });
                  }}
                >
                  <Typography
                    sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}
                    variant='h6'
                    id=''
                    component='div'
                    fontSize='20px'
                  >
                    <img src={ProfileIcon} className='tabIcon' alt='logoImg' />
                    Profile
                  </Typography>
                  <FeatherIcon icon='arrow-right' color='#1990FC' />
                </Typography>
              </Toolbar>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Toolbar
                sx={{
                  pl: { sm: 3 },
                  pr: { xs: 2, sm: 2 },
                  bgcolor: (theme) => 'white',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 4px rgba(19, 83, 140, 0.15)',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{ flex: '1 1 100%', cursor: 'pointer' }}
                  variant='h6'
                  id='newVisitTitle'
                  className='darkTitle'
                  component='div'
                  fontSize='20px'
                  onClick={() => {
                    navigate(rootRoute + '/patient/siblings', {
                      state: {
                        id: id,
                        firstName: firstName,
                        lastName: lastName,
                      },
                    });
                  }}
                >
                  <Typography
                    sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}
                    variant='h6'
                    id=''
                    component='div'
                    fontSize='20px'
                  >
                    <img
                      src={siblingsIcon}
                      width={50}
                      className='tabIcon'
                      alt='logoImg'
                    />
                    Siblings
                  </Typography>
                  <FeatherIcon icon='arrow-right' color='#1990FC' />
                </Typography>
              </Toolbar>
            </Grid>
          </Grid>
        </div>
      </PageContainer>
    </>
  );
};

export default PatientDetail;
