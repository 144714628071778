import axios from 'axios';
import {
  FETCH_SIBLINGS_SUCCESS,
  SHOW_SPINNER,
  SHOW_NOTIFICATION,
  TOTAL_SIBLING,
} from '../constants';
import { URL } from '../../config';

export function linkSiblings(data) {
  // And then creates and returns the async thunk function:
  return async function linkNewSiblingThunk(dispatch, getState) {
    try {
      let token = sessionStorage.getItem('token');
      dispatch({
        type: SHOW_SPINNER,
        data: true,
      });
      // ✅ Now we can use the data value and send it to the server
      const response = await axios.post(URL + 'sibling/add', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      return response.data;
      //   dispatch({ type: 'ADD_PATIENT', payload: response.data })
    } catch (err) {
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      dispatch({
        type: SHOW_NOTIFICATION,
        isShow: true,
        content: 'Something went wrong.',
        origin: 'saveNewPatient',
      });
      return err.response;
    }
  };
}

export const fetchSiblings = (data) => (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    axios
      .post(URL + 'sibling/all', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // structured data
        const data = response.data.data.map((item) => ({
          id: item.id,
          ...item.siblings,
        }));
        dispatch({
          type: FETCH_SIBLINGS_SUCCESS,
          siblings: data,
        });
        dispatch({
          type: TOTAL_SIBLING,
          totalSibling: response.data.total,
        });
        dispatch({
          type: SHOW_SPINNER,
          data: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SIBLINGS_SUCCESS,
          patients: [],
        });
        dispatch({
          type: TOTAL_SIBLING,
          totalSibling: 0,
        });
        dispatch({
          type: SHOW_SPINNER,
          data: false,
        });
        dispatch({
          type: SHOW_NOTIFICATION,
          isShow: true,
          content: { type: 'error', content: 'Something went wrong.' },
          origin: 'api catch fetchPatients',
        });
        return err;
      });
  } catch (err) {
    dispatch({
      type: TOTAL_SIBLING,
      totalSibling: 0,
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'action catch fetchPatients',
    });
    return err.response;
  }
};
