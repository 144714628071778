import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Toolbar,
  Button,
  FormControl,
  Modal,
  MenuItem,
  Menu,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';

import CustomTextField from '../../../forms/custom-elements/CustomTextField';
import { useEffect } from 'react';
import { getGraphOfPatient } from '../../../../redux/patients/AsyncActions';
import { useLocation } from 'react-router';
import CustomFormLabel from '../../../forms/custom-elements/CustomFormLabel';
import { showMessageModal } from '../../../../redux/MessageModal/Action';
// import { htmltopdf } from 'html-to-pdf'
import GrowthIcon from '../../../../assets/icons/GrowthIcon.png';
import VisitSidebar from '../VisitSidebar';
import {
  changeTabNo,
  openMblVisitSidebar,
  saveGrowthData,
} from '../../../../redux/patients/Action';
import { useMemo } from 'react';
import { showNotificationAction } from '../../../../redux/pushnotification/Action';

const GrowthParameters = () => {
  const growth = useSelector((state) => state.patientReducer.growthData);
  const dispatch = useDispatch();

  const { state } = useLocation();

  const { id } = state ? state : {};

  const [weight, setweight] = useState('');
  const [height, setheight] = useState('');
  const [headCir, setheadCir] = useState('');
  const [bmi, setbmi] = useState(0);
  const [tempC, settempC] = useState('');
  const [pulseRate, setpulseRate] = useState('');
  const [respi, setrespi] = useState('');
  const [bp, setbp] = useState('');
  const [lmp, setlmp] = useState('');
  // const [showDate, setShowDate] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [graphToShow, setgraphToShow] = useState({});

  const [open, setOpen] = useState(false);

  const tabNoValue = useSelector((state) => state.patientReducer.tabNo);
  const tabNo = useMemo(() => {
    if (tabNoValue) return tabNoValue;
    else return 0;
  }, [tabNoValue]);

  const customizer = useSelector((state) => state.CustomizerReducer);
  //  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const getTabSelected = (no) => {
    saveData();
    dispatch(changeTabNo(no));
  };

  const handleClose = () => {
    setOpen(false);
    setgraphToShow({});
  };

  useEffect(() => {
    localStorage.setItem('editable', true);

    setweight(growth.weight);
    setheight(growth.height);
    setheadCir(growth.headCir);
    setbmi(growth.bmi ? Number(growth.bmi).toFixed(3) : 0);
    settempC(growth.tempC);
    setpulseRate(growth.pulseRate);
    setrespi(growth.respi);
    setbp(growth.bp);
    setlmp(growth.lmp);
  }, [growth]);

  // useEffect(() => {
  //     let bmiValue = bmi && bmi !== '' ? bmi : 0
  //     growthDataDetail({ weight, height, headCir, bmi: Number(bmiValue), tempC, pulseRate, respi, bp, lmp })
  // }, [weight, height, headCir, bmi, tempC, pulseRate, respi, bp, lmp])

  useEffect(() => {
    let bmiValue = weight / ((height * height) / 10000);
    if (bmiValue) {
      if (bmiValue > 100) {
        dispatch(
          showNotificationAction(true, {
            type: 'error',
            content: 'Invalid BMI',
          })
        );
      } else {
        dispatch(
          showNotificationAction(false, {
            type: '',
            content: '',
            origin: 'remove for bmi',
          })
        );
      }
      setbmi(bmiValue);
    } else {
      setbmi(0);
    }
  }, [weight, height, dispatch]);

  const saveData = () => {
    let bmiValue = bmi && bmi !== '' ? bmi : 0;
    dispatch(
      saveGrowthData({
        ...growth,
        weight,
        height,
        headCir,
        bmi: Number(bmiValue).toFixed(3),
        tempC,
        pulseRate,
        respi,
        bp,
        lmp,
      })
    );
  };
  const clearFunction = () => {
    setweight('');
    setheight('');
    setheadCir('');
    // setbmi('')
    settempC('');
    setpulseRate('');
    setrespi('');
    setbp('');
    setlmp('');
  };

  let graphTypeOptions = [
    {
      title: 'Weight-for-length/height',
      subtitle: 'Birth to 5 years(z - scores)',
      value: 'WeightForLength',
    },
    {
      title: 'Height-for-age',
      subtitle: ' 5 to 19 years (z-scores)',
      value: 'Height',
    },
    {
      title: 'Weight-for-age',
      subtitle: 'Birth to 5 years (z-scores)',
      value: 'WeightForAgeBirthTo5',
    },
    {
      title: 'Weight-for-age',
      subtitle: '5 to 10 years (z-scores)',
      value: 'WeightForAge5To10',
    },
    {
      title: 'Length/height-for-age',
      subtitle: 'Birth to 5 years (z-scores)',
      value: 'Length/height',
    },
    {
      title: 'Head circumference-for-age',
      subtitle: 'Birth to 5 years (z-scores)',
      value: 'Head Circumference',
    },
    {
      title: 'BMI-for-age',
      subtitle: '5 to 19 years (z-scores)',
      value: 'BMI',
    },
  ];
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = (event) => {
    // console.log('event.currentTarget----', event.currentTarget)
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const selectGraphHandler = (op) => {
    console.log('success-------');
    setAnchorEl2(null);

    if (graphData.length <= 0) {
      dispatch(getGraphOfPatient({ patientId: id })).then((result) => {
        // console.log('result-------', JSON.stringify(result));
        if (result && result.status === true) {
          setGraphData(result.data);
          let graphInd = result.data.findIndex(
            (a) => a['graphName'] === op.value
          );
          // console.log('graphInd-------', graphInd);
          if (graphInd >= 0) {
            setgraphToShow(result.data[graphInd]);
            setOpen(true);
          } else {
            dispatch(
              showMessageModal({
                showModal: true,
                modalContent: {
                  message: 'Please select gender to see growth chart.',
                },
              })
            );
          }
        } else {
          dispatch(
            showMessageModal({
              showModal: true,
              modalContent: {
                message: 'Please select gender to see growth chart.',
              },
            })
          );
        }
      });
    } else {
      let graphInd = graphData.findIndex((a) => a['graphName'] === op.value);
      if (graphInd >= 0) {
        setgraphToShow(graphData[graphInd]);
        setOpen(true);
      } else {
        dispatch(
          showMessageModal({
            showModal: true,
            modalContent: {
              message: 'Please select gender to see growth chart.',
            },
          })
        );
      }
    }
  };

  return (
    <>
      <VisitSidebar
        isSidebardir={customizer.activeDir === 'rtl' ? 'left' : 'right'}
        isSidebarOpen={true}
        onSidebarClose={() => dispatch(openMblVisitSidebar(false))}
        getTabSelected={getTabSelected}
      />
      <Card
        sx={{
          p: {
            xs: '0px',
            sm: '0px',
            lg: '0px',
          },
          width: '100%',
        }}
        className='newPatientCard newVisitCard'
      >
        <CardContent
          sx={{
            p: 0,
          }}
        >
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 2, sm: 2 },
              // ...(numSelected > 0 && {
              bgcolor: (theme) => '#1990FC',
              // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
              // }),
            }}
          >
            <Typography
              sx={{
                flex: '1 1 100%',
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
              variant='h6'
              id='newpatientTitle'
              component='span'
            >
              <img src={GrowthIcon} className='tabIcon' alt='logoImg' />
              Growth Parameters & Vitals
            </Typography>
            <Typography
              sx={{
                flex: '1 1 100%',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-end',
                cursor: 'pointer',
              }}
              variant='h6'
              id='newpatientTitle'
              component='span'
              onClick={clearFunction}
            >
              Clear
              <FeatherIcon
                icon='x'
                style={{
                  width: 14,
                  height: 14,
                  marginLeft: '5px',
                  color: '#1990FC',
                  borderRadius: '50%',
                  background: 'white',
                }}
              />
            </Typography>
          </Toolbar>
          <Box
            sx={{
              width: '100%',
              p: {
                xs: '20px',
                sm: '35px',
                lg: '35px',
              },
              pt: {
                xs: '10px',
                sm: '10px',
                lg: '10px',
              },
            }}
            className='newPatientCardBox'
          >
            <div>
              <div className='tabpanelContent'>
                <Typography
                  className='panelHeading'
                  style={{ justifyContent: 'space-between' }}
                >
                  {/* <img src={logo1} className="tabIcon" /> */}
                  Details
                  <div>
                    <Button
                      variant='contained'
                      color='primary'
                      className='visitTopButton'
                      onClick={handleClick2}
                      aria-controls='chart-menu'
                      aria-haspopup='true'
                    >
                      Growth Chart
                    </Button>

                    <Menu
                      id='chart-menu'
                      anchorEl={anchorEl2}
                      keepMounted
                      open={Boolean(anchorEl2)}
                      onClose={handleClose2}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      sx={{
                        '& .MuiMenu-paper': {
                          width: '285px',
                          // right: 0,
                          // top: '70px !important',
                        },
                        '& .MuiList-padding': {
                          p: '10px',
                        },
                        '& .MuiMenuItem-root': {
                          fontSize: '14px',
                        },
                      }}
                    >
                      {graphTypeOptions.map((op) => {
                        return (
                          <MenuItem
                            value={op.title}
                            onClick={() => selectGraphHandler(op)}
                          >
                            <Box>
                              <Typography variant='h6'>{op.title}</Typography>
                              <Typography color='textSecondary' variant='h6'>
                                {op.subtitle}
                              </Typography>
                            </Box>
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </div>
                </Typography>

                <div style={{ width: '100%' }}>
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <CustomFormLabel>Weight (KG)</CustomFormLabel>
                    <CustomTextField
                      type='number'
                      placeholder='Weight'
                      variant='outlined'
                      fullWidth
                      value={weight}
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          setweight(e.target.value);
                        } else if (e.target.value < 0) {
                          setweight(0);
                        } else {
                          setweight('');
                        }
                      }}
                    />
                  </FormControl>
                </div>
                <div style={{ width: '100%' }}>
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <CustomFormLabel>Height (CM)</CustomFormLabel>
                    <CustomTextField
                      type='number'
                      placeholder='Height'
                      variant='outlined'
                      fullWidth
                      value={height}
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          setheight(e.target.value);
                        } else if (e.target.value < 0) {
                          setheight(0);
                        } else {
                          setheight('');
                        }
                      }}
                      onWheel={() => document.activeElement.blur()}
                    />
                  </FormControl>
                </div>
                <div style={{ width: '100%' }}>
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <CustomFormLabel>Head Circumference (CM)</CustomFormLabel>
                    <CustomTextField
                      type='number'
                      id='HeadCircumference'
                      placeholder='Head Circumference'
                      variant='outlined'
                      fullWidth
                      value={headCir}
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          setheadCir(e.target.value);
                        } else if (e.target.value < 0) {
                          setheadCir(0);
                        } else {
                          setheadCir('');
                        }
                      }}
                      onWheel={() => document.activeElement.blur()}
                    />
                  </FormControl>
                </div>
                <div style={{ width: '100%' }}>
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <CustomFormLabel>BMI</CustomFormLabel>

                    {/* <CustomTextField
                                            placeholder="(Weight in Kilograms / (Height in Meters x Height in Meters))"
                                            variant="outlined"
                                            fullWidth
                                            value={bmi}
                                            disabled
                                            type="number"
                                        /> */}
                    <Card
                      sx={{
                        boxShadow: 'none !important',
                        border: '1px solid #dee3e9 !important',
                        borderRadius: '5px',
                        margin: '0px',
                      }}
                    >
                      {bmi && Number(bmi.toFixed(5))}
                    </Card>
                  </FormControl>
                </div>
                <div style={{ width: '100%' }}>
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <CustomFormLabel>Temperature (Celsius)</CustomFormLabel>
                    <CustomTextField
                      type='number'
                      id='TemperatureC'
                      placeholder='Temperature'
                      variant='outlined'
                      fullWidth
                      value={tempC}
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          settempC(e.target.value);
                        } else if (e.target.value < 0) {
                          settempC(0);
                        } else {
                          settempC('');
                        }
                      }}
                    />
                  </FormControl>
                </div>
                <div style={{ width: '100%' }}>
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <CustomFormLabel>Pulse Rate (BPM)</CustomFormLabel>
                    <CustomTextField
                      type='number'
                      placeholder='Pulse Rate'
                      variant='outlined'
                      fullWidth
                      value={pulseRate}
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          setpulseRate(e.target.value);
                        } else if (e.target.value < 0) {
                          setpulseRate(0);
                        } else {
                          setpulseRate('');
                        }
                      }}
                    />
                  </FormControl>
                </div>
                <div style={{ width: '100%' }}>
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <CustomFormLabel>Respiratory Rate</CustomFormLabel>
                    <CustomTextField
                      type='number'
                      placeholder='Respiratory Rate'
                      variant='outlined'
                      fullWidth
                      value={respi}
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          setrespi(e.target.value);
                        } else if (e.target.value < 0) {
                          setrespi(0);
                        } else {
                          setrespi('');
                        }
                      }}
                    />
                  </FormControl>
                </div>
                <div style={{ width: '100%' }}>
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <CustomFormLabel>BP (mmHg)</CustomFormLabel>
                    <CustomTextField
                      type='text'
                      placeholder='BP'
                      variant='outlined'
                      fullWidth
                      value={bp}
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9\/]/g, '');
                        if (value.length >= 0) {
                          setbp(value);
                        } else if (value.length < 0) {
                          setbp(0);
                        } else {
                          setbp('');
                        }
                      }}
                    />
                  </FormControl>
                </div>
                {/* <div style={{ width: '100%' }}>
                                    <FormControl
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <CustomFormLabel>LMP</CustomFormLabel>
                                        <CustomTextField
                                            placeholder="LMP"
                                            variant="outlined"
                                            fullWidth
                                            value={lmp}
                                            onChange={e => setlmp(e.target.value)} />
                                    </FormControl>
                                </div> */}
                <div style={{ width: '100%' }}>
                  <CustomFormLabel>LMP</CustomFormLabel>

                  {/* <div style={{ width: '100%' }}
                                        onClick={() => {
                                            setShowDate(true)
                                        }}
                                        onFocus={() => {
                                            setShowDate(true)
                                        }}

                                        onBlur={() => {
                                            if (lmp === '') {
                                                setShowDate(false)
                                            }
                                        }}
                                        className="dateDiv"
                                    > */}
                  <CustomTextField
                    id='lmp'
                    type={'date'}
                    placeholder='LMP'
                    variant='outlined'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    value={lmp}
                    onChange={(e) => {
                      setlmp(e.target.value);
                    }}
                  />
                  {/* {showDate ? '' : <FeatherIcon icon="calendar" className="calendaricon" />}
                                    </div> */}
                </div>
              </div>
              <div style={{ float: 'right', margin: '15px 0px' }}>
                <Button
                  variant='contained'
                  color='primary'
                  className='visitTopButton'
                  onClick={() => {
                    saveData();
                    dispatch(changeTabNo(tabNo + 1));
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          </Box>
        </CardContent>
      </Card>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {graphToShow && graphToShow.graphLink ? (
            <div
              style={{
                backgroundColor: 'white',
                width: '90%',
                height: '90vh',
                overflow: 'auto',
                position: 'relative',
              }}
            >
              <FeatherIcon
                icon='x'
                style={{
                  width: 20,
                  height: 20,
                  position: 'absolute',
                  right: '25px',
                  top: '10px',
                  background: '#dee3e9',
                  borderRadius: '50%',
                  color: 'black',
                  padding: '3px',
                }}
                onClick={handleClose}
              />
              <iframe
                src={graphToShow.graphLink}
                title={graphToShow.graphName}
                width='100%'
                height='99%'
              ></iframe>
            </div>
          ) : null}
        </Box>
      </Modal>
    </>
  );
};

export default GrowthParameters;
