import React from 'react';
import { Grid, Typography, Box, Breadcrumbs, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Breadcrumb = ({ subtitle, items, title, children }) => (
  <Grid
    container
    sx={{
      p: '15px',
    }}
    className="breadcrumbGrid"
  >
    <Grid item xs={12} sm={9} lg={8}>
      <Typography color="textSecondary" fontWeight="400" variant="h5">
        {subtitle}
      </Typography>

      {/* <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb"> */}
      <Breadcrumbs aria-label="breadcrumb" className='breadcrimbsTitle'>
        {items
          ? items.map((item) => (
            <div key={item.title}  >
              {item.to ? (
                <Link underline="none" variant='h5' color="#1990FC" fontWeight="400" cursor="pointer" component={NavLink} to={item.to}>
                  {item.title}
                </Link>
              ) : (
                <Typography fontWeight="400" variant='h6' onClick={item.onclick}>{item.title}</Typography>
              )}
            </div>
          ))
          : ''}
      </Breadcrumbs>
      <Typography
        fontWeight="500"
        variant="h2"
        sx={{
          lineHeight: '1.235',
          color: '#1990FC',
          // fontSize:'20px'
        }}

      >
        {title}
      </Typography>
    </Grid>
    <Grid item xs={12} sm={3} lg={4} display="flex" alignItems="flex-end">
      <Box
        sx={{
          display: { xs: 'none', md: 'block', lg: 'flex' },
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
        }}
        className='childrenBox'
      >
        {children}
      </Box>
    </Grid>
  </Grid>
);

Breadcrumb.propTypes = {
  subtitle: PropTypes.string,
  items: PropTypes.array,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Breadcrumb;
