
import { useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router';
import PageContainer from "../container/PageContainer"
import {
    Typography,
    CardContent,
    Card,
    Button,
    FormControlLabel,
    Grid,
    FormControl,
    Box
} from '@mui/material';
import CustomCheckbox from '../../components/forms/custom-elements/CustomCheckbox';
import Select from 'react-select';
import CustomFilterList from "./CustomFilterList";
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import CustomRadio from '../../components/forms/custom-elements/CustomRadio';


import {
    getClinicalSummaryFilter,
    getFirstVisitFilter,
    getFolloWUpVisitFilter,
} from "../../redux/reports/AsyncAction"

const CustomReports = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const clinicalVisitData = useSelector(state => state.ReportReducer.getClinicalSummaryVisitData)
    const firstVisitData = useSelector(state => state.ReportReducer.getFirstVisitData)
    const followUpVisitData = useSelector(state => state.ReportReducer.getFollowUpVisitData)

    const rootRoute = useMemo(() => {
        if (userinfo && userinfo.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])


    const [isShowTable, SetIsShowTable] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setperPage] = useState(10);
    const [selectfilterValue, setSelectfilterValue] = useState({ "label": "Clinical Visit", "value": "clinicalVisit" });
    const [filterValueArray, setFilterValueArray] = useState([
        { "label": "Clinical Visit", "value": "clinicalVisit" },
        { "label": "First Visit", "value": "firstVisit" },
        { "label": "Follow Up Visit", "value": "followUpVisit" },
    ]);

    const [medicalRecord, setMedicalRecord] = useState({
        'Smoking': false, 'Alcohol': false, "Drugs": false, "Exercise": false, "Diet": false,
        'Immunisations': false, 'allergies': false
    })

    const medicalRecordOptions = [
        { label: 'Smoking', key: 'Smoking' },
        { label: 'Alcohol', key: 'Alcohol' },
        { label: 'Drugs', key: 'Drugs' },
        { label: 'Exercise', key: 'Exercise' },
        { label: 'Diet', key: 'Diet' },
        { label: 'Immunisations', key: 'Immunisations' },
        { label: 'Allergies', key: 'allergies' },
    ]

    const ageGroupOptions = [
        { label: '0-10', key: '0-10' },
        { label: '10-20', key: '10-20' },
        { label: '20-30', key: '20-30' },
        { label: '30-40', key: '30-40' },
        { label: '40-50', key: '40-50' },
        { label: '50-60', key: '50-60' },
        { label: '60-70', key: '60-70' },
        { label: '70-80', key: '70-80' },
        { label: '80-90', key: '80-90' },
        { label: '90-100', key: '90-100' },
    ]

    const [ageGroup, setAgeGroup] = useState({
        '0-10': false, '10-20': false, "20-30": false, "30-40": false, "40-50": false,
        '50-60': false, '60-70': false, '70-80': false, '80-90': false, '90-100': false
    })

    const familyHistoryOptions = [
        { label: 'Diabetes', key: 'diabetes' },
        { label: 'Hypertension', key: 'hypertension' },
        { label: 'Ischaemic heart disease', key: 'IschaemicHeartDisease' },
        { label: 'Stroke', key: 'stroke' },
        { label: 'Breast cancer', key: 'breastCancer' },
        { label: 'Asthma', key: 'asthma' },

        { label: 'Parkinsons', key: 'parkinsons' },
        { label: 'Glaucoma', key: 'glaucoma' },
        { label: 'Rheumatoid Arthritis', key: 'rheumatoidArthritis' },
        { label: 'Inflammatory bowel disease', key: 'inflammatoryBowelDisease' },
        { label: 'Lupus', key: 'lupus' },
        { label: 'Psychiatric disease', key: 'psychiatricDisease' },

        { label: 'Autoimmune disease', key: 'autoimmuneDisease' },
        { label: 'Psoriasis', key: 'psoriasis' },
        { label: 'Bowel cancer', key: 'bowelCancer' },
        { label: 'Ovarian cancer', key: 'ovarianCancer' },
        { label: 'Other Cancer', key: 'otherCancer' },
        { label: 'Dementia', key: 'dementia' },

        { label: 'Kidney disease', key: 'KidneyDisease' },
        { label: 'Heart failure', key: 'heartFailure' },
        { label: 'Hyperlipidaemia', key: 'hyperlipidaemia' },
    ]

    const [familyHistoryValues, setFamilyHistoryValues] = useState({
        'diabetes': false, 'hypertension': false, "IschaemicHeartDisease": false, "stroke": false, "breastCancer": false,
        'asthma': false, 'parkinsons': false, "glaucoma": false, "rheumatoidArthritis": false, "inflammatoryBowelDisease": false,
        'lupus': false, 'psychiatricDisease': false, "autoimmuneDisease": false, "psoriasis": false, "bowelCancer": false,
        'ovarianCancer': false, 'otherCancer': false, "dementia": false, "KidneyDisease": false, "heartFailure": false,
        'hyperlipidaemia': false,
    })

    const medicationHistoryOptions = [
        { label: 'Diabetes', key: 'diabetes' },
        { label: 'Hypertension', key: 'hypertension' },
        { label: 'Ischaemic heart disease', key: 'IschaemicHeartDisease' },
        { label: 'Stroke', key: 'stroke' },
        { label: 'Breast cancer', key: 'breastCancer' },
        { label: 'Asthma', key: 'asthma' },

        { label: 'Parkinsons', key: 'parkinsons' },
        { label: 'Glaucoma', key: 'glaucoma' },
        { label: 'Rheumatoid Arthritis', key: 'rheumatoidArthritis' },
        { label: 'Inflammatory bowel disease', key: 'inflammatoryBowelDisease' },
        { label: 'Lupus', key: 'lupus' },
        { label: 'Psychiatric disease', key: 'psychiatricDisease' },

        { label: 'Autoimmune disease', key: 'autoimmuneDisease' },
        { label: 'Psoriasis', key: 'psoriasis' },
        { label: 'Bowel cancer', key: 'bowelCancer' },
        { label: 'Ovarian cancer', key: 'ovarianCancer' },
        { label: 'Other Cancer', key: 'otherCancer' },
        { label: 'Dementia', key: 'dementia' },

        { label: 'Kidney disease', key: 'KidneyDisease' },
        { label: 'Heart failure', key: 'heartFailure' },
        { label: 'Hyperlipidaemia', key: 'hyperlipidaemia' },
    ]

    const [medicationHistoryValues, setMedicationHistoryValues] = useState({
        'diabetes': false, 'hypertension': false, "IschaemicHeartDisease": false, "stroke": false, "breastCancer": false,
        'asthma': false, 'parkinsons': false, "glaucoma": false, "rheumatoidArthritis": false, "inflammatoryBowelDisease": false,
        'lupus': false, 'psychiatricDisease': false, "autoimmuneDisease": false, "psoriasis": false, "bowelCancer": false,
        'ovarianCancer': false, 'otherCancer': false, "dementia": false, "KidneyDisease": false, "heartFailure": false,
        "hyperlipidaemia": false,
    })

    const screeningTestsOptions = [
        { label: 'lipid profile', key: 'lipidProfile' },
        { label: 'Hba1c', key: 'hba1c' },
        { label: 'RFT', key: 'rFT' },
        { label: 'LFT', key: 'lFT' },
        { label: 'CBC', key: 'cBC' },
        { label: 'PSA', key: 'pSA' },

        { label: 'ACR', key: 'aCR' },
        { label: 'ABI', key: 'aBI' },
        { label: 'Colonoscopy', key: 'colonoScopy' },
        { label: 'Endoscopy', key: 'endoscopy' },
        { label: 'FIT test', key: 'fITTest' },
        { label: 'Pap smear', key: 'papSmear' },

        { label: 'Co-Test', key: 'coTest' },
        { label: 'ECG', key: 'eCG' },
        { label: 'Calcium score', key: 'calciumScore' },
        { label: 'HRCT', key: 'hRCT' },
        { label: 'Spirometry', key: 'spirometry' },
        { label: 'Breast screening', key: 'breastScreening' },

        { label: 'Eye screening', key: 'eyeScreening' },
        { label: 'Dental screen', key: 'dentalScreen' },
        { label: 'Bone density', key: 'boneDensity' },

        { label: 'Thyroid Function', key: 'thyroidFunction' },
        { label: 'Erythrocyte Sedimentation Rate (ESR)', key: 'erythrocyteSedimentationRate' },
        { label: 'Ferritin Levels', key: 'ferritinLevels' },
    ]

    const [screeningTestsValues, setScreeningTestsValues] = useState(
        {
            'lipidProfile': false, 'hba1c': false, 'rFT': false, 'lFT': false, 'cBC': false,
            'pSA': false, 'aCR': false, 'aBI': false, 'colonoScopy': false, 'endoscopy': false,
            'fITTest': false, 'papSmear': false, 'coTest': false, 'eCG': false, 'calciumScore': false,
            'hRCT': false, 'spirometry': false, 'breastScreening': false, 'eyeScreening': false, 'dentalScreend': false,
            'boneDensity': false, 'thyroidFunction': false, 'erythrocyteSedimentationRate': false, 'ferritinLevels': false,
        }
    )

    const antiHypertensivesOptions = [
        { label: 'Atenolol', key: 'atenolol' },
        { label: 'Concor', key: 'concor' },
        { label: 'Carvedilol', key: 'carvedilol' },
        { label: 'Nifedipine', key: 'nifedipine' },
        { label: 'Amlodipine', key: 'amlodipine' },
        { label: 'Bendrofluazide', key: 'bendrofluazide' },

        { label: 'Natrilix sr', key: 'natrilixsr' },
        { label: 'Terazosin', key: 'terazosin' },
        { label: 'Methyldopa', key: 'methyldopa' },
        { label: 'Enalapril', key: 'enalapril' },
        { label: 'Lisinopril', key: 'lisinopril' },
        { label: 'Candesartan', key: 'candesartan' },

        { label: 'Candesartan plus', key: 'candesartanPlus' },
    ]

    const [antiHypertensivesValues, setAntiHypertensivesValues] = useState({
        'atenolol': false, 'concor': false, "carvedilol": false, "nifedipine": false, "amlodipine": false,
        'bendrofluazide': false, 'natrilixsr': false, "terazosin": false, "methyldopa": false, "enalapril": false,
        'lisinopril': false, 'candesartan': false, "candesartanPlus": false
    })

    const antiDiabeticsOptions = [
        { label: 'Metformin', key: 'metformin' },
        { label: 'Diamicron', key: 'diamicron' },
        { label: 'Gliclazide', key: 'gliclazide' },
        { label: 'Trajenta', key: 'trajenta' },
        { label: 'Januvia', key: 'januvia' },
        { label: 'Galvus', key: 'galvus' },

        { label: 'Galvus Met', key: 'galvusMet' },
        { label: 'Janumet', key: 'janumet' },
        { label: 'Forxiga', key: 'forxiga' },
        { label: 'Jardiance', key: 'jardiance' },
        { label: 'Xigduo', key: 'xigduo' },
        { label: 'Synjardy', key: 'synjardy' },

        { label: 'Glyxambi', key: 'glyxambi' },
        // { label: '70/30 Insulin', key: '70/30Insulin' },
        { label: 'Lantus', key: 'lantus' },
        // { label: 'Apidra', key: 'apidra' },
    ]

    const [antiDiabeticsValues, setAntiDiabeticsValues] = useState({
        'metformin': false, 'diamicron': false, "gliclazide": false, "trajenta": false, "januvia": false,
        'galvus': false, 'galvusMet': false, "janumet": false, "forxiga": false, "jardiance": false,
        'xigduo': false, 'synjardy': false, "glyxambi": false,
        // "70/30Insulin": false, 
        "lantus": false,
        // "70/apidra": false,
    })

    //*First Visit_____________________
    const bloodInvestigationInPregnancy = [
        { label: 'HIV', key: 'hiv' },
        { label: 'VDRL', key: 'vdrl' },
        { label: 'GBS', key: 'gbs' },
        { label: 'OGTT', key: 'ogtt' },
        { label: 'HEPATITIS', key: 'hepatitis' },
    ]

    const [bloodInvestigationInPregnancyValues, setBloodInvestigationInPregnancyValues] = useState({
        'hiv': false, 'vdrl': false, "gbs": false, "ogtt": false, "hepatitis": false,
    })


    const patientBloodGroup = [
        { label: 'A+', key: 'a+' },
        { label: 'A-', key: 'a-' },
        { label: 'B+', key: 'b+' },
        { label: 'B-', key: 'b-' },
        { label: 'AB+', key: 'ab+' },
        { label: 'AB-', key: 'ab-' },
        { label: 'O+', key: 'o+' },
        { label: 'O-', key: 'o-' },
    ]

    const [patientBloodGroupValues, setPatientBloodGroupValues] = useState({
        'a+': false, 'a-': false, "b+": false, "b-": false, "ab+": false,
        "ab-": false, "o+": false, "o-": false,
    })

    const modeOfDelivery = [
        { label: 'NSVD', key: 'NSVD' },
        { label: 'Induced Vaginal Delivery', key: 'Induced Vaginal Delivery' },
        { label: 'Elective LSCS', key: 'Elective LSCS' },
        { label: 'Emergency LSCS', key: 'Emergency LSCS' },
    ]

    const [modeOfDeliveryValues, setModeOfDeliveryValues] = useState({
        'NSVD': false,
        'Induced Vaginal Delivery': false,
        "Elective LSCS": false,
        "Emergency LSCS": false,
    })

    const indicationsOfLscs = [
        { label: 'Failure To Progress', key: 'Failure To Progress' },
        { label: 'Fetal Distress', key: 'Fetal Distress' },
        { label: 'Medical Condition', key: 'Medical Condition' },
        { label: 'Maternal Request', key: 'Maternal Request' },
        { label: 'Previous C-section', key: 'Previous C-section' },
    ]

    const [indicationsOfLscsValues, setIndicationsOfLscsValues] = useState({
        'Failure To Progress': false, 'Fetal Distress': false, "Medical Condition": false,
        "Maternal Request": false, "Previous C-section": false,
    })

    const diet = [
        { label: 'Breastmilk', key: 'Breastmilk' },
        { label: 'Formula feeds', key: 'Formulafeeds' },
        { label: 'Weaning onto solids', key: 'Weaningontosolids' },
        { label: 'Family Pot', key: 'FamilyPot' },
        { label: 'Picky eater/Poor eating habits', key: 'PickyEaterPoorEatingHabits' },
        { label: 'Eats well', key: 'EatsWell' },
    ]

    const [dietValues, setDietValues] = useState({
        'Breastmilk': false, 'Formulafeeds': false, "Weaningontosolids": false,
        "FamilyPot": false, "PickyEaterPoorEatingHabits": false, "EatsWell": false,
    })

    const dentalCare = [
        { label: 'Good dental hygiene', key: 'Good dental hygiene' },
        { label: 'Cavities', key: 'Cavities' },
    ]

    const [dentalCareValues, setDentalCareValues] = useState({
        'Good dental hygiene': false, 'Cavities': false,
    })


    useEffect(() => {
        if (clinicalVisitData.length > 0) {
            SetIsShowTable(true);
        }
    }, [clinicalVisitData])

    useEffect(() => {
        if (firstVisitData.length > 0) {
            SetIsShowTable(true);
        }
    }, [firstVisitData])

    useEffect(() => {
        if (followUpVisitData.length > 0) {
            SetIsShowTable(true);
        }
    }, [followUpVisitData])

    const onClickClearAll = () => {

        setMedicalRecord({
            'smoking': false, 'alcohol': false, "drugs": false, "exercise": false, "diet": false,
            'immunisations': false, 'allergies': false
        })

        setAgeGroup({
            '0-10': false, '10-20': false, "20-30": false, "30-40": false, "40-50": false,
            '50-60': false, '60-70': false, '70-80': false, '80-90': false, '90-100': false
        })

        setFamilyHistoryValues({
            'diabetes': false, 'hypertension': false, "IschaemicHeartDisease": false, "stroke": false, "breastCancer": false,
            'asthma': false, 'parkinsons': false, "glaucoma": false, "rheumatoidArthritis": false, "inflammatoryBowelDisease": false,
            'lupus': false, 'psychiatricDisease': false, "autoimmuneDisease": false, "psoriasis": false, "bowelCancer": false,
            'ovarianCancer': false, 'otherCancer': false, "dementia": false, "KidneyDisease": false, "heartFailure": false,
            'hyperlipidaemia': false,
        })

        setMedicationHistoryValues({
            'diabetes': false, 'hypertension': false, "IschaemicHeartDisease": false, "stroke": false, "breastCancer": false,
            'asthma': false, 'parkinsons': false, "glaucoma": false, "rheumatoidArthritis": false, "inflammatoryBowelDisease": false,
            'lupus': false, 'psychiatricDisease': false, "autoimmuneDisease": false, "psoriasis": false, "bowelCancer": false,
            'ovarianCancer': false, 'otherCancer': false, "dementia": false, "KidneyDisease": false, "heartFailure": false,
            "hyperlipidaemia": false,
        })

        setScreeningTestsValues({
            'lipidProfile': false, 'hba1c': false, 'rFT': false, 'lFT': false, 'cBC': false,
            'pSA': false, 'aCR': false, 'aBI': false, 'colonoScopy': false, 'endoscopy': false,
            'fITTest': false, 'papSmear': false, 'coTest': false, 'eCG': false, 'calciumScore': false,
            'hRCT': false, 'spirometry': false, 'breastScreening': false, 'eyeScreening': false, 'dentalScreend': false,
            'boneDensity': false, 'thyroidFunction': false, 'erythrocyteSedimentationRate': false, 'ferritinLevels': false,
        })

        setAntiHypertensivesValues({
            'atenolol': false, 'concor': false, "carvedilol": false, "nifedipine": false, "amlodipine": false,
            'bendrofluazide': false, 'natrilixsr': false, "terazosin": false, "methyldopa": false, "enalapril": false,
            'lisinopril': false, 'candesartan': false, "candesartanPlus": false
        })

        setAntiDiabeticsValues({
            'metformin': false, 'diamicron': false, "gliclazide": false, "trajenta": false, "januvia": false,
            'galvus': false, 'galvusMet': false, "janumet": false, "forxiga": false, "jardiance": false,
            'xigduo': false, 'synjardy': false, "glyxambi": false, "70/30Insulin": false, "lantus": false, "70/apidra": false,
        })

        setScreeningTestsValues({
            'lipidProfile': false, 'hba1c': false, 'rFT': false, 'lFT': false, 'cBC': false,
            'pSA': false, 'aCR': false, 'aBI': false, 'colonoScopy': false, 'endoscopy': false,
            'fITTest': false, 'papSmear': false, 'coTest': false, 'eCG': false, 'calciumScore': false,
            'hRCT': false, 'spirometry': false, 'breastScreening': false, 'eyeScreening': false, 'dentalScreend': false,
            'boneDensity': false, 'thyroidFunction': false, 'erythrocyteSedimentationRate': false, 'ferritinLevels': false,
        })

        setBloodInvestigationInPregnancyValues({
            'hiv': false, 'vdrl': false, "gbs": false, "ogtt": false, "hepatitis": false,
        })

        setPatientBloodGroupValues({
            'a+': false, 'a-': false, "b+": false, "b-": false, "ab+": false,
            "ab-": false, "o+": false, "o-": false,
        })

        setModeOfDeliveryValues({
            'NSVD': false,
            'Induced Vaginal Delivery': false,
            "Elective LSCS": false,
            "Emergency LSCS": false,
        })

        setIndicationsOfLscsValues({
            'Failure To Progress': false, 'Fetal Distress': false, "Medical Condition": false,
            "Maternal Request": false, "Previous C-section": false,
        })

        setDietValues({
            'Breastmilk': false, 'Formulafeeds': false, "Weaningontosolids": false,
            "FamilyPot": false, "PickyEaterPoorEatingHabits": false, "EatsWell": false,
        })

        setDentalCareValues({
            'Good dental hygiene': false, 'Cavities': false,
        })

    }

    const onClickFilter = () => {
        if (selectfilterValue.value === "clinicalVisit") { onClickFilterClinicalSummaryVisit(); }
        else if (selectfilterValue.value === "firstVisit") { onClickFilterFirstVisit(); }
        else if (selectfilterValue.value === "followUpVisit") { onClickFilterFollowUpVisit(); }
    }

    const onClickFilterClinicalSummaryVisit = async () => {

        let medicalRecordDuplicate = JSON.parse(JSON.stringify(medicalRecord));
        for (let key in medicalRecordDuplicate) {
            if (medicalRecordDuplicate.hasOwnProperty(key)) {
                // Update the value to "Yes" if true, otherwise update to "No"
                medicalRecordDuplicate[key] = medicalRecordDuplicate[key] ? "Yes" : "No";
            }
        }

        let dataObj = {
            "medicalRecordValuesApi": medicalRecordDuplicate,
            "antihypertensivesValuesApi": antiHypertensivesValues,
            "antiDiabeticsValuesApi": antiDiabeticsValues,
            "familyHistoryValuesApi": familyHistoryValues,
            "medicationHistoryValuesApi": medicationHistoryValues,
            "screeningTestsValuesApi": screeningTestsValues,
            "ageGroupValuesApi": ageGroup,
        }
        dispatch(getClinicalSummaryFilter(dataObj))

    }

    const onClickFilterFirstVisit = () => {

        let bloodInvestigationInPregnancyValuesApi = JSON.parse(JSON.stringify(bloodInvestigationInPregnancyValues));
        for (let key in bloodInvestigationInPregnancyValuesApi) {
            if (bloodInvestigationInPregnancyValuesApi[key] === true) {
                bloodInvestigationInPregnancyValuesApi[key] = "Positive";
            } else {
                bloodInvestigationInPregnancyValuesApi[key] = "Negative";
            }
        }

        let dataObj = {
            "bloodInvestigationInPregnancyApi": bloodInvestigationInPregnancyValuesApi,
            "patientBloodGroupApi": patientBloodGroupValues,
            "modeOfDeliverysApi": modeOfDeliveryValues,
            "indicationsOfLscsApi": indicationsOfLscsValues,
            "dietValuesApi": dietValues,
            "dentalCareApi": dentalCareValues,
        }
        dispatch(getFirstVisitFilter(dataObj))

    }

    const onClickFilterFollowUpVisit = () => {

        let dataObj = {
            "dietValuesApi": dietValues,
            "dentalCareApi": dentalCareValues,
        }
        dispatch(getFolloWUpVisitFilter(dataObj))

    }

    const filteredData = async (data) => {
        Object.entries(data)
            .filter(([key, value]) => value === true)
            .reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});

    }

    const getPageInfoFun = (page, perPage) => {
        setPage(page + 1)
        setperPage(perPage)
    }

    return <PageContainer title="Reports" description="This is Dashboard page">

        <div className="patientDetailPage">
            <Typography sx={{
                flex: '1 1 100%',
                p: {
                    xs: '10px',
                    sm: '10px',
                    lg: '10px',
                },
                color: '#464646',
                display: 'flex',
                alignItems: 'center',
                gap: '20px'
            }} variant="h4" id="" fontWeight="500" component="div">
                Add filters to generate a report
            </Typography>

            <Select
                value={selectfilterValue}
                onChange={(e) => {
                    setSelectfilterValue(e);
                    onClickClearAll();
                    SetIsShowTable(false)
                }}
                options={filterValueArray}
                placeholder={"Select Country"}
                className={'form-select p-0 border-0'}
                styles={{ width: '70%', }}
            />

            {
                selectfilterValue.value === "clinicalVisit" ?
                    <Card
                        sx={{
                            p: {
                                xs: '10px',
                                sm: '10px',
                                lg: '20px',
                            },
                            width: '100%'
                        }}
                    >
                        <CardContent>
                            {/*------------ Medical Record ------------ */}
                            <Typography sx={{
                                flex: '1 1 100%',
                                p: {
                                    xs: '10px',
                                    sm: '10px',
                                    lg: '10px',
                                },
                                color: '#464646',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '20px'
                            }} variant="h4" id="" fontWeight="500" component="div">
                                Medical Record
                            </Typography>

                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    m: 0
                                }}
                                className="dietGrid"
                                style={{ marginTop: '10px' }}
                            >

                                {medicalRecordOptions.map((item, i) =>
                                    <Grid item lg={2} md={2} sm={2} xs={2} key={i}>
                                        <FormControlLabel
                                            control={
                                                <CustomCheckbox
                                                    checked={medicalRecord[item.key]}
                                                    onChange={(e) => {
                                                        setMedicalRecord({ ...medicalRecord, [item.key]: e.target.checked })
                                                    }}
                                                    name="checkedA"
                                                    color="primary"
                                                />
                                            }
                                            label={item.label}
                                        />
                                    </Grid>)}
                            </Grid>
                            {/*------------ Medical Record ------------ */}

                            {/*------------ Medication ------------ */}
                            <Typography sx={{
                                flex: '1 1 100%',
                                p: {
                                    xs: '10px',
                                    sm: '10px',
                                    lg: '10px',
                                },
                                color: '#464646',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '20px'
                            }} variant="h4" id="" fontWeight="500" component="div">
                                Medication
                            </Typography>

                            <Typography sx={{
                                flex: '1 1 100%',
                                p: {
                                    xs: '10px',
                                    sm: '10px',
                                    lg: '10px',
                                },
                                color: '#464646',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '20px'
                            }} variant="h4" id="" fontWeight="200" component="div">
                                Anti-hypertensives
                            </Typography>


                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    m: 0
                                }}
                                className="dietGrid"
                                style={{ marginTop: '10px' }}
                            >
                                {antiHypertensivesOptions.map((item, i) =>
                                    <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                                        <FormControlLabel
                                            control={
                                                <CustomCheckbox
                                                    checked={antiHypertensivesValues[item.key]}
                                                    onChange={(e) => {
                                                        setAntiHypertensivesValues({ ...antiHypertensivesValues, [item.key]: e.target.checked })
                                                    }}
                                                    name="checkedA"
                                                    color="primary"
                                                />
                                            }
                                            label={item.label}
                                        />
                                    </Grid>)}
                            </Grid>

                            <Typography sx={{
                                flex: '1 1 100%',
                                p: {
                                    xs: '10px',
                                    sm: '10px',
                                    lg: '10px',
                                },
                                color: '#464646',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '20px'
                            }} variant="h4" id="" fontWeight="200" component="div">
                                Anti-diabetics
                            </Typography>

                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    m: 0
                                }}
                                className="dietGrid"
                                style={{ marginTop: '10px' }}
                            >
                                {antiDiabeticsOptions.map((item, i) =>
                                    <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                                        <FormControlLabel
                                            control={
                                                <CustomCheckbox
                                                    checked={antiDiabeticsValues[item.key]}
                                                    onChange={(e) => {
                                                        setAntiDiabeticsValues({ ...antiDiabeticsValues, [item.key]: e.target.checked })
                                                    }}
                                                    name="checkedA"
                                                    color="primary"
                                                />
                                            }
                                            label={item.label}
                                        />
                                    </Grid>)}
                            </Grid>
                            {/*------------ Medication ------------ */}

                            {/*------------ Family history ------------ */}
                            <Typography sx={{
                                flex: '1 1 100%',
                                p: {
                                    xs: '10px',
                                    sm: '10px',
                                    lg: '10px',
                                },
                                color: '#464646',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '20px'
                            }} variant="h4" id="" fontWeight="500" component="div">
                                Family History
                            </Typography>

                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    m: 0
                                }}
                                className="dietGrid"
                                style={{ marginTop: '10px' }}
                            >
                                {familyHistoryOptions.map((item, i) =>
                                    <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                                        <FormControlLabel
                                            control={
                                                <CustomCheckbox
                                                    checked={familyHistoryValues[item.key]}
                                                    onChange={(e) => {
                                                        setFamilyHistoryValues({ ...familyHistoryValues, [item.key]: e.target.checked })
                                                    }}
                                                    name="checkedA"
                                                    color="primary"
                                                />
                                            }
                                            label={item.label}
                                        />
                                    </Grid>)}
                            </Grid>
                            {/*------------ Family history ------------ */}

                            {/*------------ Medical history ------------ */}
                            <Typography sx={{
                                flex: '1 1 100%',
                                p: {
                                    xs: '10px',
                                    sm: '10px',
                                    lg: '10px',
                                },
                                color: '#464646',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '20px'
                            }} variant="h4" id="" fontWeight="500" component="div">
                                Medical History
                            </Typography>

                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    m: 0
                                }}
                                className="dietGrid"
                                style={{ marginTop: '10px' }}
                            >
                                {medicationHistoryOptions.map((item, i) =>
                                    <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                                        <FormControlLabel
                                            control={
                                                <CustomCheckbox
                                                    checked={medicationHistoryValues[item.key]}
                                                    onChange={(e) => {
                                                        setMedicationHistoryValues({ ...medicationHistoryValues, [item.key]: e.target.checked })
                                                    }}
                                                    name="checkedA"
                                                    color="primary"
                                                />
                                            }
                                            label={item.label}
                                        />
                                    </Grid>)}
                            </Grid>
                            {/*------------ Medical history ------------ */}

                            {/*------------ Screening tests ------------ */}
                            <Typography sx={{
                                flex: '1 1 100%',
                                p: {
                                    xs: '10px',
                                    sm: '10px',
                                    lg: '10px',
                                },
                                color: '#464646',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '20px'
                            }} variant="h4" id="" fontWeight="500" component="div">
                                Screening tests
                            </Typography>

                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    m: 0
                                }}
                                className="dietGrid"
                                style={{ marginTop: '10px' }}
                            >
                                {screeningTestsOptions.map((item, i) =>
                                    <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                                        <FormControlLabel
                                            control={
                                                <CustomCheckbox
                                                    checked={screeningTestsValues[item.key]}
                                                    onChange={(e) => {
                                                        setScreeningTestsValues({ ...screeningTestsValues, [item.key]: e.target.checked })
                                                    }}
                                                    name="checkedA"
                                                    color="primary"
                                                />
                                            }
                                            label={item.label}
                                        />
                                    </Grid>)}
                            </Grid>
                            {/*------------ Screening tests ------------ */}

                            {/*------------ Age group ------------ */}
                            <Typography sx={{
                                flex: '1 1 100%',
                                p: {
                                    xs: '10px',
                                    sm: '10px',
                                    lg: '10px',
                                },
                                color: '#464646',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '20px'
                            }} variant="h4" id="" fontWeight="500" component="div">
                                Age group
                            </Typography>

                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    m: 0
                                }}
                                className="dietGrid"
                                style={{ marginTop: '10px' }}
                            >
                                {ageGroupOptions.map((item, i) =>
                                    <Grid item lg={2.2} md={6} sm={12} xs={12} key={i}>
                                        <FormControlLabel
                                            control={
                                                <CustomCheckbox
                                                    checked={ageGroup[item.key]}
                                                    onChange={(e) => {
                                                        setAgeGroup({ ...ageGroup, [item.key]: e.target.checked })
                                                    }}
                                                    name="checkedA"
                                                    color="primary"
                                                />
                                            }
                                            label={item.label}
                                        />
                                    </Grid>)}
                            </Grid>
                            {/*------------ Age group ------------ */}

                        </CardContent>

                        <div className="d-flex" style={{ height: '50px', justifyContent: 'center', alignItems: 'center' }}>

                            <Button style={{ backgroundColor: "#9E9E9E" }} variant="contained" color="primary" onClick={() => {
                                SetIsShowTable(false);
                                onClickClearAll();
                                navigate('/reports');
                            }} >
                                Cancel
                            </Button>

                            {
                                isShowTable === true ?
                                    <Button style={{ marginLeft: 5 }} variant="contained" color="primary" onClick={() => {
                                        SetIsShowTable(false);
                                        onClickClearAll();
                                    }} >
                                        Clear all
                                    </Button> :
                                    <Button style={{ marginLeft: 5 }} variant="contained" color="primary" onClick={() => {
                                        onClickFilter();
                                    }} >
                                        Filter
                                    </Button>
                            }
                        </div>
                    </Card > :

                    <Card
                        sx={{
                            p: {
                                xs: '10px',
                                sm: '10px',
                                lg: '20px',
                            },
                            width: '100%'
                        }}
                    >
                        <CardContent>

                            {
                                selectfilterValue.value === "firstVisit" &&
                                <>
                                    <Typography sx={{
                                        flex: '1 1 100%',
                                        p: {
                                            xs: '10px',
                                            sm: '10px',
                                            lg: '10px',
                                        },
                                        color: '#464646',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '20px'
                                    }} variant="h4" id="" fontWeight="500" component="div">
                                        Blood investigations in Pregnancy
                                    </Typography>

                                    <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                            m: 0
                                        }}
                                        className="dietGrid"
                                        style={{ marginTop: '10px' }}
                                    >
                                        {bloodInvestigationInPregnancy.map((item, i) =>
                                            <Grid item lg={2} md={2} sm={2} xs={2} key={i}>
                                                <FormControlLabel
                                                    control={
                                                        <CustomCheckbox
                                                            checked={bloodInvestigationInPregnancyValues[item.key]}
                                                            onChange={(e) => {
                                                                setBloodInvestigationInPregnancyValues({ ...bloodInvestigationInPregnancyValues, [item.key]: e.target.checked })
                                                            }}
                                                            name="checkedA"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={item.label}
                                                />
                                            </Grid>)}
                                    </Grid>
                                </>

                            }

                            {
                                selectfilterValue.value === "firstVisit" &&
                                <>
                                    <Typography sx={{
                                        flex: '1 1 100%',
                                        p: {
                                            xs: '10px',
                                            sm: '10px',
                                            lg: '10px',
                                        },
                                        color: '#464646',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '20px'
                                    }} variant="h4" id="" fontWeight="200" component="div">
                                        Patient Blood Group
                                    </Typography>

                                    <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                            m: 0
                                        }}
                                        className="dietGrid"
                                        style={{ marginTop: '10px' }}
                                    >
                                        {patientBloodGroup.map((item, i) =>
                                            <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                                                <FormControlLabel
                                                    control={
                                                        <CustomCheckbox
                                                            checked={patientBloodGroupValues[item.key]}
                                                            onChange={(e) => {
                                                                setPatientBloodGroupValues({ ...patientBloodGroupValues, [item.key]: e.target.checked })
                                                            }}
                                                            name="checkedA"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={item.label}
                                                />
                                            </Grid>)}
                                    </Grid>
                                </>

                            }


                            {
                                selectfilterValue.value === "firstVisit" &&
                                <>
                                    <Typography sx={{
                                        flex: '1 1 100%',
                                        p: {
                                            xs: '10px',
                                            sm: '10px',
                                            lg: '10px',
                                        },
                                        color: '#464646',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '20px'
                                    }} variant="h4" id="" fontWeight="500" component="div">
                                        Mode of delivery
                                    </Typography>

                                    <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                            m: 0
                                        }}
                                        className="dietGrid"
                                        style={{ marginTop: '10px' }}
                                    >
                                        {modeOfDelivery.map((item, i) =>
                                            <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                                                <FormControlLabel
                                                    control={
                                                        <CustomCheckbox
                                                            checked={modeOfDeliveryValues[item.key]}
                                                            onChange={(e) => {
                                                                setModeOfDeliveryValues({ ...modeOfDeliveryValues, [item.key]: e.target.checked })
                                                            }}
                                                            name="checkedA"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={item.label}
                                                />
                                            </Grid>)}
                                    </Grid>
                                </>
                            }

                            {
                                selectfilterValue.value === "firstVisit" &&
                                <>
                                    <Typography sx={{
                                        flex: '1 1 100%',
                                        p: {
                                            xs: '10px',
                                            sm: '10px',
                                            lg: '10px',
                                        },
                                        color: '#464646',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '20px'
                                    }} variant="h4" id="" fontWeight="500" component="div">
                                        Indication for LSCS
                                    </Typography>

                                    <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                            m: 0
                                        }}
                                        className="dietGrid"
                                        style={{ marginTop: '10px' }}
                                    >
                                        {indicationsOfLscs.map((item, i) =>
                                            <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                                                <FormControlLabel
                                                    control={
                                                        <CustomCheckbox
                                                            checked={indicationsOfLscsValues[item.key]}
                                                            onChange={(e) => {
                                                                setIndicationsOfLscsValues({ ...indicationsOfLscsValues, [item.key]: e.target.checked })
                                                            }}
                                                            name="checkedA"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={item.label}
                                                />
                                            </Grid>)}
                                    </Grid>
                                </>

                            }

                            <Typography sx={{
                                flex: '1 1 100%',
                                p: {
                                    xs: '10px',
                                    sm: '10px',
                                    lg: '10px',
                                },
                                color: '#464646',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '20px'
                            }} variant="h4" id="" fontWeight="500" component="div">
                                Diet
                            </Typography>

                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    m: 0
                                }}
                                className="dietGrid"
                                style={{ marginTop: '10px' }}
                            >
                                {diet.map((item, i) =>
                                    <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                                        <FormControlLabel
                                            control={
                                                <CustomCheckbox
                                                    checked={dietValues[item.key]}
                                                    onChange={(e) => {
                                                        setDietValues({ ...dietValues, [item.key]: e.target.checked })
                                                    }}
                                                    name="checkedA"
                                                    color="primary"
                                                />
                                            }
                                            label={item.label}
                                        />
                                    </Grid>)}
                            </Grid>

                            <Typography sx={{
                                flex: '1 1 100%',
                                p: {
                                    xs: '10px',
                                    sm: '10px',
                                    lg: '10px',
                                },
                                color: '#464646',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '20px'
                            }} variant="h4" id="" fontWeight="500" component="div">
                                Dental Care
                            </Typography>

                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    m: 0
                                }}
                                className="dietGrid"
                                style={{ marginTop: '10px' }}
                            >
                                {dentalCare.map((item, i) =>
                                    <Grid item lg={2.2} md={6} sm={12} xs={12} key={i}>
                                        <FormControlLabel
                                            control={
                                                <CustomCheckbox
                                                    checked={dentalCareValues[item.key]}
                                                    onChange={(e) => {
                                                        setDentalCareValues({ ...dentalCareValues, [item.key]: e.target.checked })
                                                    }}
                                                    name="checkedA"
                                                    color="primary"
                                                />
                                            }
                                            label={item.label}
                                        />
                                    </Grid>)}
                            </Grid>


                        </CardContent>

                        <div className="d-flex" style={{ height: '50px', justifyContent: 'center', alignItems: 'center' }}>

                            <Button style={{ backgroundColor: "#9E9E9E" }} variant="contained" color="primary" onClick={() => {
                                SetIsShowTable(false);
                                onClickClearAll();
                                navigate('/reports');
                            }} >
                                Cancel
                            </Button>

                            {
                                isShowTable === true ?
                                    <Button style={{ marginLeft: 5 }} variant="contained" color="primary" onClick={() => {
                                        SetIsShowTable(false);
                                        onClickClearAll();
                                    }} >
                                        Clear all
                                    </Button> :
                                    <Button style={{ marginLeft: 5 }} variant="contained" color="primary" onClick={() => {
                                        onClickFilter();
                                    }} >
                                        Filter
                                    </Button>
                            }
                        </div>
                    </Card >
            }



            {
                isShowTable === true &&
                <Card
                    sx={{
                        p: {
                            xs: '10px',
                            sm: '10px',
                            lg: '20px',
                        },
                        width: '100%'
                    }}
                >
                    <CardContent>
                        <CustomFilterList getPageInfo={getPageInfoFun} />
                    </CardContent>
                </Card >
            }

        </div>
    </PageContainer >
}

export default CustomReports