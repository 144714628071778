import {
    GET_ACTIVE_USER,
    GET_PENDING_USER,
    GET_INACTIVE_USER,
    GET_DOCTORS_LIST
} from '../constants';

const INIT_STATE = {
    activeUser: [],
    pendingUser: [],
    inactiveUser: [],
    doctorList: []
};

const UsersReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ACTIVE_USER:
            return {
                ...state,
                activeUser: action.userList,
            };
        case GET_PENDING_USER:
            return {
                ...state,
                pendingUser: action.userList,
            };
        case GET_INACTIVE_USER:
            return {
                ...state,
                inactiveUser: action.userList,
            };
        case GET_DOCTORS_LIST:
            return {
                ...state,
                doctorList: action.data
            }
        default:
            return state;
    }
};

export default UsersReducer;
