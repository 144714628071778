import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Typography, Toolbar, Tabs, Tab, Box } from '@mui/material';
import PropTypes from 'prop-types';

import PageContainer from "../container/PageContainer";
import UserList from "./UserList";
import { getUserList } from "../../redux/users/AsyncActions";
import { useDispatch, useSelector } from "react-redux";
import './Users.css'
import ChooseHospital from "../Hospitals/ChooseHospital";
import { showNotificationAction } from "../../redux/pushnotification/Action";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
};
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};


const PatientDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const hospitalSelectedState = useSelector((state) => state.HospitalReducer.hospitalSelected)
    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)

    const [value, setValue] = React.useState(0);


    useEffect(() => {
        if (userinfo?.role === 'user') {
            navigate(-1)
        }
    }, [userinfo, navigate])

    const getUserHandler = async (data) => {
        let result = await dispatch(getUserList(data))
        if (result && result.data) {

        } else {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
        }

    }

    useEffect(() => {
        (async () => {
            if (userinfo?.role === 'admin') {
                if (hospitalSelectedState?.hospitalId)
                    getUserHandler(hospitalSelectedState)
            } else {
                getUserHandler()

            }
        })()
    }, [hospitalSelectedState, userinfo])


    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    return <>
        <PageContainer title="Users" description="this is view Users page">
            {/* <Breadcrumb title={""} items={BCrumb} /> */}
            <div className="patientDetailPage UsersPage" style={{ gap: 0 }}>
                <Toolbar
                    sx={{
                        pl: { sm: 3 },
                        pr: { xs: 2, sm: 2 },
                        // ...(numSelected > 0 && {
                        bgcolor: (theme) => '#1990FC',
                        // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                        // }),
                        borderRadius: '4px 4px 0 0',
                        boxShadow: 'none',
                        width: '100%'
                    }}
                >
                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="newVisitTitle" component="div" fontSize="20px" >
                        Users
                    </Typography>
                    {userinfo?.role === 'admin' && <ChooseHospital />}
                </Toolbar>
                <Box sx={{
                    width: '100%',
                    p: {
                        xs: '20px',
                        sm: '35px',
                        lg: '35px',
                    },
                    pt: {
                        xs: '10px',
                        sm: '10px',
                        lg: '10px',
                    }
                }}
                    className="newPatientCardBox"
                >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            textColor="secondary"
                            allowScrollButtonsMobile
                            scrollButtons
                            indicatorColor="secondary"
                        >
                            <Tab
                                sx={{
                                    textTransform: 'capitalize',
                                }}
                                label="Active users"
                                {...a11yProps(0)}
                            // onClick={() => scrollDown(newVaccineRef)}
                            />
                            <Tab
                                sx={{
                                    textTransform: 'capitalize',
                                }}
                                label="Pending users"
                                {...a11yProps(1)}
                                onClick={() => {
                                    // getHistory()
                                    // scrollDown(historyRef)
                                }}
                            />
                            <Tab
                                sx={{
                                    textTransform: 'capitalize',
                                }}
                                label="Inactive Users"
                                {...a11yProps(2)}
                                onClick={() => {
                                    // getHistory()
                                    // scrollDown(historyRef)
                                }}
                            />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0} component="div">
                        <UserList type="active" />
                    </TabPanel>
                    <TabPanel value={value} index={1} component="div">
                        <UserList type="pending" />
                    </TabPanel>
                    <TabPanel value={value} index={2} component="div">
                        <UserList type="inactive" />
                    </TabPanel>
                </Box>
            </div>
        </PageContainer>
    </>
}

export default PatientDetail