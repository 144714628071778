import { useEffect, Suspense, useMemo, useState } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import FeatherIcon from 'feather-icons-react';

import {
  Card,
  CardContent,
  Typography,
  TableHead,
  Box,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableFooter,
  IconButton,
  Toolbar,
  Collapse,
  Tooltip,
} from '@mui/material';
import CustomTextField from '../../forms/custom-elements/CustomTextField';
import {
  deleteVisit,
  getPastVisits,
} from '../../../redux/patients/AsyncActions';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../views/spinner/Spinner';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment/moment';
import { showNotificationAction } from '../../../redux/pushnotification/Action';
import PastVisitIcon from '../../../assets/icons/PastVisitIcon.png';
import { showConfirmationModalAction } from '../../../redux/confirmationModal/ActionConfirmationModal';
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  KeyboardArrowDown,
  KeyboardArrowUp,
  UnfoldMore,
} from '@mui/icons-material';
import FilteredDateRange from './FilteredDateRange';
import FilteredRecordedBy from './FilteredRecordedBy';
import axios from '../../../api/axios';
import FilteredLastUpdatedBy from './FilteredLastUpdatedBy';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const PastVisits = () => {
  const [allHospitalUsers, setAllHospitalUsers] = useState([]);
  const [recordedBy, setRecordedBy] = useState('');
  const [lastUpdatedBy, setLastUpdatedBy] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [openFilterDateRange, setOpenFilterDateRange] = useState(false);
  const [openRecordFilterModel, setOpenRecordFilterModel] = useState(false);
  const [openLastUpdateFilterModel, setOpenLastUpdateFilterModel] =
    useState(false);

  const { state } = useLocation();
  const { id, firstName, lastName, dob } = state ? state : {};

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.patientReducer.pastVisits);

  const userinfo = useSelector((state) => state.AuthenticationReducer.userinfo);
  const rootRoute = useMemo(() => {
    if (userinfo && userinfo.role === 'admin') {
      return '/admin';
    } else {
      return '';
    }
  }, [userinfo]);

  useEffect(() => {
    getPastVisitsHandler();
  }, [startDate, endDate, recordedBy, lastUpdatedBy]);
  useEffect(() => {
    axios
      .get(`user/hospital/all`)
      .then((res) => {
        setAllHospitalUsers(res.data.data.activeUser);
      })
      .catch((err) => console.log(err));
  }, []);

  const getPastVisitsHandler = () => {
    dispatch(
      getPastVisits({ id, startDate, endDate, recordedBy, lastUpdatedBy })
    ).then((result) => {
      if (result && result.code === 200) {
      } else if (result && result.status === 404) {
      } else if (result && result.status === 401) {
        dispatch(
          showNotificationAction(true, {
            type: 'error',
            content: 'Token expired.',
          })
        );
        setTimeout(() => {
          dispatch(showNotificationAction(false, { type: '', content: '' }));
          navigate('/auth/login');
        }, 3000);
      } else if (
        result &&
        result.data &&
        result.data.message &&
        typeof result.data.message === 'string'
      ) {
        dispatch(
          showNotificationAction(true, {
            type: 'error',
            content: result.data.message,
          })
        );
      } else {
        dispatch(
          showNotificationAction(true, {
            type: 'error',
            content: 'Something went wrong.',
          })
        );
      }
    });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteVisitHandler = async (visitId) => {
    dispatch(
      showConfirmationModalAction(true, {
        modalContent: 'Are you sure you want to delete this visit?',
        button1: true,
        button2: true,
        buttonText1: 'Delete',
        buttonText2: 'Cancel',
        buttonAction1: async () => {
          let data = { visitId, patientId: id };
          let result = await dispatch(deleteVisit(data));
          if (result.status === true) {
            dispatch(
              showNotificationAction(true, {
                type: 'success',
                content: 'Visit deleted successfully.',
              })
            );
            getPastVisitsHandler();
            setTimeout(() => {
              dispatch(
                showNotificationAction(false, {
                  type: '',
                  content: '',
                  origin: 'deleteVisit true',
                })
              );
            }, 3000);
          } else {
            dispatch(
              showNotificationAction(true, {
                type: 'error',
                content: 'deleteVisit Something went wrong.',
              })
            );
            setTimeout(() => {
              dispatch(
                showNotificationAction(false, {
                  type: '',
                  content: '',
                  origin: 'Something went wrong remove.',
                })
              );
            }, 3000);
          }
          dispatch(
            showConfirmationModalAction(false, {
              modalContent: '',
              button1: false,
              button2: false,
              buttonText1: '',
              buttonText2: '',
              buttonAction1: () => {},
              buttonAction2: () => {},
              origin: 'Deleted visit',
            })
          );
        },
        buttonAction2: () => {
          dispatch(
            showConfirmationModalAction(false, {
              modalContent: '',
              button1: false,
              button2: false,
              buttonText1: '',
              buttonText2: '',
              buttonAction1: () => {},
              buttonAction2: () => {},
              origin: 'Cancel Delete visit',
            })
          );
        },
        origin: 'Deleted visit',
      })
    );
  };

  const getAge = (dobValue, visitDate) => {
    if (dobValue !== undefined) {
      let year = Math.trunc(
        moment(visitDate).diff(moment(dobValue, 'YYYY-MM-DD'), 'months') / 12
      );
      let month =
        moment(visitDate).diff(moment(dobValue, 'YYYY-MM-DD'), 'months') % 12;
      let agrStr = [];
      if (year && year > 1) {
        agrStr.push(' ' + year + ' Years');
      } else if (year === 1) {
        agrStr.push(' ' + year + ' Year');
      } else {
      }
      if (month && month > 1) {
        agrStr.push(' ' + month + ' Months');
      } else if (month === 1) {
        agrStr.push(' ' + month + ' Month');
      } else {
      }
      if (month === 0 && year === 0) {
        let days = moment(visitDate).diff(
          moment(dobValue, 'YYYY-MM-DD'),
          'days'
        );
        if (days > 1) {
          agrStr.push(' ' + days + ' Days');
        } else {
          agrStr.push(' 1 Day');
        }
      }
      return agrStr.join(',');
    } else {
      return '';
    }
  };

  const formatString = (str) => {
    if (str)
      return str
        .replaceAll('&#x2F;', '/')
        .replaceAll('&amp;', '&')
        .replaceAll('&#96;', '`')
        .replaceAll('&quot;', '"')
        .replaceAll('&lt;', '<')
        .replaceAll('&gt;', '>')
        .replaceAll('&quot;', '"')
        .replaceAll('&#x27;', "'")
        .replaceAll('&#x2F;', '/')
        .replaceAll('&#x5C;', '\\');
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow>
          <TableCell
            sx={{
              borderBottom: '0',
              width: '50px',
            }}
          >
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell
            sx={{
              borderBottom: '0',
            }}
          >
            <Tooltip title='Detail'>
              <Typography>
                <span
                  onClick={() => {
                    navigate(rootRoute + '/patient/visitDetail', {
                      state: {
                        id: id,
                        firstName: firstName,
                        lastName: lastName,
                        dob: dob,
                        row,
                      },
                    });
                  }}
                >
                  <svg
                    width='20'
                    height='21'
                    viewBox='0 0 20 21'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M5 6.33333V4.66667C5 4.22464 5.17559 3.80072 5.48816 3.48816C5.80072 3.17559 6.22464 3 6.66667 3H15.8333C16.2754 3 16.6993 3.17559 17.0118 3.48816C17.3244 3.80072 17.5 4.22464 17.5 4.66667V16.3333C17.5 16.7754 17.3244 17.1993 17.0118 17.5118C16.6993 17.8244 16.2754 18 15.8333 18H6.66667C6.22464 18 5.80072 17.8244 5.48816 17.5118C5.17559 17.1993 5 16.7754 5 16.3333V14.6667H6.66667V16.3333H15.8333V4.66667H6.66667V6.33333H5ZM9.58333 5.91667L8.405 7.095L10.9767 9.66667H2.5V11.3333H10.9767L8.405 13.905L9.58333 15.0833L14.1667 10.5L9.58333 5.91667Z'
                      fill='#1990FC'
                    />
                  </svg>
                </span>
              </Typography>
            </Tooltip>
          </TableCell>
          <TableCell
            sx={{
              borderBottom: '0',
            }}
          >
            <Typography variant='h5' fontWeight='400'>
              {row.visitDate && row.visitDate !== ''
                ? moment(row.visitDate).format('DD-MMM-YYYY')
                : '-'}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              borderBottom: '0',
            }}
          >
            <Typography variant='h5' fontWeight='400'>
              {row.dob && row.dob !== '' ? getAge(row.dob, row.visitDate) : '-'}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h5' fontWeight='400'>
              {moment(row.createdAt).format('DD-MM-YYYY')}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h5' fontWeight='400'>
              {row.recordedBy}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='h5' fontWeight='400'>
              {row.lastUpdatedBy}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              borderBottom: '0',
            }}
          >
            <Typography variant='h5' fontWeight='400'>
              {row.currentIssues && row.currentIssues !== ''
                ? formatString(row.currentIssues)
                : '-'}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              borderBottom: '0',
            }}
          >
            <Typography variant='h5' fontWeight='400'>
              {row.abnormalFindings && row.abnormalFindings !== ''
                ? formatString(row.abnormalFindings)
                : '-'}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              borderBottom: '0',
            }}
          >
            <Typography variant='h5' fontWeight='400'>
              {row.weight && row.weight !== '' ? row.weight : '-'}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              borderBottom: '0',
            }}
          >
            <Typography variant='h5' fontWeight='400'>
              {row.diagnosisInformant && row.diagnosisInformant !== ''
                ? formatString(row.diagnosisInformant)
                : '-'}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              borderBottom: '0',
            }}
          >
            <Typography variant='h5' fontWeight='400'>
              {row.planInformant && row.planInformant !== ''
                ? formatString(row.planInformant)
                : '-'}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              borderBottom: '0',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Tooltip title='Edit visit'>
                <Typography>
                  {userinfo?.role !== 'admin' && (
                    <FeatherIcon
                      icon={'edit'}
                      width='20'
                      onClick={() => {
                        navigate(rootRoute + '/patient/updatevisit', {
                          state: {
                            id: id,
                            firstName: firstName,
                            lastName: lastName,
                            visitId: row.visitId,
                            dob: dob,
                          },
                        });
                      }}
                    />
                  )}{' '}
                  &nbsp;
                </Typography>
              </Tooltip>
              <Tooltip title='Delete visit'>
                <Typography>
                  {(userinfo?.role === 'admin' ||
                    userinfo?.role === 'hospital admin' ||
                    userinfo?.role === 'user') && (
                    <FeatherIcon
                      icon={'delete'}
                      width='20'
                      onClick={() => {
                        deleteVisitHandler(row.visitId);
                      }}
                    />
                  )}
                </Typography>
              </Tooltip>
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          {/* <TableCell  sx={{ padding: 0 }}></TableCell> */}
          <TableCell sx={{ padding: 0 }} colSpan={columns ? columns.length : 9}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box margin={0}>
                <Typography
                  gutterBottom
                  variant='h5'
                  fontWeight='400'
                  sx={{
                    // mt: 2,
                    // backgroundColor: (theme) => theme.palette.grey.A700,
                    p: '5px 0px',
                    ml: 10,
                    color: (theme) =>
                      `${
                        theme.palette.mode === 'dark'
                          ? theme.palette.grey.A200
                          : 'rgba(0, 0, 0, 0.87)'
                      }`,
                  }}
                >
                  Recorded by:{' '}
                  {row.recordedBy && row.recordedBy !== ''
                    ? row.recordedBy
                    : '-'}
                </Typography>
                <Typography
                  gutterBottom
                  variant='h5'
                  fontWeight='400'
                  sx={{
                    // mt: 2,
                    // backgroundColor: (theme) => theme.palette.grey.A700,
                    p: '5px 0px',
                    ml: 10,
                    color: (theme) =>
                      `${
                        theme.palette.mode === 'dark'
                          ? theme.palette.grey.A200
                          : 'rgba(0, 0, 0, 0.87)'
                      }`,
                  }}
                >
                  Last updated by:{' '}
                  {row.lastUpdatedBy && row.lastUpdatedBy !== ''
                    ? row.lastUpdatedBy
                    : '-'}
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const columns = useMemo(() => {
    if (userinfo?.role === 'admin' || userinfo?.role === 'hospital admin') {
      return [
        '',
        'Details',
        'Visit Date',
        'Age',
        'Created at',
        'Recorded by',
        'Last updated by',
        'Current Issues',
        'Abnormal Examination Findings',
        'Weight',
        'Issues/ Diagnosis',
        'Plan',
        'Action',
      ];
    } else {
      return [
        '',
        'Details',
        'Visit Date',
        'Age',
        'Created at',
        'Recorded by',
        'Last updated by',
        'Current Issues',
        'Abnormal Examination Findings',
        'Weight',
        'Issues/ Diagnosis',
        'Plan',
        'Action',
      ];
    }
  }, [userinfo]);

  let slicedRows = [];
  let newRows =
    rowsPerPage > 0
      ? rows.filter(
          (f) =>
            f.recordedBy &&
            (f.recordedBy.toUpperCase().includes(search.toUpperCase()) ||
              f.lastUpdatedBy?.toUpperCase().includes(search.toUpperCase()))
        )
      : rows;
  if (newRows.length < rowsPerPage) {
    slicedRows = newRows.slice(0, page * rowsPerPage + rowsPerPage);
  } else {
    slicedRows = newRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }

  let SortByIcon = <UnfoldMore />;

  const toggleFilterRangeOpen = () => {
    setOpenFilterDateRange((prevS) => !prevS);
  };
  const updateFilterDate = (start, end) => {
    if (!start && !end) {
      setStartDate('');
      setEndDate('');
      return;
    }
    setStartDate(moment(start).format('YYYY/MM/DD'));
    setEndDate(moment(end).format('YYYY/MM/DD'));
  };
  const toggleFilterRecordBy = () => {
    setOpenRecordFilterModel((prevS) => !prevS);
  };
  const updateFilterRecordBy = (value) => {
    setRecordedBy(value);
  };
  const toggleFilterLastUpdateBy = () => {
    setOpenLastUpdateFilterModel((prevS) => !prevS);
  };
  const updateFilterLastUpdateBy = (value) => {
    setLastUpdatedBy(value);
  };

  return (
    <>
      <div className='pastVisits' style={{ width: '100%' }}>
        {/* <Spinner /> */}
        <Suspense fallback={<Spinner />}>
          <Card>
            <CardContent>
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  // ...(numSelected > 0 && {
                  bgcolor: (theme) => '#1990FC',
                  // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                  // }),
                }}
              >
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  variant='h6'
                  id='newvisitTitle'
                  component='div'
                >
                  <Typography
                    sx={{
                      mr: { lg: 4, xs: 1 },
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                    fontSize='20px'
                  >
                    <img
                      src={PastVisitIcon}
                      className='tabIcon'
                      alt='logoImg'
                    />
                    Past Visits:
                  </Typography>
                  <Tooltip title='Search visit by Recorded / Updated by name'>
                    <span>
                      <CustomTextField
                        id='search'
                        placeholder='Enter text'
                        variant='outlined'
                        //  fullWidth
                        size='small'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </span>
                  </Tooltip>
                  <FeatherIcon
                    icon={'x'}
                    style={{ marginLeft: '-30px', zIndex: 2 }}
                    onClick={() => setSearch('')}
                  />{' '}
                  &nbsp;
                </Typography>
              </Toolbar>
              <Box
                sx={{
                  overflow: {
                    xs: 'auto',
                    sm: 'auto',
                    md: 'auto',
                  },
                }}
              >
                <Table
                  aria-label='custom pagination table'
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((el, i) => (
                        <TableCell
                          sx={{
                            cursor:
                              el === 'Created at' ||
                              el === 'Recorded by' ||
                              el === 'Last updated by'
                                ? 'pointer'
                                : 'default',
                          }}
                          key={i}
                          onClick={() => {
                            if (el == 'Created at') {
                              toggleFilterRangeOpen();
                            } else if (el === 'Recorded by') {
                              toggleFilterRecordBy();
                            } else if (el === 'Last updated by') {
                              toggleFilterLastUpdateBy();
                            }
                          }}
                        >
                          <Typography
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            variant='div'
                          >
                            <Typography variant='h5' fontWeight='600'>
                              {el}
                              {(el === 'Created at' ||
                                el === 'Recorded by' ||
                                el === 'Last updated by') &&
                                SortByIcon}
                            </Typography>
                            {el === 'Recorded by' && recordedBy && (
                              <em style={{ marginTop: '-10px', color: 'gray' }}>
                                <small>({recordedBy})</small>
                              </em>
                            )}
                            {el === 'Last updated by' && lastUpdatedBy && (
                              <em style={{ marginTop: '-10px', color: 'gray' }}>
                                <small>({lastUpdatedBy})</small>
                              </em>
                            )}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {slicedRows.map((row, i) => (
                      <Row key={i} row={row} />
                    ))}
                    {slicedRows.length <= 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={columns ? columns.length : 9}>
                          <Typography
                            variant='h6'
                            fontWeight='600'
                            justifyContent='center'
                          >
                            No data available.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={columns ? columns.length : 9} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          10,
                          25,
                          100,
                          { label: 'All', value: -1 },
                        ]}
                        colSpan={6}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputprops: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Box>
            </CardContent>
          </Card>
        </Suspense>
      </div>
      <FilteredDateRange
        open={openFilterDateRange}
        toggle={toggleFilterRangeOpen}
        updateFilterDate={updateFilterDate}
        currStart={startDate}
        currEnd={endDate}
      />
      <FilteredRecordedBy
        open={openRecordFilterModel}
        toggle={toggleFilterRecordBy}
        updateFilterRecordBy={updateFilterRecordBy}
        currRecordBy={recordedBy}
        users={allHospitalUsers}
      />
      <FilteredLastUpdatedBy
        open={openLastUpdateFilterModel}
        toggle={toggleFilterLastUpdateBy}
        updateFilterLastUpdateBy={updateFilterLastUpdateBy}
        currLastUpdateBy={lastUpdatedBy}
        users={allHospitalUsers}
      />
    </>
  );
};

export default PastVisits;
