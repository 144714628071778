import { FETCH_SIBLINGS_SUCCESS, TOTAL_SIBLING } from '../constants';

const INIT_STATE = {
  siblings: [],
  totalSibling: 0,
};

const SiblingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SIBLINGS_SUCCESS:
      return {
        ...state,
        siblings: action.siblings,
      };

    case TOTAL_SIBLING:
      return {
        ...state,
        totalSibling: action.totalSibling,
      };

    default:
      return state;
  }
};
// export default combineReducers({
//   SiblingReducer
// });
export default SiblingReducer;
