import { Button } from '@mui/material';
import React, { Component } from 'react'
import SignatureCanvas from "react-signature-canvas";
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import './style.css'

class SignatureCanvasComp extends Component {
  state = { trimmedDataURL: null, showDraw: false }
  sigCanvas = {}
  clear = () => {
    this.sigCanvas.clear()
  }
  trim = () => {
    this.setState({
      trimmedDataURL: this.sigCanvas.getTrimmedCanvas()
        .toDataURL('image/png')
    })
    this.props.getImgUrl(this.sigCanvas.getTrimmedCanvas()
      .toDataURL('image/png'))
  }

  render() {
    let { showDraw } = this.state
    return (
      showDraw
        ? <div className="sigContainer">
          <CustomFormLabel>New Sketch</CustomFormLabel>
          <div className="">
            <SignatureCanvas
              ref={(ref) => { this.sigCanvas = ref; }}
              penColor="black"
              dotSize={1}
              backgroundColor="white"
              canvasProps={{ height: 300, className: "sigCanvas" }}
            />
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button variant="contained" className='' color="primary" sx={{ marginRight: '10px', marginTop: '10px' }}
              onClick={this.trim}>
              Save Sketch
            </Button>
            <Button variant="contained" className='' color="primary" sx={{ marginTop: '10px' }} onClick={this.clear}>
              Clear
            </Button>
          </div>
        </div >
        : <Button variant="contained" className='' color="primary" sx={{ marginTop: '10px', float: 'right' }}
          onClick={() => this.setState({ showDraw: true })}>
          Add Sketch
        </Button>
    )

  }
}

export default SignatureCanvasComp;