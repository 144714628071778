import { Suspense, useMemo, useState } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import FeatherIcon from 'feather-icons-react';
import {
    Card,
    CardContent,
    Typography,
    TableHead,
    Box,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TableFooter,
    IconButton,
    Chip,
    Avatar,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    FormControl,
    Button,
    TableContainer,
    Autocomplete,
    TextField
} from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CustomTextField from '../forms/custom-elements/CustomTextField';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../views/spinner/Spinner';
import { deleteuser, getUserList, updateUserAction, updateUserStatus } from '../../redux/users/AsyncActions';
import CustomFormLabel from '../forms/custom-elements/CustomFormLabel';
import { showNotificationAction } from '../../redux/pushnotification/Action'
import { showConfirmationModalAction } from '../../redux/confirmationModal/ActionConfirmationModal';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const UserList = ({ type }) => {
    // const { state } = useLocation();
    // const { id, firstName, lastName, dob, visitId } = state ? state : {};
    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)

    // const navigate = useNavigate()
    const dispatch = useDispatch()
    const activeArr = useSelector((state) => state.UsersReducer.activeUser);
    const pendingArr = useSelector((state) => state.UsersReducer.pendingUser);
    const inactiveArr = useSelector((state) => state.UsersReducer.inactiveUser);
    const rows = useMemo(() => {
        if (type === "active") {
            return activeArr
        } else if (type === "pending") {
            return pendingArr
        } else if (type === 'inactive') {
            return inactiveArr
        }
    }, [activeArr, pendingArr, inactiveArr, type])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [updateUser, setUpdateUser] = useState({})
    const [search] = React.useState("");
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const [openEdit, setOpenEdit] = useState(false)
    const userType = [{ label: 'Doctor' }, { label: 'Receptionist' }]
    const speciality = [{ label: 'Paediatrician' }, { label: 'Neonatologist' }, { label: 'Paediatric Neurologist' }, { label: 'Paediatric Nephrologist' }, { label: 'Gastroenterologist' }, { label: 'Paediatric Surgeon' }, { label: 'Paediatric Neurosurgeon' }, { label: 'Clinical Psychologist' }, { label: 'Dietitian' }, { label: 'Dermatologist' }, { label: 'Cardiologist' }, { label: 'Obstetrician' }]

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const handleClose = () => {
        setOpenEdit(false);
        setUpdateUser({})
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getActionIcon = (tooltipTitle, colortype, iconName) => {
        return <Tooltip title={tooltipTitle}>
            <Avatar
                sx={{
                    backgroundColor: colortype === 'success'
                        ? (theme) => theme.palette.success.main
                        : colortype === 'secondary'
                            ? (theme) => theme.palette.secondary.main
                            : colortype === 'error'
                                ? (theme) => theme.palette.error.main
                                : (theme) => theme.palette.error.main,
                    width: '25px',
                    height: '25px',
                    color: '#fff',
                }}
            >
                <FeatherIcon icon={iconName} width="15px" />
            </Avatar>
        </Tooltip >
    }

    const editUserHandler = (row) => {
        setOpenEdit(true)
        setUpdateUser(row)
    }
    const deleteUserHandler = (row) => {
        let { userId, hospitalId } = row
        if (userId)
            dispatch(showConfirmationModalAction(true, {
                modalContent: "Are you sure you want to delete this user?",
                button1: true,
                button2: true,
                buttonText1: 'Delete',
                buttonText2: 'Cancel',
                buttonAction1: () => {
                    dispatch(deleteuser({
                        "userId": userId
                    }))
                        .then((result) => {
                            if (result?.status === true) {
                                if (userinfo?.role === 'admin') {
                                    getUserHandler({ hospitalId })
                                } else {
                                    getUserHandler()
                                }
                                dispatch(showConfirmationModalAction(false, {
                                    modalContent: "",
                                    button1: false,
                                    button2: false,
                                    buttonText1: '',
                                    buttonText2: '',
                                    buttonAction1: () => { },
                                    buttonAction2: () => { },
                                    origin: "Deleted user."
                                }))
                            } else {
                                dispatch(showNotificationAction(true, { type: 'error', content: 'Failed to delete.' }))
                            }
                        })
                },
                buttonAction2: () => {
                    dispatch(showConfirmationModalAction(false, {
                        modalContent: "",
                        button1: false,
                        button2: false,
                        buttonText1: '',
                        buttonText2: '',
                        buttonAction1: () => { },
                        buttonAction2: () => { },
                        origin: "Cancel delete user."
                    }))
                },
                origin: "delete guardian"
            }))
    }
    const updateUserHandler = async () => {
        let { userId, contactNo, speciality, name, userType, role, hospitalId, hospital } = updateUser
        let data = {
            userId, contactNo, speciality, name, userType, role, hospitalId, hospital, sendNoti: false,
        }
        if (role === 'hospital admin') {
        } else if (userType === 'Receptionist') {
            data.role = userType
        } else {
            data.role = 'user'
        }
        let rowdata = rows.filter(f => f.userId === userId)
        if ((rowdata[0]?.contactNo && rowdata[0].contactNo !== "" && data?.contactNo === "")) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Contact number cannot be removed.' }))

        } else if (contactNo && contactNo.trim().length !== 0 && (contactNo.trim().length > 15 || contactNo.trim().length < 7)) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Contact number should have minimum 7 letter and maximum 15 characters.' }))
        } else {
            let result = await dispatch(updateUserAction(data))
            if (result && result.status === true) {
                handleClose()
                if (userinfo?.role === 'admin') {
                    getUserHandler({ hospitalId })
                } else {
                    getUserHandler()
                }
            }
        }
    }

    const Row = (props) => {
        const { row } = props;
        // const [open, setOpen] = React.useState(false);

        return (
            <>
                <TableRow>
                    <TableCell>
                        <Typography variant="h5" fontWeight="400">
                            {row && row.name !== null && row.name !== '' ? row.name : '-'}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="h5" fontWeight="400">
                            {row && row.email !== null && row.email !== '' ? row.email : '-'}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="h5" fontWeight="400">
                            {row && row.contactNo !== null
                                && row.contactNo
                                !== '' ? row.contactNo
                                : '-'}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="h5" fontWeight="400">
                            {row && row.userType === null
                                ? row.role === "hospital admin"
                                    ? "Hospital Admin"
                                    : '-'
                                : row.userType !== ''
                                    ? row.userType
                                    : '-'
                            }
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="h5" fontWeight="400">
                            {row && row.speciality !== null && row.speciality !== '' ? row.speciality : '-'}
                        </Typography>
                    </TableCell>
                    <TableCell>

                        {row && row.status !== null && row.status !== "" ?
                            <Chip
                                sx={{
                                    backgroundColor:
                                        row.status === 'active'
                                            ? (theme) => theme.palette.success.main
                                            : row.status === 'pending'
                                                ? (theme) => theme.palette.secondary.main
                                                : row.status === 'inactive'
                                                    ? (theme) => theme.palette.error.main
                                                    : '',
                                    color: '#fff',
                                    borderRadius: '6px',
                                    '&.MuiChip-root:hover': {
                                        backgroundColor:
                                            row.status === 'active'
                                                ? (theme) => theme.palette.success.main
                                                : row.status === 'pending'
                                                    ? (theme) => theme.palette.secondary.main
                                                    : row.status === 'inactive'
                                                        ? (theme) => theme.palette.error.main
                                                        : '',
                                    }
                                }}
                                size="small"
                                label={row.status[0].toUpperCase() + row.status.substring(1)}
                                onClick={() => {

                                }}
                            /> : '-'}
                    </TableCell>
                    <TableCell
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Tooltip title='Edit user'>
                            <span>
                                <FeatherIcon icon="edit" style={{ marginRight: '3px' }} onClick={() => { editUserHandler(row) }} />
                            </span>
                        </Tooltip>
                        {userinfo?.role === "admin" && <Tooltip title='Delete user'>
                            <span>
                                <FeatherIcon icon="delete" style={{ marginRight: '6px' }} onClick={() => { deleteUserHandler(row) }} />
                            </span>
                        </Tooltip>}
                        {row && row.status !== null && row.status !== "" && row.role !== "hospital admin" && userinfo.userId !== row.userId ?
                            row.status === 'active'
                                ? <span onClick={() => { changeStatusHandler(row, true, 'inactive') }}>
                                    {getActionIcon('Inactive', 'error', 'x')}
                                </span>
                                : row.status === 'pending'
                                    ? <span style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '5px',
                                    }}>
                                        <span onClick={() => { changeStatusHandler(row, true, 'active') }}>
                                            {getActionIcon('Activate', 'success', 'check')}
                                        </span>
                                        <span onClick={() => { changeStatusHandler(row, false, 'inactive') }}>
                                            {getActionIcon('Inactive', 'error', 'x')}
                                        </span>
                                    </span>
                                    : row.status === 'inactive'
                                        ? <span onClick={() => { changeStatusHandler(row, true, 'active') }}>
                                            {getActionIcon('Activate', 'success', 'check')}
                                        </span>
                                        : ''
                            : ''}
                    </TableCell>
                </TableRow>

            </>
        );
    }

    const changeStatusHandler = async (row, verified, status) => {
        let { userId, hospitalId } = row
        let data = {
            "userId": userId,
            "isVerified": verified,
            "status": status
        }
        let result = await dispatch(updateUserStatus(data))
        if (result?.status === true) {
            if (userinfo?.role === 'admin') {
                getUserHandler({ hospitalId })
            } else {
                getUserHandler()
            }
        } else {

        }
    }
    const getUserHandler = async (data) => {
        let result = await dispatch(getUserList(data))
        if (result && result.data) {

        } else {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
        }
    }

    let slicedRows = []
    let newRows = rowsPerPage > 0
        ? rows.filter(f => f.name && f.name.toUpperCase().includes(search.toUpperCase()))
        : rows
    if (newRows.length < rowsPerPage) {
        slicedRows = newRows.slice(0, page * rowsPerPage + rowsPerPage)
    } else {
        slicedRows = newRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }

    return (<div className="pastVisits" style={{ width: '100%' }}>
        {/* <Spinner /> */}
        <Suspense fallback={<Spinner />}>
            <Card>
                <CardContent>

                    {/* <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                                // ...(numSelected > 0 && {
                                bgcolor: (theme) => '#1990FC'
                                // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                                // }),
                            }}
                        >

                            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="newvisitTitle" component="div">
                                <Typography sx={{ mr: { lg: 4, xs: 1 } }} fontSize="20px" >
                                   Pending
                                </Typography>
                                <CustomTextField
                                    id="search"
                                    placeholder="Enter text"
                                    variant="outlined"
                                    //  fullWidth
                                    size="small"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <FeatherIcon icon={'x'} style={{ marginLeft: '-30px', zIndex: 2 }} onClick={() => setSearch("")} /> &nbsp;
                            </Typography>

                        </Toolbar> */}

                    <Box
                        sx={{
                            overflow: {
                                xs: 'auto',
                                sm: 'auto',
                                md: 'auto',

                            },
                        }}
                    >
                        <TableContainer
                            sx={{
                                maxHeight: { lg: 520, md: 520, sm: 400, xs: 400 },
                            }}
                        >
                            <Table stickyHeader aria-label="sticky table"
                                // aria-label="custom pagination table"
                                sx={{
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h5" fontWeight='600' >Name</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h5" fontWeight='600' >Email</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h5" fontWeight='600' >Contact No</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h5" fontWeight='600' >User Type</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h5" fontWeight='600' >Speciality</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h5" fontWeight='600' >Status</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h5" fontWeight='600' >Action</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {slicedRows.map((row, i) => (
                                        <Row key={i} row={row} />
                                    ))
                                    }
                                    {slicedRows.length <= 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={7} >
                                                <Typography variant="h6" fontWeight="600" justifyContent="center"  >
                                                    No data available.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
                                            colSpan={6}
                                            count={rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputprops: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Box>
                </CardContent>
            </Card>
            <Dialog open={openEdit} onClose={handleClose} fullWidth >
                <DialogContent className='appointmentForm' sx={{ padding: '20px 16px' }}>
                    <Typography sx={{ mb: 2, ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} id="" component="div"  >
                        <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            Update {updateUser.name}
                        </Typography>
                        <FeatherIcon icon="x" style={{
                            width: 20, height: 20, marginLeft: '5px', color: 'white', borderRadius: '50%', background: '#1990FC', padding: '2px'
                        }}
                            onClick={handleClose} />
                    </Typography>
                    <Grid container spacing={0}>
                        {updateUser.role !== 'hospital admin' && <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={6}
                            sx={{
                                background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#EBF5FF'}`,
                                p: 1
                            }}
                        >
                            <CustomFormLabel htmlFor="demo-simple-select">User Type</CustomFormLabel>
                            <Autocomplete
                                disablePortal
                                disabled={!updateUser}
                                value={updateUser ? updateUser.userType : updateUser.userType}
                                onChange={(event, newValue) => {
                                    setUpdateUser({ ...updateUser, userType: newValue.label })
                                }}
                                id="combo-box-demo"
                                options={userType}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField {...params} size="small" placeholder="" aria-label="Select Allergen" />
                                )}
                                sx={{
                                    '& .MuiOutlinedInput-input': {
                                        border: 'none !important'
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        padding: '16.5px 14px !important'
                                    }
                                }}
                            />
                        </Grid>

                        }
                        <Grid
                            item
                            xs={12}
                            sm={updateUser.role !== 'hospital admin' ? 6 : 12}
                            lg={updateUser.role !== 'hospital admin' ? 6 : 12}
                            sx={{
                                background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#EBF5FF'}`,
                                p: 1
                            }}
                        >
                            <CustomFormLabel htmlFor="demo-simple-select">Speciality</CustomFormLabel>
                            <Autocomplete
                                disablePortal
                                disabled={!updateUser}
                                value={updateUser ? updateUser.speciality : updateUser.speciality}
                                onChange={(event, newValue) => {
                                    if (newValue)
                                        setUpdateUser({ ...updateUser, speciality: newValue.label })
                                    else {
                                        setUpdateUser({ ...updateUser, speciality: "" })

                                    }
                                }}
                                id="combo-box-demo"
                                options={speciality}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField {...params} size="small" placeholder="" aria-label="Select Allergen" />
                                )}
                                sx={{
                                    '& .MuiOutlinedInput-input': {
                                        border: 'none !important'
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        padding: '16.5px 14px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>



                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <CustomFormLabel>Contact No. </CustomFormLabel>

                            <CustomTextField
                                type="number"
                                variant="outlined"
                                fullWidth
                                // size="small"
                                placeholder=""
                                disabled={!updateUser}
                                value={updateUser ? updateUser.contactNo : updateUser.contactNo}
                                onChange={(e) => {
                                    setUpdateUser({ ...updateUser, contactNo: e.target.value })
                                }}
                                onWheel={() => document.activeElement.blur()}

                            />
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions className='appointmentFormActions'>

                    <Box>
                        <Button type="button"
                            variant="contained"
                            onClick={handleClose}
                            sx={{
                                mr: '8px',
                                background: "#888888"

                            }}
                        >Cancel</Button>


                        <Button variant="contained" className='registerButton' color="primary"
                            onClick={updateUserHandler}>
                            Update
                        </Button>

                    </Box>
                </DialogActions>
            </Dialog >
        </Suspense>
    </div>
    );
};

export default UserList;
