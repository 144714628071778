// Layout and colors CONSTANTS
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const BLUE_THEME = 'BLUE_THEME';
export const GREEN_THEME = 'GREEN_THEME';
export const RED_THEME = 'RED_THEME';
export const BLACK_THEME = 'BLACK_THEME';
export const PURPLE_THEME = 'PURPLE_THEME';
export const INDIGO_THEME = 'INDIGO_THEME';
export const ORANGE_THEME = 'ORANGE_THEME';

// root reducer constants
export const ON_APP_LOAD = 'ON_APP_LOAD';
export const RESET_STATE = 'RESET_STATE';

// NOTES CONSTANTS
export const SELECTED_NOTES = 'SELECTED_NOTES';
export const SEARCH_NOTES = 'SEARCH_NOTES';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const ADD_NOTE = 'ADD_NOTE';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';

// EMAIL CONSTANTS
export const STAR_EMAIL = 'STAR_EMAIL';
export const IMPORTANT_EMAIL = 'IMPORTANT_EMAIL';
export const OPEN_EMAIL = 'OPEN_EMAIL';
export const TRASH_EMAIL = 'TRASH_EMAIL';
export const ASSIGN_FOLDER = 'ASSIGN_FOLDER';
export const ASSIGN_LABEL = 'ASSIGN_LABEL';
export const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL';
export const FILTER_EMAIL = 'FILTER_EMAIL';
export const SET_EMAIL_VISIBILITY_FILTER = 'SET_EMAIL_VISIBILITY_FILTER';

// PATIENTS CONSTANTS
export const SELECTED_PATIENTS = 'SELECTED_PATIENTS';
export const SEARCH_PATIENTS = 'SEARCH_PATIENTS';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const DELETE_PATIENT = 'DELETE_PATIENT';
export const ADD_PATIENT = 'ADD_PATIENT';
export const FETCH_PATIENTS_SUCCESS = 'FETCH_PATIENTS_SUCCESS';
export const EDITED_PATIENT_LIST = 'EDITED_PATIENT_LIST';
export const TOTAL_PATIENT = 'TOTAL_PATIENT';
export const MEDICAL_SUMMARY = 'MEDICAL_SUMMARY';
export const PATIENT_LIST = 'PATIENT_LIST';
export const BATCH_LIST = 'BATCH_LIST';
export const EXAMINATION_HISTORY = 'EXAMINATION_HISTORY';
export const TAB_NO = 'TAB_NO';
export const MOBILEVISITSIDEBAROPEN = 'MOBILEVISITSIDEBAROPEN';
export const PAST_VISITS = 'PAST_VISITS';
export const LAST_VISIT_ID = 'LAST_VISIT_ID';
export const ADD_VISITS = 'ADD_VISITS';
export const VACCINE_DOSE_LIST = 'VACCINE_DOSE_LIST';
export const VACCINE_HISTORY_BY_AGE = 'VACCINE_HISTORY_BY_AGE';

// SIBLINGS CONSTANTS
export const SELECTED_SIBLINGS = 'SELECTED_SIBLINGS';
export const SEARCH_SIBLINGS = 'SEARCH_SIBLINGS';
export const UPDATE_SIBLING = 'UPDATE_SIBLING';
export const DELETE_SIBLING = 'DELETE_SIBLING';
export const ADD_SIBLING = 'ADD_SIBLING';
export const FETCH_SIBLINGS_SUCCESS = 'FETCH_SIBLINGS_SUCCESS';
export const EDITED_SIBLING_LIST = 'EDITED_SIBLING_LIST';
export const TOTAL_SIBLING = 'TOTAL_SIBLING';

export const ADD_PATIENT_EMERGENCY_CONTACT = 'ADD_PATIENT_EMERGENCY_CONTACT';
export const DELETE_PATIENT_EMERGENCY_CONTACT = 'DELETE_PATIENT_EMERGENCY_CONTACT';
export const GET_PATIENT_EMERGENCY_CONTACT = 'GET_PATIENT_EMERGENCY_CONTACT';

export const SAVE_OBSERVATION = 'SAVE_OBSERVATION';
export const SAVE_ALLERGY = 'SAVE_ALLERGY';
export const SAVE_VACCINATION = 'SAVE_VACCINATION';
export const SAVE_EXAMINATION = 'SAVE_EXAMINATION';
export const SAVE_GROWTH = 'SAVE_GROWTH';
export const SAVE_DOCUMENTUPLOAD = 'SAVE_DOCUMENTUPLOAD';
export const SAVED_DOCUMENTUPLOAD = 'SAVED_DOCUMENTUPLOAD';
export const SAVE_DIAGNOSIS = 'SAVE_DIAGNOSIS';

// Push Notification Constants
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

// modal message
export const MODAL_MESSAGE = 'MODAL_MESSAGE';

// spinner
export const SHOW_SPINNER = 'SHOW_SPINNER';

// login
export const USER_LOGGED_IN = 'USER_LOGGED_IN';

// users
export const GET_ACTIVE_USER = 'GET_ACTIVE_USER';
export const GET_PENDING_USER = 'GET_PENDING_USER';
export const GET_INACTIVE_USER = 'GET_INACTIVE_USER';
export const GET_DOCTORS_LIST = 'GET_DOCTORS_LIST';

// Appointment
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const APPOINTMENTLIST = 'APPOINTMENTLIST';

// Dashboard
export const GET_DASHBOARD_COUNT = 'GET_DASHBOARD_COUNT';
export const GET_UPCOMING_APPOINTMENTS = 'GET_UPCOMING_APPOINTMENTS';
export const MONTHWISE_APPOINTMENT = 'MONTHWISE_APPOINTMENT';

// Hospital
export const HOSPITALLIST = 'HOSPITALLIST';
export const HOSPITALSELECTED = 'HOSPITALSELECTED';

// notification
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const NOTIFICATION_COUNT = 'NOTIFICATION_COUNT';

// confirmation modal
export const CONFIRMATION_MODAL_DATA = 'CONFIRMATION_MODAL_DATA';

// NEW_UPDATES modal
export const NEW_UPDATES_MODAL_DATA = 'NEW_UPDATES_MODAL_DATA';
export const OPEN_NEW_UPDATES_MODAL_DATA = 'OPEN_NEW_UPDATES_MODAL_DATA';

//Reports
export const GET_VISUALIZATION_OF_PATIENT_AGES = 'GET_VISUALIZATION_OF_PATIENT_AGES';
export const GET_MONTHLEY_PATIENT_COUNT = 'GET_MONTHLEY_PATIENT_COUNT';
export const GET_MOST_COMMON_DIAGNOSIS = 'GET_MOST_COMMON_DIAGNOSIS';
export const GET_MOST_COMMNLY_PRESCRIBED_MEDICATION = 'GET_MOST_COMMNLY_PRESCRIBED_MEDICATION';
export const GET_TYPES_OF_VACCINE_ADMINNISTRATION = 'GET_TYPES_OF_VACCINE_ADMINNISTRATION';
export const GET_CLINICAL_SUMMARY_VISIT_FILTER = 'GET_CLINICAL_SUMMARY_VISIT_FILTER';
export const GET_FIRST_VISIT_FILTER = 'GET_FIRST_VISIT_FILTER';
export const GET_FOLLOW_UP_VISIT = 'GET_FOLLOW_UP_VISIT';
export const RESET_VISIT_FILTER = 'RESET_VISIT_FILTER';
