import Backdrop from '@mui/material/Backdrop';
import {
  Box,
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import moment from 'moment';
import CustomCheckbox from '../../../forms/custom-elements/CustomCheckbox';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const ScreeningTest = ({
  screeningTestsOptions,
  setScreeningTestsValues,
  screeningTestsValues,
}) => {
  const [currKey, setCurrKey] = useState(null);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevS) => !prevS);
  return (
    <>
      <div className='tabpanelContent'>
        <Typography className='panelHeading'>Screening Tests</Typography>
        <div style={{ width: '100%' }}>
          <FormControl
            sx={{
              width: '100%',
            }}
          >
            {/* <CustomFormLabel htmlFor="demo-simple-select">Diet </CustomFormLabel> */}

            <Grid
              container
              spacing={0}
              sx={{
                m: 0,
              }}
              className='dietGrid'
            >
              {screeningTestsOptions.map((item, i) => (
                <Grid item lg={4} md={6} sm={12} key={i}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        checked={screeningTestsValues[item.key]}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCurrKey(item.key);
                            setOpen(true);
                          }
                          setScreeningTestsValues({
                            ...screeningTestsValues,
                            [item.key]: e.target.checked,
                            [`${item.key}_date`]: !e.target.checked && null,
                          });
                        }}
                        name='checkedA'
                        color='primary'
                      />
                    }
                    label={
                      <>
                        <span>{item.label}</span>
                        <br />
                        <small style={{ color: 'gray' }}>
                          <em>
                            {screeningTestsValues[`${item.key}_date`]
                              ? ' (' +
                                moment(
                                  screeningTestsValues[`${item.key}_date`]
                                ).format('MMM, DD, YYYY') +
                                ')'
                              : ''}
                          </em>
                        </small>
                      </>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </FormControl>
        </div>
      </div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={toggle}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <TextField
              placeholder='Date'
              variant='outlined'
              fullWidth
              type='date'
              // value={search}
              onChange={(e) => {
                setScreeningTestsValues({
                  ...screeningTestsValues,
                  [`${currKey}_date`]: e.target.value,
                });
              }}
            />
            <Box
              sx={{
                marginTop: 1,
                display: 'flex',
                gap: '10px',
              }}
            >
              <Button onClick={toggle} variant='outlined' color='primary'>
                Close
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ScreeningTest;
