import React, { useEffect, useState } from "react";
import {
    Typography, Toolbar, Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Tabs,
    Tab,
} from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

import PageContainer from '../container/PageContainer';
import FeatherIcon from 'feather-icons-react';
// import MedicalSummary from "./MedicalSummary";
// import PastVisits from "./PastVisits";
import { useDispatch } from "react-redux";
import CustomTextField from "../forms/custom-elements/CustomTextField";
import CustomFormLabel from "../forms/custom-elements/CustomFormLabel";
import { addHospital, getAllHospital } from "../../redux/Hospital/AsyncAction";
// import NewVisitIcon from '../../../assets/icons/NewVisitIcon.png' 
import HospitalList from "./HospitalList";
import { showNotificationAction } from "../../redux/pushnotification/Action";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
};
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const HospitalPage = () => {
    const dispatch = useDispatch();

    const [newHospitalData, setnewHospitalData] = useState({ hospitalName: "", userName: "", email: "", password: "" });
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        dispatch(getAllHospital())
    }, [dispatch])

    // const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const addHospitalHandler = () => {
        let { hospitalName, userName, email, password } = newHospitalData
        let emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i
        let passWordFormate = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/

        if (hospitalName === "" || hospitalName.length > 30) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Hospital name must be minimum one and maximum 30 characters.' }))
        } else if (userName === "" || userName.length > 50) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Username must be minimum one and maximum 50 characters.' }))
        } else if (email === "" || email.length > 50) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Email must be minimum one and maximum 50 characters.' }))
        } else if (!emailValidator.test(email)) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Invalid email provided.' }))
        } else if (password === "" || password.length > 15) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Password must be minimum 8 and maximum 15 characters.' }))
        } else if (!passWordFormate.test(password)) {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Password must be a minimum of 8 characters, including at least one number, one uppercase letter, and one lowercase letter.' }))
        } else {
            dispatch(addHospital(newHospitalData)).then((result) => {
                if (result?.code === 200) {
                    dispatch(getAllHospital())
                    setOpen(false)
                    setnewHospitalData({})
                }

            })
        }
    }

    return <>
        <PageContainer title="Hospital" description="This is Hospital page">
            {/* <Breadcrumb items={BCrumb} /> */}

            <div className="patientDetailPage UsersPage"  >

                <Toolbar
                    sx={{
                        pl: { sm: 3 },
                        pr: { xs: 2, sm: 2 },
                        // ...(numSelected > 0 && {
                        bgcolor: (theme) => '#1990FC',
                        // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                        // }),
                        borderRadius: '4px',
                        // boxShadow: '0px 4px 4px rgba(19, 83, 140, 0.15)',
                        width: '100%'
                    }}
                >

                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="newVisitTitle" component="div" fontSize="20px" onClick={() => {
                        setOpen(true)
                    }} >
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: '20px' }} variant="h6" id="" component="div" fontSize="20px" >
                            {/* <img src={NewVisitIcon} className="tabIcon" alt="logoImg" /> */}
                            New Hospital
                        </Typography>
                        <FeatherIcon icon="arrow-right" />
                    </Typography>
                </Toolbar>
                <Box sx={{
                    width: '100%',
                    padding: '0 !important'
                }}
                    className="newPatientCardBox"
                >
                    <Toolbar
                        sx={{
                            pl: { sm: 3 },
                            pr: { xs: 2, sm: 2 },
                            // ...(numSelected > 0 && {
                            bgcolor: (theme) => '#1990FC',
                            // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            // }),
                            borderRadius: '4px',
                            // boxShadow: '0px 4px 4px rgba(19, 83, 140, 0.15)',
                            width: '100%'
                        }}
                    >

                        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="newVisitTitle" component="div" fontSize="20px" >
                            <Typography sx={{ display: 'flex', alignItems: 'center', gap: '20px' }} variant="h6" id="" component="div" fontSize="20px">
                                {/* <img src={NewVisitIcon} className="tabIcon" alt="logoImg" /> */}
                                Hospital
                            </Typography>

                        </Typography>
                    </Toolbar>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: '20px', paddingRight: '20px' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            textColor="secondary"
                            allowScrollButtonsMobile
                            scrollButtons
                            indicatorColor="secondary"
                        >
                            <Tab
                                sx={{
                                    textTransform: 'capitalize',
                                }}
                                label="Active Hospitals"
                                {...a11yProps(0)}
                            // onClick={() => scrollDown(newVaccineRef)}
                            />
                            <Tab
                                sx={{
                                    textTransform: 'capitalize',
                                }}
                                label="Inactive Hospitals"
                                {...a11yProps(1)}
                                onClick={() => {
                                    // getHistory()
                                    // scrollDown(historyRef)
                                }}
                            />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0} component="div" style={{ padding: '0 20px' }}>
                        <HospitalList type='active' />
                    </TabPanel>
                    <TabPanel value={value} index={1} component="div" style={{ padding: '0 20px' }}>
                        <HospitalList type='inactive' />
                    </TabPanel>
                </Box>
            </div>
            <Dialog open={open} onClose={handleClose} fullWidth >
                <DialogContent className='appointmentForm' sx={{ padding: '20px 16px' }}>
                    <Typography sx={{ mb: 2, ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} id="" component="div"  >
                        <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            {/* <img src={FormIcon} className="tabIcon" alt="logoImg" /> */}
                            Hospital Form
                        </Typography>
                        <FeatherIcon icon="x" style={{
                            width: 20, height: 20, marginLeft: '5px', color: 'white', borderRadius: '50%', background: '#1990FC', padding: '2px'
                        }}
                            onClick={handleClose} />
                    </Typography>

                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <CustomFormLabel>Hospital Name </CustomFormLabel>

                            <CustomTextField
                                id="outlined-multiline-static"
                                // multiline
                                // rows={4}
                                variant="outlined"
                                fullWidth
                                // size="small"
                                placeholder="Hospital Name"
                                value={newHospitalData.hospitalName}
                                onChange={(e) => {
                                    setnewHospitalData({ ...newHospitalData, hospitalName: e.target.value })
                                }}
                            />
                        </FormControl>
                    </div>
                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <CustomFormLabel>Username</CustomFormLabel>

                            <CustomTextField
                                id="outlined-multiline-static"
                                // multiline
                                // rows={4}
                                variant="outlined"
                                fullWidth
                                // size="small"
                                placeholder="Username"
                                value={newHospitalData.userName}
                                onChange={(e) => {
                                    setnewHospitalData({ ...newHospitalData, userName: e.target.value })
                                }}
                            />
                        </FormControl>
                    </div>
                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <CustomFormLabel>Email Address </CustomFormLabel>

                            <CustomTextField
                                id="outlined-multiline-static"
                                // multiline
                                // rows={4}
                                variant="outlined"
                                fullWidth
                                // size="small"
                                placeholder="Email Address"
                                value={newHospitalData.email}
                                onChange={(e) => {
                                    setnewHospitalData({ ...newHospitalData, email: e.target.value.toLowerCase() })
                                }}
                            />
                        </FormControl>
                    </div>
                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <CustomFormLabel>Password </CustomFormLabel>

                            <CustomTextField
                                id="outlined-multiline-static"
                                // multiline
                                // rows={4}
                                variant="outlined"
                                fullWidth
                                // size="small"
                                placeholder="Password"
                                value={newHospitalData.password}
                                onChange={(e) => {
                                    setnewHospitalData({ ...newHospitalData, password: e.target.value })
                                }}
                            />
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions className=''>

                    <Box>
                        <Button type="button"
                            variant="contained"
                            onClick={handleClose}
                            sx={{
                                mr: '8px',
                                background: "#888888"

                            }}
                        >Cancel</Button>
                        <Button variant="contained" className='registerButton' color="primary"
                            onClick={addHospitalHandler}
                        >
                            Add
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog >
        </PageContainer>
    </>
}

export default HospitalPage