import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import notesReducer from './notes/NotesReducer';
import patientReducer from './patients/PatientReducer';
import pushNotificationReducer from './pushnotification/PushNotificationReducer';
import MessageModalReducer from './MessageModal/MessageModalReducer';
import SpinnerReducer from './Spinner/SpinnerReducer';
import AuthenticationReducer from './authentication/AuthenticationReducer';
import UsersReducer from './users/UsersReducer';
import AppointmentReducer from './appointment/AppointmentReducer';
import DashboardReducer from './dashboard/DashboardReducer';
import HospitalReducer from './Hospital/HospitalReducer';
import NotificationReducer from './notification/NotificationReducer';
import ConfirmationModalReducer from './confirmationModal/ConfirmationModalReducer';
import NewUpdateModalReducer from './NewUpdatesModal/NewUpdateModalReducer';
import ReportReducer from './reports/ReportReducer';
import SiblingReducer from './siblings/SiblingReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  notesReducer,
  patientReducer,
  pushNotificationReducer,
  MessageModalReducer,
  SpinnerReducer,
  AuthenticationReducer,
  UsersReducer,
  AppointmentReducer,
  DashboardReducer,
  HospitalReducer,
  NotificationReducer,
  ConfirmationModalReducer,
  NewUpdateModalReducer,
  ReportReducer,
  SiblingReducer,
});

export default RootReducers;
