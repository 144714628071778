import axios from 'axios';
import {
  FETCH_PATIENTS_SUCCESS,
  MEDICAL_SUMMARY,
  PAST_VISITS,
  LAST_VISIT_ID,
  SAVE_ALLERGY,
  SAVE_OBSERVATION,
  SAVE_VACCINATION,
  SAVE_EXAMINATION,
  SAVE_GROWTH,
  SAVED_DOCUMENTUPLOAD,
  SAVE_DIAGNOSIS,
  SHOW_SPINNER,
  SHOW_NOTIFICATION,
  PATIENT_LIST,
  BATCH_LIST,
  EDITED_PATIENT_LIST,
  EXAMINATION_HISTORY,
  VACCINE_DOSE_LIST,
  VACCINE_HISTORY_BY_AGE,
  TOTAL_PATIENT,
  ADD_PATIENT_EMERGENCY_CONTACT,
  GET_PATIENT_EMERGENCY_CONTACT,
  DELETE_PATIENT_EMERGENCY_CONTACT,
} from '../constants';
import { URL } from '../../config';

export function saveNewPatient(data) {
  // And then creates and returns the async thunk function:

  return async function saveNewPatientThunk(dispatch, getState) {
    try {
      let token = sessionStorage.getItem('token');
      dispatch({
        type: SHOW_SPINNER,
        data: true,
      });
      // ✅ Now we can use the data value and send it to the server
      const response = await axios.post(URL + 'patient/add', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      return response.data;
      //   dispatch({ type: 'ADD_PATIENT', payload: response.data })
    } catch (err) {
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      dispatch({
        type: SHOW_NOTIFICATION,
        isShow: true,
        content: 'Something went wrong.',
        origin: 'saveNewPatient',
      });
      return err.response;
    }
  };
}

export const fetchPatients = (data) => (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    axios
      .post(URL + 'patient/all', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch({
          type: FETCH_PATIENTS_SUCCESS,
          patients: response.data.data,
        });
        dispatch({
          type: TOTAL_PATIENT,
          totalPatient: response.data.total,
        });
        dispatch({
          type: SHOW_SPINNER,
          data: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_PATIENTS_SUCCESS,
          patients: [],
        });
        dispatch({
          type: TOTAL_PATIENT,
          totalPatient: 0,
        });
        dispatch({
          type: SHOW_SPINNER,
          data: false,
        });
        dispatch({
          type: SHOW_NOTIFICATION,
          isShow: true,
          content: { type: 'error', content: 'Something went wrong.' },
          origin: 'api catch fetchPatients',
        });
        return err;
      });
  } catch (err) {
    dispatch({
      type: TOTAL_PATIENT,
      totalPatient: 0,
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'action catch fetchPatients',
    });
    return err.response;
  }
};
export const fetchEditedPatients = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: true
    // });
    const response = await axios.post(URL + 'patient/edited', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: EDITED_PATIENT_LIST,
      patients: response.data.data,
    });
    dispatch({
      type: TOTAL_PATIENT,
      totalPatient: response.data.total,
    });
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    return response.data;
  } catch (err) {
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    dispatch({
      type: EDITED_PATIENT_LIST,
      patients: [],
    });
    dispatch({
      type: TOTAL_PATIENT,
      totalPatient: 0,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'getPatientList',
    });
    return err.response;
  }
};

export const getPatientDetail = (patientId) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.get(URL + 'patient/details/' + patientId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return err.response;
  }
};

export const updatePatientDetail = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'patient/update', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: 'Something went wrong.',
      origin: 'editPatient',
    });
    return err.response;
  }
};

export const deletePatient = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'patient/delete', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: 'Something went wrong.',
      origin: 'deletepatient',
    });
    return err.response;
  }
};

export const updateGuardianDetail = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'guardian/update', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: 'Something went wrong.',
      origin: 'updateGuardianDetail',
    });
    return err.response;
  }
};
export const deleteGuardian = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'guardian/delete', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: 'Something went wrong.',
      origin: 'deleteguardian',
    });
    return err.response;
  }
};
export const addGuardianDetail = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'guardian/add', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: 'Something went wrong.',
      origin: 'addguardian',
    });
    return err.response;
  }
};

export const getMedicalSummary = (d) => async (dispatch, getState) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.get(URL + 'patient/medical/summary/' + d, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: MEDICAL_SUMMARY,
      data: response.data.data,
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'getMedicalSummary',
    });
    return err.response;
  }
};

export const getPastVisits =
  ({ id, startDate, endDate, recordedBy, lastUpdatedBy }) =>
  async (dispatch, getState) => {
    try {
      let token = sessionStorage.getItem('token');

      dispatch({
        type: SHOW_SPINNER,
        data: true,
      });
      const filterDate = `?startDate=${startDate}&endDate=${endDate}&recordedBy=${recordedBy}&lastUpdatedBy=${lastUpdatedBy}`;
      const response = await axios.get(
        URL + 'visit/patient/' + id + filterDate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: PAST_VISITS,
        data: response.data.data,
      });
      dispatch({
        type: LAST_VISIT_ID,
        id: response.data.data[0] ? response.data.data[0].visitId : null,
      });
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      return response.data;
    } catch (err) {
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      dispatch({
        type: PAST_VISITS,
        data: [],
      });
      dispatch({
        type: LAST_VISIT_ID,
        id: null,
      });
      return err.response;
    }
  };

const formatString = (str) => {
  if (str)
    return str
      .replaceAll('&#x2F;', '/')
      .replaceAll('&amp;', '&')
      .replaceAll('&#96;', '`')
      .replaceAll('&quot;', '"')
      .replaceAll('&lt;', '<')
      .replaceAll('&gt;', '>')
      .replaceAll('&quot;', '"')
      .replaceAll('&#x27;', "'")
      .replaceAll('&#x2F;', '/')
      .replaceAll('&#x5C;', '\\');
};
export const getLastVisitDetail =
  (data, type) => async (dispatch, getState) => {
    try {
      let token = sessionStorage.getItem('token');

      dispatch({
        type: SHOW_SPINNER,
        data: true,
      });
      const response = await axios.post(URL + 'visit/details', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let dietv = {};
      if (
        response.data.data.observation?.diet !== '' &&
        JSON.parse(response.data.data.observation.diet)
      ) {
        dietv = JSON.parse(response.data.data.observation.diet);
      } else {
        dietv = {
          Breastmilk: false,
          Formulafeeds: false,
          Weaningontosolids: false,
          FamilyPot: false,
          PickyEaterPoorEatingHabits: false,
          EatsWell: false,
        };
      }
      dispatch({
        type: SAVE_OBSERVATION,
        data: {
          visitId: response.data.data.observation?.visitId,
          selectedVisitType: response.data.data.observation?.visitType,
          informant: formatString(response.data.data.observation?.informant),
          currentIssues: formatString(
            response.data.data.observation?.currentIssues
          ),
          visitDate: response.data.data.observation?.visitDate,
          age: response.data.data.observation?.age,
          // selectedStatusType: response.data.data.observation?.overviewStatus,
          // feedDetails: response.data.data.observation?.feedDetails,
          // selectedValueFeeder: response.data.data.observation?.feeder,
          // feederDetails: response.data.data.observation?.feederDetails,
          // selectedValueNutritionStatus: response.data.data.observation?.nutritianStatus,
          selectedValueUrine: response.data.data.observation?.urine,
          selectedValueStool: response.data.data.observation?.stool,
          detailsExc: formatString(
            response.data.data.observation?.urineDetails
          ),
          // selectedValueI: response.data.data.observation?.immunizationField1,
          // detailsI: response.data.data.observation?.immunizationDetails,
          selectedValueDental: response.data.data.observation?.dentalHealth,
          detailsD: formatString(
            response.data.data.observation?.dentalComments
          ),
          detailsDiet: formatString(
            response.data.data.observation?.dietaryDetail
          ),

          school: formatString(response.data.data.observation?.school),
          // classN: response.data.data.observation?.classes,
          // grade: response.data.data.observation?.grade,
          behavioralConcerns:
            response.data.data.observation?.behavioralConcerns,
          mothersAge: response.data.data.observation?.motherAge,
          gravida: response.data.data.observation?.gravidaPara,
          modeOfDelivery: response.data.data.observation?.modeOfDelivery,
          gestationalAge: response.data.data.observation?.gestationalAge,
          indicationForLscs: response.data.data.observation?.indicationForLscs,
          birthHistoryDetail: formatString(
            response.data.data.observation?.birthHistoryDetail
          ),
          headCircumference: response.data.data.observation?.headCircumference,
          birthWeight: response.data.data.observation?.birthWeight,
          lengthBirth: response.data.data.observation?.length,
          apgarScore: formatString(response.data.data.observation?.apgarScore),
          pastMedicalHistory: formatString(
            response.data.data.observation?.pastMedicalHistory
          ),
          familyHistory: formatString(
            response.data.data.observation?.familyHistory
          ),
          medications: formatString(
            response.data.data.observation?.medications
          ),
          commentsSc: formatString(response.data.data.observation?.comments),
          compMed: formatString(response.data.data.observation?.complications),
          compBirth: formatString(
            response.data.data.observation?.complicationsBox
          ),
          dietValues: dietv,
          bloodInvestigationValues: {
            HIV: response.data.data.observation?.hiv,
            VDRL: response.data.data.observation?.vdrl,
            GBS: response.data.data.observation?.gbs,
            OGTT: response.data.data.observation?.ogtt,
            HEPATITIS: response.data.data.observation?.hepatitis,
            SickleCell: response.data.data.observation?.sickleCell,
            MaternalBloodGroup:
              response.data.data.observation?.metarnalBloodGroup,
            PatientBloodGroup:
              response.data.data.observation?.patientBloodGroup,
          },
          bloodDetail: formatString(
            response.data.data.observation?.bloodInvestigationDetails
          ),
          dentalCare: response.data.data.observation?.dentalCare,

          medicalRecordsValues: JSON.parse(
            response.data.data.observation?.medicalRecord
          ),
          familyHistoryValues: JSON.parse(
            response.data.data.observation?.familyHistoryTwo
          ),
          medicationHistoryValues: JSON.parse(
            response.data.data.observation?.medicalHistory
          ),
          surgicalHistory: response.data.data.observation?.surgicalHistory,
          obstetricHistory: response.data.data.observation?.obstetricHistory,
          screeningTestsValues: JSON.parse(
            response.data.data.observation?.screeningTest
          ),
          clinicalSummaryMedicationData: JSON.parse(
            response.data.data.observation?.clinicalSummaryMedicationData
          ),
          CheckMedicalHistoryOther:
            response.data.data.observation?.checkMedicalHistoryOther,
        },
      });
      dispatch({
        type: SAVE_ALLERGY,
        data: {
          allergyId: response.data.data.allergy?.allergyId,
          allergenSelected: formatString(response.data.data.allergy?.allergen),
          allergenOtherExplain: formatString(
            response.data.data.allergy?.allergenOtherExplain
          ),
          reactionSelected: formatString(response.data.data.allergy?.reaction),
          notes: formatString(response.data.data.allergy?.notes),
        },
      });
      if (type === 'updatevisit') {
        dispatch({
          type: SAVE_VACCINATION,
          data: response.data.data.vaccination,
        });
      }
      dispatch({
        type: SAVE_EXAMINATION,
        data: {
          examinationId: response.data.data.examination?.examinationId,
          general: response.data.data.examination?.general,
          skin: response.data.data.examination?.skin,
          head: response.data.data.examination?.head,
          eyes: response.data.data.examination?.eyes,
          ears: response.data.data.examination?.ears,
          nose: response.data.data.examination?.nose,
          throat: response.data.data.examination?.throat,
          mouthTeeth: response.data.data.examination?.mouth,
          neck: response.data.data.examination?.neck,
          chest: response.data.data.examination?.chest,
          heart: response.data.data.examination?.heart,
          peripheralPulses: response.data.data.examination?.peripheralPulses,
          back: response.data.data.examination?.back,
          abdomen: response.data.data.examination?.abdomen,
          genitalia: response.data.data.examination?.genitalia,
          extremeties: response.data.data.examination?.extremeties,
          neurological: response.data.data.examination?.neurological,
          lymphNodes: response.data.data.examination?.lymphNodes,
          abnormalFindings: formatString(
            response.data.data.examination?.abnormalFindings
          ),
        },
      });
      dispatch({
        type: SAVE_GROWTH,
        data: {
          growthId: response.data.data.growthAndParam?.growthParamId,
          tempC: response.data.data.growthAndParam?.temprature,
          headCir: response.data.data.growthAndParam?.headCircumferences,
          pulseRate: response.data.data.growthAndParam?.pulseRate,
          respi: response.data.data.growthAndParam?.respiratoryRate,
          bp: response.data.data.growthAndParam?.bp,
          weight: response.data.data.growthAndParam?.weight,
          height: response.data.data.growthAndParam?.height,
          bmi: response.data.data.growthAndParam?.bmi,
          lmp: response.data.data.growthAndParam?.lmp,
        },
      });
      dispatch({
        type: SAVE_DIAGNOSIS,
        data: {
          diagnosisAndPlanId: response.data.data.diagnosis?.diagnosisAndPlanId,
          diagInformant: formatString(
            response.data.data.diagnosis?.diagnosisInformant
          ),
          planInformant: formatString(
            response.data.data.diagnosis?.planInformant
          ),
          nextVisit: response.data.data.diagnosis?.nextVisit,
        },
      });
      dispatch({
        type: SAVED_DOCUMENTUPLOAD,
        data: response.data.data.documents,
      });
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      return response;
    } catch (err) {
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      dispatch({
        type: SHOW_NOTIFICATION,
        isShow: true,
        content: { type: 'error', content: 'Something went wrong.' },
        origin: 'getLastVisitDetail',
      });
      return err.response;
    }
  };
export const getExaminationHistory = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'visit/details', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let abnormalKeys = [];
    response.data.data.examination &&
      Object.keys(response.data.data.examination).forEach((el) => {
        if (
          response.data.data.examination &&
          response.data.data.examination[el] === 'Abnormal' &&
          el !== 'abnormalFindings'
        ) {
          abnormalKeys.push(el);
        }
      });
    dispatch({
      type: EXAMINATION_HISTORY,
      data: {
        pastFindings: response.data.data.examination?.abnormalFindings,
        pastAbnormalFindings: abnormalKeys,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'getExaminationHistory',
    });
    return err.response;
  }
};

export const saveNewVisit = (data) => async (dispatch, getState) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'visit/add', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'saveNewVisit',
    });
    return err.response;
  }
};

export const updateObservationVisit = (data) => async (dispatch, getState) => {
  try {
    let token = sessionStorage.getItem('token');

    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: true
    // });
    const response = await axios.post(URL + 'visit/update', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'updateObservationVisit',
    });
    return err.response;
  }
};
export const updatAllergyVisit = (data) => async (dispatch, getState) => {
  try {
    let token = sessionStorage.getItem('token');

    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: true
    // });
    const response = await axios.post(URL + 'allergy/update', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    return response.data;
  } catch (err) {
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'updatAllergyVisit',
    });
    return err.response;
  }
};
export const updateVaccinationVisit = (data) => async (dispatch, getState) => {
  try {
    let token = sessionStorage.getItem('token');

    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: true
    // });
    const response = await axios.post(URL + 'vaccination/update', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    return response.data;
  } catch (err) {
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'updateVaccinationVisit',
    });
    return err.response;
  }
};

export const updateExaminationVisit = (data) => async (dispatch, getState) => {
  try {
    let token = sessionStorage.getItem('token');

    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: true
    // });
    const response = await axios.post(URL + 'examination/update', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    return response.data;
  } catch (err) {
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'updateExaminationVisit',
    });
    return err.response;
  }
};
export const updateGrowthVisit = (data) => async (dispatch, getState) => {
  try {
    let token = sessionStorage.getItem('token');

    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: true
    // });
    const response = await axios.post(URL + 'growthAndParam/update', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    return response.data;
  } catch (err) {
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'updateGrowthVisit',
    });
    return err.response;
  }
};
export const updateDocumentationVisit =
  (data) => async (dispatch, getState) => {
    try {
      let token = sessionStorage.getItem('token');

      // dispatch({
      //     type: SHOW_SPINNER,
      //     data: true
      // });
      const response = await axios.post(URL + 'document/update', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // dispatch({
      //     type: SHOW_SPINNER,
      //     data: false
      // });
      return response.data;
    } catch (err) {
      // dispatch({
      //     type: SHOW_SPINNER,
      //     data: false
      // });
      dispatch({
        type: SHOW_NOTIFICATION,
        isShow: true,
        content: { type: 'error', content: 'Something went wrong.' },
        origin: 'updateDocumentationVisit',
      });
      return err.response;
    }
  };
export const updatediagnosisVisit = (data) => async (dispatch, getState) => {
  try {
    let token = sessionStorage.getItem('token');

    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: true
    // });
    const response = await axios.post(URL + 'diagnosisAndPlan/update', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    return response.data;
  } catch (err) {
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'updatediagnosisVisit',
    });
    return err.response;
  }
};
export const deleteVisit = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'visit/delete', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return err.response;
  }
};

export const getVaccineHistoryByAge = (patientId) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.get(
      URL + 'vaccination/history/patient/' + patientId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: VACCINE_HISTORY_BY_AGE,
      data: response.data.data,
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'getVaccineHistoryByAge',
    });
    return err.response;
  }
};
export const getVaccineList = (patientId) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.get(URL + 'vaccination/patient/' + patientId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'getVaccineList',
    });
    return err.response;
  }
};
export const addNewVaccination = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'vaccination/add', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return err.response;
  }
};

export const addAllergy = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'allergy/add', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return err.response;
  }
};
export const addGrowth = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'growthAndParam/add', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return err.response;
  }
};
export const addExamination = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'examination/add', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return err.response;
  }
};
export const addDiagnosisAndPlan = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'diagnosisAndPlan/add', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return err.response;
  }
};

export const deleteVaccination = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'vaccination/delete', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return err.response;
  }
};
export const getVaccinationDetail = (vaccinationId) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.get(
      URL + 'vaccination/details/' + vaccinationId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return err.response;
  }
};
export const uploadDocuments = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'document/upload', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'uploadDocuments',
    });
    return err.response;
  }
};

export const getUploadedDocuments = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'document/all', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'getUploadedDocuments',
    });
    return err.response;
  }
};

export const getAllUploadedDocuments = (patientId) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.get(URL + 'document/patient/' + patientId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'getAllUploadedDocuments',
    });
    return err.response;
  }
};

export const deleteDocuments = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'document/delete', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'uploadDocuments',
    });
    return err.response;
  }
};

export const getGraphOfPatient = (patientId) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'graph/all', patientId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'getGraphOfPatient',
    });
    return err.response;
  }
};

export const getAllGraphListOfPatient = (patientId) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'graph/patient', patientId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'getAllGraphListOfPatient',
    });
    return err.response;
  }
};

export const getPatientList = () => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: true
    // });
    const response = await axios.get(URL + 'patient/dropdown', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: PATIENT_LIST,
      data: response.data.data,
    });
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    return response.data;
  } catch (err) {
    // dispatch({
    //     type: SHOW_SPINNER,
    //     data: false
    // });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'getPatientList',
    });
    return err.response;
  }
};

export const getBatchList = () => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.get(URL + 'batchNo/all', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: BATCH_LIST,
      data: response.data.data.batchNoList,
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: BATCH_LIST,
      data: [],
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: { type: 'error', content: 'Something went wrong.' },
      origin: 'getBatchList',
    });
    return err.response;
  }
};

export const addBatchNo = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'batchNo/add', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(getBatchList());
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: BATCH_LIST,
      data: [],
    });
    return err.response;
  }
};

export const getVaccineDose = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');

    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(URL + 'vaccination/list', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: VACCINE_DOSE_LIST,
      data: response.data.data.vaccinationList,
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: VACCINE_DOSE_LIST,
      data: [],
    });
    return err.response;
  }
};

export function saveNewPatientEmergecyContacts(data) {
  // And then creates and returns the async thunk function:

  return async function saveNewPatientContactsThunk(dispatch, getState) {
    try {
      let token = sessionStorage.getItem('token');
      dispatch({
        type: SHOW_SPINNER,
        data: true,
      });
      // ✅ Now we can use the data value and send it to the server
      const response = await axios.post(
        URL + 'patient/emergency/contacts/add',
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      return response.data;
      //   dispatch({ type: 'ADD_PATIENT', payload: response.data })
    } catch (err) {
      console.log('err---', err);
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      dispatch({
        type: SHOW_NOTIFICATION,
        isShow: true,
        content: 'Something went wrong.',
        origin: 'saveNewPatient',
      });
      return err.response;
    }
  };
}

export const getPatientEmergecyContactsDetail =
  (patientId) => async (dispatch) => {
    try {
      let token = sessionStorage.getItem('token');
      dispatch({
        type: SHOW_SPINNER,
        data: true,
      });
      const response = await axios.get(
        URL + 'patient/emergency/contacts/details/' + patientId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      return response.data;
    } catch (err) {
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      return err.response;
    }
  };

export const deletePatientEmergecyContacts = (data) => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.post(
      URL + 'patient/emergency/contacts/delete',
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    dispatch({
      type: SHOW_NOTIFICATION,
      isShow: true,
      content: 'Something went wrong.',
      origin: 'deletepatient',
    });
    return err.response;
  }
};

export function saveNewMedicalProblem(data) {
  // And then creates and returns the async thunk function:

  return async function saveNewPatientThunk(dispatch, getState) {
    try {
      let token = sessionStorage.getItem('token');
      dispatch({
        type: SHOW_SPINNER,
        data: true,
      });
      // ✅ Now we can use the data value and send it to the server
      const response = await axios.post(URL + 'medicalProblme/add', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      return response.data;
      //   dispatch({ type: 'ADD_PATIENT', payload: response.data })
    } catch (err) {
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      dispatch({
        type: SHOW_NOTIFICATION,
        isShow: true,
        content: 'Something went wrong.',
        origin: 'saveNewPatient',
      });
      return err.response;
    }
  };
}

export function saveNewMedicalReason(data) {
  // And then creates and returns the async thunk function:

  return async function saveNewPatientThunk(dispatch, getState) {
    try {
      let token = sessionStorage.getItem('token');
      dispatch({
        type: SHOW_SPINNER,
        data: true,
      });
      // ✅ Now we can use the data value and send it to the server
      const response = await axios.post(URL + 'medicalReason/add', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      return response.data;
      //   dispatch({ type: 'ADD_PATIENT', payload: response.data })
    } catch (err) {
      dispatch({
        type: SHOW_SPINNER,
        data: false,
      });
      dispatch({
        type: SHOW_NOTIFICATION,
        isShow: true,
        content: 'Something went wrong.',
        origin: 'saveNewPatient',
      });
      return err.response;
    }
  };
}

export const getMedicalProblemList = () => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.get(URL + 'medicalProblme/get', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return err.response;
  }
};

export const getMedicalReasonList = () => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('token');
    dispatch({
      type: SHOW_SPINNER,
      data: true,
    });
    const response = await axios.get(URL + 'medicalReason/get', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: SHOW_SPINNER,
      data: false,
    });
    return err.response;
  }
};
