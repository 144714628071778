import React, { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Card, CardContent, Toolbar, Grid, Tooltip } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { deleteVaccination, getVaccineList } from '../../../redux/patients/AsyncActions';
import { useEffect } from 'react';
import PageContainer from '../../container/PageContainer';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import VaccineIcon from '../../../assets/icons/VaccineIcon.png'
import NewVaccination from './NewVaccination';
import { showConfirmationModalAction } from '../../../redux/confirmationModal/ActionConfirmationModal';

const PatientVaccination = () => {
    const dispatch = useDispatch()

    const { state } = useLocation();
    const navigate = useNavigate();
    const { id, firstName, lastName } = state ? state : {};
    const [vaccinationArr, setvaccinationArr] = useState([])
    const [openNewVaccine, setopenNewVaccine] = useState(false)
    const [vaccineToUpdate, setvaccineToUpdate] = useState(false)

    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const rootRoute = useMemo(() => {
        if (userinfo && userinfo.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])

    useEffect(() => {
        // setSelectedFiles(document.selectedFiles)
        getVaccineListHandler()
    }, [dispatch, id])

    const getVaccineListHandler = async () => {
        let result = await dispatch(getVaccineList(id))
        if (result.status === true && result.data.length > 0)
            setvaccinationArr(result.data)
        else
            setvaccinationArr([])
    }

    let breadtitle = (firstName && firstName[0] && (firstName[0].toUpperCase() + firstName.slice(1))) + ' ' +
        (lastName && lastName[0] && (lastName[0].toUpperCase() + lastName.slice(1)))

    const BCrumb = [
        {
            to: rootRoute + '/patient',
            title: 'Patients',
        },
        {
            onclick: () => { navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName } }) },
            title: breadtitle,
        },
        {
            title: 'Vaccinations',
        },
    ];

    const openAddVaccineHandler = (val) => {
        setopenNewVaccine(val)
        getVaccineListHandler()
    }
    const deleteVaccinationConfirmation = (element) => {
        dispatch(showConfirmationModalAction(true, {
            modalContent: "Are you sure you want to remove this vaccine?",
            button1: true,
            button2: true,
            buttonText1: 'Remove',
            buttonText2: 'Cancel',
            buttonAction1: () => {
                dispatch(deleteVaccination({ vaccinationId: element.vaccinationId }))
                    .then((result) => {
                        if (result.status === true) {
                            getVaccineListHandler()
                            dispatch(showConfirmationModalAction(false, {
                                modalContent: "",
                                button1: false,
                                button2: false,
                                buttonText1: '',
                                buttonText2: '',
                                buttonAction1: () => { },
                                buttonAction2: () => { },
                                origin: "Deleted Vaccine"
                            }))
                        }
                    })
            },
            buttonAction2: () => {
                dispatch(showConfirmationModalAction(false, {
                    modalContent: "",
                    button1: false,
                    button2: false,
                    buttonText1: '',
                    buttonText2: '',
                    buttonAction1: () => { },
                    buttonAction2: () => { },
                    origin: "Cancel vaccine delete."
                }))
            },
            origin: "delete vaccine"
        }))
    }
    return (
        <PageContainer title="Vaccinations" description="This is Vaccinations page" className="newPatientPage">
            <Breadcrumb
                title={
                    <Typography
                        fontWeight="500"
                        variant="h3"
                        sx={{
                            lineHeight: '1.235',
                            color: '#1990FC',
                            // fontSize:'20px'
                        }}
                    >
                        {breadtitle}
                    </Typography>
                }
                items={BCrumb}
            />
            <Card
                sx={{
                    p: {
                        xs: '0px',
                        sm: '0px',
                        lg: '0px',
                    },
                    width: '100%'
                }}
                className="newPatientCard"

            >
                <CardContent
                    sx={{
                        p: 0,
                    }}
                >
                    <Toolbar
                        sx={{
                            pr: { xs: 2, sm: 2 },
                            pl: { sm: 2 },
                            // ...(numSelected > 0 && {
                            bgcolor: (theme) => '#1990FC'
                            // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            // }),
                        }}
                    >
                        <Typography sx={{
                            flex: '1 1 100%', display: 'flex',
                            alignItems: 'center',
                            gap: '20px'
                        }} variant="h6" id="newpatientTitle" component="span">
                            <img src={VaccineIcon} className="tabIcon" alt="logoImg" />
                            Vaccinations
                        </Typography>
                    </Toolbar>
                    <div className="patientDetailPage" >
                        {vaccinationArr.map((ele, i) => <Card key={i} sx={{ p: 3, m: 0, mt: 2, width: '100%' }}>
                            <Typography sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="h3" >{ele.vaccination} - Dose {ele.dose}</Typography>
                                {userinfo?.role !== 'admin' && <Typography variant="h4" >
                                    <Tooltip title='Edit vaccination'>
                                        <span>  <FeatherIcon icon={'edit'} width="20" onClick={() => {
                                            setopenNewVaccine(true)
                                            setvaccineToUpdate(ele)
                                        }} /> &nbsp;
                                        </span>
                                    </Tooltip>
                                    <Tooltip title='Delete vaccination'>
                                        <span>
                                            <FeatherIcon icon={'delete'} width="20" onClick={() => { deleteVaccinationConfirmation(ele) }} />
                                        </span>
                                    </Tooltip>
                                </Typography>}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Typography variant="h6" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                        Batch Number
                                    </Typography>
                                    <Typography variant="body2">
                                        {ele.batchNumber ? ele.batchNumber : 'Not available.'}
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Typography variant="h6" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                        Route of Administration
                                    </Typography>
                                    <Typography variant="body2">
                                        {ele.routeOfAdministration ? ele.routeOfAdministration : 'Not available.'}
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Typography variant="h6" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                        Site of Injection
                                    </Typography>
                                    <Typography variant="body2">
                                        {ele.siteOfInjection ? ele.siteOfInjection : 'Not available.'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h6" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                        Notes
                                    </Typography>
                                    <Typography variant="body2">
                                        {ele.notes ? ele.notes : 'Not available.'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Typography variant="h6" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                        Vaccination Date - <Typography fontWeight="400" variant="span">{ele.vaccinationDate ? ele.vaccinationDate : 'Not available.'}</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: 'right' }}>
                                    <Typography variant="h6" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                        Updated date - <Typography fontWeight="400" variant="span">{ele.updatedAt ? ele.updatedAt : 'Not available.'}</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>)
                        }

                        {(!vaccinationArr || vaccinationArr.length <= 0)
                            && <Card sx={{ p: 3, m: 0, width: '100%' }}>
                                <Typography>No vaccination available.</Typography>
                            </Card>
                        }
                    </div>
                    {openNewVaccine && <NewVaccination changeopenNewVaccine={openAddVaccineHandler} updateVaccineData={vaccineToUpdate} />}

                </CardContent>
            </Card >
        </PageContainer >
    );
};

export default PatientVaccination;
