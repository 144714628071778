import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, FormControlLabel, FormControl } from '@mui/material';
import CustomTextField from '../../forms/custom-elements/CustomTextField';
import CustomRadio from '../../forms/custom-elements/CustomRadio';
import CustomFormLabel from '../../forms/custom-elements/CustomFormLabel';
import { showNotificationAction } from '../../../redux/pushnotification/Action';
import logo1 from '../../../assets/images/newpatientIcon/Rectangle 4.png'
import moment from 'moment';

const IdentificationPanel = ({ detail, returnPanelData, scrollToField }) => {
    const dispatch = useDispatch()

    const [selectedValueGender, setSelectedValueGender] = React.useState('');
    const [selectedValueStatus, setSelectedValueStatus] = React.useState('');
    const fnameRef = useRef(null);
    const mnameRef = useRef(null);
    const lnameRef = useRef(null);
    const dobRef = useRef(null);
    const emailRef = useRef(null);
    const genderRef = useRef(null);
    const statusRef = useRef(null);
    const phoneRef = useRef(null);
    const secPhoneRef = useRef(null);

    useEffect(() => {
        setSelectedValueGender(detail.gender)
        setSelectedValueStatus(detail.status)
    }, [detail])

    const handleChange3 = (event) => {
        setSelectedValueGender(event.target.value);
        returnPanelData('gender', event.target.value)

    };
    const handleChange4 = (event) => {
        setSelectedValueStatus(event.target.value);
        returnPanelData('status', event.target.value)
    }
    const scrollDown = (ref) => {
        if (ref.current) {
            let scrollToPx = ref.current.offsetTop - 100
            window.scrollTo({
                top: scrollToPx,
                behavior: 'smooth',
            });

        }
    };
    const changeBorderColor = (ref) => {
        // ref.current.style.border = "2px solid red";
        // ref.current.style.borderRadius = "5px";
    }
    const removeError = (ref) => {
        ref.current.style.border = "unset";
        ref.current.style.borderRadius = "unset";
        // dispatch(showNotificationAction(false, { type: '', content: '' }))
    }

    useEffect(() => {
        if (scrollToField && scrollToField !== "") {
            if (scrollToField === "fnameRef") {
                scrollDown(fnameRef)
                // changeBorderColor(fnameRef)
            } else if (scrollToField === "mnameRef") {
                scrollDown(mnameRef)
                // changeBorderColor(mnameRef)
            } else if (scrollToField === "lnameRef") {
                scrollDown(lnameRef)
                // changeBorderColor(lnameRef)
            } else if (scrollToField === "dobRef") {
                scrollDown(dobRef)
                // changeBorderColor(dobRef)
            } else if (scrollToField === "emailRef" || scrollToField === "invalidemailRef") {
                scrollDown(emailRef)
                // changeBorderColor(emailRef)
            } else if (scrollToField === "genderRef") {
                scrollDown(genderRef)
            } else if (scrollToField === "statusRef") {
                scrollDown(statusRef)
            } else if (scrollToField === "phoneRef") {
                scrollDown(phoneRef)
                // changeBorderColor(phoneRef)
            } else {

            }
        }
    }, [scrollToField])

    return <div className="tabpanelContent">
        <Typography className='panelHeading'>
            <img src={logo1} className="tabIcon" alt="logoImg" />
            Identification
        </Typography>
        <div style={{ width: '100%' }} ref={fnameRef} onClick={() => { removeError(fnameRef) }} >
            <CustomFormLabel>First Name</CustomFormLabel>
            <CustomTextField
                id="fname"
                placeholder="First Name"
                variant="outlined"
                fullWidth
                value={detail.fName}
                onChange={(e) => {
                    // setfName(e.target.value)
                    returnPanelData('fName', e.target.value)
                }}
            />
        </div>
        <div style={{ width: '100%' }} ref={mnameRef} onClick={() => { removeError(mnameRef) }}>
            <CustomFormLabel>Middle Name</CustomFormLabel>
            <CustomTextField
                id="mname"
                placeholder="Middle Name"
                variant="outlined"
                fullWidth
                value={detail.mName}
                onChange={(e) => {
                    returnPanelData('mName', e.target.value)
                }}
            />
        </div>
        <div style={{ width: '100%' }} ref={lnameRef} onClick={() => { removeError(lnameRef) }}>
            <CustomFormLabel>Last Name</CustomFormLabel>
            <CustomTextField
                id="lname"
                placeholder="Last Name"
                variant="outlined"
                fullWidth
                value={detail.lName}
                onChange={(e) => {
                    returnPanelData('lName', e.target.value)
                }}
            />
        </div>
        <div style={{ width: '100%' }} ref={emailRef} onClick={() => { removeError(emailRef) }}>
            <CustomFormLabel>Email</CustomFormLabel>
            <CustomTextField
                id="email"
                placeholder="Email"
                type="email"
                variant="outlined"
                fullWidth
                value={detail.email}
                onChange={(e) => {
                    returnPanelData('email', e.target.value)
                }}
            />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ width: '48%' }} ref={phoneRef} onClick={() => { removeError(phoneRef) }}>
                <CustomFormLabel>Phone Number</CustomFormLabel>
                <CustomTextField
                    id="phone"
                    type="mobile"
                    placeholder="Phone Number"
                    variant="outlined"
                    fullWidth
                    value={detail.phone}
                    onChange={(e) => {
                        returnPanelData('phone', e.target.value)
                    }}
                    onWheel={() => document.activeElement.blur()}
                />
            </div>

            <div style={{ width: '48%' }} ref={secPhoneRef} onClick={() => { removeError(secPhoneRef) }}>
                <CustomFormLabel>secondary Phone Number</CustomFormLabel>
                <CustomTextField
                    id="secPhone"
                    type="mobile"
                    placeholder="Secondary Phone Number"
                    variant="outlined"
                    fullWidth
                    value={detail.secPhone}
                    onChange={(e) => {
                        returnPanelData('secPhone', e.target.value)
                    }}
                    onWheel={() => document.activeElement.blur()}
                />
            </div>
        </div>





        {/* <div ref={dobRef}
            onClick={() => {
                removeError(dobRef)
                setShowDate(true)
            }}
            onFocus={() => {
                removeError(dobRef)
                setShowDate(true)
            }}

            onBlur={() => {
                if (dobDate === '') {
                    setShowDate(false)
                }
            }}
            className="dateDiv"
        > */}
        <div style={{ width: '100%' }} ref={dobRef} onClick={() => { removeError(dobRef) }}>
            <CustomFormLabel>D.O.B</CustomFormLabel>
            <CustomTextField
                id="dobdate"
                type={'date'}
                placeholder="D.O.B"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                value={detail.dob}
                onChange={(e) => {
                    if (moment(e.target.value, 'YYYY-MM-DD').utc().diff(moment().utc(), 'days') < 0 || moment(e.target.value, 'YYYY-MM-DD').format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                        returnPanelData('dob', e.target.value)
                    } else {
                        dispatch(showNotificationAction(true, { type: 'error', content: 'DOB in the future date cannot be selected.' }))
                        changeBorderColor(dobRef)

                    }
                }}
            />
            {/* {showDate ? '' : <FeatherIcon icon="calendar" className="calendaricon" />} */}
        </div>
        <div style={{ width: '100%' }} ref={genderRef} onClick={() => { removeError(genderRef) }}>
            <FormControl
                sx={{
                    width: '100%',
                }}
            >
                <CustomFormLabel>Gender</CustomFormLabel>
                <Box id="genderBox">
                    <FormControlLabel
                        checked={selectedValueGender === 'Female'}
                        onChange={handleChange3}
                        value="Female"
                        label="Female"
                        name="radio-button-demo"
                        control={<CustomRadio />}
                        inputprops={{ 'aria-label': 'A' }}
                    />
                    <FormControlLabel
                        checked={selectedValueGender === 'Male'}
                        onChange={handleChange3}
                        value="Male"
                        label="Male"
                        control={<CustomRadio />}
                        name="radio-button-demo"
                        inputprops={{ 'aria-label': 'B' }}
                    />
                </Box>
            </FormControl>
        </div>
        <div style={{ width: '100%' }} ref={statusRef} onClick={() => { removeError(statusRef) }}>
            <FormControl
                sx={{
                    width: '100%',
                }}
            >
                <CustomFormLabel>Status</CustomFormLabel>

                <Box id="statusBox">
                    <FormControlLabel
                        checked={selectedValueStatus === 'active'}
                        onChange={handleChange4}
                        value="active"
                        label="Active"
                        name="radio-button-demo"
                        control={<CustomRadio />}
                        inputprops={{ 'aria-label': 'A' }}
                    />
                    <FormControlLabel
                        checked={selectedValueStatus === 'inactive'}
                        onChange={handleChange4}
                        value="inactive"
                        label="Inactive"
                        control={<CustomRadio />}
                        name="radio-button-demo"
                        inputprops={{ 'aria-label': 'B' }}
                    />
                </Box>
            </FormControl>
        </div>
    </div>
}
export default IdentificationPanel