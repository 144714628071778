import { URL } from '../../config';
import axios from 'axios';
import {
    UPDATE_NOTE,
    DELETE_NOTE,
    FETCH_NOTES_SUCCESS,
    SHOW_NOTIFICATION,
    SHOW_SPINNER
} from '../constants';

export const fetchNotes = () => async (dispatch) => {
    try {
        let token = sessionStorage.getItem('token')
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let response = await axios.get(URL + 'note/all', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: FETCH_NOTES_SUCCESS,
            notes: response.data.data.notesList,
        });
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return;
    } catch (err) {
        dispatch({
            type: FETCH_NOTES_SUCCESS,
            notes: [],
        });
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        dispatch({
            type: SHOW_NOTIFICATION,
            isShow: true,
            content: { type: 'error', content: 'Something went wrong.' },
            origin: 'fetchNotes'
        })
        return err.response
    }
};

export const getPatientNotes = (data) => async (dispatch) => {
    try {
        let token = sessionStorage.getItem('token')
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let response = await axios.post(URL + 'note/patient', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: FETCH_NOTES_SUCCESS,
            notes: response.data.data.notesList,
        });
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data;
    } catch (err) {
        dispatch({
            type: FETCH_NOTES_SUCCESS,
            notes: [],
        });
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
};

export const addNotes = (data) => async (dispatch) => {
    try {
        let token = sessionStorage.getItem('token')
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let response = await axios.post(URL + 'note/add', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        dispatch({
            type: SHOW_NOTIFICATION,
            isShow: true,
            content: { type: 'error', content: 'Something went wrong.' },
            origin: 'addNotes'
        })
        return err.response
    }
};

export const updateNotes = (data) => async (dispatch) => {
    try {
        let token = sessionStorage.getItem('token')
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let response = await axios.post(URL + 'note/update', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: UPDATE_NOTE,
            notes: response.data,
        });
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data

    } catch (err) {
        dispatch({
            type: SHOW_NOTIFICATION,
            isShow: true,
            content: { type: 'error', content: 'Something went wrong.' },
            origin: 'updateNotes'
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return err.response
    }
};

export const deleteNotes = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        let response = await axios.post(URL + 'note/delete', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: DELETE_NOTE,
            notes: response.data,
        });
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data

    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        dispatch({
            type: SHOW_NOTIFICATION,
            isShow: true,
            content: { type: 'error', content: 'Something went wrong.' },
            origin: 'deleteNotes'
        })
        return err.response
    }
};