import { Suspense, useMemo, useState } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import FeatherIcon from 'feather-icons-react';
import {
    Card,
    CardContent,
    Typography,
    TableHead,
    Box,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TableFooter,
    IconButton,
    Avatar,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    FormControl,
    Button,
    TableContainer
} from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CustomTextField from '../forms/custom-elements/CustomTextField';
import CustomPasswordField from '../forms/custom-elements/CustomPasswordField';
// import { getUserList } from '../../../redux/patients/AsyncActions';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../views/spinner/Spinner';
import CustomFormLabel from '../forms/custom-elements/CustomFormLabel';
import { showNotificationAction } from '../../redux/pushnotification/Action'
import { getAllHospital, updateHospitalStatusAction } from '../../redux/Hospital/AsyncAction';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const HospitalList = ({ type }) => {
    const dispatch = useDispatch()

    const [openEdit, setOpenEdit] = useState(false)
    const [updateHospitalData, setUpdateHospitalData] = useState(null)
    const [viewHospital, setviewHospital] = useState(null)
    const [rows, setRows] = useState([])

    const hospitalArr = useSelector((state) => state.HospitalReducer.hospitalList);
    useMemo(() => {
        if (hospitalArr.activeHosp || hospitalArr.inactiveHosp) {
            setRows([])
            if (type === 'active')
                setRows(hospitalArr?.activeHosp)
            if (type === 'inactive')
                setRows(hospitalArr?.inactiveHosp)

        }
    }, [hospitalArr, type])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const search = "";
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleClose = () => {
        setOpenEdit(false);
        setviewHospital(null)
    };
    const showHospitalHandler = (row) => {
        setviewHospital(row)
        setOpenEdit(true)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const Row = (props) => {
        const { row } = props;
        // const [open, setOpen] = React.useState(false);

        return (
            <>
                <TableRow>
                    <TableCell>
                        <Typography variant="h5" fontWeight="400">
                            {row && row.hospitalCode !== null && row.hospitalCode !== '' ? row.hospitalCode : '-'}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="h5" fontWeight="400">
                            {row && row.hospitalName !== null
                                && row.hospitalName
                                !== '' ? row.hospitalName
                                : '-'}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="h5" fontWeight="400">
                            {row && row.userName !== null
                                && row.userName
                                !== '' ? row.userName
                                : '-'}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="h5" fontWeight="400">
                            {row && row.email !== null && row.email !== '' ? row.email : '-'}
                        </Typography>
                    </TableCell>
                    <TableCell sx={{
                        display: 'flex'
                    }}>

                        <Tooltip title="View detail">
                            <span>
                                <FeatherIcon icon={'eye'} style={{ margin: '0 6px' }} onClick={() => {
                                    showHospitalHandler(row)
                                }} />
                            </span>
                        </Tooltip>

                        {row && row.status !== null && row.status !== "" ?
                            row.status === 'active'
                                ? <span onClick={() => { changeStatusHandler('inactive', row) }}>
                                    {getActionIcon('Inactive', 'error', 'x')}
                                </span>
                                : row.status === 'inactive'
                                    ? <span onClick={() => { changeStatusHandler('active', row) }}>
                                        {getActionIcon('Activate', 'success', 'check')}
                                    </span>
                                    : 'sadasds'
                            : '-'}
                    </TableCell>
                </TableRow>
            </>
        );
    }

    const changeStatusHandler = async (newstatus, row) => {
        const { email, hospitalCode, hospitalId, hospitalName, userName } = row
        let data = {
            email, hospitalCode, hospitalId, hospitalName, userName, status: newstatus
        }
        let result = await dispatch(updateHospitalStatusAction(data))
        if (result && result.status === true) {
            dispatch(getAllHospital())
            setUpdateHospitalData(null)
            setOpenEdit(false)
        } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
            dispatch(showNotificationAction(true, { type: 'error', content: result.data.message }))
        } else {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.' }))
        }
    }

    const getActionIcon = (tooltipTitle, colortype, iconName) => {
        return <Tooltip title={tooltipTitle}>
            <Avatar
                sx={{
                    backgroundColor: colortype === 'success'
                        ? (theme) => theme.palette.success.main
                        : colortype === 'secondary'
                            ? (theme) => theme.palette.secondary.main
                            : colortype === 'error'
                                ? (theme) => theme.palette.error.main
                                : (theme) => theme.palette.error.main,
                    width: '25px',
                    height: '25px',
                    color: '#fff',
                }}
            >
                <FeatherIcon icon={iconName} width="15px" />
            </Avatar>
        </Tooltip >
    }

    let slicedRows = []
    let newRows = rowsPerPage > 0
        ? rows.filter(f => f.hospitalCode && f.hospitalCode.toUpperCase().includes(search.toUpperCase()))
        : rows
    if (newRows.length < rowsPerPage) {
        slicedRows = newRows.slice(0, page * rowsPerPage + rowsPerPage)
    } else {
        slicedRows = newRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }

    return (<div className="pastVisits" style={{ width: '100%' }}>
        {/* <Spinner /> */}
        <Suspense fallback={<Spinner />}>
            <Card>
                <CardContent>

                    <Box
                        sx={{
                            overflow: {
                                xs: 'auto',
                                sm: 'auto',
                                md: 'auto',

                            },
                        }}
                    >
                        <TableContainer sx={{
                            maxHeight: { lg: 520, md: 520, sm: 400, xs: 400 },
                        }}>
                            <Table
                                stickyHeader aria-label="sticky table"
                                sx={{
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h5" fontWeight='600' >Hospital Code</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h5" fontWeight='600' >Hospital Name</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h5" fontWeight='600' >Username</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h5" fontWeight='600' >Email</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h5" fontWeight='600' >Action</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {slicedRows.map((row, i) => (
                                        <Row key={i} row={row} />
                                    ))
                                    }
                                    {slicedRows.length <= 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={7} >
                                                <Typography variant="h6" fontWeight="600" justifyContent="center"  >
                                                    No data available.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
                                            colSpan={6}
                                            count={rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputprops: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Box>
                </CardContent>
            </Card>
            <Dialog open={openEdit} onClose={handleClose} fullWidth >
                <DialogContent className='appointmentForm' sx={{ padding: '20px 16px' }}>
                    <Typography sx={{ mb: 2, ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} id="" component="div"  >
                        <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            {updateHospitalData ? 'Update Hospital' : 'View Hospital'}
                        </Typography>
                        <FeatherIcon icon="x" style={{
                            width: 20, height: 20, marginLeft: '5px', color: 'white', borderRadius: '50%', background: '#1990FC', padding: '2px'
                        }}
                            onClick={handleClose} />
                    </Typography>
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={6}
                            sx={{
                                background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#EBF5FF'}`,
                                p: 1
                            }}
                        >
                            <CustomFormLabel htmlFor="demo-simple-select">Hospital Name</CustomFormLabel>
                            <CustomTextField
                                variant="outlined"
                                fullWidth
                                // size="small"
                                placeholder=""
                                disabled={!updateHospitalData}
                                value={updateHospitalData ? updateHospitalData.hospitalName : viewHospital ? viewHospital.hospitalName : ''}
                                onChange={(e) => {
                                    setUpdateHospitalData({ ...updateHospitalData, hospitalName: e.target.value })
                                }}

                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={6}
                            sx={{
                                background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#EBF5FF'}`,
                                p: 1
                            }}
                        >
                            <CustomFormLabel htmlFor="demo-simple-select">User Name</CustomFormLabel>
                            <CustomTextField
                                variant="outlined"
                                fullWidth
                                // size="small"
                                placeholder=""
                                disabled={!updateHospitalData}
                                value={updateHospitalData ? updateHospitalData.userName : viewHospital ? viewHospital.userName : ''}
                                onChange={(e) => {
                                    setUpdateHospitalData({ ...updateHospitalData, userName: e.target.value })
                                }}

                            />
                        </Grid>
                    </Grid>
                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <CustomFormLabel>Email </CustomFormLabel>
                            <CustomTextField
                                variant="outlined"
                                fullWidth
                                // size="small"
                                placeholder=""
                                disabled={!updateHospitalData}
                                value={updateHospitalData ? updateHospitalData.email : viewHospital ? viewHospital.email : ''}
                                onChange={(e) => {
                                    setUpdateHospitalData({ ...updateHospitalData, email: e.target.value })
                                }}
                            />
                        </FormControl>
                    </div>
                    <div style={{ width: '100%', marginTop: '10px', padding: '0 8px' }}>
                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <CustomFormLabel>Password </CustomFormLabel>
                            <CustomPasswordField
                                variant="outlined"
                                fullWidth
                                // size="small"
                                placeholder=""
                                disabled={!updateHospitalData}
                                value={updateHospitalData ? updateHospitalData.password : viewHospital ? viewHospital.password : ''}
                                onChange={(e) => {
                                    setUpdateHospitalData({ ...updateHospitalData, password: e })
                                }}
                            />
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions className=''>

                    {updateHospitalData && <Box>
                        <Button type="button"
                            variant="contained"
                            onClick={handleClose}
                            sx={{
                                mr: '8px',
                                background: "#888888"

                            }}
                        >Cancel</Button>
                    </Box>}
                </DialogActions>
            </Dialog >
        </Suspense>
    </div>
    );
};

export default HospitalList;

