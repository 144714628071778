import axios from "axios";
import {
    SHOW_SPINNER,
    SHOW_NOTIFICATION,
    GET_DASHBOARD_COUNT,
    MONTHWISE_APPOINTMENT
} from '../constants';
import { URL } from '../../config';

export const getDashboardData = (role) => async (dispatch) => {
    try {
        let token = sessionStorage.getItem('token')
        // dispatch({
        //     type: SHOW_SPINNER,
        //     data: true
        // });
        const response = await axios.get(URL + 'user/dashboard', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data.data) {
            let { hospitalCount, appointmentCount, doctorCount, patientCount, appointmentMonthWise, receptionistCount } = response.data.data
            if (role === 'admin') {
                dispatch({
                    type: GET_DASHBOARD_COUNT,
                    data: [
                        { title: 'Hospitals', count: hospitalCount },
                        { title: 'Doctors', count: doctorCount },
                        { title: 'Receptionist', count: receptionistCount },
                        { title: 'Patients', count: patientCount },
                        { title: 'Appointments', count: appointmentCount }
                    ]
                });
            } else {
                dispatch({
                    type: GET_DASHBOARD_COUNT,
                    data: [
                        { title: 'Doctors', count: doctorCount },
                        { title: 'Receptionist', count: receptionistCount },
                        { title: 'Patients', count: patientCount },
                        { title: 'Appointments', count: appointmentCount }
                    ]
                });
            }

            dispatch({
                type: MONTHWISE_APPOINTMENT,
                data: appointmentMonthWise
            });
        }
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        dispatch({
            type: SHOW_NOTIFICATION,
            isShow: true,
            content: { type: 'error', content: 'Something went wrong.' },
            origin: 'getDashboardData'
        })
        return err.response
    }
}
