import React from 'react';
import { Dialog, DialogContent, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ViewImage = ({ viewImage, setViewImage }) => {
    return <Dialog
        open={viewImage.open}
        TransitionComponent={Transition}
        onClose={() => {
            setViewImage({ open: false, imgUrl: "" })
        }} fullWidth className="summaryVaccineCard" >
        <DialogContent>
            <img src={viewImage.imgUrl} alt="document" style={{ maxWidth: '800px' }} />
        </DialogContent>
    </Dialog>
}
export default ViewImage