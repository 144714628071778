import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import FeatherIcon from 'feather-icons-react';
import Typography from '@mui/material/Typography';
import CustomTextField from '../../../forms/custom-elements/CustomTextField';
import TextField from '@mui/material/TextField';
import { useDetectClickOutside } from 'react-detect-click-outside';
import {
  Checkbox,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@mui/material';
import CustomCheckbox from '../../../forms/custom-elements/CustomCheckbox';
import axios from 'axios';
import { URL } from '../../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_NOTIFICATION } from '../../../../redux/constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SearchSiblings({
  toggle,
  open,
  patientId,
  getPatientLists,
}) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const dispatch = useDispatch();
  let token = sessionStorage.getItem('token');
  const [showSearchResults, setShowSearchResults] = React.useState(false);
  const ref = useDetectClickOutside({
    onTriggered: () => setShowSearchResults(false),
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const hospitalSelectedState = useSelector(
    (state) => state.HospitalReducer.hospitalSelected
  );
  const userinfo = useSelector((state) => state.AuthenticationReducer.userinfo);
  const patientsArr = useSelector((state) => state.SiblingReducer.siblings);
  const patientArrIds = patientsArr.map((item) => item.patientId);
  const [selectedPatients, setSelectedPatients] = React.useState([]);
  React.useEffect(() => {
    setResults([]);
    if (!search.trim()) return;
    const fetchData = async () => {
      const body = {
        search,
        patientId,
      };
      if (userinfo?.role === 'admin') {
        body.hospitalId = hospitalSelectedState?.hospitalId;
      } else if (userinfo?.role !== 'admin') {
        body.hospitalId = userinfo?.hospitalId;
      }
      try {
        setIsLoading(true);
        const { data } = await axios.post(URL + 'patient/search', body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const results = data.data;
        setResults(results);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    const countId = setTimeout(() => {
      fetchData();
    }, 500);
    return () => {
      clearTimeout(countId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  const onSelect = (val) => {
    const findData = selectedPatients.find(
      (i) => i.patientId === val.patientId
    );
    if (findData) {
      setSelectedPatients((prevS) =>
        prevS.filter((item) => item.patientId !== val.patientId)
      );
    } else {
      setSelectedPatients([...selectedPatients, val]);
    }
  };
  const onSubmitHandler = async () => {
    const patientSiblings = selectedPatients.map((item) => ({
      patientId,
      siblingId: item.patientId,
    }));
    const siblingPatients = selectedPatients.map((item) => ({
      patientId: item.patientId,
      siblingId: patientId,
    }));
    const body = [...patientSiblings, ...siblingPatients];
    try {
      setIsSubmitting(true);
      await axios.post(URL + 'sibling/add', body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: SHOW_NOTIFICATION,
        isShow: true,
        content: 'Sibling linked successfully',
        origin: 'saveNewPatient',
      });
      getPatientLists();
      toggle();
    } catch (error) {
      console.log(error);
      dispatch({
        type: SHOW_NOTIFICATION,
        isShow: true,
        content: 'Something went wrong.',
        origin: 'saveNewPatient',
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  const filteredResults = results.filter(
    (item) => !patientArrIds.includes(item.patientId)
  );
  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={toggle}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box sx={{ position: 'relative' }} ref={ref}>
            <Box sx={{ border: '1px solid #ddd' }}>
              <TextField
                onFocus={() => setShowSearchResults(true)}
                placeholder='Search Siblings'
                variant='outlined'
                fullWidth
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Box>
            {isLoading && (
              <Box
                sx={{
                  backgroundColor: '#ebf5ff',
                  position: 'absolute',
                  width: '100%',
                  zIndex: 100,
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {filteredResults?.length > 0 && showSearchResults && (
              <List
                sx={{
                  backgroundColor: '#ebf5ff',
                  position: 'absolute',
                  width: '100%',
                  zIndex: 100,
                }}
              >
                {results.map((item) => (
                  <ListItem
                    key={item.patientId}
                    sx={{ borderBottom: '1px solid #ddd' }}
                    onClick={() => onSelect(item)}
                  >
                    <CustomCheckbox
                      checked={Boolean(
                        selectedPatients.find(
                          (i) => i.patientId === item.patientId
                        )
                      )}
                      name={`${item.firstName} ${item.lastName}`}
                      color='primary'
                    />
                    <ListItemText
                      primary={`${item.firstName} ${item.lastName}`}
                      // secondary={secondary ? 'Secondary text' : null}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          <List
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              listStyle: 'none',
              p: 0.5,
              m: 0,
            }}
          >
            {selectedPatients.map((data, index) => (
              <ListItem sx={{ width: 'fit-content' }} key={index}>
                <Chip
                  label={`${data.firstName} ${data.lastName}`}
                  onDelete={() =>
                    setSelectedPatients((prevS) =>
                      prevS.filter((item) => item.patientId !== data.patientId)
                    )
                  }
                />
              </ListItem>
            ))}
          </List>
          <Box sx={{ marginTop: 4 }}>
            <Button
              variant='contained'
              color='primary'
              className='addpatientBtn'
              onClick={onSubmitHandler}
              disabled={!Boolean(selectedPatients.length) || isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={30} />
              ) : (
                <>
                  <FeatherIcon icon='plus' style={{ width: 15, height: 15 }} />
                  Save
                </>
              )}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
