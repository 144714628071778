import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  Dialog,
  Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMedicalSummary,
  getVaccineHistoryByAge,
} from '../../../redux/patients/AsyncActions';
import MedicalSummaryIcon from '../../../assets/icons/MedicalSummaryIcon.png';
import moment from 'moment/moment';
import VaccineHistory from '../NewVisit/VisitPages/VaccineHistory';
import { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import NewVaccination from './NewVaccination';
import { useMemo } from 'react';
import MedicalSummaryIssues from './MedicalSummaryIssues';
import MedicalSummaryMedication from './MedicalSummaryMedication';

const MedicalSummary = () => {
  const { state } = useLocation();
  const { id } = state ? state : {};
  const dispatch = useDispatch();
  const medicalSummaryData = useSelector(
    (state) => state.patientReducer.medicalSummary
  );

  const historyByAge = useSelector(
    (state) => state.patientReducer.vaccinationHistoryByAge
  );
  const vaccineDetail = useMemo(() => {
    if (historyByAge) return historyByAge;
    else return [];
  }, [historyByAge]);

  const userinfo = useSelector((state) => state.AuthenticationReducer.userinfo);

  // const [vaccineDetail, setvaccineDetail] = useState({});
  const [open, setopen] = useState(false);
  const [openNewVaccine, setopenNewVaccine] = useState(false);

  useEffect(() => {
    dispatch(getMedicalSummary(id));
  }, [dispatch, id]);

  const getHistory = () => {
    dispatch(getVaccineHistoryByAge(id)).then((result) => {
      setopen(true);
    });
  };

  const openAddVaccineHandler = (val) => {
    setopenNewVaccine(val);
  };

  const formatString = (str) => {
    if (str)
      return str
        .replaceAll('&#x2F;', '/')
        .replaceAll('&amp;', '&')
        .replaceAll('&#96;', '`')
        .replaceAll('&quot;', '"')
        .replaceAll('&lt;', '<')
        .replaceAll('&gt;', '>')
        .replaceAll('&quot;', '"')
        .replaceAll('&#x27;', "'")
        .replaceAll('&#x2F;', '/')
        .replaceAll('&#x5C;', '\\');
  };

  return (
    <>
      <Card
        sx={{
          p: {
            xs: '10px',
            sm: '10px',
            lg: '20px',
          },
          width: '100%',
        }}
      >
        <CardContent>
          <Typography
            sx={{
              flex: '1 1 100%',
              p: {
                xs: '10px',
                sm: '10px',
                lg: '0 10px',
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              },
              color: '#464646',
            }}
            variant='h4'
            id=''
            fontWeight='500'
            component='div'
          >
            <img src={MedicalSummaryIcon} className='tabIcon' alt='logoImg' />
            Medical Summary
          </Typography>
          <Grid container spacing={0} className='medicalSummaryCard'>
            <Grid item xs={12} md={6} lg={6}>
              <Card
                sx={{
                  p: {
                    xs: '10px',
                    sm: '10px',
                    lg: '20px',
                  },
                }}
              >
                <Typography
                  component='div'
                  color='textSecondary'
                  variant='h5'
                  fontWeight='500'
                  sx={{
                    mr: '2px',
                  }}
                >
                  Allergies
                </Typography>
                <Box sx={{ width: '100%', height: '150px', overflow: 'auto' }}>
                  {medicalSummaryData &&
                  medicalSummaryData.allergies &&
                  medicalSummaryData.allergies.filter(
                    (f) => f.allergen && f.allergen !== ''
                  ).length > 0 ? (
                    medicalSummaryData.allergies.map((a, i) => {
                      return (
                        <Typography
                          variant='h6'
                          fontWeight='600'
                          key={i}
                          sx={{
                            fontSize: { xs: '12px', md: '12px', lg: '13px' },
                          }}
                        >
                          {a.allergen && a.allergen.toUpperCase() === 'OTHER'
                            ? formatString(a.allergenOtherExplain)
                            : formatString(a.allergen)}
                        </Typography>
                      );
                    })
                  ) : (
                    <Typography
                      variant='h6'
                      fontWeight='600'
                      sx={{
                        fontSize: { xs: '12px', md: '12px', lg: '13px' },
                      }}
                    >
                      No data available.
                    </Typography>
                  )}
                </Box>
                {/* below box only for UI purpose */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    opacity: 0,
                  }}
                >
                  <Typography
                    variant='h6'
                    fontWeight='500'
                    component={'span'}
                    fontSize='12px'
                  >
                    page 1/5
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <button style={{ cursor: 'pointer' }}>&lt;</button>
                    <button style={{ cursor: 'pointer' }}>&gt;</button>
                  </Box>
                </Box>
              </Card>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6}>
              <Card
                sx={{
                  p: {
                    xs: '10px',
                    sm: '10px',
                    lg: '20px',
                  },
                }}
              >
                <Typography
                  component='div'
                  color='textSecondary'
                  variant='h5'
                  fontWeight='500'
                  sx={{
                    mr: '2px',
                  }}
                >
                  Medical Issues
                </Typography>
                <Box sx={{ width: '100%', height: '150px', overflow: 'auto' }}>
                  {medicalSummaryData &&
                  medicalSummaryData.medicalIssues &&
                  medicalSummaryData.medicalIssues.filter(
                    (f) => f.diagnosisInformant && f.diagnosisInformant !== ''
                  ).length > 0 ? (
                    medicalSummaryData.medicalIssues.map((a, i) => {
                      if (a.diagnosisInformant !== '')
                        return (
                          <Box key={i}>
                            <Typography
                              variant='h6'
                              fontWeight='600'
                              component={'span'}
                              sx={{
                                fontSize: {
                                  xs: '12px',
                                  md: '12px',
                                  lg: '13px',
                                },
                              }}
                            >
                              {formatString(a.diagnosisInformant)} &nbsp;
                            </Typography>
                            <Typography
                              variant='h6'
                              fontWeight='500'
                              component={'span'}
                              fontSize='12px'
                            >
                              at {moment(a.createdAt).format('DD/MM/YYYY')}
                            </Typography>
                          </Box>
                        );
                      else return null;
                    })
                  ) : (
                    <Typography
                      variant='h6'
                      fontWeight='600'
                      sx={{
                        fontSize: { xs: '12px', md: '12px', lg: '13px' },
                      }}
                    >
                      No data available.
                    </Typography>
                  )}
                </Box>
              </Card>
            </Grid> */}
            <MedicalSummaryIssues />
          </Grid>
          <Grid container spacing={0} className='medicalSummaryCard'>
            <MedicalSummaryMedication />
            <Grid item xs={12} md={6} lg={6}>
              <Card
                sx={{
                  p: {
                    xs: '10px',
                    sm: '10px',
                    lg: '20px',
                  },
                }}
              >
                <Grid container spacing={0} className='medicalSummaryCard'>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography
                      component='div'
                      color='textSecondary'
                      variant='h5'
                      fontWeight='500'
                      sx={{
                        mr: '2px',
                        display: 'flex',
                      }}
                    >
                      Vaccinations &nbsp;
                      {userinfo?.role !== 'admin' && (
                        <Tooltip title='Add new vaccination'>
                          <span>
                            <FeatherIcon
                              icon='plus'
                              width='20'
                              style={{
                                width: 16,
                                height: 16,
                                fontWeight: '700',
                                marginRight: '5px',
                                background: 'rgba(0, 0, 0, 0.4)',
                                color: 'white',
                                borderRadius: '50%',
                                padding: '2px',
                              }}
                              onClick={() => {
                                openAddVaccineHandler(true);
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {openNewVaccine && (
                        <NewVaccination
                          changeopenNewVaccine={openAddVaccineHandler}
                        />
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <Typography
                      component='span'
                      fontWeight='500'
                      sx={{
                        float: 'right',
                        color: '#1990FC',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        getHistory();
                      }}
                    >
                      View Vaccine Card
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ width: '100%', height: '150px', overflow: 'auto' }}>
                  {medicalSummaryData &&
                    medicalSummaryData.vaccinations &&
                    medicalSummaryData.vaccinations.map((a, i) => {
                      if (a.vaccination !== '')
                        return (
                          <Box key={i} sx={{ mr: 2 }}>
                            <Typography
                              variant='h6'
                              fontWeight='600'
                              component={'span'}
                              sx={{
                                fontSize: {
                                  xs: '12px',
                                  md: '12px',
                                  lg: '13px',
                                },
                              }}
                            >
                              {a.vaccination ? formatString(a.vaccination) : ''}{' '}
                              {a.dose ? ' - Dose ' + a.dose : '-'}&nbsp;
                            </Typography>
                            <Typography
                              variant='h6'
                              fontWeight='500'
                              component={'span'}
                              sx={{
                                fontSize: { xs: '10px', lg: '12px' },
                              }}
                            >
                              at{' '}
                              {moment(
                                a.vaccinationDate
                                  ? a.vaccinationDate
                                  : a.createdAt
                              ).format('DD/MM/YYYY')}
                              {/* if vaccination date is not present then show created date because imported data dont have vaccination date */}
                            </Typography>
                          </Box>
                        );
                      else return null;
                    })}
                  {(!medicalSummaryData ||
                    !medicalSummaryData.vaccinations ||
                    medicalSummaryData.vaccinations.length <= 0 ||
                    medicalSummaryData.vaccinations.filter(
                      (f) => f.vaccination !== ''
                    ).length <= 0) && (
                    <Typography
                      variant='h6'
                      fontWeight='600'
                      sx={{
                        fontSize: { xs: '12px', md: '12px', lg: '13px' },
                      }}
                    >
                      No data available.
                    </Typography>
                  )}
                </Box>
                {/* below box only for ui purpose */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    opacity: 0,
                  }}
                >
                  <Typography
                    variant='h6'
                    fontWeight='500'
                    component={'span'}
                    fontSize='12px'
                  >
                    page 1/5
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <button style={{ cursor: 'pointer' }}>&lt;</button>
                    <button style={{ cursor: 'pointer' }}>&gt;</button>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>

          <Dialog
            aria-labelledby='simple-dialog-title'
            open={open}
            fullWidth
            className='summaryVaccineCard'
            onClose={() => setopen(false)}
          >
            <VaccineHistory
              vaccineData={vaccineDetail && vaccineDetail.history}
            />
          </Dialog>
        </CardContent>
      </Card>
    </>
  );
};

export default MedicalSummary;
