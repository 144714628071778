import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Tabs,
  Tab,
  Toolbar,
} from '@mui/material';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import VisitObservationIcon from '../../../../assets/icons/VisitObservationIcon.png';
import DiagnosisIcon from '../../../../assets/icons/DiagnosisIcon.png';
import AllergyIcon from '../../../../assets/icons/AllergyIcon.png';
import VaccineIcon from '../../../../assets/icons/VaccineIcon.png';
import ExamIcon from '../../../../assets/icons/ExamIcon.png';
import GrowthIcon from '../../../../assets/icons/GrowthIcon.png';
import TextareaAutosize from 'react-textarea-autosize';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const VisitObservation = ({ changeShowVisitObservation }) => {
  const observation = useSelector(
    (state) => state.patientReducer.observationData
  );
  const allergy = useSelector((state) => state.patientReducer.allergyData);
  const vaccination = useSelector(
    (state) => state.patientReducer.vaccinationData
  );
  const growth = useSelector((state) => state.patientReducer.growthData);
  const examination = useSelector(
    (state) => state.patientReducer.examinationData
  );
  const diagnosis = useSelector((state) => state.patientReducer.diagnosisData);

  const [value, setValue] = React.useState(0);
  const observationRef = useRef(null);
  const allergyRef = useRef(null);
  const vaccinationRef = useRef(null);
  const examinationRef = useRef(null);
  const growthRef = useRef(null);
  const diagnosisRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const scrollDown = (ref) => {
    if (ref.current) {
      let scrollToPx = ref.current.offsetTop - 100;
      window.scrollTo({
        top: scrollToPx,
        behavior: 'smooth',
      });
    }
  };

  const formatText = (text) => {
    // return text.split('\n').map(m =>
    //     <div>{m}</div>)
    // let rowsNo = 2
    // let colNo = text.split('\n').length
    // if (colNo && typeof colNo === 'number') {
    //     rowsNo = colNo
    // }
    // return <textarea style={{ background: '#EBF5FF', border: 'none', width: '100%', color: 'black', fontSize: '14px' }} rows={rowsNo} disabled value={text}></textarea>
    return (
      <TextareaAutosize
        maxRows={7}
        value={text}
        className='visitAutoSizeTextArea'
        disabled
      />
    );
  };

  return (
    <Card
      sx={{
        p: {
          xs: '0px',
          sm: '0px',
          lg: '0px',
        },
        width: '100%',
      }}
      className='newPatientCard'
    >
      <CardContent
        sx={{
          p: 0,
        }}
      >
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 2, sm: 2 },
            bgcolor: (theme) => '#1990FC',
          }}
        >
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant='h5'
            id='newpatientTitle'
            component='span'
          >
            Visit Overview
          </Typography>
          <Typography
            sx={{
              flex: '1 1 100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            onClick={() => {
              changeShowVisitObservation(false);
            }}
            variant='h5'
            id='newpatientTitle'
            component='span'
          >
            <FeatherIcon
              icon='edit'
              style={{
                width: 14,
                height: 14,
                marginRight: '5px',
                color: 'white',
              }}
            />
            Edit
          </Typography>
        </Toolbar>
        <Box
          sx={{
            width: '100%',
            p: {
              xs: '20px',
              sm: '35px',
              lg: '35px',
            },
            pt: {
              xs: '10px',
              sm: '10px',
              lg: '10px',
            },
          }}
          className='newPatientCardBox'
        >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
              textColor='secondary'
              allowScrollButtonsMobile
              scrollButtons
              indicatorColor='secondary'
            >
              <Tab
                sx={{
                  textTransform: 'capitalize',
                }}
                label='Observations'
                {...a11yProps(0)}
                onClick={() => scrollDown(observationRef)}
              />
              <Tab
                sx={{
                  textTransform: 'capitalize',
                }}
                label='Allergy'
                {...a11yProps(1)}
                onClick={() => scrollDown(allergyRef)}
              />
              <Tab
                sx={{
                  textTransform: 'capitalize',
                }}
                label='Vaccination'
                {...a11yProps(2)}
                onClick={() => scrollDown(vaccinationRef)}
              />
              <Tab
                sx={{
                  textTransform: 'capitalize',
                }}
                label='Examination Findings'
                {...a11yProps(3)}
                onClick={() => scrollDown(examinationRef)}
              />
              <Tab
                sx={{
                  textTransform: 'capitalize',
                }}
                label='Growth Parameters & Vitals'
                {...a11yProps(4)}
                onClick={() => scrollDown(growthRef)}
              />
              {/* <Tab
                                sx={{
                                    textTransform: 'capitalize',
                                }}
                                label="Document Uploads"
                                {...a11yProps(5)}
                                onClick={() => scrollDown(documentRef)}
                            /> */}
              <Tab
                sx={{
                  textTransform: 'capitalize',
                }}
                label='Diagnosis & Plan'
                {...a11yProps(5)}
                onClick={() => scrollDown(diagnosisRef)}
              />
            </Tabs>
          </Box>
          {observation.selectedVisitType === 'First Visit' ||
          observation.selectedVisitType === 'Follow Up Visit' ? (
            <TabPanel value={value} index={0} component='div'>
              <div ref={observationRef}>
                <Typography
                  className='panelHeading'
                  sx={{
                    marginTop: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                >
                  <img
                    src={VisitObservationIcon}
                    className='tabIcon'
                    alt='logoImg'
                    style={{ margin: 0 }}
                  />
                  Visit Observations-{' '}
                  {observation.selectedVisitType
                    ? observation.selectedVisitType
                    : 'Not available.'}
                </Typography>

                <div className='observationtabpanelContent'>
                  <Typography className='' variant='h5' fontWeight='500'>
                    {' '}
                    Overview{' '}
                  </Typography>
                  <Box sx={{ width: '100%' }}>
                    <Typography color='textSecondary' component='span'>
                      Informant:{' '}
                    </Typography>
                    {observation.informant
                      ? observation.informant
                      : 'Not available.'}
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Typography color='textSecondary' component='p'>
                      Current issues:{' '}
                    </Typography>
                    {observation.currentIssues
                      ? formatText(observation.currentIssues)
                      : 'Not available.'}
                  </Box>
                  {/* <Typography className='' color="" variant="h5" fontWeight="400"> 
                            <Typography color="textSecondary" component="span">Status:  </Typography>{observation.selectedStatusType ? observation.selectedStatusType : 'Not available.'} </Typography> */}
                  <Box sx={{ width: '100%' }}>
                    <Typography color='textSecondary' component='span'>
                      {' '}
                      Medications:{' '}
                    </Typography>
                    {observation.medications
                      ? formatText(observation.medications)
                      : 'Not available.'}
                  </Box>
                </div>
                {observation.selectedVisitType === 'First Visit' && (
                  <>
                    <div className='observationtabpanelContent'>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='500'
                      >
                        Antenatal History
                      </Typography>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Mother’s age:{' '}
                        </Typography>
                        {observation.mothersAge
                          ? observation.mothersAge
                          : 'Not available.'}
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Parity:{' '}
                        </Typography>
                        {observation.gravida
                          ? observation.gravida
                          : 'Not available.'}
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Complications & Medications:{' '}
                        </Typography>
                        {observation.compMed
                          ? formatText(observation.compMed)
                          : 'Not available.'}
                      </Box>
                      <Box>
                        <Typography color='textSecondary'>
                          Blood investigations:{' '}
                        </Typography>
                        <Typography
                          className=''
                          color=''
                          variant='h5'
                          fontWeight='400'
                          component='ul'
                        >
                          {observation.bloodInvestigationValues
                            ? Object.keys(
                                observation.bloodInvestigationValues
                              ).map((el) => {
                                return (
                                  <Typography
                                    className=''
                                    color=''
                                    variant='h5'
                                    fontWeight='400'
                                    component='li'
                                  >
                                    {el} -{' '}
                                    {observation.bloodInvestigationValues[el]
                                      ? observation.bloodInvestigationValues[el]
                                      : 'Not available.'}
                                  </Typography>
                                );
                              })
                            : 'Not available.'}
                        </Typography>
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Details:{' '}
                        </Typography>
                        {observation.bloodDetail
                          ? formatText(observation.bloodDetail)
                          : 'Not available.'}
                      </Box>
                    </div>
                    <div className='observationtabpanelContent'>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='500'
                      >
                        Birth History
                      </Typography>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Gestational Age:{' '}
                        </Typography>
                        {observation.gestationalAge
                          ? observation.gestationalAge
                          : 'Not available.'}
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Mode of delivery :{' '}
                        </Typography>{' '}
                        {observation.modeOfDelivery
                          ? observation.modeOfDelivery
                          : 'Not available.'}
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Indication for LSCS:{' '}
                        </Typography>
                        {observation.indicationForLscs
                          ? observation.indicationForLscs
                          : 'Not available.'}
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Details:{' '}
                        </Typography>
                        {observation.birthHistoryDetail
                          ? formatText(observation.birthHistoryDetail)
                          : 'Not available.'}
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Birth weight:{' '}
                        </Typography>
                        {observation.birthWeight
                          ? observation.birthWeight + ' KG'
                          : 'Not available.'}
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Length:{' '}
                        </Typography>
                        {observation.lengthBirth
                          ? observation.lengthBirth + ' CM'
                          : 'Not available.'}
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Head Circumference:{' '}
                        </Typography>
                        {observation.headCircumference
                          ? observation.headCircumference
                          : 'Not available.'}
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          APGAR score:{' '}
                        </Typography>
                        {observation.apgarScore
                          ? observation.apgarScore
                          : 'Not available.'}
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Complications:{' '}
                        </Typography>
                        {observation.compBirth
                          ? formatText(observation.compBirth)
                          : 'Not available.'}
                      </Box>
                    </div>
                    <div className='observationtabpanelContent'>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='500'
                      >
                        History
                      </Typography>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Past medical History:{' '}
                        </Typography>
                        {observation.pastMedicalHistory
                          ? formatText(observation.pastMedicalHistory)
                          : 'Not available.'}
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Family History:{' '}
                        </Typography>
                        {observation.familyHistory
                          ? formatText(observation.familyHistory)
                          : 'Not available.'}
                      </Box>
                    </div>
                  </>
                )}
                <div className='observationtabpanelContent'>
                  <Typography
                    className=''
                    color=''
                    variant='h5'
                    fontWeight='500'
                  >
                    {' '}
                    Diet
                  </Typography>
                  <Box sx={{ width: '100%' }}>
                    {observation.dietValues ? (
                      Object.keys(observation.dietValues).filter(
                        (f) => observation.dietValues[f] === true
                      ).length > 0 ? (
                        Object.keys(observation.dietValues).map((el) => {
                          return (
                            <>
                              {observation.dietValues[el] === true ? (
                                el === 'Breastmilk' ? (
                                  <Typography
                                    variant='h5'
                                    fontWeight='400'
                                    component='li'
                                  >
                                    {' '}
                                    Breast milk
                                  </Typography>
                                ) : el === 'Formulafeeds' ? (
                                  <Typography
                                    variant='h5'
                                    fontWeight='400'
                                    component='li'
                                  >
                                    {' '}
                                    Formula feeds
                                  </Typography>
                                ) : el === 'Weaningontosolids' ? (
                                  <Typography
                                    variant='h5'
                                    fontWeight='400'
                                    component='li'
                                  >
                                    {' '}
                                    Weaning onto solids
                                  </Typography>
                                ) : el === 'FamilyPot' ? (
                                  <Typography
                                    variant='h5'
                                    fontWeight='400'
                                    component='li'
                                  >
                                    {' '}
                                    Family Pot
                                  </Typography>
                                ) : el === 'PickyEaterPoorEatingHabits' ? (
                                  <Typography
                                    variant='h5'
                                    fontWeight='400'
                                    component='li'
                                  >
                                    {' '}
                                    Picky Eater Poor Eating Habits
                                  </Typography>
                                ) : el === 'EatsWell' ? (
                                  <Typography
                                    variant='h5'
                                    fontWeight='400'
                                    component='li'
                                  >
                                    {' '}
                                    Eats Well
                                  </Typography>
                                ) : (
                                  ''
                                )
                              ) : (
                                ''
                              )}
                            </>
                          );
                        })
                      ) : (
                        <>
                          {' '}
                          <Typography color='textSecondary' component='span'>
                            {' '}
                            Diet:{' '}
                          </Typography>
                          Not available.
                        </>
                      )
                    ) : (
                      <>
                        {' '}
                        <Typography color='textSecondary' component='span'>
                          {' '}
                          Diet:{' '}
                        </Typography>
                        Not available.
                      </>
                    )}
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Typography color='textSecondary' component='span'>
                      Dietary Details:{' '}
                    </Typography>
                    {observation.detailsDiet
                      ? formatText(observation.detailsDiet)
                      : 'Not available.'}
                  </Box>
                </div>
                <div className='observationtabpanelContent'>
                  <Typography
                    className=''
                    color=''
                    variant='h5'
                    fontWeight='500'
                  >
                    {' '}
                    Excretion
                  </Typography>
                  <Box sx={{ width: '100%' }}>
                    <Typography color='textSecondary' component='span'>
                      {' '}
                      Urine:{' '}
                    </Typography>
                    {observation.selectedValueUrine
                      ? formatText(observation.selectedValueUrine)
                      : 'Not available.'}
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Typography color='textSecondary' component='span'>
                      Stool:{' '}
                    </Typography>
                    {observation.selectedValueStool
                      ? formatText(observation.selectedValueStool)
                      : 'Not available.'}
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Typography color='textSecondary' component='span'>
                      Details:{' '}
                    </Typography>
                    {observation.detailsExc
                      ? formatText(observation.detailsExc)
                      : 'Not available.'}
                  </Box>
                </div>
                <div className='observationtabpanelContent'>
                  <Typography
                    className=''
                    color=''
                    variant='h5'
                    fontWeight='500'
                  >
                    {' '}
                    Behavioral Concerns{' '}
                  </Typography>
                  <Box sx={{ width: '100%' }}>
                    <Typography color='textSecondary' component='span'>
                      Behavioral Concerns:{' '}
                    </Typography>
                    {observation.behavioralConcerns
                      ? formatText(observation.behavioralConcerns)
                      : 'Not available.'}
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Typography color='textSecondary' component='span'>
                      School:{' '}
                    </Typography>
                    {observation.school
                      ? formatText(observation.school)
                      : 'Not available.'}
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Typography color='textSecondary' component='span'>
                      Comments:{' '}
                    </Typography>
                    {observation.commentsSc
                      ? formatText(observation.commentsSc)
                      : 'Not available.'}
                  </Box>
                </div>
                <div className='observationtabpanelContent'>
                  <Typography
                    className=''
                    color=''
                    variant='h5'
                    fontWeight='500'
                  >
                    {' '}
                    Dental
                  </Typography>
                  <Box sx={{ width: '100%' }}>
                    <Typography color='textSecondary' component='span'>
                      Dental care:{' '}
                    </Typography>
                    {observation.dentalCare
                      ? formatText(observation.dentalCare)
                      : 'Not available.'}
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Typography color='textSecondary' component='span'>
                      Comments:{' '}
                    </Typography>
                    {observation.detailsD
                      ? formatText(observation.detailsD)
                      : 'Not available.'}
                  </Box>
                </div>
              </div>
            </TabPanel>
          ) : observation.selectedVisitType === 'Clinical Summary' ? (
            <TabPanel value={value} index={0} component='div'>
              <div ref={observationRef}>
                <Typography
                  className='panelHeading'
                  sx={{
                    marginTop: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                >
                  <img
                    src={VisitObservationIcon}
                    className='tabIcon'
                    alt='logoImg'
                    style={{ margin: 0 }}
                  />
                  Visit Observations-{' '}
                  {observation.selectedVisitType
                    ? observation.selectedVisitType
                    : 'Not available.'}
                </Typography>

                <>
                  <div className='observationtabpanelContent'>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='500'
                    >
                      Medical Record
                    </Typography>
                    <Box>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='400'
                        component='ul'
                      >
                        {observation.medicalRecordsValues
                          ? Object.keys(observation.medicalRecordsValues).map(
                              (el) => {
                                return (
                                  <Typography
                                    className=''
                                    color=''
                                    variant='h5'
                                    fontWeight='400'
                                    component='li'
                                  >
                                    {el} -{' '}
                                    {observation.medicalRecordsValues[el]
                                      ? observation.medicalRecordsValues[el]
                                      : 'Not available.'}
                                  </Typography>
                                );
                              }
                            )
                          : 'Not available.'}
                      </Typography>
                    </Box>
                  </div>
                  <div className='observationtabpanelContent'>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='500'
                    >
                      {' '}
                      Medication
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                      {Object.keys(
                        observation.clinicalSummaryMedicationData
                      ).map((item) => (
                        <Typography
                          variant='h5'
                          fontWeight='400'
                          component='li'
                        >
                          {item}
                          {item === 'other' &&
                            ' - ' +
                              observation.clinicalSummaryMedicationData.other}
                        </Typography>
                      ))}
                    </Box>
                  </div>
                  {/* <div className="observationtabpanelContent">
                    <Typography className="" color="" variant="h5" fontWeight="500">
                      {' '}
                      Medication
                    </Typography>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Anti-hypertensives
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.antihypertensives ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.antihypertensives)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Anti-diabetics
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.antiDiabetics ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.antiDiabetics)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Thyroid
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.thyroid ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.thyroid)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Blood thinners
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.bloodThinners ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.bloodThinners)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Antimicrobials
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.antimicrobials ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.antimicrobials)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Lipid lowering
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.lipidLowering ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.lipidLowering)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Migraine
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.migraine ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.migraine)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Respiratory
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.respiratory ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.respiratory)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Allergic Rhinitis
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.allergicRhinitis ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.allergicRhinitis)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Contraceptives
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.contraceptives ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.contraceptives)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span"></Typography>Not
                          available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Acid reflux
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.acidReflux ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.acidReflux)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span"></Typography>Not
                          available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Topical Steroids
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.topicalSteroids ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.topicalSteroids)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span"></Typography>Not
                          available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Analgesia
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.analgesia ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.analgesia)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Parkinsons
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.parkinsons ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.parkinsons)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Prostate
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.prostate ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.prostate)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Psychiatric
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.psychiatric ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.psychiatric)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Epilepsy
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.epilepsy ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.epilepsy)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>

                    <Box sx={{ width: '100%' }}>
                      <Typography color="textSecondary" component="span">
                        Injectable drugs
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      {observation.clinicalSummaryMedicationData?.injectableDrugs ? (
                        Object.entries(observation.clinicalSummaryMedicationData?.injectableDrugs)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography variant="h5" fontWeight="400" component="li">
                              {medicine}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color="textSecondary" component="span">
                            {' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>
                  </div> */}

                  <div className='observationtabpanelContent'>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='500'
                    >
                      Family History
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                      {observation.familyHistoryValues ? (
                        Object.entries(observation.familyHistoryValues)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography
                              variant='h5'
                              fontWeight='400'
                              component='li'
                            >
                              {medicine}
                              {medicine === 'other' &&
                                ' - ' + observation.familyHistoryValues.other}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color='textSecondary' component='span'>
                            {' '}
                            Family History:{' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>
                  </div>
                  <div className='observationtabpanelContent'>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='500'
                    >
                      Medical History
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                      {observation.medicationHistoryValues ? (
                        Object.entries(observation.medicationHistoryValues)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) => (
                            <Typography
                              variant='h5'
                              fontWeight='400'
                              component='li'
                            >
                              {medicine}
                              {medicine === 'other' &&
                                ' - ' +
                                  observation.medicationHistoryValues.other}
                            </Typography>
                          ))
                      ) : (
                        <>
                          {' '}
                          <Typography color='textSecondary' component='span'>
                            {' '}
                            Medical History:{' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>
                  </div>
                  {observation.isCheckMedicalHistoryOther && (
                    <div className='observationtabpanelContent'>
                      <Typography
                        className=''
                        color=''
                        variant='h5'
                        fontWeight='500'
                      >
                        Other Medical History
                      </Typography>
                      <Box sx={{ width: '100%' }}>
                        <Typography color='textSecondary' component='span'>
                          Other Medical History:{' '}
                        </Typography>
                        {observation.CheckMedicalHistoryOther
                          ? formatText(observation.CheckMedicalHistoryOther)
                          : 'Not available.'}
                      </Box>
                    </div>
                  )}
                  <div className='observationtabpanelContent'>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='500'
                    >
                      Surgical History
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        Surgical History:{' '}
                      </Typography>
                      {observation.surgicalHistory
                        ? formatText(observation.surgicalHistory)
                        : 'Not available.'}
                    </Box>
                  </div>

                  <div className='observationtabpanelContent'>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='500'
                    >
                      Obstetric History
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        Obstetric History:{' '}
                      </Typography>
                      {observation.obstetricHistory
                        ? formatText(observation.obstetricHistory)
                        : 'Not available.'}
                    </Box>
                  </div>

                  <div className='observationtabpanelContent'>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='500'
                    >
                      Screening Tests
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                      {observation.screeningTestsValues ? (
                        Object.entries(observation.screeningTestsValues)
                          .filter(([medicine, value]) => value)
                          .map(([medicine]) =>
                            medicine.endsWith('date') ? null : (
                              <Typography
                                variant='h5'
                                fontWeight='400'
                                component='li'
                              >
                                {medicine}{' '}
                                {observation.screeningTestsValues[
                                  `${medicine}_date`
                                ] && (
                                  <small style={{ color: 'gray' }}>
                                    <em>
                                      (
                                      {moment(
                                        observation.screeningTestsValues[
                                          `${medicine}_date`
                                        ]
                                      ).format('MMM, DD, YYYY')}
                                      )
                                    </em>
                                  </small>
                                )}
                              </Typography>
                            )
                          )
                      ) : (
                        <>
                          {' '}
                          <Typography color='textSecondary' component='span'>
                            {' '}
                            Screening Tests:{' '}
                          </Typography>
                          Not available.
                        </>
                      )}
                    </Box>
                  </div>
                </>
              </div>
            </TabPanel>
          ) : null}

          <TabPanel value={value} index={1} component='div'>
            <Typography
              className='panelHeading'
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <img
                src={AllergyIcon}
                className='tabIcon'
                alt='logoImg'
                style={{ margin: 0 }}
              />
              Allergy -{' '}
              {observation.selectedVisitType
                ? observation.selectedVisitType
                : 'Not available.'}
            </Typography>
            <div className='observationtabpanelContent'>
              <Typography className='' color='' variant='h5' fontWeight='500'>
                Allergy
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Allergen:{' '}
                </Typography>
                {allergy.allergenSelected
                  ? allergy.allergenSelected
                  : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Other allergen:{' '}
                </Typography>
                {allergy.allergenOtherExplain
                  ? allergy.allergenOtherExplain === 'none'
                    ? 'None'
                    : allergy.allergenOtherExplain
                  : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Reaction:{' '}
                </Typography>
                {allergy.reactionSelected
                  ? allergy.reactionSelected
                  : 'Not available.'}
              </Typography>
              <Box sx={{ width: '100%' }}>
                <Typography color='textSecondary' component='span'>
                  Notes:{' '}
                </Typography>
                {allergy.notes ? formatText(allergy.notes) : 'Not available.'}
              </Box>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2} component='div'>
            <Typography
              className='panelHeading'
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <img
                src={VaccineIcon}
                className='tabIcon'
                alt='logoImg'
                style={{ margin: 0 }}
              />
              Vaccination -{' '}
              {observation.selectedVisitType
                ? observation.selectedVisitType
                : 'Not available.'}
            </Typography>
            {vaccination &&
              vaccination?.length > 0 &&
              vaccination?.map((ele, i) => {
                return (
                  <div className='observationtabpanelContent'>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='500'
                    >
                      Vaccination&nbsp;{Number(i) + 1}{' '}
                    </Typography>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='400'
                    >
                      <Typography color='textSecondary' component='span'>
                        Vaccination:{' '}
                      </Typography>
                      {ele.vaccination ? ele.vaccination : 'Not available.'}
                    </Typography>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='400'
                    >
                      <Typography color='textSecondary' component='span'>
                        Dose:{' '}
                      </Typography>
                      {ele.dose ? ele.dose : 'Not available.'}
                    </Typography>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='400'
                    >
                      <Typography color='textSecondary' component='span'>
                        Batch Number:{' '}
                      </Typography>
                      {ele.batchNumber ? ele.batchNumber : 'Not available.'}
                    </Typography>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='400'
                    >
                      <Typography color='textSecondary' component='span'>
                        Route of Administrator:{' '}
                      </Typography>
                      {ele.routeOfAdministration
                        ? ele.routeOfAdministration
                        : 'Not available.'}
                    </Typography>
                    <Typography
                      className=''
                      color=''
                      variant='h5'
                      fontWeight='400'
                    >
                      <Typography color='textSecondary' component='span'>
                        Site Of injection:{' '}
                      </Typography>
                      {ele.siteOfInjection
                        ? ele.siteOfInjection
                        : 'Not available.'}
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                      <Typography color='textSecondary' component='span'>
                        Notes:{' '}
                      </Typography>
                      {ele.notes ? formatText(ele.notes) : 'Not available.'}
                    </Box>
                  </div>
                );
              })}
          </TabPanel>
          <TabPanel value={value} index={3} component='div'>
            <Typography
              className='panelHeading'
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <img
                src={ExamIcon}
                className='tabIcon'
                alt='logoImg'
                style={{ margin: 0 }}
              />
              Examination Findings -{' '}
              {observation.selectedVisitType
                ? observation.selectedVisitType
                : 'Not available.'}
            </Typography>
            <div className='observationtabpanelContent'>
              <Typography className='' color='' variant='h5' fontWeight='500'>
                Examination Findings
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  General:{' '}
                </Typography>
                {examination.general ? examination.general : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Skin:{' '}
                </Typography>
                {examination.skin ? examination.skin : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Head:{' '}
                </Typography>
                {examination.head ? examination.head : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Eyes:{' '}
                </Typography>
                {examination.eyes ? examination.eyes : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Ears:{' '}
                </Typography>
                {examination.ears ? examination.ears : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Nose:{' '}
                </Typography>
                {examination.nose ? examination.nose : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Throat:{' '}
                </Typography>
                {examination.throat ? examination.throat : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Mouth Teeth:{' '}
                </Typography>
                {examination.mouthTeeth
                  ? examination.mouthTeeth
                  : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Neck:{' '}
                </Typography>
                {examination.neck ? examination.neck : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Chest:{' '}
                </Typography>
                {examination.chest ? examination.chest : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Heart:{' '}
                </Typography>
                {examination.heart ? examination.heart : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Peripheral Pulses:{' '}
                </Typography>
                {examination.peripheralPulses
                  ? examination.peripheralPulses
                  : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Back:{' '}
                </Typography>
                {examination.back ? examination.back : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Abdomen:{' '}
                </Typography>
                {examination.abdomen ? examination.abdomen : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Genitalia:{' '}
                </Typography>
                {examination.genitalia
                  ? examination.genitalia
                  : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Extremeties:{' '}
                </Typography>
                {examination.extremeties
                  ? examination.extremeties
                  : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Neurological:{' '}
                </Typography>
                {examination.neurological
                  ? examination.neurological
                  : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Lymph Nodes:{' '}
                </Typography>
                {examination.lymphNodes
                  ? examination.lymphNodes
                  : 'Not available.'}
              </Typography>
              <Box sx={{ width: '100%' }}>
                <Typography color='textSecondary' component='span'>
                  Abnormal Findings:
                </Typography>
                {examination.abnormalFindings
                  ? formatText(examination.abnormalFindings)
                  : 'Not available.'}
              </Box>
            </div>
          </TabPanel>
          <TabPanel value={value} index={4} component='div'>
            <Typography
              className='panelHeading'
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <img
                src={GrowthIcon}
                className='tabIcon'
                alt='logoImg'
                style={{ margin: 0 }}
              />
              Growth Parameters & Vitals -{' '}
              {observation.selectedVisitType
                ? observation.selectedVisitType
                : 'Not available.'}
            </Typography>
            <div className='observationtabpanelContent'>
              <Typography className='' color='' variant='h5' fontWeight='500'>
                Growth Parameters & Vitals
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Weight:{' '}
                </Typography>
                {growth.weight ? growth.weight : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Height:{' '}
                </Typography>
                {growth.height ? growth.height : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Head Circumference:{' '}
                </Typography>
                {growth.headCir ? growth.headCir : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  BMI:{' '}
                </Typography>
                {growth.bmi || growth.bmi === 0 ? growth.bmi : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Temperature C:{' '}
                </Typography>
                {growth.tempC ? growth.tempC : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Pulse Rate (BPM):{' '}
                </Typography>
                {growth.pulseRate ? growth.pulseRate : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  Respiratory Rate:{' '}
                </Typography>
                {growth.respi ? growth.respi : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  BP:{' '}
                </Typography>
                {growth.bp ? growth.bp : 'Not available.'}
              </Typography>
              <Typography className='' color='' variant='h5' fontWeight='400'>
                <Typography color='textSecondary' component='span'>
                  LMP:{' '}
                </Typography>
                {growth.lmp ? growth.lmp : 'Not available.'}
              </Typography>
            </div>
          </TabPanel>
          {/* <TabPanel value={value} index={5} component="div">
                        <Typography className='panelHeading' sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '20px'
                        }}>
                            <img src={DocumentUploadIcon} className="tabIcon" alt="logoImg" style={{ margin: 0 }} />

                            Document Uploads
                            - {observation.selectedVisitType ? observation.selectedVisitType : 'Not available.'}
                        </Typography>
                        <div className="observationtabpanelContent" >
                      
                        </div>
                    </TabPanel> */}
          <TabPanel value={value} index={5} component='div'>
            <div ref={diagnosisRef}>
              <Typography
                className='panelHeading'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <img
                  src={DiagnosisIcon}
                  className='tabIcon'
                  alt='logoImg'
                  style={{ margin: 0 }}
                />
                Diagnosis & Plan -{' '}
                {observation.selectedVisitType
                  ? observation.selectedVisitType
                  : 'Not available.'}
              </Typography>
              <div className='observationtabpanelContent'>
                <Typography
                  className=''
                  color='textSecondary'
                  variant='h5'
                  fontWeight='500'
                >
                  {' '}
                  Diagnosis & Impression
                </Typography>
                <Box sx={{ width: '100%' }}>
                  {diagnosis.diagInformant
                    ? formatText(diagnosis.diagInformant)
                    : 'Diagnosis: Not available.'}
                </Box>
              </div>
              <div className='observationtabpanelContent'>
                <Typography
                  className=''
                  color='textSecondary'
                  variant='h5'
                  fontWeight='500'
                >
                  {' '}
                  Plan{' '}
                </Typography>
                <Box sx={{ width: '100%' }}>
                  {diagnosis.planInformant
                    ? formatText(diagnosis.planInformant)
                    : 'Plan: Not available.'}
                </Box>
                {/* <Typography className='' color="" variant="h5" fontWeight="400">Encouraged to not use walker and reassess with specialist if needed.
                                </Typography> */}
                <Typography className='' color='' variant='h5' fontWeight='400'>
                  Next visit:
                  {diagnosis.nextVisit ? diagnosis.nextVisit : 'Not available.'}
                </Typography>
              </div>
            </div>
          </TabPanel>
        </Box>
      </CardContent>
    </Card>
  );
};

export default VisitObservation;
