import axios from 'axios';
import {
    SHOW_SPINNER,
    HOSPITALLIST
} from '../constants';
import { URL } from '../../config';

export const getAllHospital = () => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.get(URL + 'hospital/all', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: HOSPITALLIST,
            data: response.data.data
            // data: { hospitalsList: [{ hospitalCode: '11122', hospitalName: 'NNHIS' }] }
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const addHospital = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'hospital/add', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const updateHospitalAction = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'hospital/update', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}

export const updateHospitalStatusAction = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_SPINNER,
            data: true
        });
        let token = sessionStorage.getItem('token')
        const response = await axios.post(URL + 'hospital/updateStatus', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });
        return response.data
    } catch (err) {
        dispatch({
            type: SHOW_SPINNER,
            data: false
        });

        return err.response
    }
}