import React, { useMemo } from 'react';
import { Box, MenuItem, Typography, Divider, Tooltip } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { deleteNotes, getPatientNotes } from '../../../../redux/notes/AsyncActions';
import NoteIcon from '@mui/icons-material/StickyNote2';
import { useLocation } from 'react-router';
import { showNotificationAction } from '../../../../redux/pushnotification/Action';
import { URL } from '../../../../config';
import { getNote } from '../../../../redux/notes/Action';

const NotesList = ({ editNoteHandler }) => {
    const dispatch = useDispatch()
    const notesAr = useSelector((state) => state.notesReducer.notes);
    const notesArr = useMemo(() => {
        if (notesAr && notesAr.length > 0) {
            return notesAr
        } else {
            return []
            // return [{ "note": "This is first note. This is first note. This is first note. This is first note. This is first note.", "subject": "Note", "createdBy": "EMR Admin", "userId": 1, "hospitalId": 1, "patientId": 20127, "createdAt": "12/1/23" }]
        }
    }, [notesAr])
    const { state } = useLocation();
    const { id } = state ? state : {};

    useEffect(() => {
        getPatientNoteHandler()
    }, [])

    const getPatientNoteHandler = async () => {
        let result = await dispatch(getPatientNotes({
            "patientId": id
        }))
        if (result.status === true) {
            dispatch(showNotificationAction(false, { type: '', content: '', origin: 'getPatientNotes true' }))
        } else if (result.status === 404) {
            dispatch(showNotificationAction(false, { type: '', content: '', origin: 'getPatientNotes 404' }))
        } else if (result && result.data && result.data.message && typeof result.data.message === 'string') {
            dispatch(showNotificationAction(true, { type: 'error', content: result.data.message, origin: 'getPatientNotes' }))
        } else {
            dispatch(showNotificationAction(true, { type: 'error', content: 'Something went wrong.', origin: 'getPatientNotes' }))
        }
    }
    const editNote = (noteEle) => {
        editNoteHandler(noteEle)
    }

    const deleteNote = async (noteId) => {
        let noteIndex = notesArr.findIndex(a => a.noteId === noteId)
        let result = await dispatch(deleteNotes({
            "noteId": noteId
        }))
        if (result && result.status === true) {
            let ar = [...notesArr]
            ar.splice(noteIndex, 1)
            dispatch(getNote([...ar]));
            //     // getPatientNoteHandler()
        }
    }

    return (
        <Box className='NotesList' >
            {
                notesArr.map((noteEle, i) => (
                    <Box key={i} >
                        <MenuItem
                            sx={{
                                pt: 1,
                                pb: 0,
                                borderRadius: '0px',
                            }}
                        >
                            <Box
                                sx={{
                                    ml: 2,
                                }}
                            >
                                <Typography
                                    sx={{
                                        // textAlign: 'right'
                                        // width: '140px',
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            textAlign: 'left'
                                        }}
                                    >
                                        <NoteIcon style={{
                                            width: 20, height: 20, marginRight: '2px', color: '#1990FC'
                                        }} />
                                        <Typography
                                            fontWeight="500"
                                            sx={{
                                                whiteSpace: 'break-spaces',
                                                fontSize: '0.875rem',  // width: '140px',
                                            }}
                                        >
                                            {noteEle.subject}
                                        </Typography>
                                    </Box>
                                    <Typography width='50px' component={'span'}>
                                        <Tooltip title='Edit note'>
                                            <span>
                                                <FeatherIcon icon="edit" style={{
                                                    width: 20, height: 20, marginLeft: '0px', color: '#1990FC', padding: '2px', textAlign: 'right'
                                                }} onClick={() => { editNote(noteEle) }} />
                                            </span>
                                        </Tooltip>
                                        <Tooltip title='Delete note'>
                                            <span>
                                                <FeatherIcon icon="delete" style={{
                                                    width: 20, height: 20, marginLeft: '0px', color: '#1990FC', padding: '2px', float: 'right'
                                                }} onClick={() => { deleteNote(noteEle.noteId) }} />
                                            </span>
                                        </Tooltip>
                                    </Typography>
                                </Typography>

                                <Typography
                                    // color="textSecondary"
                                    variant="h6"
                                    // noWrap
                                    fontWeight="400"
                                    sx={{
                                        whiteSpace: 'break-spaces',
                                        // width: '140px',
                                        mb: 2
                                    }}
                                >
                                    {noteEle.note}
                                </Typography>
                                {noteEle.fileName && <Typography
                                    // color="textSecondary"
                                    variant="h6"
                                    // noWrap
                                    fontWeight="400"
                                    sx={{
                                        textAlign: 'center'
                                        // width: '140px',
                                    }}
                                >
                                    <img src={URL + noteEle.fileName} alt="noteImg" style={{ width: '100%', border: '1px solid rgba(233, 99, 186, 0.1)' }} />
                                </Typography>}
                                <Box sx={{
                                    // textAlign: 'right'
                                    // width: '140px',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <Typography
                                        color="textSecondary"
                                        // variant="h6"
                                        // noWrap
                                        fontSize='12px'
                                        fontWeight="500"
                                        sx={{
                                            textAlign: 'right'
                                            // width: '140px',
                                        }}
                                    >
                                        {noteEle.createdBy}
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        // variant="h6"
                                        // noWrap
                                        fontSize='12px'
                                        fontWeight="400"
                                        sx={{
                                            textAlign: 'right'
                                            // width: '140px',
                                        }}
                                    >
                                        {noteEle.createdAt}
                                    </Typography>
                                </Box>

                            </Box>
                        </MenuItem>
                        <Divider
                            style={{
                                marginTop: 0,
                                marginBottom: 0,
                            }}
                        />
                    </Box>
                ))
            }
            {notesArr.length <= 0
                ? <Typography
                    fontWeight="500"
                    sx={{
                        whiteSpace: 'break-spaces',
                        fontSize: '0.875rem',  // width: '140px',
                    }}
                >
                    No data available.
                </Typography> : null}
        </Box >
    )
};

export default NotesList;
