import React, { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Card, CardContent, Toolbar, Button } from '@mui/material';
import { getAllGraphListOfPatient } from '../../../redux/patients/AsyncActions';
import { useEffect } from 'react';
import PageContainer from '../../container/PageContainer';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import GrowthIcon from '../../../assets/icons/GrowthIcon.png'

const GrowthParams = () => {
    const dispatch = useDispatch()

    const { state } = useLocation();
    const navigate = useNavigate();
    const { id, firstName, lastName } = state ? state : {};
    const [graphArr, setgraphArr] = useState([])

    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const rootRoute = useMemo(() => {
        if (userinfo && userinfo.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])
    useEffect(() => {
        // setSelectedFiles(document.selectedFiles)
        localStorage.setItem('editable', false)
        if (id) {
            let data = {
                patientId: id
            }
            dispatch(getAllGraphListOfPatient(data)).then((result) => {
                if (result?.code === 200)
                    setgraphArr(result.data)
            }).catch((err) => {

            })

        }

    }, [dispatch, id])

    let breadtitle = (firstName && firstName[0] && (firstName[0].toUpperCase() + firstName.slice(1))) + ' ' +
        (lastName && lastName[0] && (lastName[0].toUpperCase() + lastName.slice(1)))

    const BCrumb = [
        {
            to: rootRoute + '/patient',
            title: 'Patients',
        },
        {
            onclick: () => { navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName } }) },
            title: breadtitle,
        },
        {
            title: 'Growth Charts',
        },
    ];

    let graphTypeOptions = [
        { title: 'Weight-for-length/height', subtitle: 'Birth to 5 years(z - scores)', value: 'WeightForLength' },
        { title: 'Height-for-age', subtitle: ' 5 to 19 years (z-scores)', value: 'Height' },
        { title: 'Weight-for-age', subtitle: 'Birth to 5 years (z-scores)', value: 'WeightForAgeBirthTo5' },
        { title: 'Weight-for-age', subtitle: '5 to 10 years (z-scores)', value: 'WeightForAge5To10' },
        { title: 'Length/height-for-age', subtitle: 'Birth to 5 years (z-scores)', value: 'Length/height' },
        { title: 'Head circumference-for-age', subtitle: 'Birth to 5 years (z-scores)', value: 'Head Circumference' },
        { title: 'BMI-for-age', subtitle: '5 to 19 years (z-scores)', value: 'BMI' },
    ]

    const getGraphHeading = (element) => {
        let graphType = graphTypeOptions.filter(f => f.value === element.graphName)
        if (graphType.length > 0) {
            return < >
                <Typography textAlign="left" fontSize="14px" fontWeight="500" component={'span'}>
                    {graphType[0].title}
                </Typography>
                <br />
                <Typography textAlign="left" color='textSecondary' fontSize="14px" fontWeight="500" sx={{ marginLeft: '22px' }}  >
                    {graphType[0].subtitle}
                </Typography>
            </>
        }

    }
    return (
        <PageContainer title="Growth Parameters" description="This is Patient Growth Parameters page" className="newPatientPage">
            <Breadcrumb
                title={
                    <Typography
                        fontWeight="500"
                        variant="h3"
                        sx={{
                            lineHeight: '1.235',
                            color: '#1990FC',
                            // fontSize:'20px'
                        }}
                    >
                        {breadtitle}
                    </Typography>
                }

                items={BCrumb}
            />
            <Card
                sx={{
                    p: {
                        xs: '0px',
                        sm: '0px',
                        lg: '0px',
                    },
                    width: '100%'
                }}
                className="newPatientCard"

            >
                <CardContent
                    sx={{
                        p: 0,
                    }}
                >
                    <Toolbar
                        sx={{
                            pr: { xs: 2, sm: 2 },
                            pl: { sm: 2 },
                            // ...(numSelected > 0 && {
                            bgcolor: (theme) => '#1990FC'
                            // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            // }),
                        }}
                    >
                        <Typography sx={{
                            flex: '1 1 100%', display: 'flex',
                            alignItems: 'center',
                            gap: '20px'
                        }} variant="h6" id="newpatientTitle" component="span">
                            <img src={GrowthIcon} className="tabIcon" alt="logoImg" />
                            Growth Charts
                        </Typography>
                    </Toolbar>
                    <Box sx={{
                        width: '100%',
                        p: {
                            xs: '20px',
                            sm: '35px',
                            lg: '35px',
                        },
                        pt: {
                            xs: '10px',
                            sm: '10px',
                            lg: '10px',
                        }
                    }}
                        className="newPatientCardBox"
                    >
                        {graphArr.length > 0
                            ? <div className="">
                                {
                                    graphArr.map((element, i) => {
                                        return <div style={{
                                            display: 'flex',
                                            alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', margin: '15px'
                                        }}>
                                            <Typography component="li" >
                                                {getGraphHeading(element)}
                                            </Typography>
                                            <Button variant="contained" className='registerButton' sx={{ marginLeft: '30px' }}>
                                                <a href={`https://portal.3stripestech.com/${element.graphLink}`} target='blank' style={{ textDecoration: 'none', color: 'white' }}>
                                                    View
                                                </a>
                                            </Button>
                                        </div>
                                    })
                                }
                            </div>
                            : <Typography>No chart available</Typography>
                        }
                    </Box>
                </CardContent>
            </Card >
        </PageContainer >
    );
};

export default GrowthParams;
