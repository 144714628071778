import React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Paper,
    Card,
    CardContent,
    Typography,
    Tooltip,
    Grid,
    Button
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import FeatherIcon from 'feather-icons-react';
// import Papa from 'papaparse';

import '../Patient/Patient.css'
import { deletePatient, fetchEditedPatients, fetchPatients } from '../../redux/patients/AsyncActions';
import { useEffect } from 'react';


var arrayData = [
    {
        "firstName": "User 1",
        "lastName": "User 1",
        "dob": "18/06/1988",
        "email": "user1@gmail.com",
        "phone": "8657442223",
        "patientId": 1,
    },
    {
        "firstName": "User 1",
        "lastName": "User 1",
        "dob": "18/06/1988",
        "email": "user1@gmail.com",
        "phone": "8657442223",
        "patientId": 2,
    },
    {
        "firstName": "User 1",
        "lastName": "User 1",
        "dob": "18/06/1988",
        "email": "user1@gmail.com",
        "phone": "8657442223",
        "patientId": 3,
    },
    {
        "firstName": "User 1",
        "lastName": "User 1",
        "dob": "18/06/1988",
        "email": "user1@gmail.com",
        "phone": "8657442223",
        "patientId": 4,
    }
]

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


const headCells = [
    {
        id: 'patientId',
        numeric: false,
        disablePadding: false,
        label: 'Patient ID',
    },
    {
        id: 'firstName',
        numeric: false,
        disablePadding: false,
        label: 'Patient Name',
    },
    {
        id: 'dob',
        numeric: false,
        disablePadding: false,
        label: 'D.O.B',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'phone',
        numeric: false,
        disablePadding: false,
        label: 'Phone',
    },
];




function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Typography color="#8E8D8D" fontWeight='500' variant="h6">
                                {headCell.label}
                            </Typography>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const CustomFilterList = ({ type, search, getPageInfo, props }) => {
    const dispatch = useDispatch()
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    // const [dense, setDense] = React.useState(false);
    const dense = false;
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tableData, setTableData] = React.useState([]);
    let navigate = useNavigate()

    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const rootRoute = useMemo(() => {
        if (userinfo && userinfo?.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])

    const patientsArr = useSelector((state) => state.patientReducer.patients);
    const editedpatientsArr = useSelector((state) => state.patientReducer.editedpatients);
    const totalPatient = useSelector(state => state.patientReducer.totalPatient);
    const hospitalSelectedState = useSelector((state) => state.HospitalReducer.hospitalSelected)

    const clinicalVisitData = useSelector(state => state.ReportReducer.getClinicalSummaryVisitData)
    const firstVisitData = useSelector(state => state.ReportReducer.getFirstVisitData)
    const followUpVisitData = useSelector(state => state.ReportReducer.getFollowUpVisitData)

    const rows = useMemo(() => {
        if (type === "all") {
            return patientsArr
        } else if (type === "users") {
            return editedpatientsArr
        } else {
            return []
        }
    }, [patientsArr, editedpatientsArr, type])

    useEffect(() => {
        setPage(0)
    }, [search])

    useEffect(() => {
        if (clinicalVisitData.length > 0) {
            setTableData(clinicalVisitData);
        }
    }, [clinicalVisitData])

    useEffect(() => {
        if (firstVisitData.length > 0) {
            setTableData(firstVisitData);
        }
    }, [firstVisitData])

    useEffect(() => {
        if (followUpVisitData.length > 0) {
            setTableData(followUpVisitData);
        }
    }, [followUpVisitData])

    function stableSort(array, comparator) {
        const stabilizedThis = tableData.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleDownloadCSV = () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += Object.keys(tableData[0]).join(",") + "\n";
        csvContent += tableData.map(row => Object.values(row).join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
    };

    const deletePatientHandler = async (patientId) => {
        let data = { patientId }
        let result = await dispatch(deletePatient(data))
        if (result && result.status) {
            if (userinfo?.role === 'admin') {
                if (hospitalSelectedState?.hospitalId)
                    dispatch(fetchPatients({ hospitalId: hospitalSelectedState?.hospitalId }));
            } else if (userinfo?.role !== 'admin') {
                if (userinfo?.hospitalId)
                    dispatch(fetchEditedPatients({ hospitalId: userinfo?.hospitalId }))
                dispatch(fetchPatients());
            }
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        getPageInfo(0, newRowsPerPage);
    };

    const getPaginatedData = () => {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return tableData.slice(startIndex, endIndex);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    // let filteredRows = rowsPerPage > 0
    //     ? rows.filter(f => f.firstName?.toUpperCase().includes(search.toUpperCase()) || f.lastName?.toUpperCase().includes(search.toUpperCase())
    //         || (f.firstName?.toUpperCase() + ' ' + f.lastName?.toUpperCase()).includes(search.toUpperCase()) || (f.patientId + ' ' + f.patientId).includes(search.toUpperCase()))
    //     : rows


    return (
        <div className="patientlist">
            <Card
                sx={{
                    p: {
                        xs: '10px',
                        sm: '10px',
                        lg: '20px',
                    },
                    width: '100%'
                }}
            >
                <CardContent>

                    <div className='d-flex justify-content-between'>
                        <Typography sx={{
                            flex: '1 1 100%',
                            p: {
                                xs: '10px',
                                sm: '10px',
                                lg: '10px',
                            },
                            color: '#464646',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '20px'
                        }} variant="h4" id="" fontWeight="500" component="div">
                            Results
                        </Typography>

                        <Typography className='panelAddButton' style={{ marginLeft: '10px', width: 220, height: 16 }} component="div">
                            <Button variant="contained" color="primary" onClick={() => {
                                handleDownloadCSV();
                            }} >
                                <a href={'/#' + rootRoute + '/custom/reports'} style={{ color: 'white', textDecoration: 'none' }}> Download CSV</a>
                            </Button>
                        </Typography>
                    </div>

                    <Box>
                        <Paper sx={{ width: '100%', mb: 2 }}>
                            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                            <TableContainer sx={{
                                maxHeight: 'auto',
                            }}>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    stickyHeader aria-label="sticky table"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    <TableBody>
                                        {
                                            getPaginatedData().map((row, index) => {
                                                const isItemSelected = isSelected(row.name);
                                                // const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    row.firstName !== null && row.firstName !== 'null' && row.firstName !== 'undefined' && row.firstName?.trim() !== "" && row.firstName?.trim() !== undefined
                                                    && <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={index}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell onClick={(event) => {
                                                            handleClick(event, row.name);
                                                            navigate(rootRoute + '/patient/detail', { state: { id: row.patientId, firstName: row.firstName, lastName: row.lastName, dob: row.dob } })
                                                        }} >
                                                            <Typography color='#171717' variant="h6" fontWeight="500">
                                                                {row.patientId}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell onClick={(event) => {
                                                            // handleClick(event, row.name);
                                                            // navigate(rootRoute + '/patient/detail', { state: { id: row.patientId, firstName: row.firstName, lastName: row.lastName, dob: row.dob } })
                                                        }} >
                                                            <Typography color='#171717' variant="h6" fontWeight="500">
                                                            {
                                                               row.firstName + " " + row.lastName
                                                            }
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell onClick={(event) => {
                                                            // handleClick(event, row.name);
                                                            // navigate(rootRoute + '/patient/detail', { state: { id: row.patientId, firstName: row.firstName, lastName: row.lastName, dob: row.dob } })
                                                        }} >
                                                            <Typography color='#171717' variant="h6" fontWeight="500">
                                                                {row.dob}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell onClick={(event) => {
                                                            // handleClick(event, row.name);
                                                            // navigate(rootRoute + '/patient/detail', { state: { id: row.patientId, firstName: row.firstName, lastName: row.lastName, dob: row.dob } })
                                                        }} >
                                                            <Typography color='#171717' variant="h6" fontWeight="500">
                                                                {row.email}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell onClick={(event) => {
                                                            // handleClick(event, row.name);
                                                            // navigate(rootRoute + '/patient/detail', { state: { id: row.patientId, firstName: row.firstName, lastName: row.lastName, dob: row.dob } })
                                                        }} >
                                                            <Typography color='#171717' variant="h6" fontWeight="500">{row.phone}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {/* {rows.length === 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={headCells.length} >
                                                    <Typography variant="h6" fontWeight="600" justifyContent="center"  >
                                                        No data available.
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )} */}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={tableData.length}
                                rowsPerPage={10}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>

                </CardContent>
            </Card >
        </div>
    );
};

export default CustomFilterList;
