import {
    APPOINTMENTLIST,
} from '../constants';



export const setAppointmentList = (data) => ({
    type: APPOINTMENTLIST,
    data: data
});
