import React, { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Card, CardContent, Toolbar, Grid, Button } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { getAllUploadedDocuments } from '../../../redux/patients/AsyncActions';
import { useEffect } from 'react';
import PageContainer from '../../container/PageContainer';
import Breadcrumb from '../../../layouts/full-layout/breadcrumb/Breadcrumb';
import DocumentUploadIcon from '../../../assets/icons/DocumentUploadIcon.png'
import ViewImage from '../../../views/ViewImage/ViewImage';
import { URL as BASE_URL } from '../../../config';

const DocumentUploaded = () => {
    const dispatch = useDispatch()

    const { state } = useLocation();
    const navigate = useNavigate();
    const { id, firstName, lastName, visitId } = state ? state : {};
    const [documentsArr, setDocumentsArr] = useState([])
    const [viewImage, setViewImage] = useState({ open: false, imgUrl: "" })

    const userinfo = useSelector(state => state.AuthenticationReducer.userinfo)
    const rootRoute = useMemo(() => {
        if (userinfo && userinfo.role === 'admin') {
            return '/admin'
        } else {
            return ''
        }
    }, [userinfo])

    useEffect(() => {
        if (visitId) {
            dispatch(getAllUploadedDocuments(id)).then((result) => {
                if (result?.code === 200)
                    setDocumentsArr(result.data)
            }).catch((err) => {

            })
        }
    }, [dispatch, visitId, id])

    let breadtitle = (firstName && firstName[0] && (firstName[0].toUpperCase() + firstName.slice(1))) + ' ' +
        (lastName && lastName[0] && (lastName[0].toUpperCase() + lastName.slice(1)))

    const BCrumb = [
        {
            to: rootRoute + '/patient',
            title: 'Patients',
        },
        {
            onclick: () => { navigate(rootRoute + '/patient/detail', { state: { id, firstName, lastName } }) },
            title: breadtitle,
        },
        {
            title: 'Document Upload',
        },
    ];

    const debugBase64 = async (base64URL, originalName) => {
        var win = window.open("", originalName);
        win.document.write('<title>' + originalName + '</title><iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen ></iframe>');
        // const base64Response = await fetch(base64URL);
        // const blob = await base64Response.blob();
        // console.log(blob, base64Response);
        // //Build a URL from the file
        // const fileURL = URL.createObjectURL(blob);
        // console.log(fileURL);
        // //Open the URL on new Window
        // window.open(fileURL, 'pdf', '');
    }

    return (
        <PageContainer title="Document Uploaded" description="This is Document Uploaded page" className="newPatientPage">
            <Breadcrumb
                title={
                    <Typography
                        fontWeight="500"
                        variant="h3"
                        sx={{
                            lineHeight: '1.235',
                            color: '#1990FC',
                            // fontSize:'20px'
                        }}
                    >
                        {breadtitle}
                    </Typography>
                }
                items={BCrumb}
            />
            <Card
                sx={{
                    p: {
                        xs: '0px',
                        sm: '0px',
                        lg: '0px',
                    },
                    width: '100%'
                }}
                className="newPatientCard"
            >
                <CardContent sx={{ p: 0 }}  >
                    <Toolbar
                        sx={{
                            pr: { xs: 2, sm: 2 },
                            pl: { sm: 2 },
                            // ...(numSelected > 0 && {
                            bgcolor: (theme) => '#1990FC'
                            // alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            // }),
                        }}
                    >
                        <Typography sx={{
                            flex: '1 1 100%', display: 'flex',
                            alignItems: 'center',
                            gap: '20px'
                        }} variant="h6" id="newpatientTitle" component="span">
                            <img src={DocumentUploadIcon} className="tabIcon" alt="logoImg" />
                            Document Uploaded
                        </Typography>
                    </Toolbar>
                    <Box sx={{
                        width: '100%',
                        p: {
                            xs: '20px',
                            sm: '35px',
                            lg: '35px',
                        },
                        pt: {
                            xs: '10px',
                            sm: '10px',
                            lg: '10px',
                        }
                    }}
                        className="newPatientCardBox"
                    >
                        {
                            documentsArr.map((element, i) => {
                                return <div className="tabpanelContent" key={i}>
                                    <Grid container spacing={0} className="fileGrid" style={{ width: '100%', marginTop: '20px' }}>
                                        <Grid item xs={12} lg={12}>
                                            <Typography variant="h5" fontWeight="600" textAlign="left" >
                                                <Typography variant="h5" fontWeight="600" color="textSecondary" component="span">
                                                    Date: &nbsp;
                                                </Typography>
                                                {element.documents[0].createdAt}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {element.documents.map((doc, index) => {
                                        return <div key={index} style={{
                                            display: 'flex',
                                            alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%'
                                        }}>

                                            {doc.originalName.endsWith('.png') || doc.originalName.endsWith('.jpg') || doc.originalName.endsWith('.jpeg') || doc.originalName.endsWith('.gif') || doc.originalName.endsWith('.psd')
                                                ? <>
                                                    <Typography textAlign="center" fontSize="14px" fontWeight="500" component="div" sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                        {doc.exists
                                                            ? <img src={`data:image/png;base64,${doc.fileName}`} alt="doc" style={{
                                                                width: 20, height: 20, color: '#1990FC', margin: '0 10px'
                                                            }} />
                                                            : <img src={`${BASE_URL}${doc.fileName}`} alt="oldDoc" style={{
                                                                width: 20, height: 20, color: '#1990FC', margin: '0 10px'
                                                            }} />}
                                                        {doc.originalName}
                                                    </Typography>
                                                    {doc.exists ? <Button variant="contained" className='registerButton' sx={{ marginLeft: '30px', float: 'right' }} onClick={() => {
                                                        setViewImage({ open: true, imgUrl: `data:image/${doc.originalName?.split('.')[1]};base64,${doc.fileName}` })
                                                    }}>
                                                        View
                                                    </Button> :
                                                        <Button variant="contained" className='registerButton' sx={{ marginLeft: '30px', float: 'right' }} onClick={() => {
                                                            setViewImage({ open: true, imgUrl: `${BASE_URL}${doc.fileName}` })
                                                        }}>
                                                            View
                                                        </Button>}
                                                </>
                                                : doc.originalName.endsWith('.pdf')
                                                    ? <>
                                                        <Typography textAlign="center" fontSize="14px" fontWeight="500" component="div" sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}>
                                                            <FeatherIcon icon="file" style={{
                                                                width: 20, height: 20, color: '#1990FC', margin: '0 10px'
                                                            }} />
                                                            {doc.originalName}
                                                        </Typography>
                                                        {doc.exists ? <Button variant="contained" className='registerButton' sx={{ marginLeft: '30px', float: 'right' }}
                                                            onClick={() => debugBase64(`data:application/pdf;base64,${doc.fileName}`, doc.originalName)}
                                                        >
                                                            View
                                                        </Button> :
                                                            <Button variant="contained" className='registerButton' sx={{ marginLeft: '30px', float: 'right' }}>
                                                                <a href={`${BASE_URL}${doc.fileName}`} target='blank' download={doc.originalName}
                                                                    style={{ textDecoration: 'none', color: 'white' }}>
                                                                    {doc.fileName.endsWith('.pdf') || doc.fileName.endsWith('.png') || doc.fileName.endsWith('.jpg') || doc.fileName.endsWith('.jpeg') || doc.fileName.endsWith('.gif') || doc.fileName.endsWith('.psd') || doc.fileName.endsWith('.js') || doc.fileName.endsWith('.json') ? 'View' : 'Download'}
                                                                </a>
                                                            </Button>}
                                                    </> : <>
                                                        <Typography textAlign="center" fontSize="14px" fontWeight="500" component="div" sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}>
                                                            <FeatherIcon icon="file" style={{
                                                                width: 20, height: 20, color: '#1990FC', margin: '0 10px'
                                                            }} />
                                                            {doc.originalName}
                                                        </Typography>
                                                        {doc.exists ? <Button variant="contained" className='registerButton' sx={{ marginLeft: '30px', float: 'right' }}  >
                                                            <a href={`data:application/${doc.originalName?.split('.')[1]};base64,${doc.fileName}`}
                                                                target='blank' download={doc.originalName}
                                                                style={{ textDecoration: 'none', color: 'white' }}>
                                                                Download
                                                            </a>
                                                        </Button> :
                                                            <Button variant="contained" className='registerButton' sx={{ marginLeft: '30px', float: 'right' }}>
                                                                <a href={`${BASE_URL}${doc.fileName}`} target='blank' download={doc.originalName}
                                                                    style={{ textDecoration: 'none', color: 'white' }}>
                                                                    {doc.fileName.endsWith('.pdf') || doc.fileName.endsWith('.png') || doc.fileName.endsWith('.jpg') || doc.fileName.endsWith('.jpeg') || doc.fileName.endsWith('.gif') || doc.fileName.endsWith('.psd') || doc.fileName.endsWith('.js') || doc.fileName.endsWith('.json') ? 'View' : 'Download'}
                                                                </a>
                                                            </Button>}
                                                    </>
                                            }
                                        </div>
                                    })}
                                </div>
                            })
                        }


                        {(!documentsArr || documentsArr.length <= 0)
                            && <Typography>No document available.</Typography>}

                    </Box>
                </CardContent>
            </Card >
            <ViewImage viewImage={viewImage} setViewImage={setViewImage} />
            {/* <Dialog
                open={viewImage.open}
                TransitionComponent={Transition}
                onClose={() => {
                    setViewImage({ open: false, imgUrl: "" })
                }} fullWidth className="summaryVaccineCard" >
                <DialogContent>
                    <img src={viewImage.imgBASE_URL} style={{ maxWidth: '800px' }} />
                </DialogContent>
            </Dialog> */}
        </PageContainer >
    );
};

export default DocumentUploaded;
